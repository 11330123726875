
import { useAppDispatch } from "../../../../store/redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { Radio, RadioGroup } from "@mui/material";
import { SinglePaymentHandler, setPayment } from "../../../../store/Cart/ActionCart";
import InfoIcon from "@mui/icons-material/Info";
import PrimaryButton from "../../../../component/PrimaryButton";
import {useTranslation} from "react-i18next";
type props = {
    handleNext: () => void
}
const PaymentSelection = (props: props) => {
    const {t}=useTranslation()
    const paymentSelection = useSelector((state: RootState) => state.cartReducer.checkoutState.paymentSelection)
    const events = useSelector((state: RootState) => state.cartReducer?.cartItem.selectedCart);
    const userInfo = useSelector((state: RootState) => state.cartReducer?.checkoutState);
    const clientToken = localStorage.getItem('clientToken')
    const dispatch = useAppDispatch();
    const handleRadioButton = (event: any) => {
        dispatch(setPayment(event.target.value))
    }

    const handleSubmitPaymentSelection = () => {
        const payload:any = {
          carts: events.map((item) => item.id),
        };
      
        if (clientToken) {
          payload.guest_token = clientToken;
          payload.user_email = userInfo?.userData?.email;
          payload.user_full_name = userInfo?.userData?.name;
        }
      
        if (paymentSelection === '1') {
          dispatch(SinglePaymentHandler(payload))
            .then(() => {
              props.handleNext();
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          props.handleNext();
        }
      };
      
    return (
        <div className="mt-5">
{clientToken && <div className="flex items-center gap-3  mb-2 border border-primary-orange w-fit rounded-full px-3 py-2">
    <InfoIcon  className="!w-[20px] !h-[20px] cursor-pointer !fill-primary-orange" />  
    <p className="text-sm">{t('checkout.personalDetails.clientSplitInformation')}</p>
</div>}
            <RadioGroup
                className="bg-[#F3F3F4] rounded-lg shadow-md"
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                <div className="grid lg:grid-cols-2 grid-cols-1 justify-center p-10 gap-8 shadow-md">
                    <div className="bg-white w-full  flex justify-center items-center rounded-lg text-center h-[150px] px-5 shadow-md shadow-primary-orange/30 " >
                        <label className="flex justify-center items-center cursor-pointer" htmlFor="1">
                            <Radio
                                id="1"
                                checked={paymentSelection === "1"}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#FF913F', // Color when active (selected)
                                    },
                                }}
                                value="1"
                                onChange={handleRadioButton}
                            />
                            {t('checkout.pSelection.singlePayment')}
                        </label>

                    </div>
                    <div className="bg-white  w-full flex justify-center items-center rounded-lg text-center h-[150px] px-5 shadow-md shadow-primary-orange/30 " >
                        <label className="flex justify-center items-center cursor-pointer" htmlFor="2">
                            <Radio
                            disabled={clientToken ? true : false}
                                checked={paymentSelection === "2"}
                                id="2"
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#FF913F', // Color when active (selected)
                                    },
                                }}
                                value="2"
                                onChange={handleRadioButton}
                            />
                            {t('checkout.pSelection.separatePayment')}
                        </label>
                    </div>
                </div>

            </RadioGroup>
            <div className="w-full flex justify-start">
                <PrimaryButton

                    onClick={handleSubmitPaymentSelection}
                    className={` mt-10 flex w-1/2 py-2`}
                >
                    <span className="px-1">{t('checkout.nextStep')}</span>
                    <img src="/assets/svgs/common/white-arrow.svg" className="w-4" alt="Arrow" />
                </PrimaryButton>
            </div>
        </div>
    );
};
export default PaymentSelection;
