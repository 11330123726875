import SvgArrow from "../../../../assets/svgs/arrows/SvgArrow";
import { useHomeCategories } from "../../_hooks";
import CategoryEvents from "./Events";

const CategorySelection = () => {
  const {
    selectedCategoryId,
    displayedCategories,
    selectedLanguage,
    otherCategories,
    handleSelectCategory,
    othersOpen,
    handleToggleOthers,
    data,
    isLoading,
    selectedCategory,
    isFilterLoading,
  } = useHomeCategories();
  // if (displayedCategories.length < 1) return null;
  return (
    <>
      <section id="home-categories-selection" className="max-xl:hidden">
        <div className="single-grid border-b-2 border-primary-orange">
          <div
            className="single-grid-item h-[600px] w-full bg-primary-orange/75 bg-cover bg-fixed bg-no-repeat"
            style={{
              backgroundImage: selectedCategory?.thumbnail
                ? `url(${selectedCategory?.thumbnail})`
                : undefined,
            }}
          >
            <div className="grid h-full w-full place-items-center bg-gradient-to-b from-black/75 to-transparent">
              <div className="z-10 max-w-md text-center">
                <h2 className="mb-2 text-white">
                  {selectedCategory?.translations[selectedLanguage].title}
                </h2>
                {selectedCategory?.translations[selectedLanguage]
                  .description && (
                  <p className="text-lg text-white">
                    {
                      selectedCategory?.translations[selectedLanguage]
                        .description
                    }
                  </p>
                )}
              </div>
            </div>
          </div>
          <nav className="single-grid-item container flex items-end">
            <ul className="grid w-full grid-cols-5 gap-6">
              {displayedCategories.map(({ id, image_url, translations }) => {
                const title = translations[selectedLanguage].title;
                return (
                  <li
                    key={`home_category_${id}`}
                    className={`rounded-t-3xl ${
                      selectedCategoryId === id
                        ? "border-primary-orange bg-primary-orange/90"
                        : ""
                    }`}
                  >
                    <button
                      disabled={isLoading}
                      title={title}
                      type="button"
                      className={`flex h-full w-full items-center justify-center gap-2 px-2 py-4 text-white ${
                        isLoading ? "" : "hover-link"
                      }`}
                      onClick={() => handleSelectCategory(id)}
                    >
                      {image_url && (
                        <img
                          src={image_url}
                          alt={title}
                          className="h-10 w-10 object-contain"
                        />
                      )}
                      {title}
                    </button>
                  </li>
                );
              })}
              {otherCategories.length > 1 && (
                <li
                  className={`relative rounded-t-3xl ${
                    othersOpen
                      ? "border-primary-orange bg-primary-orange/90"
                      : "bg-primary-orange/25"
                  }`}
                >
                  <button
                    disabled={isLoading}
                    type="button"
                    title="Open categories"
                    onClick={handleToggleOthers}
                    className={`flex h-full w-full items-center justify-center gap-2 px-2 py-4 text-white ${
                      isLoading ? "" : "hover-link"
                    }`}
                  >
                    Others
                    <SvgArrow
                      className={`h-4 w-4 transition-transform duration-300 ${
                        othersOpen ? "-rotate-90" : ""
                      }`}
                    />
                  </button>
                  <ul
                    className={`absolute -bottom-0.5 -left-0.5 -right-0.5 z-40 box-content w-[calc(100%+4px)] max-w-[calc(100%+4px)] origin-top translate-y-full overflow-hidden rounded-b-2xl bg-white duration-200 ${
                      othersOpen ? "scale-y-100" : "scale-y-0"
                    }`}
                  >
                    {otherCategories.map(({ id, image_url, translations }) => {
                      const title = translations[selectedLanguage].title;
                      return (
                        <li
                          key={`home_category_${id}`}
                          className={
                            selectedCategoryId === id
                              ? "rounded-2xl bg-primary-orange text-white first-of-type:rounded-t-none last-of-type:rounded-b-none"
                              : ""
                          }
                        >
                          <button
                            className={`flex w-full items-center gap-2 px-4 py-3 ${
                              selectedCategoryId === id || isLoading
                                ? ""
                                : "hover-link"
                            }`}
                            type="button"
                            title={title}
                            disabled={isLoading || selectedCategoryId === id}
                            onClick={() => handleSelectCategory(id)}
                          >
                            {image_url && (
                              <img
                                src={image_url}
                                alt={title}
                                title={title}
                                className="h-10 w-10 object-contain"
                              />
                            )}
                            {title}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </section>
      <CategoryEvents
        events={data?.events}
        isLoading={isLoading}
        isFilterLoading={isFilterLoading}
      />
    </>
  );
};

export default CategorySelection;
