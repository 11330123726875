import ArrowedButton from "../../../../components/common/buttons/ArrowedButton";
import BasicInfo from "./components/basicInfo/_index";
import {
  useEventCreation,
  useEventCreationData,
  useEventCreationDraft,
  useEventCreationSaveDraftTimer,
  useEventUpdate,
} from "./_hooks";
import TypeCategory from "./components/TypeCategory";
import Media from "./components/media/_index";
import About from "./components/about/_index";
import FAQs from "./components/FAQs";
import EventType from "./components/EventType";
import Tabs from "./components/Tabs";
import Requests from "./components/Requests";
import Details from "./components/details/_index";

const EventCreationPage = () => {
  const { t, handleSubmit, isPending } = useEventCreation();
  useEventCreationData();
  useEventCreationDraft();
  const { eventId } = useEventUpdate();
  useEventCreationSaveDraftTimer();
  return (
    <>
      {!eventId && <Tabs />}
      <form
        id="event-creation-form"
        className="grid gap-10"
        onSubmit={handleSubmit}
      >
        <EventType />
        <BasicInfo />
        <TypeCategory />
        <Media />
        <About />
        <Details />
        <FAQs />
        <Requests />
        <ArrowedButton
          disabled={isPending}
          title="Submit"
          type="submit"
          className="mx-auto my-4 bg-primary-orange"
        >
          {t("businessProfile.eventCreation.send")}
        </ArrowedButton>
      </form>
    </>
  );
};

export default EventCreationPage;
