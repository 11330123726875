import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLanguage } from "./types";
import { TLang } from "../../types/language";
export const initialState: TLanguage = {
  selectedLanguage: "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<TLang>) {
      state.selectedLanguage = action.payload;
    },
  },
});
export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
