import { Link, SetURLSearchParams } from "react-router-dom";
import { TTicket } from "../../../../types/ticket";
import { TFunction } from "i18next";
import PrimaryButton from "../../../../component/PrimaryButton";
import EventLocation from "../../../../components/common/EventLocation";
import {
  convertPrices,
  getNextURL,
  turnEventIdToSlug,
} from "../../../../utils/functions";
import TicketModal from "./Modal";
import SvgLoadingInfinity from "../../../../assets/svgs/loading/SvgLoadingInfinity";
import SvgArrow from "../../../../assets/svgs/arrows/SvgArrow";
import { useTickets } from "../_hooks";
import { TLang } from "../../../../types/language";
import { TCurrencyAtom } from "../../../../types/currency";

const TicketsPage = () => {
  const {
    t,
    isLoading,
    data,
    searchParams,
    setSearchParams,
    selectedCurrency,
    selectedLanguage,
    handlePrevious,
    handleNext,
    page,
  } = useTickets();
  return (
    <section id="client-tickets" className="h-full">
      {isLoading ? (
        <div className="grid h-full place-content-center">
          <SvgLoadingInfinity className="h-24 w-24" />
        </div>
      ) : data?.success ? (
        data.data.data.length > 0 ? (
          <>
            <ul className="mb-4 grid gap-12">
              {data.data.data.map((ticket) => {
                return (
                  <li key={`client_ticket_${ticket.id}`}>
                    <TicketCard
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                      selectedCurrency={selectedCurrency}
                      ticket={ticket}
                      selectedLanguage={selectedLanguage}
                      t={t}
                    />
                  </li>
                );
              })}
            </ul>
            {data?.success &&
              (data.data.next_page_url || data.data.prev_page_url) && (
                <div className="grid w-full grid-cols-[1fr_1.5rem_1fr] items-center gap-1">
                  <div className="flex w-full justify-end">
                    {data.data.prev_page_url && (
                      <button
                        disabled={isLoading}
                        onClick={handlePrevious}
                        type="button"
                        className="[&:not(:disabled)]:hover-link grid h-6 w-6 place-items-center rounded-lg bg-primary-orange disabled:opacity-50"
                        title="Previous page"
                      >
                        <SvgArrow className="h-3 w-3 -translate-x-px rotate-90" />
                      </button>
                    )}
                  </div>
                  <div className="grid h-6 w-6 place-items-center rounded-lg bg-primary-orange">
                    <span className="aspect-square text-xs leading-none text-white">
                      {page}
                    </span>
                  </div>
                  <div>
                    {data.data.next_page_url && (
                      <button
                        disabled={isLoading}
                        onClick={handleNext}
                        type="button"
                        className="[&:not(:disabled)]:hover-link grid h-6 w-6 place-items-center rounded-lg bg-primary-orange disabled:opacity-50"
                        title="Next page"
                      >
                        <SvgArrow className="h-3 w-3 translate-x-px -rotate-90" />
                      </button>
                    )}
                  </div>
                </div>
              )}
          </>
        ) : (
          <p className="my-10 text-center">No tickets purchased yet</p>
        )
      ) : (
        <p className="my-10 text-center text-red-600">
          Something went wrong...
        </p>
      )}
      {searchParams.get("ticket-id") && <TicketModal />}
    </section>
  );
};
export default TicketsPage;

type TProps = {
  ticket: TTicket;
  selectedLanguage: TLang;
  t: TFunction<"translation", undefined>;
  selectedCurrency: TCurrencyAtom;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
};

const TicketCard = ({
  ticket: {
    event,
    ticket_status,
    paid_amount,
    status,
    user_email,
    unique_user_code,
    splitters,
    id,
  },
  selectedLanguage,
  t,
  selectedCurrency,
  searchParams,
  setSearchParams,
}: TProps) => {
  return (
    <article className="grid xl:grid-cols-[4fr_1fr]">
      <Link
        target="_blank"
        rel="noreferrer noopener nofollow"
        title={event.translations[selectedLanguage].title}
        to={getNextURL({
          nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
            event.translations.en.title,
            event.id,
          )}`,
        })}
        className={`hover-link mb-4 grid gap-6 rounded-xl p-3 xl:mb-0 xl:grid-cols-[1fr_3.5fr_3.5fr] ${
          ticket_status === "Done" ? "bg-green-50" : "bg-blue-50"
        }`}
      >
        <div className="container flex flex-col justify-center">
          <p
            className={
              ticket_status === "Done" ? "text-green-500" : "text-blue-400"
            }
          >
            {ticket_status}
          </p>
        </div>
        <div className="flex flex-wrap gap-x-6 rounded-2xl bg-white p-4">
          <img
            src={event.images[0].image_path.toString()}
            alt={event.translations[selectedLanguage].title}
            title={event.translations[selectedLanguage].title}
            className="h-28 w-28 rounded-2xl object-cover"
          />
          <div className="flex basis-60 flex-col justify-between py-4 font-bold">
            <EventLocation location={event.location} />
            <h4>{event.translations[selectedLanguage].title}</h4>
          </div>
        </div>
        <ul className="content-center rounded-2xl bg-white p-4">
          <li className="flex flex-wrap gap-4">
            <span className="basis-20">{status}</span>
            <p className="flex-grow">{user_email ?? unique_user_code}</p>
            <span>{convertPrices(paid_amount, selectedCurrency)}</span>
          </li>
          {splitters.length > 0 &&
            splitters.map((ticket) => {
              return (
                <li
                  key={`ticket_${id}_split_${ticket.id}`}
                  className="flex flex-wrap gap-4"
                >
                  <span className="basis-20">{ticket.status}</span>
                  <p className="flex-grow">
                    {ticket.user_email ?? ticket.unique_user_code}
                  </p>
                  <span>
                    {convertPrices(ticket.paid_amount, selectedCurrency)}
                  </span>
                </li>
              );
            })}
        </ul>
      </Link>
      <PrimaryButton
        onClick={() => {
          searchParams.set("ticket-id", id.toString());
          setSearchParams((_prev) => (_prev = searchParams));
        }}
        className="hover-link ml-auto h-fit w-fit self-center justify-self-center border border-primary-orange bg-white py-2 text-primary-navy"
        type="button"
        title={`View ${event.translations[selectedLanguage].title}`}
      >
        {t("userProfile.tickets.viewTicket")}
      </PrimaryButton>
    </article>
  );
};

// {t("userProfile.tickets.paid")}
// {t("userProfile.tickets.exploreMore")}
