import { Link } from "react-router-dom";
import { socialData } from "../../../constants/footer/social";
import { useTranslation } from "react-i18next";
import SvgEnvelope from "../../../assets/svgs/icons/SvgEnvelope";

export const Contact = () => {
  const { t } = useTranslation();
  return (
    <section id="footer-contact">
      <div className="grid gap-y-6 text-white">
        <div>
          <h3 className="mb-1 text-lg">{t("footer.contactUs.title")}</h3>
          <Link
            to="mailto:tripsupport@explorenearme.co.uk"
            className="hover-link flex w-max items-center gap-2 text-sm font-light"
          >
            <SvgEnvelope className="w-4" />
            tripsupport@explorenearme.co.uk
          </Link>
        </div>
        <div>
          <h3 className="mb-1 text-lg">{t("footer.contactUs.contactSeo")}</h3>
          <Link
            to="mailto:HQ@explorenearme.co.uk"
            className="hover-link flex w-max items-center gap-2 text-sm font-light"
          >
            <SvgEnvelope className="w-4" />
            HQ@explorenearme.co.uk
          </Link>
        </div>
        <div>
          <h3 className="mb-1 text-lg">{t("footer.contactUs.socialMedia")}</h3>
          <ul className="flex items-center gap-4">
            {socialData.map((social) => {
              return (
                <li
                  key={`footer_social_${social.name}`}
                  className="hover-link w-4 duration-500"
                >
                  <Link
                    to={social.path}
                    title={social.name}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      loading="lazy"
                      src={social.image}
                      title={social.name}
                      alt={social.name}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Contact;
