import { ComponentProps, FC } from "react";
import { TSvgComponent } from "../../types/utils/component";

const HeartFillSvg: FC<
  ComponentProps<TSvgComponent> & { filled?: boolean }
> = ({ filled = false, ...props }) => {
  return (
    <svg viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.90471 15.2285C9.69518 15.2285 16.8094 11.2763 16.8094 5.74316C16.8094 2.97662 14.438 1.03482 12.0666 1.00051C10.8809 0.983355 9.69518 1.39575 8.90471 2.58141C8.11424 1.39575 6.90802 1.00051 5.74282 1.00051C3.37141 1.00051 1 2.97662 1 5.74316C1 11.2763 8.11424 15.2285 8.90471 15.2285Z"
        fill={filled ? "#FF913F" : "none"}
        stroke="black"
        strokeWidth="0.6"
        strokeOpacity={filled ? "0.75" : "1"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeartFillSvg;
