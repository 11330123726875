import Banner from "./components/Banner";
import Family from "./components/Family";
import Flexibility from "./components/Flexibility";
import HowWeDiffer from "./components/HowWeDiffer";
import OurMission from "./components/OurMission";
import RapidlyGrowing from "./components/RapidlyGrowing";

const AboutPage = () => {
  return (
    <>
      <h1 hidden>About us</h1>
      <Banner />
      <RapidlyGrowing />
      <Flexibility />
      <Family />
      <HowWeDiffer />
      <OurMission />
    </>
  );
};

export default AboutPage;
