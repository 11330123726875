import { toast } from "sonner";
import { AxiosError } from "axios";
import { TLaravelError } from "../types/utils/laravel";
import { TModal } from "../types/modal";
import { TCurrency } from "../types/currency";

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export const toastFallback = (
  message?: string | undefined,
  status:
    | "error"
    | "success"
    | "info"
    | "warning"
    | "message"
    | "loading" = "error",
  fallback?: string,
) => {
  toast[status](message ? message : fallback ?? "Something went wrong...");
};

export const errorResponseToast = (err: AxiosError<TLaravelError>) => {
  toastFallback(err.response?.data.message);
};

export const convertPrices = (
  price: string | number,
  selectedCurrency:
    | TCurrency
    | {
        name: TCurrency["name"];
        symbol: TCurrency["symbol"];
        abbreviation: TCurrency["abbreviation"];
        exchange_rate: TCurrency["exchange_rate"];
      },
) => {
  if (selectedCurrency.abbreviation === "USD")
    return selectedCurrency.symbol + price;
  return (
    selectedCurrency.symbol +
    (
      (typeof price === "number" ? price : parseFloat(price)) *
      +selectedCurrency.exchange_rate
    ).toFixed(2)
  );
};

export const checkImageSize = (file: File, width: number, height?: number) => {
  const img = new Image();
  const promise: Promise<URL | false> = new Promise((resolve, reject) => {
    img.onload = () => {
      if (height) {
        resolve(
          img.naturalHeight < height && img.naturalWidth < width
            ? new URL(img.src)
            : false,
        );
      } else {
        resolve(
          img.naturalHeight < width && img.naturalWidth < width
            ? new URL(img.src)
            : false,
        );
      }
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
  return promise;
};

export const removeBodyBackground = (color?: string) => {
  document.body.style.backgroundImage = "none";
  if (color) document.body.style.backgroundColor = color;
};

export const toggleModal = (id: TModal) => {
  const modal = document.getElementById(`${id}-modal`) as HTMLDialogElement;
  if (modal.open) {
    modal.close();
    document.body.style.overflowY = "inherit";
    return false;
  } else {
    modal.showModal();
    document.body.style.overflowY = "hidden";
    return true;
  }
};

export const isModalOpen = (id: TModal) => {
  const modal = document.getElementById(`${id}-modal`) as HTMLDialogElement;
  if (modal?.open) {
    return true;
  } else {
    return false;
  }
};

export const getNextURL = ({
  session: token,
  nextRedirect,
}: { session?: string | null; nextRedirect?: string | null } = {}) => {
  let nextURL = process.env.REACT_APP_NEXT_URL;
  const storageToken = localStorage.getItem("token");
  if (nextRedirect) nextURL += nextRedirect;
  if (token) {
    nextURL += `?session=${token}`;
  } else if (storageToken) {
    nextURL += `?session=${storageToken}`;
  }
  return nextURL ?? window.location.href;
};

export const turnEventIdToSlug = (event: string, id: number | string) => {
  return (
    event.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "-e" + id
  );
};
