import React, { useState } from "react";
import CustomInput from "../../../../components/common/inputs/Input";
import Person2Icon from "@mui/icons-material/Person2";
import PrimaryButton from "../../../PrimaryButton";
import { useTranslation } from "react-i18next";
import {
  createNewPassword,
  setShowForgotPasswordContent,
  setShowResetPassword,
  setShowSignInContent,
  setShowVerificationEmailContent,
  setShowVerificationPassword,
  toggleVerificationModalPassword,
} from "../../../../store/Auth/ActionAuth";
import { useAppDispatch } from "../../../../store/redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

type SendRequestProps = {
  onClose?: () => void;
  onForgotPasswordClick?: () => void;
  open: boolean;
};

const ResetPassword: React.FC<SendRequestProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [initialValues] = useState({
    password: "",
    password_confirmation: "",
  });
  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t("passwordRequired"))
      .min(8, t("requiredCharacters"))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-.#?!@$%^&_*=()+]).{8,}$/,
        t("specialCharacter"),
      ),
    password_confirmation: Yup.string()
      .required(t("confirmationPassword"))
      .oneOf([Yup.ref("password"), ""], t("passwordMatch")),
  });
  const handleResetPassword = async (values: {
    password: string;
    password_confirmation: string;
  }) => {
    try {
      const email = localStorage.getItem("verificationEmail");
      const otp = localStorage.getItem("otp");
      if (email !== null && otp != null) {
        await dispatch(
          createNewPassword(
            values.password,
            values.password_confirmation,
            email,
            otp,
          ),
        );
        dispatch(setShowResetPassword(false));
        dispatch(setShowVerificationEmailContent(false));
        dispatch(setShowVerificationPassword(false));
        dispatch(toggleVerificationModalPassword(false));
        dispatch(setShowSignInContent(false));
        dispatch(setShowForgotPasswordContent(false));
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
      } else {
        console.error("An error occurred:", error);
      }
    }
  };

  return (
    <div className="pt-3">
      <div className="flex justify-center pb-5">
        <img
          loading="lazy"
          src="/assets/images/pages/black-logo.png"
          className="w-70 h-10"
        />
      </div>
      <h3 className="text-2xl font-bold ">Enter New Password</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleResetPassword(values);
        }}
      >
        {({ handleChange, values, errors, touched }) => (
          <Form>
            <div>
              <CustomInput
                onChange={handleChange}
                type="password"
                value={values.password}
                placeholder="New Password"
                icon={<Person2Icon />}
                name="password"
                label=""
              />
            </div>
            <ErrorMessage
              name="password"
              component="div"
              className="ml-2 text-[12px] text-red-600"
            />
            <div>
              <CustomInput
                onChange={handleChange}
                type="password"
                placeholder="Confirm Password"
                icon={<Person2Icon />}
                value={values.password_confirmation}
                name="password_confirmation"
                label=""
              />
            </div>
            <div className="flex items-center justify-between gap-4 pt-5">
              <PrimaryButton className="gap-2">
                <p>Reset Password </p>
                <img
                  loading="lazy"
                  src="/assets/svgs/common/white-arrow.svg"
                  className="w-4"
                />
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
