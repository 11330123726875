import EventCard from "../../../../components/common/cards/EventCard";
import SvgVerified from "../../../../assets/svgs/icons/SvgVerified";
import { TEvent } from "../../../../types/event";
import { TPartner } from "../../../../types/partner";
import { TUser } from "../../../../types/user";

type TProps = {
  business: (TUser & { partner: TPartner }) | null;
  events: Array<TEvent> | null;
};

const Events = ({ business, events }: TProps) => {
  return (
    <section id="events-grid" className="section-margin container">
      <div className="mb-6 flex flex-wrap items-center gap-2">
        <span>Operator:</span>
        <article className="flex items-center gap-4">
          <div className="relative h-16 w-16 rounded-full bg-white p-4 shadow-xl">
            <img
              loading="lazy"
              src={business?.partner.logo}
              alt={business?.name}
              title={business?.name}
              className="h-full w-full object-contain"
            />
            <SvgVerified className="absolute right-0 top-0 h-6 w-6 -translate-y-1/4 translate-x-1/4 rounded-full bg-primary-orange p-1.5" />
          </div>
          <h6 className="text-lg font-bold">{business?.name}</h6>
        </article>
      </div>
      {events && events?.length > 0 ? (
        <ul className="events-grid">
          {events.map((event) => {
            return (
              <li key={`events_business_${event.id}`}>
                <EventCard event={event} />
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="my-10 text-lg">No events for this operator yet</p>
      )}
    </section>
  );
};

export default Events;
