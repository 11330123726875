export const partnersData = [
  {
    name: "klev",
    image: "/assets/svgs/common/partners/klev.svg",
    path: "https://klevlb.com",
  },
  {
    name: "aws",
    image: "/assets/svgs/common/partners/aws.svg",
    path: "https://aws.amazon.com/",
  },
  {
    name: "turitop",
    image: "/assets/svgs/common/partners/turitop.svg",
    path: "https://turitop.com",
  },
];
