import SvgLogoCompass from "../../assets/svgs/logo/SvgLogoCompass";

type TProps = {
  src: string;
  title: string;
  id: string;
};

const BannerImage = ({ src, title, id }: TProps) => {
  return (
    <section
      id={`${id}-banner`}
      className="section-margin lg:single-grid container relative items-end"
    >
      <div className="single-grid-item relative before:absolute before:inset-0 before:rotate-2 before:rounded-2xl before:border-2 before:border-primary-orange after:absolute after:inset-0 after:-rotate-3 after:rounded-2xl after:border-2 after:border-primary-navy">
        <img
          src={src}
          alt="Banner"
          className="max-h-[550px] w-full -rotate-1 rounded-2xl object-cover object-center"
        />
      </div>
      <h2 className="section-heading single-grid-item z-10 h-fit w-fit translate-y-1/4 rounded-lg bg-primary-orange px-6 py-2 text-white lg:translate-x-20">
        {title}
      </h2>
      <SvgLogoCompass className="absolute right-6 top-0 h-14 w-14 -translate-x-4 translate-y-4 rounded-full bg-white p-1 lg:h-32 lg:w-32" />
    </section>
  );
};

export default BannerImage;
