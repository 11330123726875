import { TSvgComponent } from "../../types/utils/component";

const ClockSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 14 13"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04546 0.239746C3.64026 0.239746 0.879791 3.00021 0.879791 6.40542C0.879791 9.81062 3.64026 12.5711 7.04546 12.5711C10.4507 12.5711 13.2111 9.81062 13.2111 6.40542C13.2111 3.00021 10.4507 0.239746 7.04546 0.239746ZM1.82836 6.40542C1.82836 3.52409 4.16413 1.18831 7.04546 1.18831C9.92679 1.18831 12.2626 3.52409 12.2626 6.40542C12.2626 9.28674 9.92679 11.6225 7.04546 11.6225C4.16413 11.6225 1.82836 9.28674 1.82836 6.40542ZM7.51974 3.87591C7.51974 3.61397 7.3074 3.40163 7.04546 3.40163C6.78352 3.40163 6.57118 3.61397 6.57118 3.87591V6.40542C6.57118 6.56399 6.65043 6.71208 6.78238 6.80004L8.67951 8.0648C8.89745 8.21009 9.19192 8.1512 9.33722 7.93325C9.48252 7.71531 9.42362 7.42084 9.20567 7.27554L7.51974 6.15159V3.87591Z"
      />
    </svg>
  );
};

export default ClockSvg;
