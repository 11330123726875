import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { prices } from "../../../constants/contribution";
import { useDispatch } from "react-redux";
import {
  CrowfundingPayment,
  subscription,
} from "../../../store/Crowdfunding/ActionCrowdfunding";
import {
  countriesAPI,
  getContributions,
} from "../../../networking/crowdfunding/crowdfunding";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TContributer } from "../../../types/contribution";
import StripeDonation from "./StripeDonation";

type TProps = {
  contributors: TContributer[] | undefined;
};
interface Country {
  name: string;
  code: string;
}

export const Contribution = ({ contributors }: TProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("monthly");
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [customAmount, setCustomAmount] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [country, setCountry] = useState({ name: "", code: "" });
  const [countries, setCountries] = useState<Country[]>([]);
  const [wordsOfSupport, setWordsOfSupport] = useState<string>("");
  const [isContributionSubmitted, setIsContributionSubmitted] =
    useState<boolean>(false);
  const [goal, setGoal] = useState("");
  const [remaining, setRemaining] = useState("");
  const [totalContributors, setTotalContributors] = useState("");
  const [raised, setRaised] = useState("");
  const [percentage, setPercentage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is Required"),
  });
  const [anonymousName, setAnonymousName] = useState<boolean>(false);

  const handlePriceSelection = (price: number) => {
    setSelectedPrice(price);
    setCustomAmount(price.toString());
  };
  const handleCustomAmountChange = (amount: string) => {
    setSelectedPrice(null);
    setCustomAmount(amount);
  };
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const handleContributeNow = async (formValues: any) => {
    try {
      const requestData = {
        amount: selectedPrice ? selectedPrice.toString() : customAmount,
        is_subscribed: activeTab === "monthly",
        words_of_support: wordsOfSupport,
        name: anonymousName ? "anonymous" : name,
        email: formValues.email,
        country: country.name,
        country_code: country.code,
      };

      if (activeTab === "monthly") {
        const response = await dispatch(subscription(requestData) as any);
        if (
          response &&
          response.success &&
          response.data &&
          response.data.client_secret
        ) {
          setIsContributionSubmitted(true);
        }
        return response;
      } else {
        const response = await dispatch(CrowfundingPayment(requestData) as any);
        if (
          response &&
          response.success &&
          response.data &&
          response.data.client_secret
        ) {
          setIsContributionSubmitted(true);
        } else {
          console.error(
            "Error: Client Secret is not available in the API response.",
          );
        }
      }
    } catch (error) {
      console.log("inn in errorrr");
    }
  };
  const handleShareButtonClick = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
  };
  const getContributionsData = async () => {
    try {
      const data = await getContributions();
      if (data) {
        setGoal(data.goal);
        setRemaining(data.remaining);
        setTotalContributors(data.total_contributors);
        setRaised(data.raised);
        setPercentage(data.percentage);
      }
    } catch (error) {
      console.error("Error fetching contribution data:", error);
    }
  };
  const getCountries = async () => {
    try {
      const data = await countriesAPI();
      if (data) {
        setCountries(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getContributionsData();
    getCountries();
  }, []);
  return (
    <section>
      <div className="rounded-xl bg-white text-[#1B1B1B] max-md:px-3 max-md:py-4 md:p-6">
        <h2 className="text-4xl font-bold max-md:text-center max-md:text-2xl">
          £{raised}
        </h2>
        <div className="mt-1 h-2.5 w-full rounded-full bg-[#EAECF0]">
          <div
            className="h-2.5 rounded-full bg-gradient-to-l from-secondary-orange to-[#FF8A3F]"
            style={{ width: `${parseFloat(percentage) * 100}%` }}
          ></div>
        </div>
        <div className="mt-5 grid grid-cols-3 justify-between md:gap-6">
          <p className="text-sm text-[#667085]">{t("crowdfunding.goal")}</p>
          <div></div>
          <p className="text-sm text-[#667085]">
            {t("crowdfunding.remaining")}
          </p>
        </div>
        <div className="grid-cols-3 max-md:flex max-md:justify-between md:grid md:gap-6">
          <p className="font-bold max-md:text-center max-md:text-[16px] md:gap-3">
            £{goal}
          </p>
          <p className="font-regular"> Raised of</p>
          <p className="font-bold max-md:text-[16px]">£{remaining}</p>
        </div>
        <p className="my-3 border-[1px] border-[#DCDCDC]/60"></p>
        <div className="items-center gap-2 md:grid md:grid-cols-2">
          <div className="max-md:flex max-md:justify-between max-md:gap-5 md:gap-6">
            <p className="text-[20px] font-bold max-md:text-[15px]">
              {" "}
              {t("crowdfunding.helpedRaised")}
            </p>
            <p className="text-lg font-light max-md:text-[15px] md:pt-2">
              ❤️ {totalContributors} Contributors
            </p>
          </div>
          <Link to="">
            <button
              className="mt-5 flex items-center justify-center gap-1 rounded-full bg-primary-orange py-3 text-white hover:bg-black max-md:w-full max-md:px-10 md:px-14"
              onClick={handleShareButtonClick}
            >
              {t("crowdfunding.share")}
              <img src="/assets/svgs/common/white-arrow.svg" alt="" />
            </button>
          </Link>
          {showAlert && (
            <div className="fixed bottom-0 right-0 mb-4 mr-4 rounded bg-primary-orange p-4 text-white">
              Link copied!
            </div>
          )}
        </div>
      </div>

      {isContributionSubmitted ? (
        <StripeDonation />
      ) : (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await handleContributeNow(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mt-4 rounded-xl bg-white p-6 text-[#1B1B1B]">
                <div className="pb-5">
                  <h2 className="text-lg font-bold md:text-2xl">
                    {t("crowdfunding.contribution")}
                  </h2>
                  <p className="text-black/40">
                    {" "}
                    {t("crowdfunding.contributionText")}
                  </p>
                </div>
                <div className="text-md mb-4 grid grid-cols-2 border-b-2 border-[#131C23]/10 text-center text-[#131C23]/50">
                  <button
                    className={`cursor-pointer ${
                      activeTab === "monthly" ? "font-bold text-[#131C23]" : ""
                    }`}
                    onClick={() => handleTabChange("monthly")}
                  >
                    Monthly
                    {activeTab === "monthly" && (
                      <div
                        className={`mt-1 cursor-pointer ${
                          activeTab === "monthly"
                            ? "h-1 w-full rounded-t-[50px] bg-[#FF913F] font-bold text-[#131C23]"
                            : "h-1 w-full rounded-t-2xl bg-transparent"
                        }`}
                      ></div>
                    )}
                  </button>
                  <button
                    className={`cursor-pointer ${
                      activeTab === "oneTime" ? "font-bold text-[#131C23]" : ""
                    }`}
                    onClick={() => handleTabChange("oneTime")}
                  >
                    One Time
                    {activeTab === "oneTime" && (
                      <div
                        className={`mt-1 cursor-pointer ${
                          activeTab === "oneTime"
                            ? "h-1 w-full rounded-t-[50px] bg-[#FF913F] font-bold text-[#131C23]"
                            : "h-1 w-full rounded-t-2xl bg-transparent"
                        }`}
                      ></div>
                    )}
                  </button>
                </div>
                <div className="grid gap-3 max-md:grid-cols-2 md:grid-cols-3">
                  {prices.map((price) => (
                    <label key={price} className="relative block">
                      <input
                        type="checkbox"
                        value={price}
                        checked={price === selectedPrice}
                        onChange={() => handlePriceSelection(price)}
                        className="absolute opacity-0"
                      />
                      <p
                        className={`cursor-pointer rounded-lg bg-[#131C23]/10 p-2 text-center text-black ${
                          price === selectedPrice ? "bg-primary-orange" : ""
                        }`}
                      >
                        £ {price}
                      </p>
                    </label>
                  ))}
                </div>
                <div className="pt-4 text-black">
                  <p className="font-bold text-primary-orange">
                    {t("crowdfunding.customAmount")}
                  </p>
                  <input
                    type="number"
                    placeholder={t("crowdfunding.enterAmount")}
                    value={customAmount}
                    onChange={(e) => handleCustomAmountChange(e.target.value)}
                    className="w-full rounded-xl border-2 border-[#E3E2E2]/30 p-2 text-sm font-light placeholder:text-black"
                  />
                </div>
                {!anonymousName && (
                  <div className="pt-4 text-black">
                    <p className="font-bold"> {t("crowdfunding.fullName")}</p>
                    <input
                      type="text"
                      placeholder={t("crowdfunding.fullName")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="my-2 w-full rounded-xl border-2 border-[#E3E2E2]/30 p-2 text-sm font-extralight placeholder:text-black"
                    />
                  </div>
                )}
                <div className="flex items-center pt-2">
                  <input
                    type="checkbox"
                    id="anonymousName"
                    checked={anonymousName}
                    onChange={() => setAnonymousName(!anonymousName)}
                  />
                  <label htmlFor="anonymousName" className="ml-2">
                    Contribute anonymously
                  </label>
                </div>
                {/*<p className="font-bold">{t("crowdfunding.email")}</p>*/}
                {/*<input type="email" placeholder={t("crowdfunding.enterEmail")} value={email}*/}
                {/*       onChange={(e) => setEmail(e.target.value)}*/}
                {/*       className="border-2 border-[#E3E2E2]/30 w-full rounded-xl text-sm p-2 font-light my-2 placeholder:text-black" />*/}
                <div className="pt-4 text-black">
                  <p className="font-bold">{t("crowdfunding.email")}</p>
                  <Field
                    name="email"
                    type="email"
                    placeholder={t("crowdfunding.enterEmail")}
                    className="my-2 w-full rounded-xl border-2 border-[#E3E2E2]/30 p-2 text-sm font-light placeholder:text-black"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <p className="font-bold">{t("crowdfunding.country")}</p>
                <div className="my-3 [&_#react-select-2-live-region+div>div]:rounded-full">
                  {/*<FormControl*/}
                  {/*  // sx={{*/}
                  {/*  //   border: "1px solid #E3E2E2",*/}
                  {/*  //   borderRadius: "10px",*/}
                  {/*  //   backgroundColor: "transparent",*/}
                  {/*  //   paddingLeft: "10px"*/}
                  {/*  // }}*/}
                  {/*  className="w-fit"*/}
                  {/*  variant="standard"*/}
                  {/*  fullWidth*/}
                  {/*>*/}
                  {/*<Select*/}
                  {/*  labelId="country-select-label"*/}
                  {/*  id="country-select"*/}
                  {/*  value={country.code}*/}
                  {/*  onChange={(e:any) => {*/}
                  {/*    const selectedCode = e.target.value;*/}
                  {/*    const selectedCountry = countries.find((country: Country) => country.code === selectedCode);*/}
                  {/*    setCountry({*/}
                  {/*      name: selectedCountry ? selectedCountry.name : "",*/}
                  {/*      code: selectedCode*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*  displayEmpty*/}
                  {/*  inputProps={{ "aria-label": "Without label" }}*/}
                  {/*>*/}
                  {/*  <MenuItem value="" disabled>*/}
                  {/*    {t("crowdfunding.enterCountry")}*/}
                  {/*  </MenuItem>*/}
                  {/*  {countries.map((country: any) => (*/}
                  {/*    <MenuItem key={country.code} value={country.code}>*/}
                  {/*      {country.name}*/}
                  {/*    </MenuItem>*/}
                  {/*  ))}*/}
                  {/*</Select>*/}
                  <Select
                    value={{ label: country.name, value: country.code }}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setCountry({
                          name: selectedOption.label,
                          code: selectedOption.value,
                        });
                      }
                    }}
                    placeholder={t("crowdfunding.country")}
                    options={[
                      // { label: "Enter Country", value: "" },
                      ...countries.map((country) => ({
                        label: country.name,
                        value: country.code,
                      })),
                    ]}
                  />
                  {/*</FormControl>*/}
                </div>
                <p className="font-bold">{t("crowdfunding.wordsOfSupport")} </p>
                <textarea
                  rows={4}
                  cols={4}
                  placeholder={t("crowdfunding.shareWords")}
                  value={wordsOfSupport}
                  onChange={(e) => setWordsOfSupport(e.target.value)}
                  className="my-2 w-full rounded-xl border-2 border-[#E3E2E2]/30 p-2 text-sm font-light placeholder:text-black"
                />
                {/*<button*/}
                {/*  className="bg-primary-orange text-white rounded-full md:px-14 max-md:px-10 py-3 my-5 flex items-center justify-center gap-1 w-full hover:bg-black"*/}
                {/*  onClick={handleContributeNow}*/}
                {/*>*/}
                {/*  {t("crowdfunding.contributeNow")}*/}
                {/*  <img src="/assets/svgs/common/white-arrow.svg" />*/}
                {/*</button>*/}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="my-5 flex w-full items-center justify-center gap-1 rounded-full bg-primary-orange py-3 text-white hover:bg-black max-md:px-10 md:px-14"
                >
                  {t("crowdfunding.contributeNow")}
                  <img src="/assets/svgs/common/white-arrow.svg" alt="" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {contributors && (
        <div className="mt-4 rounded-xl bg-white p-4 text-[#1B1B1B] max-md:my-5 max-md:block md:hidden">
          <h1 className="flex items-center gap-2 text-lg font-bold">
            {t("crowdfunding.recentContributors")}
          </h1>
          <div className="mt-4 grid gap-4 max-md:grid-cols-2 md:grid-cols-3">
            {contributors.length === 0 ? (
              <p className="font-bold text-primary-orange">
                No contributors yet
              </p>
            ) : (
              contributors.map(({ name, amount }) => (
                <div key={name}>
                  <p className="text-[#667085]">{name}</p>
                  <p>❤️ £ {amount}</p>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </section>
  );
};
export default Contribution;
