import Video from "./Video";
import Images from "./Images";

const Media = () => {
  return (
    <section id="create-event-media" className="dashboard-card gray">
      <Images />
      <Video />
    </section>
  );
};

export default Media;
