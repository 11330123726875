import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GenerateGuestToken, logoutUser } from "../../store/Auth/ActionAuth";
import { userProfileSideBar } from "../../constants/dashboard/UserProfileSideBar";
import { businessProfileSideBar } from "../../constants/dashboard/BusinessProfileSideBar";
import { useState } from "react";

export const useDashboardSidebar = (type: "business" | "user") => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      dispatch(GenerateGuestToken()).then((res) => {
        localStorage.setItem("clientToken", res);
        navigate("/");
      });
    });
  };
  const location = useLocation();
  const navigationLinks =
    type === "user" ? userProfileSideBar : businessProfileSideBar;
  const isPath = (path: string) => {
    if (location.pathname === "/event-list/pending") {
      return path === "/event-list/accepted";
    }
    if (location.pathname.startsWith("/event-creation")) {
      return path === "/event-creation/custom";
    }
    return path === location.pathname;
  };
  const [openMenu, setOpenMenu] = useState(false);
  const handleClose = () => {
    setOpenMenu((_prev) => (_prev = false));
  };
  const handleOpen = () => {
    setOpenMenu((_prev) => (_prev = true));
  };
  return {
    t,
    handleLogout,
    navigationLinks,
    isPath,
    openMenu,
    handleClose,
    handleOpen,
  };
};
