import { ComponentProps } from "react";
import { TSvgComponent } from "../../../types/utils/component";
import { twMerge } from "tailwind-merge";
import HeartsSvg from "../../../component/SvgComponents/HeartsSvg";

type TProps = { className?: ComponentProps<TSvgComponent>["className"] };

const HeartsCard = ({ className }: TProps) => {
  return (
    <div
      className={twMerge(
        "shadow-card absolute rounded-xl border bg-white p-1.5",
        className,
      )}
    >
      <HeartsSvg className="h-10 w-10" />
    </div>
  );
};

export default HeartsCard;
