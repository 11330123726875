import { AppDispatch } from "../store";
import {setLoading, setEventDetails, setReviews} from "./EventDetailsSlice";
import {
    createReviewAxios,
    getEventDetailsAxios,
    likeEventReviewAxios
} from "../../networking/eventDetails/eventDetails";
import { TEventDetails, TEventReviewsData } from "./types";
import { toggleBackDrop } from "../GlobalState/ActionGlobalState";

export const getEventDetails = (eventId: number,page:number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleBackDrop(true));

        const eventDetailsResponse = await getEventDetailsAxios(eventId,page);

        if (eventDetailsResponse) {
            const eventDetails: TEventDetails = {
                ...eventDetailsResponse,
            };
            dispatch(setEventDetails(eventDetails));
            const reviews: TEventReviewsData = {
                ...eventDetailsResponse.reviews,
            };
            dispatch(setReviews(reviews));
        } else {
            console.error("Event details not found.");
        }
    } catch (error) {
        console.error("Error:", error);
    } finally {
        dispatch(toggleBackDrop(false));
    }
};
export const createEventReview = (eventId: number , reviewData:any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));

        const eventReviewResponse = await createReviewAxios(eventId, reviewData);

        if (eventReviewResponse) {
            const reviews: TEventReviewsData = {
                ...eventReviewResponse.reviews,
            };
            dispatch(setReviews(reviews));
        } else {
            console.error("Event review not created.");
        }
    } catch (error) {
        console.error("Error:", error);
        throw error
    } finally {
        dispatch(setLoading(false));
    }
};
export const likeReview = (eventId: number, reviewId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
        const eventLikeReviewResponse = await likeEventReviewAxios(eventId, reviewId);
        if (eventLikeReviewResponse) {
            dispatch(setReviews(eventLikeReviewResponse.reviews));
        } else {
            console.error("Failed to like the review.");
        }
    } catch (error) {
        console.error("Error:", error);
    } finally {
        dispatch(setLoading(false));
    }
};