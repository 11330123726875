import { Link } from "react-router-dom";
import { useEventsTabs } from "../../_hooks";

const Tabs = () => {
  const { t, pathname } = useEventsTabs();
  return (
    <section id="events-tabs-selection" className="mb-4">
      <h3 className="section-title mb-4">
        {t("businessProfile.eventList.title")}
      </h3>
      <div className="flex gap-4">
        {pathname === "/event-list/accepted" ? (
          <span className="relative pb-3 font-bold after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full after:rounded-t-full after:bg-primary-orange">
            {t("businessProfile.eventList.EventListTabs.accepted")}
          </span>
        ) : (
          <Link
            to="/event-list/accepted"
            title="Accepted events"
            className="hover-link inline-block pb-3"
          >
            {t("businessProfile.eventList.EventListTabs.accepted")}
          </Link>
        )}
        {pathname === "/event-list/pending" ? (
          <span className="relative pb-3 font-bold after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full after:rounded-t-full after:bg-primary-orange">
            {t("businessProfile.eventList.EventListTabs.pending")}
          </span>
        ) : (
          <Link
            to="/event-list/pending"
            title="Pending events"
            className="hover-link inline-block pb-3"
          >
            {t("businessProfile.eventList.EventListTabs.pending")}
          </Link>
        )}
      </div>
    </section>
  );
};

export default Tabs;
