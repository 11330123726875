import React, { useEffect, useState } from "react";
import { TEventImage } from "../../../store/EventDetails/types";

type GalleryImagesProps = {
  images: TEventImage[];
  videoUrl: string | null;
};

const GalleryImages: React.FC<GalleryImagesProps> = ({ images, videoUrl }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleImages, setVisibleImages] = useState<TEventImage[]>([]);
  const [loadMoreCount, setLoadMoreCount] = useState(4);
  const [startOfArray, setStartOfArray] = useState(0);
  const openPopup = (src: string, index: number) => {
    setSelectedImage(src);
    setCurrentIndex(index);
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  const showNextImage = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex].image_path);
    setCurrentIndex(nextIndex);
  };
  const showPreviousImage = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    const previousIndex = (currentIndex - 1 + images?.length) % images?.length;
    setSelectedImage(images[previousIndex].image_path);
    setCurrentIndex(previousIndex);
  };

  const loadMoreImages = () => {
    setStartOfArray((prevCount) => prevCount + 4);
    setLoadMoreCount((prevCount) => prevCount + 4);
  };

  const showLessImages = () => {
    setStartOfArray((prevCount) => prevCount - 4);
    setLoadMoreCount((prevCount) => prevCount - 4);
  };

  useEffect(() => {
    if (images) {
      setVisibleImages(images.slice(startOfArray, loadMoreCount));
    }
  }, [images, loadMoreCount, startOfArray]);

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <>
      {videoUrl ? (
        <div className="col-span-2 grid cursor-pointer grid-rows-[200px_200px] gap-4 md:grid-cols-3 max-md:grid-cols-2">
          <iframe
            title="Youtube video"
            src={videoUrl}
            className="col-start-1 rounded-xl md:row-span-2 max-md:col-span-2"
          />
          {visibleImages.map((image, index) => (
            <div
              key={index}
              className={index === 0 ? "" : ""}
              onClick={() => openPopup(image.image_path, index)}
            >
              <img
                src={image.image_path}
                alt={index.toString()}
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid auto-rows-[235px] grid-cols-2 gap-4">
          {visibleImages.map((image, index) => (
            <div key={index} onClick={() => openPopup(image.image_path, index)}>
              <img
                src={image.image_path}
                alt={index.toString()}
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center gap-2 pt-4">
        {startOfArray > 0 && (
          <button
            onClick={showLessImages}
            className="rounded-md border-2 border-primary-orange px-3 hover:bg-primary-orange"
          >
            Previous
          </button>
        )}
        {startOfArray + 4 < images.length && (
          <button
            onClick={loadMoreImages}
            className="rounded-md border-2 border-primary-orange px-3 hover:bg-primary-orange"
          >
            Next
          </button>
        )}
      </div>
      {showPopup && (
        <div
          className="fixed inset-0 z-[60] grid place-items-center"
          onClick={closePopup}
        >
          <div className="relative grid h-full w-full place-items-center bg-black bg-opacity-60">
            <div className="grid h-max max-w-3xl place-content-center overflow-hidden rounded-2xl bg-white p-1">
              <img
                src={selectedImage}
                alt=""
                className="z-50 max-h-[600px] w-full max-w-full rounded-2xl object-contain"
              />
            </div>
            <button
              onClick={showPreviousImage}
              className="absolute left-[20%] block"
            >
              <img
                src="/assets/svgs/eventDetails/left-arrow.svg"
                className="z-70 relative h-10 w-10"
                alt="Left Arrow"
              />
            </button>
            <button
              onClick={showNextImage}
              className="absolute right-[20%] block"
            >
              <img
                src="/assets/svgs/eventDetails/right-arrow.svg"
                className="z-70 relative h-10 w-10"
                alt="Right Arrow"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default GalleryImages;
