import { useTranslation } from "react-i18next";
import { TContributer } from "../../../types/contribution";

type TProps = {
  contributors: Array<TContributer> | undefined;
};
export const VulnerableCommunities = ({ contributors }: TProps) => {
  const { t } = useTranslation();
  return (
    <section>
      <div className="flex justify-between">
        <h1 className="text-3xl text-white max-md:text-center">
          {t("crowdfunding.hereToSupport")}
          <br />
          {t("crowdfunding.vulnerable")}
        </h1>
        <img
          src="/assets/images/heart.png"
          className="h-14 w-14 max-md:hidden"
          alt=""
        />
      </div>
      <div className="text-md py-5 text-[#B1B1B1] max-md:text-center">
        <p className="">{t("crowdfunding.supportDescription_1")}</p>
        <p className="pt-4">{t("crowdfunding.supportDescription_2")}</p>
        <p className="pt-4">{t("crowdfunding.supportDescription_3")}</p>
        <p className="pt-4">{t("crowdfunding.supportDescription_4")}</p>
        <p className="pt-4">{t("crowdfunding.supportDescription_5")}</p>
        <p className="pt-4">{t("crowdfunding.supportDescription_6")}</p>
        <p className="pt-4">{t("crowdfunding.supportDescription_7")}</p>
      </div>
      <img src="/assets/images/vulnerable.png" className="my-5 w-full" alt="" />
      <div className="mt-4 rounded-xl bg-white p-4 text-[#1B1B1B] max-md:my-5 max-md:hidden">
        <h1 className="flex items-center gap-2 text-lg font-bold">
          {t("crowdfunding.recentContributors")}
        </h1>
        {contributors && (
          <div className="mt-4 grid gap-4 max-md:grid-cols-2 md:grid-cols-3">
            {contributors.length === 0 ? (
              <p className="font-bold text-primary-orange">
                No contributors yet
              </p>
            ) : (
              contributors.map(({ name, amount }) => (
                <div key={name}>
                  <p className="text-[#667085]">{name}</p>
                  <p>❤️ £ {amount}</p>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
};
export default VulnerableCommunities;
