import { Link } from "react-router-dom";
import SvgUpload from "../../../../assets/svgs/icons/SvgUpload";
import SvgLoadingInfinity from "../../../../assets/svgs/loading/SvgLoadingInfinity";
import { useInsuranceFile } from "../_hooks";

const BusinessInsurance = () => {
  const { handleChange, isPending, isSuccess, isIdle, user } =
    useInsuranceFile();
  return (
    <section id="profile-business-insurance" className="dashboard-card gray">
      <h3 className="section-title mb-4">Business Insurance</h3>
      <div className="single-grid mb-2 h-28 overflow-hidden rounded-xl bg-gray-300">
        <input
          type="file"
          hidden
          id="profile-business-file"
          name="business_insurance_url"
          onChange={handleChange}
        />
        <label
          className={`single-grid-item hover-link flex w-full cursor-pointer items-center justify-center gap-2 bg-gray-300 duration-500 ${
            !isIdle ? "-z-10 opacity-0" : "z-10 opacity-100"
          }`}
          htmlFor="profile-business-file"
        >
          Upload business insurance <SvgUpload className="h-6 w-6" />
        </label>
        <div className="single-grid-item grid place-content-center">
          {!isIdle &&
            (isPending ? (
              <SvgLoadingInfinity className="h-10 w-10" />
            ) : isSuccess ? (
              <p>File uploaded</p>
            ) : (
              <p className="text-red-700">Something went wrong...</p>
            ))}
        </div>
      </div>
      {user.business_insurance_url && (
        <Link
          to={user.business_insurance_url}
          target="_blank"
          rel="noopener noreferrer nofollow"
          title="View"
          className="hover-link inline-block text-primary-navy underline underline-offset-2"
        >
          View Document
        </Link>
      )}
    </section>
  );
};

export default BusinessInsurance;
