import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { twMerge } from "tailwind-merge";
import ArrowLineSvg from "../../../component/SvgComponents/ArrowLineSvg";

type TProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ArrowedButton = (props: TProps) => {
  return (
    <button
      type="button"
      {...props}
      className={twMerge(
        "hover-link group flex w-max items-center gap-2 rounded-full bg-white px-5 py-2.5 text-black",
        props.className,
      )}
    >
      {props.children}
      <ArrowLineSvg className="box-content h-3 w-3 rounded-full bg-black p-1.5 duration-300 group-hover:rotate-[360deg]" />
    </button>
  );
};

export default ArrowedButton;
