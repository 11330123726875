import { useHomeMap } from "../_hooks";
import CustomSelect from "../../../components/common/inputs/Select";
import { TLabel } from "../../../types/utils/component";
import SvgLoadingInfinity from "../../../assets/svgs/loading/SvgLoadingInfinity";
import CustomMap from "../../../components/common/map/Map";

const HomeMap = () => {
  const { countries, center, handleChange, events, isPending } = useHomeMap();
  return (
    <section id="home-map" className="section-margin container">
      <div className="mb-6 flex justify-between gap-4 max-lg:flex-col lg:items-end">
        <div>
          <h2 className="section-heading heading-bar mb-2 font-poppins-bold capitalize text-primary-navy after:bg-primary-navy">
            Things to do near you
          </h2>
          <p className="text-lg capitalize text-gray-500">
            Available Activities near your current location
          </p>
        </div>
        <CustomSelect
          placeholder="Explore other countries..."
          options={countries}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: "2rem",
            }),
            container: (base) => ({
              ...base,
              border: "1px solid #FF913F",
              borderRadius: "2rem",
              minWidth: "min(20rem, 100%)",
              width: "100%",
              flexGrow: 1,
            }),
          }}
          onChange={(e) => handleChange(e as TLabel)}
        />
      </div>
      <div className="relative">
        <CustomMap center={center ?? undefined} events={events} />
        <div
          className={`absolute inset-0 grid place-content-center rounded-2xl bg-black/90 duration-1000 ${
            isPending ? "z-10 opacity-100" : "-z-10 opacity-0"
          }`}
        >
          <SvgLoadingInfinity className="h-20 w-20" />
        </div>
      </div>
    </section>
  );
};

export default HomeMap;
