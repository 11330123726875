import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../store/redux";

interface FAQ {
    id: number;
    question: string;
    answer: string;
    event_id: number;
    created_at: string;
    updated_at: string;
    translations?: {
        [key: string]: {
            answer: string;
            question: string;
        };
    };
}

interface FAQSProps {
    faqs: FAQ[];
}

const FAQS: React.FC<FAQSProps> = ({faqs}) => {
    const [faqStates, setFaqStates] = useState<(boolean | undefined)[]>([]);
    const selectedLanguage = useAppSelector(state => state.languageReducer.selectedLanguage);

    const {t} = useTranslation();
    useEffect(() => {
        setFaqStates(faqs?.map(() => true) || []);
    }, [faqs]);

    const toggleCollapse = (index: number) => {
        setFaqStates((prevState) => {
            const newFaqStates = [...prevState];
            newFaqStates[index] = !newFaqStates[index];
            return newFaqStates;
        });
    };

    return (
        <div>
            <h3 className="text-[#131C23] font-bold text-2xl pt-4 mb-3">{t('faqs')}</h3>
            {faqs?.map((faq, index) => (
                <div key={index}>
                    <button
                        onClick={() => toggleCollapse(index)}
                        className="border-b py-4 focus:outline-none flex items-center justify-between w-full font-bold"
                    >
                        {faq.translations && faq.translations[selectedLanguage] ? faq.translations[selectedLanguage].question : faq.question}
                        <span
                            className={`transform ${
                                faqStates[index] ? 'rotate-0' : 'rotate-180'
                            } transition-transform duration-300 ml-2`}
                        >
                            <svg
                                className="w-4 h-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </span>
                    </button>
                    {faqStates[index] ? null : (
                        <div className="mt-4 font-medium">
                            <p>{faq.translations && faq.translations[selectedLanguage] ? faq.translations[selectedLanguage].answer : faq.answer}
                            </p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FAQS;
