import { TSvgComponent } from "../../../types/utils/component";

const SvgChecked: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.49902 4L3.16569 5.66667L7.33236 1.5"
        stroke="#008A2E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgChecked;
