import {
  IAgeType,
  ICategoryType,
  IEventInterest,
  IEventType,
} from "../../networking/BusinessDashboard/types";
import {
  BusinessDashboardState,
  eventCreationObject,
  IWeekdayData,
  TDocuments,
  TNotification,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: BusinessDashboardState = {
  isLoading: false,
  errors: {},
  eventData: {
    types: [],
    interests: [],
    ages: [],
    categories: [],
    commission: "",
    exclusive_price: 0,
    promo_price: 0,
  },
  createEventData: {
    title: "",
    language: "",
    address: "",
    duration: "",
    video_url: "",
    start_time: "",
    end_time: "",
    display_age: "",
    interests: [],
    ages: [],
    max_per_group: 5,
    currency_id: 1,
    event_types: [],
    categories: [],
    images: [],
    end_weekday: " ",
    start_weekday: " ",
    description: "",
    duration_type: "h",
    duration_option: "custom",
    highlights: [],
    includes: [],
    important_information: [],
    faqs: [],
    working_days: [],
    latitude: "",
    longitude: "",
    event_fees: [],
    quantity: "",
    quantity_per_duration: "",
    request_promote: 0,
    request_exclusive: 0,
  },
  eventList: {
    pending: [],
    accepted: [],
  },
  businessHistory: {
    current_page: 0,
    historyData: [],
    per_page: 0,
    total: 0,
    last_page: 0,
  },
  businessBalance: 0,
  eventViews: {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  },
  eventCreationPercentage: 100,
  documents: [],
  notifications: [],
  connectedToStripe: null,
};
export const BusinessDashboardSlice = createSlice({
  name: "BusinessDashboardSlice",
  initialState,
  reducers: {
    setTypes(state, action: PayloadAction<IEventType[]>) {
      state.eventData.types = action.payload;
    },
    setInterests(state, action: PayloadAction<IEventInterest[]>) {
      state.eventData.interests = action.payload;
    },
    setAges(state, action: PayloadAction<IAgeType[]>) {
      state.eventData.ages = action.payload;
    },
    setCommision(state, action: PayloadAction<string>) {
      state.eventData.commission = action.payload;
    },
    setCategories(state, action: PayloadAction<ICategoryType[]>) {
      state.eventData.categories = action.payload;
    },
    setExclusive(state, action: PayloadAction<number>) {
      state.eventData.exclusive_price = action.payload;
    },
    setPromotion(state, action: PayloadAction<number>) {
      state.eventData.promo_price = action.payload;
    },
    setEventData(state, action: PayloadAction<{ name: string; value: any }>) {
      state.createEventData = {
        ...state.createEventData,
        [action.payload.name]: action.payload.value,
      };
      localStorage.setItem("eventData", JSON.stringify(state.createEventData));
    },
    setHistoryData(state, action: PayloadAction<[]>) {
      state.businessHistory.historyData = action.payload;
    },
    setNotificaton(state, action: PayloadAction<TNotification[]>) {
      state.notifications = action.payload;
    },
    setBusinessBalance(state, action: PayloadAction<number>) {
      state.businessBalance = action.payload;
    },
    setEventViews(state, action: PayloadAction<IWeekdayData>) {
      state.eventViews = action.payload;
    },
    setDocuments(state, action: PayloadAction<TDocuments[]>) {
      state.documents = action.payload;
    },
    setConnectedToStripe(state, action: PayloadAction<number | null>) {
      state.connectedToStripe = action.payload;
    },
    setBusinessHistory(
      state,
      action: PayloadAction<{ name: string; value: any }>,
    ) {
      state.businessHistory = {
        ...state.businessHistory,
        [action.payload.name]: action.payload.value,
      };
    },
    setEventDataById(state, action: PayloadAction<eventCreationObject>) {
      state.createEventData = action.payload;
    },
    clearEventDataObject(state) {
      state.createEventData = {
        title: "",
        language: "",
        address: "",
        duration: "",
        video_url: "",
        start_time: "",
        end_time: "",
        display_age: "",
        interests: [],
        ages: [],
        max_per_group: 5,
        event_types: [],
        categories: [],
        images: [],
        currency_id: 1,
        description: "",
        end_weekday: " ",
        start_weekday: " ",
        duration_type: "h",
        duration_option: "custom",
        highlights: [],
        includes: [],
        important_information: [],
        faqs: [],
        working_days: [],
        latitude: "",
        longitude: "",
        event_fees: [],
        quantity: "",
        quantity_per_duration: "",
        request_promote: 0,
        request_exclusive: 0,
      };
    },
    setEventList(state, action: PayloadAction<{ name: string; value: any }>) {
      state.eventList = {
        ...state.eventList,
        [action.payload.name]: action.payload.value,
      };
    },
    setCreationEventPercentatge(state, action: PayloadAction<number>) {
      state.eventCreationPercentage = action.payload;
    },
    setErrors(state, action: PayloadAction<Object | null>) {
      state.errors = action.payload;
    },
    setErrorStatus(state, action: PayloadAction<Boolean>) {
      state.errors = action.payload;
    },
  },
});
export default BusinessDashboardSlice.reducer;
