import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type TProps = ComponentProps<typeof DatePicker>;

const CustomDatePicker = (props: TProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="D-M-YYYY"
        {...props}
        className={twMerge(
          "flex w-full justify-center rounded-lg bg-white !py-0.5 !pl-3",
          props.className,
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
