import { TSvgComponent } from "../../../types/utils/component";

const SvgBanner: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 16 18"
      fill="#888D90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.1501 0.666016H4.85014C1.70014 0.666016 0.916809 1.50768 0.916809 4.86602V14.2493C0.916809 16.466 2.13348 16.991 3.60848 15.4077L3.61681 15.3993C4.30014 14.6743 5.34181 14.7327 5.93348 15.5243L6.77514 16.6493C7.45014 17.541 8.54181 17.541 9.21681 16.6493L10.0585 15.5243C10.6585 14.7243 11.7001 14.666 12.3835 15.3993C13.8668 16.9827 15.0751 16.4577 15.0751 14.241V4.86602C15.0835 1.50768 14.3001 0.666016 11.1501 0.666016ZM10.2918 7.95768H5.70848C5.36681 7.95768 5.08348 7.67435 5.08348 7.33268C5.08348 6.99102 5.36681 6.70768 5.70848 6.70768H10.2918C10.6335 6.70768 10.9168 6.99102 10.9168 7.33268C10.9168 7.67435 10.6335 7.95768 10.2918 7.95768Z" />
    </svg>
  );
};

export default SvgBanner;
