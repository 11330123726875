type TProps = {
  image: string | null;
  title: string | null;
};

const Banner = ({ image, title }: TProps) => {
  return (
    <section
      id="events-banner"
      className="single-grid bg-gradient-to-b from-black to-white md:h-[400px]"
    >
      <img
        className="single-grid-item h-full w-full object-cover"
        src={image?.toString()}
        alt={title ?? ""}
      />
      {title && (
        <div className="single-grid-item section-padding bg-black/25">
          <div className="container h-full content-center capitalize">
            <h2 className="section-heading text-white">Test</h2>
          </div>
        </div>
      )}
    </section>
  );
};

export default Banner;
