import { Link, useLocation } from "react-router-dom";
import { navigationEventTypes } from "../_data";

const Tabs = () => {
  const { pathname } = useLocation();
  return (
    <>
      <h3 className="section-title mb-4 capitalize">Create activity</h3>
      <nav className="mb-8 border-b-2 border-b-[#8888]">
        <ul className="flex flex-wrap gap-x-20 gap-y-2">
          {navigationEventTypes.map(({ path, title }, index) => {
            return (
              <li
                key={`event_type_${index}`}
                className={`relative pb-2 after:absolute after:bottom-0 after:left-0 after:h-1 after:translate-y-1/2 after:rounded-t-full after:bg-primary-orange after:duration-200 ${
                  pathname === `/event-creation/${path}`
                    ? "after:w-full"
                    : "after:w-0"
                }`}
              >
                <Link
                  className="hover-link inline-block"
                  title={title}
                  to={`/event-creation/${path}`}
                >
                  {title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Tabs;
