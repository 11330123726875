import { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import { useAppDispatch } from "../../../../store/redux";
import { getBalanceStatistics } from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SvgCalendar from "../../../../assets/svgs/icons/SvgCalendar";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../../store/currency";
const Balance = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const today = new Date();
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 7);
  const [value, setValues] = useState<Date[]>([prevDate, today]);
  const balance = useSelector(
    (state: RootState) => state.businessDashboardReducer.businessBalance,
  );
  const currency = useAtomValue(currencyAtom);
  useEffect(() => {
    let object = {
      start_date: moment(value[0]).format("YYYY-MM-DD"),
      end_date: moment(value[1]).format("YYYY-MM-DD"),
    };
    dispatch(getBalanceStatistics(object));
    //eslint-disable-next-line
  }, []);
  const handleChangeTime = (time: any) => {
    setValues(time);
    if (time.length === 2) {
      let object = {
        start_date: moment(new Date(time[0])).format("YYYY-MM-DD"),
        end_date: moment(new Date(time[1])).format("YYYY-MM-DD"),
      };
      dispatch(getBalanceStatistics(object));
    }
  };
  return (
    <section id="overview-balance" className="dashboard-card gray">
      <h3 className="section-title mb-2">
        {t("businessProfile.overview.balance.title")}
      </h3>
      <div className="mb-8 flex items-center">
        <p>{t("businessProfile.overview.balance.period")}</p>
        &nbsp;
        <SvgCalendar className="mb-0.5 inline-block h-4 w-4" />
        &nbsp;
        <DatePicker
          range
          value={value}
          format="YYYY-MM-DD"
          dateSeparator="-"
          onChange={(val) => handleChangeTime(val)}
        />
      </div>
      <div className="mb-4 grid place-items-center rounded-3xl bg-white py-10">
        <span className="text-5xl">
          {currency.symbol}
          {(balance * +currency.exchange_rate).toFixed(2)}
        </span>
      </div>
      <h3 className="section-title mb-2">
        {t("businessProfile.overview.balance.balanceSummary")}
      </h3>
      <div className="flex items-center gap-2">
        <span className="box-content rounded-lg bg-primary-orange px-[18px] py-2 text-3xl text-white">
          {currency.symbol}
        </span>
        <p className="w-fit">
          {t("businessProfile.overview.balance.trackEarnings") +
            t("businessProfile.overview.balance.info")}
        </p>
      </div>
    </section>
  );
};

export default Balance;
