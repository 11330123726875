import { Dispatch, SetStateAction } from "react";
import CustomInput from "../../../../../components/common/inputs/NewInput";
import CustomTextArea from "../../../../../components/common/inputs/TextArea";
import SvgEdit from "../../../../../assets/svgs/icons/SvgEdit";
import SvgTrash from "../../../../../assets/svgs/icons/SvgTrash";
import SvgChecked from "../../../../../assets/svgs/icons/SvgChecked";
import { useEventCreationFAQ, useEventCreationFAQs } from "../_hooks";

const FAQs = () => {
  const { handleSave, faqs, setFaqs, t } = useEventCreationFAQs();
  return (
    <section id="event-creation-faqs" className="dashboard-card gray">
      <h2 className="section-title mb-4">
        {t("businessProfile.eventCreation.faqs.title")}
      </h2>
      <div className="mb-4 grid gap-2">
        <CustomInput id="faq-question" placeholder="Enter question" />
        <CustomTextArea id="faq-answer" placeholder="Write the answer" />
        <button
          type="button"
          title="Save"
          className="hover-link ml-auto text-green-500"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      {faqs.length > 0 && (
        <ul className="grid gap-4">
          {faqs.map((_, index) => {
            return (
              <li key={`faq_${index}`}>
                <FAQ index={index} faqs={faqs} setFaqs={setFaqs} />
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default FAQs;

type TFAQ = {
  index: number;
  faqs: { question: string; answer: string }[];
  setFaqs: Dispatch<SetStateAction<{ question: string; answer: string }[]>>;
};

const FAQ = ({ index, faqs, setFaqs }: TFAQ) => {
  const {
    handleDelete,
    handleEdit,
    handleInputChange,
    handleTextAreaChange,
    isEditing,
  } = useEventCreationFAQ(faqs, setFaqs, index);
  return (
    <article className="grid gap-2 rounded-xl bg-primary-gray/25 p-4 shadow-md">
      <div className="flex flex-wrap-reverse items-center justify-between">
        <CustomInput
          type="text"
          required
          tabIndex={isEditing ? 0 : -1}
          readOnly={!isEditing}
          name={`faqs[${index}][question]`}
          value={faqs[index]?.question}
          onChange={handleInputChange}
          className={
            isEditing
              ? undefined
              : "rounded-none bg-transparent p-0 font-bold focus:shadow-none focus-visible:shadow-none"
          }
        />
        <div className="flex items-center gap-2">
          <button
            title={isEditing ? "Save" : "Edit"}
            type="button"
            className="hover-link"
            onClick={handleEdit}
          >
            {isEditing ? (
              <SvgChecked className="h-5 w-5" />
            ) : (
              <SvgEdit className="h-5 w-5" />
            )}
          </button>
          <button
            title="Delete"
            type="button"
            className="hover-link"
            onClick={handleDelete}
          >
            <SvgTrash className="h-5 w-5" />
          </button>
        </div>
      </div>
      <CustomTextArea
        required
        tabIndex={isEditing ? 0 : -1}
        readOnly={!isEditing}
        name={`faqs[${index}][answer]`}
        value={faqs[index]?.answer}
        onChange={handleTextAreaChange}
        className={
          isEditing
            ? undefined
            : "h-fit rounded-none bg-transparent p-0 focus:shadow-none focus-visible:shadow-none"
        }
      />
    </article>
  );
};
