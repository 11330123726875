import axios from "axios";

export const getCountriesAPI = async () => {
  try {
    const response = await axios.get("countries");
    let data = response.data.data;
    return data;
  } catch (error) {
    throw error;
  }
};
