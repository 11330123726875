import ArrowedLink from "../../../../components/common/buttons/ArrowedLink";
import EventCard from "../../../../components/common/cards/EventCard";
import { useAppSelector } from "../../../../store/redux";
import { TEvent } from "../../../../types/event";
import { getNextURL } from "../../../../utils/functions";

type TProps = {
  events: Array<TEvent> | undefined;
  isLoading: boolean;
  isFilterLoading: boolean;
};

const CategoryEvents = ({ events, isLoading, isFilterLoading }: TProps) => {
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );
  return (
    <section id="home-category-events" className="section-margin container">
      {isLoading || isFilterLoading ? (
        <p>Loading...</p>
      ) : events ? (
        events.length > 1 ? (
          <ul className="events-grid mb-6 items-start">
            {events.map((event) => {
              return (
                <li
                  key={`category_${event.pivot.category_id}_event_${event.id}`}
                  className="h-full"
                >
                  <EventCard event={event} />
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No events in this category yet...</p>
        )
      ) : (
        <p className="mb-6 text-red-600">Something went wrong...</p>
      )}
      <div className="flex justify-center">
        <ArrowedLink
          to={getNextURL({
            nextRedirect: `${selectedLanguage}/categories`,
          })}
          title="All events"
          className="bg-primary-blue"
        >
          See All
        </ArrowedLink>
      </div>
    </section>
  );
};

export default CategoryEvents;
