import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TEventDetails, TEventReview, TEventReviewsData} from "./types";

type TInitial = {
    isLoading: boolean;
    eventDetails: TEventDetails;
    currentEventId: number | null;
    currentReviewPage: number;
    reviews: TEventReviewsData;
}
export const initialState: TInitial = {
    isLoading: false,
    eventDetails: {} as TEventDetails,
    currentEventId: null,
    currentReviewPage: 1,
    reviews: {
        current_page: 1,
        data: [] as TEventReview[],
        first_page_url: null,
        from: null,
        last_page: 1,
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: '',
        per_page: 0,
        prev_page_url: null,
        to: null,
        total: 0,
    },
}

const eventDetailsSlice = createSlice({
    name: 'eventDetails',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setEventDetails(state, action: PayloadAction<TEventDetails>) {
            state.eventDetails = action.payload;
        },
        setCurrentEventId(state, action: PayloadAction<number | null>) {
            state.currentEventId = action.payload;
        },
        setCurrentReviewPage(state, action: PayloadAction<number>) {
            state.currentReviewPage = action.payload;
        },
        setReviews(state, action: PayloadAction<TEventReviewsData>) {
            state.reviews = action.payload;
        },
        createReview(state, action: PayloadAction<TEventReviewsData>) {
            state.reviews = action.payload;
        },
        likeReview(state, action: PayloadAction<TEventReviewsData>) {
            state.reviews = action.payload;
        },
    },
});

export const { setLoading, setEventDetails,setCurrentEventId,
    setCurrentReviewPage, setReviews,createReview,likeReview} = eventDetailsSlice.actions;

export default eventDetailsSlice.reducer;
