import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import { getHistoryEvents } from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useAppDispatch, useAppSelector } from "../../../../store/redux";
import { TEvent } from "../../../../types/event";
import { Link } from "react-router-dom";
import EventLocation from "../../../../components/common/EventLocation";
import { getNextURL, turnEventIdToSlug } from "../../../../utils/functions";

const EventSelection = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [events, setEvents] = useState<Array<TEvent>>([]);
  const dispatch = useAppDispatch();
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  useEffect(() => {
    const date = new Date();
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (selectedDate) {
      dispatch(getHistoryEvents(selectedDate)).then((res) => {
        setEvents(res);
      });
    }
    //eslint-disable-next-line
  }, [selectedDate]);
  return (
    <section
      id="overview-event-selection"
      className="dashboard-card rounded-xl p-5 @container"
    >
      <h3 className="section-title mb-2">Event Selection</h3>
      <div className="grid gap-10 @2xl:grid-cols-[1fr_1.5fr]">
        <Calendar
          className="!rounded-3xl !shadow-lg"
          value={selectedDate}
          onChange={(event: any) => {
            setSelectedDate(moment(new Date(event)).format("YYYY-MM-DD"));
          }}
        />
        <div className="h-full w-full rounded-2xl bg-white p-4 shadow-lg">
          {events.length > 0 ? (
            <>
              <h4 className="mb-2">Active Events</h4>
              <ul className="grid justify-center gap-4 @xl:justify-stretch">
                {events.map(({ id, images, translations, location, total }) => (
                  <li key={`event_selection_${id}`}>
                    <Link
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      to={getNextURL({
                        nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
                          translations.en.title,
                          id,
                        )}`,
                      })}
                      title={translations[selectedLanguage].title}
                      className="hover-link inline-block w-full"
                    >
                      <article className="grid w-fit flex-wrap items-center gap-3 rounded-xl p-0.5 shadow-lg @xl:w-full @xl:grid-cols-[6rem_auto]">
                        <img
                          className="aspect-square h-full max-h-24 min-h-[200px] w-full rounded-xl object-cover @xl:min-h-0"
                          src={images[0].image_path.toString()}
                          alt={translations[selectedLanguage].title}
                          title={translations[selectedLanguage].title}
                        />
                        <div className="px-4 pb-4 @xl:p-1">
                          <EventLocation
                            location={location}
                            className="mb-2 text-xs"
                            svgClassName="h-3.5 w-3.5"
                          />
                          <h4 className="mb-1 text-sm">
                            {translations[selectedLanguage].title}
                          </h4>
                          <p className="text-sm">
                            Total: <span className="font-bold">{total}</span>
                          </p>
                        </div>
                      </article>
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>No events yet...</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default EventSelection;
