import { Link } from "react-router-dom";
import SvgCart from "../../../assets/svgs/icons/Cart";
import { useHeaderCart } from "../../../utils/hooks";

const Cart = () => {
  const { data } = useHeaderCart();
  return (
    <Link
      to={process.env.NEXT_PUBLIC_REACT_WEBSITE_URL ?? "/"}
      title="Cart"
      className="hover-link relative"
    >
      <SvgCart className="svg-fill h-5 w-5" />
      {data?.success && data.data > 0 && (
        <span className="badge absolute -right-0.5 -top-0.5 rounded-full bg-primary-orange px-1 text-[10px]">
          {data.data}
        </span>
      )}
    </Link>
  );
};

export default Cart;
