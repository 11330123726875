export const paymentSystemsData = [
    {
        id: "visa",
        image: "/assets/svgs/common/paymentSystems/visa.svg",
        path: "",
    },
    {
        id: "payment",
        image: "/assets/svgs/common/paymentSystems/masterCard.svg",
        path: "",
    },
    {
        id: "revolut",
        image: "/assets/svgs/common/paymentSystems/revolut.svg",
        path: "",
    },
    // {
    //     id: "tether",
    //     image: "/assets/svgs/common/paymentSystems/tether.svg",
    //     path: "",
    // },
    // {
    //     id: "klarna",
    //     image: "/assets/svgs/common/paymentSystems/klarna.svg",
    //     path: "",
    // },
    {
        id: "applePay",
        image: "/assets/svgs/common/paymentSystems/apple-pay.svg",
        path: "",
    },
    {
        id: "googlePay",
        image: "/assets/svgs/common/paymentSystems/google-pay.svg",
        path: "",
    },
];
