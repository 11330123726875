import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enTranslations from "./languages/en.json"
import frTranslations from "./languages/fr.json";
import ruTranslations from "./languages/ru.json";
import zhTranslations from "./languages/zh.json";
import deTranslations from "./languages/de.json";
import esTranslations from "./languages/es.json";
import arTranslations from "./languages/ar.json";
import noTranslations from "./languages/no.json";
import svTranslations from "./languages/sv.json";
import isTranslations from "./languages/is.json";
import ptTranslations from "./languages/pt.json";
import itTranslations from "./languages/it.json";
import jaTranslations from "./languages/ja.json";
import trTranslations from "./languages/tr.json";
import hiTranslations from "./languages/hi.json";
import elTranslations from "./languages/el.json";
import plTranslations from "./languages/pl.json";
import nlTranslations from "./languages/nl.json";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslations,
        },
        //French
        fr: {
            translation: frTranslations,
        },
        //Russian
        ru: {
            translation: ruTranslations,
        },
        //Chinese
        zh: {
            translation: zhTranslations,
        },
        //German
        de: {
            translation: deTranslations,
        },
        //Spanish
        es: {
            translation: esTranslations,
        },
        //Arabic
        ar: {
            translation: arTranslations,
        },
        //Norwegian
        no: {
            translation: noTranslations,
        },
        //Swedish
        sv: {
            translation: svTranslations,
        },
        //Icelandic
        is: {
            translation: isTranslations,
        },
        //Portuguese
        pt: {
            translation: ptTranslations,
        },
        //Italian
        it: {
            translation: itTranslations,
        },
        //Japanese
        ja: {
            translation: jaTranslations,
        },
        //Turkish
        tr: {
            translation: trTranslations,
        },
        //Hindi language
        hi: {
            translation: hiTranslations,
        },
        //Greek language
        el: {
            translation: elTranslations,
        },
        //Polish language
        pl:{
            translation:plTranslations
        },
        //Dutch language
        nl:{
            translation:nlTranslations
        }
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    ns: ['translation'],
});


export default i18n;
