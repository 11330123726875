import { AppDispatch } from "../store";
import { eventsSlice } from "./EventsSlice";
import {
  fetchGoogleMapEventsAxios,
  getCategoriesAxios,
  getEventsAxios,
  getInterestsAxios,
  getSearchEventsAPI,
  likeEventAxios,
  searchEventsApi,
} from "../../networking/events/events";
// import {getExclusiveEvents, getPromotionalEvents} from "../../networking/seeAllEvents/allEvents";
import { IEventCategory, IEventsData } from "../../networking/events/types";
import { TEventsFilters } from "./types";
import { setLoading } from "../EventDetails/EventDetailsSlice";
import { GlobalStateSLice } from "../GlobalState/GlobalStateSlice";
import {
  getExclusiveEvents,
  getPromotionalEvents,
} from "../../networking/seeAllEvents/allEvents";

export const getHomeEvents = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getEventsAxios();
    if (response !== null) {
      dispatch(eventsSlice.actions.setAllLargeEvents(response.large_events));
      dispatch(
        eventsSlice.actions.setCategoriesLargeEvents(
          response.large_events_categories,
        ),
      );
      dispatch(
        eventsSlice.actions.setAllPromotionalEvents(
          response.promotional_events,
        ),
      );
      dispatch(
        eventsSlice.actions.setCategoriesPromotionalEvents(
          response.promotional_events_categories,
        ),
      );
    }
  } catch (error) {
    dispatch(eventsSlice.actions.setAllLargeEvents([]));
  } finally {
  }
};
export const searchEvents =
  (
    eventType: string | undefined,
    searchData: TEventsFilters,
    categoryId: number | null,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
      let url =
        "events/" + (eventType === "large" ? "large-events" : "exclusive");
      if (categoryId !== 0 && categoryId) {
        url += `/${categoryId}`;
      }

      let response = await searchEventsApi(searchData, url);
      dispatch(eventsSlice.actions.setSearchedEvents(response));

      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    } catch (error) {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
      console.error("Error fetching events");
    }
  };
export const resetSearchedEvents = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(eventsSlice.actions.setSearchedEvents([]));
  } catch (error) {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
  }
};

export const getAllExclusiveEvents =
  (
    eventType: string,
    searchData: TEventsFilters,
    shouldFetchData: boolean = true,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
      let events: { all: IEventsData[]; categories: IEventCategory[] };
      events = await getExclusiveEvents();
      if (events.all.length > 0) {
        dispatch(eventsSlice.actions.setAllExclusiveEvents(events.all));
        dispatch(
          eventsSlice.actions.setCategoriesExclusiveEvents(events.categories),
        );
      }
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    } catch (error) {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
      console.error("Error fetching events");
    }
  };
export const getAllPromotionalEvents =
  (searchData: TEventsFilters, shouldFetchData: boolean = true, page: number) =>
  async (dispatch: AppDispatch) => {
    try {
      if (shouldFetchData) {
        const { data, next_page_url, last_page } = await getPromotionalEvents(
          searchData,
          page,
        );
        dispatch(eventsSlice.actions.setAllPromotionalEvents(data));
        dispatch(
          eventsSlice.actions.setPromotionalEventsNextPage(next_page_url),
        );
        dispatch(eventsSlice.actions.setLastPage(last_page));
      }
    } catch (error) {}
  };
export const fetchGoogleMapEventsAction =
  (latitude: number, longitude: number, filterParams: any) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await fetchGoogleMapEventsAxios(
        latitude,
        longitude,
        filterParams,
      );
      if (response !== null) {
        dispatch(eventsSlice.actions.setGoogleMapEvents(response));
      }
    } catch (error) {}
  };

export const likeEvent = (eventId: number) => async (dispatch: AppDispatch) => {
  try {
    const eventLikeResponse = await likeEventAxios(eventId);
    if (eventLikeResponse) {
      dispatch(eventsSlice.actions.setLikedEvent(eventId));
    }
  } catch (error) {
    console.error("Error:", error);
  } finally {
    dispatch(setLoading(false));
  }
};
export const clearError = () => async (dispatch: AppDispatch) => {
  dispatch(eventsSlice.actions.setAllLargeEvents([]));
};

export const getSearchEvents =
  (search: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await getSearchEventsAPI(search);
      dispatch(eventsSlice.actions.setSearchEvents(response));
    } catch (error) {
      console.error("Error fetching events");
    }
  };

export const getCategories = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getCategoriesAxios();
    dispatch(eventsSlice.actions.setCategories(response));
  } catch (error) {
    console.error("Error fetching categories");
  }
};
export const getInterests = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getInterestsAxios();
    dispatch(eventsSlice.actions.setInterests(response));
  } catch (error) {
    console.error("Error fetching categories");
  }
};
