import { TSvgComponent } from "../../../types/utils/component";

const SvgAward: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 13 17"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.3 2.90833V3.525L8.34997 1.81667C7.2333 1.175 5.67497 1.175 4.56664 1.81667L1.61664 3.53333V2.90833C1.61664 1.03333 2.64164 0 4.51664 0H8.39997C10.275 0 11.3 1.03333 11.3 2.90833Z" />
      <path d="M11.325 4.97487L11.2083 4.91654L10.075 4.26654L7.725 2.9082C7.00833 2.49154 5.90833 2.49154 5.19167 2.9082L2.84167 4.2582L1.70833 4.92487L1.55833 4.99987C0.0999999 5.9832 0 6.16654 0 7.74154V11.5082C0 13.0832 0.0999999 13.2665 1.59167 14.2749L5.19167 16.3499C5.55 16.5665 6 16.6582 6.45833 16.6582C6.90833 16.6582 7.36667 16.5582 7.725 16.3499L11.3583 14.2499C12.825 13.2665 12.9167 13.0915 12.9167 11.5082V7.74154C12.9167 6.16654 12.8167 5.9832 11.325 4.97487ZM8.78333 9.5832L8.275 10.2082C8.19167 10.2999 8.13333 10.4749 8.14167 10.5999L8.19167 11.3999C8.225 11.8915 7.875 12.1415 7.41667 11.9665L6.675 11.6665C6.55833 11.6249 6.36667 11.6249 6.25 11.6665L5.50833 11.9582C5.05 12.1415 4.7 11.8832 4.73333 11.3915L4.78333 10.5915C4.79167 10.4665 4.73333 10.2915 4.65 10.1999L4.13333 9.5832C3.81667 9.2082 3.95833 8.79154 4.43333 8.66654L5.20833 8.46654C5.33333 8.4332 5.475 8.31654 5.54167 8.21654L5.975 7.54987C6.24167 7.1332 6.66667 7.1332 6.94167 7.54987L7.375 8.21654C7.44167 8.32487 7.59167 8.4332 7.70833 8.46654L8.48333 8.66654C8.95833 8.79154 9.1 9.2082 8.78333 9.5832Z" />
    </svg>
  );
};
export default SvgAward;
