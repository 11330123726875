import { TSvgComponent } from "../../../types/utils/component";

const SvgShuriken: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM9.58333 10.625L9.325 10.5417L9.16667 11.0417L8.33333 13.5417L6.25 9.64167L6.16666 9.48334L3.33333 8.54167L5.14999 7.63334L6.66667 6.875L7.05833 6.69999L8.33333 2.70833L9.62499 5.54166L10.4167 7.29167L10.075 7.46667L13.3333 8.54167L9.58333 10.625Z" />
    </svg>
  );
};

export default SvgShuriken;
