import { useState } from "react";
import CustomSelect from "../../../../../../../components/common/inputs/Select";
import { TCurrency } from "../../../../../../../types/currency";
import { useCurrencies } from "../../../../../../../utils/hooks";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { eventCreationDraftAtom, eventUpdateAtom } from "../../../_atom";

const Currency = () => {
  const { t } = useTranslation();
  const { data: currenciesData } = useCurrencies();
  const [edit] = useAtom(eventUpdateAtom);
  const [draft] = useAtom(eventCreationDraftAtom);
  const [currency, setCurrency] = useState<TCurrency | null>(null);
  const { currencies } = currenciesData?.success
    ? currenciesData.data
    : { currencies: null };
  return (
    <CustomSelect
      required
      className="basis-40"
      containerClass="flex justify-between items-center gap-2"
      label={t("businessProfile.eventCreation.basicInfo.selectCurrency")}
      value={
        currency ??
        currencies?.find((c) => c.id === edit?.currency_id) ??
        (draft
          ? currencies?.find((c) => c.id === parseInt(draft?.currency_id))
          : undefined)
      }
      onChange={(e) => setCurrency(e as TCurrency)}
      id="currency"
      name="currency_id"
      getOptionLabel={(option) => (option as TCurrency).symbol}
      getOptionValue={(option) => (option as TCurrency).id.toString()}
      options={currencies ?? []}
    />
  );
};

export default Currency;
