import SvgLightning from "../../../../../../assets/svgs/icons/SvgLightning";
import CustomInput from "../../../../../../components/common/inputs/NewInput";
import SvgTrash from "../../../../../../assets/svgs/icons/SvgTrash";
import SvgEdit from "../../../../../../assets/svgs/icons/SvgEdit";
import SvgChecked from "../../../../../../assets/svgs/icons/SvgChecked";
import { useEventCreationCard } from "../../_hooks";

type TProps = {
  name: "highlights" | "includes" | "important_information";
};

const DetailsCard = ({ name }: TProps) => {
  const {
    array,
    handleRemove,
    handleChange,
    handleAdd,
    handleSave,
    isEditing,
    t,
  } = useEventCreationCard(name);
  return (
    <div className="dashboard-card bg-white duration-500 focus-within:shadow-primary-orange">
      <div className="mb-4 flex items-center gap-2">
        <Svg name={name} />
        <h4 className="text-lg font-bold capitalize">
          {t(name === "important_information" ? "information" : name)}
        </h4>
      </div>
      <div className="mb-4 grid gap-2">
        {array.map((item, index) => {
          return (
            <div
              key={`${name}_${index}`}
              className="flex items-center justify-between gap-2"
            >
              <CustomInput
                required
                readOnly={!isEditing}
                value={item ?? ""}
                name={`${name}[${index}]`}
                className="bg-primary-gray/25"
                containerClass="duration-200 flex-grow"
                onChange={(e) => handleChange(e, index)}
              />
              <button
                type="button"
                tabIndex={isEditing ? 0 : -1}
                style={{ transitionDuration: `${(index + 1) * 150}ms` }}
                className={`hover-link overflow-hidden ${
                  isEditing ? "w-5" : "w-0"
                }`}
                onClick={(e) => handleRemove(e, index)}
              >
                <SvgTrash className="h-5 w-5" />
              </button>
            </div>
          );
        })}
      </div>
      <div className="flex flex-wrap justify-between gap-4">
        {isEditing && (
          <button
            className="hover-link flex items-center gap-2 font-bold text-blue-600"
            type="button"
            title="Add"
            onClick={handleAdd}
          >
            <span className="grid aspect-square h-5 w-5 place-items-center rounded-full border border-blue-600 text-sm">
              +
            </span>
            Add
          </button>
        )}
        {array.length > 0 && (
          <button
            type="button"
            title={isEditing ? "Save" : "Edit"}
            className="hover-link ml-auto text-green-500"
            onClick={handleSave}
          >
            {isEditing ? "Save" : <SvgEdit className="my-0.5 h-5 w-5" />}
          </button>
        )}
      </div>
    </div>
  );
};

export default DetailsCard;

const Svg = ({ name }: TProps) => {
  switch (name) {
    case "highlights":
      return <SvgLightning className="h-5 w-5" />;
    case "includes":
      return (
        <SvgChecked className="h-5 w-5 rounded-full bg-blue-600 p-1 [&_path]:stroke-white" />
      );
    case "important_information":
      return (
        <SvgChecked className="h-5 w-5 rounded-full bg-green-600 p-1 [&_path]:stroke-white" />
      );
    default:
      return null;
  }
};
