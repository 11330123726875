import CustomInput from "../../../../../../components/common/inputs/NewInput";
import Modal from "../../../../../../components/layout/Modal";
import { TEventUpdate } from "../../../../../../types/event";
import { useEventCreationAge } from "../../_hooks";

const Age = () => {
  const {
    t,
    data,
    draft,
    edit,
    ages,
    handleChange,
    handleSubmit,
    handleOpenModal,
    newAge,
    handleUpdatePrices,
  } = useEventCreationAge();
  return (
    <div className="mb-8">
      <h6 className="mb-4">
        {t("businessProfile.eventCreation.basicInfo.selectAge")}
      </h6>
      <ul className="mb-4 grid grid-cols-[repeat(auto-fill,minmax(min(200px,100%),1fr))] gap-x-10 gap-y-4">
        {data &&
          ages.map((age, index) => {
            return (
              <li key={`event_creation_age_${index}`}>
                <input
                  type="text"
                  hidden
                  defaultValue={age.id ?? undefined}
                  name={`ages[${index}][id]`}
                />
                <CustomInput
                  data-id="age-input"
                  required
                  placeholder="Price"
                  title={age.label}
                  id={age.id ? `age-${age.id}` : `new-age-${index}`}
                  label={age.label}
                  name={`ages[${index}][price]`}
                  containerClass="flex justify-between w-full gap-2 items-center"
                  type="number"
                  defaultValue={
                    !draft
                      ? (age as TEventUpdate["ages"][0]).price
                      : age.id
                      ? draft?.ages?.find((a) => a.id === age.id.toString())
                          ?.price
                      : undefined
                  }
                  onChange={handleUpdatePrices}
                  blockClass="flex-shrink basis-32"
                  className="text-center"
                />
              </li>
            );
          })}
        <li className="flex items-center justify-center">
          <button
            className="hover-link flex items-center gap-2 text-primary-orange"
            type="button"
            title="Add age"
            onClick={handleOpenModal}
          >
            Add age&nbsp;
            <div className="box-content grid h-4 w-4 place-items-center rounded-full border border-primary-orange p-1">
              <span className="leading-none">+</span>
            </div>
          </button>
        </li>
      </ul>
      <CustomInput
        title="Age limit"
        label="Set Age Limit for Activity"
        containerClass="flex gap-4 items-center w-full"
        type="text"
        className="w-40 text-center"
        name="display_age"
        defaultValue={edit?.display_age ?? draft?.display_age}
        placeholder="Sample: 8-60"
      />
      <Modal id="event-creation-age">
        <h3 className="section-title mb-4">
          {t("businessProfile.eventCreation.basicInfo.other")}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 grid grid-cols-[2fr_1fr_1fr] gap-2">
            <CustomInput
              placeholder="Age"
              type="text"
              id="add-age-name"
              className="bg-secondary-gray"
              value={newAge?.label}
              onChange={(e) => handleChange(e, "label")}
            />
            <CustomInput
              placeholder="Min age"
              type="number"
              id="add-age-min"
              className="bg-secondary-gray"
              value={newAge?.min_age}
              onChange={(e) => handleChange(e, "min_age")}
            />
            <CustomInput
              placeholder="Max age"
              type="number"
              id="add-age-max"
              className="bg-secondary-gray"
              value={newAge?.max_age}
              onChange={(e) => handleChange(e, "max_age")}
            />
          </div>
          <button
            type="submit"
            className="ml-auto flex items-center gap-2 text-green-500"
          >
            Save
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default Age;
