import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import moment from "moment/moment";
import { mapStyle } from "./mapStyle";
import { useTranslation } from "react-i18next";

interface EventDetailsType {
  location: string;
  start_time: string;
  end_time: string;
}

const SingleEventMap: React.FC<{
  latitude: string;
  longitude: string;
  image: string;
  eventDetails: EventDetailsType;
}> = ({ latitude, longitude, image, eventDetails }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

  if (!googleMapsApiKey) {
    throw new Error(
      "Google Maps API key is not defined in your environment variables.",
    );
  }
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
    // libraries: ["places"],
  });

  const { t } = useTranslation();
  const defaultLocation = {
    lat: parseFloat(latitude || "0"),
    lng: parseFloat(longitude || "0"),
  };
  const [map, setMap] = useState<any | null>(null);

  const onLoad = (map: any | null) => {
    if (map) {
      setMap(map);
    }
  };
  const onMapClick = () => {
    if (window.google && map) {
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(url, "_blank");
    }
  };

  if (!isLoaded) {
    return <p>Loading...</p>;
  }
  const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

  const markerIcon = {
    url: image,
    scaledSize: new window.google.maps.Size(30, 30),
    fillColor: "transparent",
    fillOpacity: 1,
    strokeColor: "red",
    strokeWeight: 2,
    scale: 2,
    borderRadius: 100,
    borderColor: "#FF913F",
  };
  return (
    <div className="-z-40 w-full rounded-full md:h-[200px] ">
      <div className="absolute z-40 m-2 hidden rounded-2xl bg-white px-6 py-5 md:block">
        <h3 className="text-lg font-bold text-black">{t("eventLocation")}</h3>
        <div className="flex items-center space-x-2 pt-2 text-sm">
          <img
            loading="lazy"
            src="/assets/svgs/events/location.svg"
            alt="Location"
          />
          <p>{eventDetails.location}</p>
        </div>
        <div className="flex items-center space-x-2 pt-3 text-sm">
          <img
            loading="lazy"
            src="/assets/svgs/common/red-time.svg"
            alt="Time"
          />
          <p className="font-bold text-[#131C23]/40">{t("operatingTime")} </p>{" "}
          <span className="font-bold text-black">
            {moment(eventDetails.start_time, "HH:mm:ss").format("HH:mm A")} -{" "}
            {moment(eventDetails.end_time, "HH:mm:ss").format("HH:mm A")}
          </span>
        </div>
      </div>
      {isLoaded ? (
        <GoogleMap
          center={defaultLocation}
          zoom={10}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            border: "2px solid #FF913F",
          }}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: true,
            // streetViewControl: true,
            // disableDefaultUI: true,
            styles: mapStyle,
          }}
          onClick={onMapClick}
          onLoad={onLoad}
        >
          <Marker position={position} icon={markerIcon}>
            {image && (
              <img
                src={image}
                alt="Custom Marker"
                className="rounded-marker-image border-2-red-500 rounded-full "
              />
            )}
          </Marker>
        </GoogleMap>
      ) : (
        <>
          {" "}
          <p>Loading...</p>{" "}
        </>
      )}
    </div>
  );
};
export default SingleEventMap;
