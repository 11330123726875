import { TClientNotification } from "../../../types/client";
import CustomToggle from "../../../components/common/inputs/Toggle";
import {
  useProfileToggleNotification,
  useProfileNotification,
} from "../_hooks";

const Notifications = () => {
  const { data, isLoading, t } = useProfileNotification();
  return (
    <section id="profile-notifications" className="dashboard-card">
      <h3 className="section-title mb-4">
        {t("userProfile.profileSettings.notification.title")}
      </h3>
      {isLoading ? (
        <p>Loading...</p>
      ) : data?.success ? (
        data.data.length > 0 ? (
          <ul className="[&_li:last-child]:mb-2 [&_li:not(:last-child)]:mb-4">
            {data.data.map((notification) => {
              return (
                <li key={`profile_notification_setting_${notification.id}`}>
                  <NotificationBlock notification={notification} />
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No notification option added</p>
        )
      ) : (
        <p className="text-red-600">Something went wrong...</p>
      )}
    </section>
  );
};
export default Notifications;

const NotificationBlock = ({
  notification: { id, name, description, status },
}: {
  notification: TClientNotification;
}) => {
  const { data, isPending, mutate } = useProfileToggleNotification();
  return (
    <>
      <p className="text-sm">{description}</p>
      <div className="my-2 w-full rounded-xl bg-primary-gray/50 p-px"></div>
      <div className="flex items-center justify-between">
        <h4 className="font-bold">{name}</h4>
        <CustomToggle
          defaultChecked={status}
          onChange={(e) => {
            mutate(id);
            if (data?.success) e.target.checked = !e.target.checked;
          }}
          loading={isPending}
          id={`notification-${id}`}
        />
      </div>
    </>
  );
};
