import {
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  forwardRef,
  useState,
} from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  phoneChange?: ComponentProps<typeof PhoneInput>["onChange"];
  label?:
    | DetailedHTMLProps<
        HTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
      >["children"]
    | true;
  labelClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  containerClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  error?: string;
};

const CustomPhoneInput = forwardRef<HTMLInputElement, TProps>(
  function CustomPhoneInput(
    {
      labelClass,
      label,
      id,
      error,
      phoneChange,
      defaultValue,
      containerClass,
      ...props
    }: TProps,
    ref,
  ) {
    const [value, setValue] = useState(defaultValue ? defaultValue : "");
    const handleChange = (val: string | undefined) => {
      if (!val) return;
      setValue((_prev) => (_prev = val));
    };
    return (
      <div className={twMerge("phone-selector", containerClass)}>
        <label
          htmlFor={id ? id : props.name ? `${props.name}-input` : undefined}
          className={twMerge("mb-0.5 block", labelClass)}
          hidden={!!!label}
        >
          {label ? props.title : ""}
          {props.required && " *"}
        </label>
        <PhoneInput
          disabled={props.disabled}
          id={id ? id : props.name ? `${props.name}-input` : undefined}
          name={`${props.name}-local`}
          required={props.required}
          defaultCountry="LB"
          value={value.toString()}
          onChange={(val) => {
            handleChange(val);
            phoneChange && phoneChange(val);
          }}
          className={twMerge("", props.className)}
        />
        <input
          hidden
          ref={ref}
          type="tel"
          value={value}
          {...props}
          required={false}
        />
        <div
          className={`flex items-center gap-1.5 overflow-y-hidden duration-500 ${
            error ? "max-h-[100px]" : "max-h-0"
          }`}
        >
          <span className="text-sm text-red-600">{error && error}</span>
        </div>
      </div>
    );
  },
);
export default CustomPhoneInput;
