const Banner = () => {
  return (
    <section id="partner-banner" className="single-grid md:h-[400px]">
      <img
        className="single-grid-item h-full w-full object-cover"
        src="/assets/images/common/man-with-kids-banner.webp"
        alt="Man with 2 kids"
      />
      <div className="single-grid-item section-padding container content-end capitalize">
        <h4 className="text-xl font-bold text-white">What makes our</h4>
        <h2 className="section-heading text-white">Partners special</h2>
      </div>
    </section>
  );
};

export default Banner;
