import { FC, useEffect } from "react";
import "./App.css";
import { useAppDispatch } from "./store/redux";
import AppRouter from "./routes/AppRouter";
import "./i18n/i18n";
import { GenerateGuestToken } from "./store/Auth/ActionAuth";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "./ga-utils";
import BackDropLoader from "./component/BackDropLoader";
export const App: FC = () => {
  const dispatch = useAppDispatch();
  const clientToken = localStorage.getItem("clientToken");
  useEffect(() => {
    if (!clientToken && !localStorage.getItem("user")) {
      dispatch(GenerateGuestToken()).then((res) => {
        localStorage.setItem("clientToken", res);
      });
    }
  }, []);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)",
    );
    const favicon = document.querySelector(
      "link[rel='icon']",
    ) as HTMLLinkElement;

    const changeFavicon = (darkMode: boolean) => {
      favicon.href = darkMode ? "/favicon-dark.ico" : "/favicon.ico";
    };
    changeFavicon(darkModeMediaQuery.matches);
    const darkModeChangeListener = (e: MediaQueryListEvent) => {
      changeFavicon(e.matches);
    };
    darkModeMediaQuery.addEventListener("change", darkModeChangeListener);
    return () => {
      darkModeMediaQuery.removeEventListener("change", darkModeChangeListener);
    };
  }, []);

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <>
      {/*<CookieBanner />*/}
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonStyle={{
          backgroundColor: "#FF913F",
        }}
        declineButtonStyle={{
          backgroundColor: "#FF913F",
          color: "black",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <BackDropLoader />
      <AppRouter />
    </>
  );
};
