import { atomWithStorage } from "jotai/utils";
import { TCurrency } from "../types/currency";

export const currencyAtom = atomWithStorage<
  | TCurrency
  | {
      name: TCurrency["name"];
      symbol: TCurrency["symbol"];
      abbreviation: TCurrency["abbreviation"];
      exchange_rate: TCurrency["exchange_rate"];
    }
>(
  "currency",
  {
    name: "US Dollar",
    symbol: "$",
    abbreviation: "USD",
    exchange_rate: "1",
  },
  undefined,
  {
    getOnInit: true,
  },
);
