import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/AuthSlice";
import businessDashboardReducer from "./BusinessDashboard/BusinessDashboardSlice";
import eventsReducer from "./events/EventsSlice";
import globalStateReducer from "./GlobalState/GlobalStateSlice";
import eventDetailsReducer from "./EventDetails/EventDetailsSlice";
import cartReducer from "./Cart/CartSlice";
import wishlistReducer from "./Wishlist/WishlistSlice";
import languageReducer from "./Language/LanguageSlice";
import termsReducer from "./TermsAndCondition/TermsSlice";
import crowdfundingReducer from "./Crowdfunding/CrowdfundingSlice";
const rootReducer = combineReducers({
  // todo : add your reducer here
  authReducer,
  businessDashboardReducer,
  eventsReducer,
  eventDetailsReducer,
  cartReducer,
  globalStateReducer,
  wishlistReducer,
  languageReducer,
  termsReducer,
  crowdfundingReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
