import Modal from "../../../../../../../components/layout/Modal";
import MapPicker from "react-google-map-picker";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useEventCreationAddress } from "../../../_hooks";

const Address = () => {
  const {
    selectedLocation,
    openModal,
    isLoaded,
    handleSelect,
    value,
    setValue,
    data,
    isOpen,
    defaultLocation,
    handleChangeLocation,
  } = useEventCreationAddress();
  return (
    <>
      {selectedLocation && (
        <>
          <input
            // required
            readOnly
            hidden
            id="event-creation-address-longitude"
            name="longitude"
            type="text"
            value={selectedLocation?.lng}
            title="Longitude"
          />
          <input
            // required
            readOnly
            hidden
            id="event-creation-address-latitude"
            name="latitude"
            type="text"
            value={selectedLocation?.lat}
            title="Latitude"
          />
        </>
      )}
      <button
        onClick={openModal}
        type="button"
        className={`flex rounded-xl bg-white px-4 py-2 text-left ${
          selectedLocation ? undefined : "text-gray-500"
        }`}
      >
        {selectedLocation
          ? `${selectedLocation?.lng},${selectedLocation?.lat}`
          : "Longitude, Latitude"}
      </button>
      <Modal id="event-creation-map" className="min-w-[min(100%,600px)]">
        {isLoaded ? (
          <Combobox className="w-full" onSelect={handleSelect}>
            <ComboboxInput
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              disabled={!isLoaded}
              className="relative mb-3 w-1/2 rounded-2xl border-2 border-primary-orange bg-gray-200 px-2 py-1 outline-0"
              placeholder="Location"
            />
            <div
              className="mt-2 rounded-lg bg-white"
              style={{ position: "absolute", zIndex: 1000 }}
            >
              <ComboboxList>
                {data.map(({ place_id, description }) => (
                  <ComboboxOption key={place_id} value={description} />
                ))}
              </ComboboxList>
            </div>
          </Combobox>
        ) : null}
        {process.env.REACT_APP_GOOGLE_MAP_KEY && isOpen ? (
          <MapPicker
            className="rounded-xl"
            defaultLocation={defaultLocation}
            zoom={10}
            style={{ height: "450px", width: "100%" }}
            onChangeLocation={handleChangeLocation}
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          />
        ) : (
          <p className="text-red-500">
            Something went wrong displaying the map
          </p>
        )}
      </Modal>
    </>
  );
};

export default Address;
