import HeaderOld from "../../component/Header";
import Header from "../section/header/_index";
import { Outlet } from "react-router-dom";
import Footer from "../section/footer/_index";

const LayoutPage = () => {
  return (
    <>
      {/* <HeaderOld /> */}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutPage;
