import { AppDispatch } from "../store";
import { setLoading } from "../EventDetails/EventDetailsSlice";
import {
  addEventToCategoryAxios,
  createWishlistAxios,
  deleteCategoryAxios,
  getWishlistsAxios,
} from "../../networking/wishlist/wishlist";
import { setCreateWishlist, WishlistSlice } from "./WishlistSlice";
import { setAllEventsCategories, setCategories } from "./WishlistSlice";
import { GlobalStateSLice } from "../GlobalState/GlobalStateSlice";

export const createWishlist =
  (wishlistTitle: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const createWishlistResponse = await createWishlistAxios(wishlistTitle);

      if (createWishlistResponse) {
        dispatch(setCreateWishlist(createWishlistResponse));
      } else {
        console.error("Failed to create wishlist.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
export const getWishlistsCategories = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
    const response = await getWishlistsAxios();
    if (response !== null) {
      dispatch(setAllEventsCategories(response.all));
      dispatch(setCategories(response.categories));
    }
  } catch (error) {
    dispatch(setAllEventsCategories([]));
  } finally {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
  }
};

export const addEventToCategory =
  (category: number, likedEvent: number) => async (dispatch: AppDispatch) => {
    try {
      const eventAddedResponse = await addEventToCategoryAxios(
        category,
        likedEvent,
      );
      if (eventAddedResponse) {
        dispatch(
          WishlistSlice.actions.setEventToCategory({
            eventId: likedEvent,
            categoryId: category,
          }),
        );
      } else {
        console.error("Failed to add event");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };
export const deleteCategory =
  (category: number) => async (dispatch: AppDispatch) => {
    try {
      const eventAddedResponse = await deleteCategoryAxios(category);
      if (eventAddedResponse) {
        dispatch(WishlistSlice.actions.deleteCategory(category));
      } else {
        console.error("Failed to delete category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
