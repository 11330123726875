import CustomInput from "../../../../../../components/common/inputs/NewInput";
import CustomSelect from "../../../../../../components/common/inputs/Select";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useEventCreationTime } from "../../_hooks";
import { TLabel } from "../../../../../../types/utils/component";
import SvgClock from "../../../../../../assets/svgs/icons/SvgClock";

const Time = () => {
  const {
    duration,
    handleDurationChange,
    options,
    durationType,
    handleDurationTypeChange,
    eventType,
    week,
    startWeek,
    handleStartWeekChange,
    remainder,
    endWeek,
    handleEndWeekChange,
    startTime,
    handleStartTimeChange,
    endTime,
    handleEndTimeChange,
    possibleNumber,
    days,
    handleCalendarChange,
  } = useEventCreationTime();
  return (
    <>
      <div>
        <div className="mb-8 grid gap-4 md:grid-cols-2">
          <CustomInput
            label="Duration"
            name="duration"
            placeholder="Duration"
            type="number"
            value={duration}
            onChange={handleDurationChange}
          />
          <CustomSelect
            label="Duration Type"
            options={options}
            name="duration_type"
            value={durationType}
            onChange={(e) => handleDurationTypeChange(e as TLabel)}
          />
        </div>
        {eventType !== "custom" && (
          <div className="mb-8 grid gap-4 md:grid-cols-2">
            <CustomSelect
              label="Start Weekday"
              options={week}
              name="start_weekday"
              value={startWeek}
              onChange={(e) => handleStartWeekChange(e as TLabel)}
            />
            <CustomSelect
              label="End Weekday"
              options={remainder}
              name="end_weekday"
              value={endWeek}
              onChange={(e) => handleEndWeekChange(e as TLabel)}
            />
          </div>
        )}
        <div className="mb-8 grid gap-4 md:grid-cols-2">
          <CustomInput
            type="time"
            name="start_time"
            label="Start Time"
            value={startTime}
            onChange={handleStartTimeChange}
          />
          <CustomInput
            min={startTime}
            type="time"
            name="end_time"
            label="End Time"
            value={endTime}
            onChange={handleEndTimeChange}
          />
          {durationType.value === "h" &&
            duration &&
            startTime &&
            endTime &&
            !isNaN(possibleNumber) &&
            possibleNumber > 1 && (
              <ul className="flex flex-wrap gap-2 md:col-span-2">
                {[...Array(possibleNumber)].map((_, index) => {
                  return (
                    <li
                      key={`event_creation_time_${index}`}
                      className="flex items-center gap-1.5 rounded-lg bg-white px-2 py-1"
                    >
                      <SvgClock className="h-4 w-4 fill-primary-orange" />
                      {index * parseInt(duration) +
                        parseInt(startTime.split(":")[0])}
                      :{startTime.split(":")[1]}
                    </li>
                  );
                })}
              </ul>
            )}
        </div>
      </div>
      <div className="relative">
        <h6 className="absolute left-0 right-0 top-0 col-start-3 hidden -translate-y-full pb-2 text-center xl:block">
          <b className="text-primary-orange">Select</b> day on calendar manually
        </h6>
        <Calendar
          className="!rounded-3xl px-2 !shadow-lg"
          multiple
          minDate={new Date()}
          value={days?.map((day) =>
            typeof day === "string" ? new DateObject(day) : day,
          )}
          onChange={handleCalendarChange}
        />
      </div>
      {days?.map((day, index) => {
        return (
          <input
            key={`event-creation-date-${index}`}
            type="text"
            hidden
            readOnly
            value={
              typeof day === "string"
                ? day
                : `${day.year}-${day.month}-${day.day}`
            }
            name={`working_days[${index}]`}
          />
        );
      })}
    </>
  );
};

export default Time;
