import { TSvgComponent } from "../../../types/utils/component";

const SvgFile: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.1916 0.565758C9.90123 0.275341 9.39832 0.473674 9.39832 0.877424V3.34951C9.39832 4.38367 10.2766 5.24076 11.3462 5.24076C12.0191 5.24784 12.9541 5.24784 13.7546 5.24784C14.1583 5.24784 14.3708 4.77326 14.0875 4.48992C13.0675 3.46284 11.24 1.61409 10.1916 0.565758Z" />
      <path d="M13.5208 6.21824H11.4738C9.79501 6.21824 8.42793 4.85116 8.42793 3.17241V1.12533C8.42793 0.735742 8.10918 0.416992 7.71959 0.416992H4.71626C2.53459 0.416992 0.770844 1.83366 0.770844 4.36241V10.6382C0.770844 13.167 2.53459 14.5837 4.71626 14.5837H10.2838C12.4654 14.5837 14.2292 13.167 14.2292 10.6382V6.92658C14.2292 6.53699 13.9104 6.21824 13.5208 6.21824ZM7.14584 11.5732H4.31251C4.02209 11.5732 3.78126 11.3324 3.78126 11.042C3.78126 10.7516 4.02209 10.5107 4.31251 10.5107H7.14584C7.43626 10.5107 7.67709 10.7516 7.67709 11.042C7.67709 11.3324 7.43626 11.5732 7.14584 11.5732ZM8.56251 8.73991H4.31251C4.02209 8.73991 3.78126 8.49908 3.78126 8.20866C3.78126 7.91824 4.02209 7.67741 4.31251 7.67741H8.56251C8.85293 7.67741 9.09376 7.91824 9.09376 8.20866C9.09376 8.49908 8.85293 8.73991 8.56251 8.73991Z" />
    </svg>
  );
};

export default SvgFile;
