import { Link } from "react-router-dom";
import SvgMagnifyingGlass from "../../../assets/svgs/icons/MagnifyingGlass";
import { turnEventIdToSlug } from "../../../utils/functions";
import { useHeaderGlobal } from "./_hooks";

const GlobalSearch = () => {
  const { data, handleChange, isLoading, searchTerm } = useHeaderGlobal();
  //   const { searchTerm, data, isLoading, handleChange, lang } = useGlobalSearch();
  return (
    <div className="relative flex items-center gap-2">
      <label htmlFor="global-search-input">
        <SvgMagnifyingGlass className="svg-fill h-4 w-4" />
      </label>
      <input
        title="Search"
        type="search"
        id="global-search-input"
        className="max-w-[150px] bg-transparent"
        onChange={handleChange}
        placeholder="Search"
      />
      <div
        className={`absolute -bottom-2 right-0 max-h-[500px] min-w-full origin-top-right translate-y-full overflow-y-auto rounded-lg bg-gray-200 p-2 duration-200 ${
          isLoading || (searchTerm && data) ? "scale-100" : "scale-0"
        }`}
      >
        {!isLoading && data ? (
          data.length > 0 ? (
            <ul className="grid gap-2">
              {data.map((event) => {
                return (
                  <li key={`global_search_${event.id}`}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      to={`${
                        process.env.REACT_APP_NEXT_URL
                      }/${"en"}/events/${turnEventIdToSlug(
                        event.translations["en"].title,
                        event.id,
                      )}`}
                      className="hover-link grid min-w-[350px] auto-rows-fr grid-cols-[1fr_3fr] gap-2 rounded-lg border bg-white p-1"
                    >
                      <img
                        src={event.image.image_path}
                        alt={event.translations["en"].title}
                        title={event.translations["en"].title}
                        className="aspect-square w-full rounded-md object-cover object-center"
                      />
                      <div className="p-1">
                        <h4 className="mb-2 text-sm">
                          {event.translations["en"].title.slice(0, 50)}...
                        </h4>
                        <p className="text-xs text-primary-orange">
                          {event.translations["en"].description.slice(0, 100)}
                          ...
                        </p>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>"No results found</p>
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default GlobalSearch;
