import DetailsCard from "./Card";
import Intrests from "./Intrests";

const Details = () => {
  return (
    <section id="event-creation-details" className="dashboard-card gray">
      <h3 className="section-title mb-4">Add more details</h3>
      <div className="mb-4 grid md:grid-cols-3 gap-4">
        <DetailsCard name="highlights" />
        <DetailsCard name="includes" />
        <DetailsCard name="important_information" />
      </div>
      <Intrests />
    </section>
  );
};

export default Details;
