import { useState } from "react";
import { useTranslation } from "react-i18next";
import { referredAPI } from "../../../networking/crowdfunding/crowdfunding";
import { useAppDispatch } from "../../../store/redux";
import { toast } from "sonner";

export const PartOfJourney = () => {
  const { t } = useTranslation();
  const [full_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleSubscribe = async () => {
    try {
      const payload = { full_name, email };
      await referredAPI(payload);
      setName("");
      setEmail("");
      toast.success("The email is sent");
    } catch {}
  };
  return (
    <section className="container place-items-center gap-32 bg-white md:mb-20 md:grid md:grid-cols-2">
      <img
        src="/assets/images/donate.png"
        className="h-full w-full max-md:block md:hidden"
        alt=""
      />
      <div className="md:mt-8">
        <h1 className="flex items-center gap-2 text-3xl font-bold max-md:justify-center max-md:text-center">
          {t("crowdfunding.partOfJourney")}
          <div className="mt-2 w-[60px] rounded-2xl border-b-[3px] border-black max-md:hidden"></div>
        </h1>
        <p className="font-bold text-primary-orange max-md:text-center">
          {" "}
          {t("crowdfunding.partJourneyTag")}
        </p>
        <div className="text-lg text-black opacity-80 max-md:text-center">
          <h3 className="max-md:pt-4 max-md:text-sm md:pt-8">
            {t("crowdfunding.journeyDescription")}
          </h3>
        </div>
        <div className="mt-5 rounded-2xl bg-[#F8F8F8] p-8">
          <div className="relative">
            <img
              src="/assets/svgs/user.svg"
              alt="Email Icon"
              className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-400"
            />
            <input
              type="text"
              placeholder={t("crowdfunding.fullName")}
              value={full_name}
              onChange={handleNameChange}
              className="w-full rounded-lg border border-[#E3E2E2] bg-white p-3 pl-10 text-black placeholder:font-light placeholder:text-black"
            />
          </div>
          <div className="relative my-5">
            <img
              src="/assets/svgs/email.svg"
              alt="Email Icon"
              className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-400"
            />
            <input
              type="text"
              placeholder={t("crowdfunding.enterEmail")}
              value={email}
              onChange={handleEmailChange}
              className="w-full rounded-lg border border-[#E3E2E2] bg-white p-3 pl-10 text-black placeholder:font-light placeholder:text-black"
            />
          </div>
          <button
            className="my-5 flex w-full items-center justify-center gap-1 rounded-full bg-primary-orange py-3 text-white max-md:px-10 md:px-14"
            onClick={handleSubscribe}
          >
            {t("crowdfunding.subscribe")}
            <img src="/assets/svgs/common/white-arrow.svg" alt="arrow" />
          </button>
        </div>
      </div>
      <img
        src="/assets/images/donate.png"
        className="h-full w-full max-md:hidden md:block"
        alt=""
      />
    </section>
  );
};
export default PartOfJourney;
