import React, { useRef } from "react";

interface TabComponentProps {
    titles?: { id: number; label: string }[];
    activeTab?: number;
    handleTabClick: (title: any, tabId: number) => void;
}

const TabComponent = (props: TabComponentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const handleTabClick = (title: Object, tabId: number) => {
        props.handleTabClick(title, tabId);
    };
    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 100;
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 100;
        }
    };
    return (
      <div className="flex items-center">
          <button type="button" onClick={scrollLeft} className="px-3">
              <img src="/assets/svgs/left.svg" className="w-6 h-6"/>
          </button>
          <div
            ref={containerRef}
            className="flex overflow-x-auto max-md:flex-wrap md:gap-5 gap-3 mt-5 md:mt-0 border-b-2 border-gray-400/0.5"
          >
              {props.titles?.map((title, index) => (
                <div key={index}>
                    <button
                      className={`whitespace-nowrap max-md:bg-[#131C23]/10 max-md:px-3 max-md:py-2 max-md:rounded-full max-md:text-sm  ${
                        props.activeTab === title.id
                          ? "text-primary-orange max-md:bg-transparent max-md:border-2 max-md:border-primary-orange"
                          : ""
                      }`}
                      onClick={() => handleTabClick(title, title.id)}
                    >
                        {title.label}
                    </button>
                </div>
              ))}
          </div>
          <button type="button" onClick={scrollRight} className="px-3">
              <img src="/assets/svgs/right.svg" className="w-6 h-6"/>
          </button>
      </div>
    );
};
export default TabComponent;