import CustomSelect from "../../../../../components/common/inputs/Select";
import { TEventCategory, TEventType } from "../../../../../types/event";
import { useEventCreationTypesCategories } from "../_hooks";

const TypeCategory = () => {
  const { data, fields, handleChangeTypes, handleChangeCategories } =
    useEventCreationTypesCategories();
  return (
    <section
      id="create-event-type-category"
      className="dashboard-card gray grid gap-x-10 gap-y-4 lg:grid-cols-2"
    >
      <CustomSelect
        id="event-type"
        isMulti
        getOptionValue={(option) => (option as TEventType).id.toString()}
        getOptionLabel={(option) => (option as TEventType).title}
        options={data?.types ?? []}
        value={fields?.event_types ?? []}
        onChange={handleChangeTypes}
        placeholder="Select event type"
      />
      {fields?.event_types &&
        fields?.event_types.map((type, index) => {
          if (type)
            return (
              <input
                key={`event_creation_type_${type?.id}`}
                type="text"
                name={`event_types[${index}]`}
                defaultValue={type?.id}
                hidden
              />
            );
          return null;
        })}
      <CustomSelect
        id="event-category"
        isMulti
        getOptionValue={(option) => (option as TEventCategory).id.toString()}
        getOptionLabel={(option) => (option as TEventCategory).title}
        options={data?.categories ?? []}
        value={fields?.categories ?? []}
        onChange={handleChangeCategories}
        placeholder="Select event category"
      />
      {fields?.categories &&
        fields?.categories.map((cat, index) => {
          if (cat)
            return (
              <input
                key={`event_creation_category_${cat?.id}`}
                type="text"
                name={`categories[${index}]`}
                defaultValue={cat?.id}
                hidden
              />
            );
          return null;
        })}
    </section>
  );
};

export default TypeCategory;
