import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { TContributer } from "../../../types/contribution";

type TProps = {
  contributors: Array<TContributer> | undefined;
};

const WordsOfSupport = ({ contributors }: TProps) => {
  const { t } = useTranslation();
  let maxSlideHeight = 0;

  const calculateMaxHeight = (height: number) => {
    maxSlideHeight = Math.max(maxSlideHeight, height);
  };
  const settings = {
    dots: false,
    infinite: contributors ? contributors.length > 5 : false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow:
      contributors && contributors.length > 0 ? (
        <img src="/assets/svgs/next-arrow.svg" alt="Next Arrow" />
      ) : undefined,
    prevArrow:
      contributors && contributors.length > 0 ? (
        <img src="/assets/svgs/prev-arrow.svg" alt="Previous Arrow" />
      ) : undefined,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="container">
      <div className="pb-10 md:pt-20">
        <h1 className="flex items-center gap-2 text-3xl font-bold max-md:justify-center">
          {t("crowdfunding.wordsOfSupportTitle")}
          <div className="mt-2 w-[60px] rounded-2xl border-b-[3px] border-black"></div>
        </h1>
        <p className="font-bold text-primary-orange max-md:text-center">
          {t("crowdfunding.wordsTag")}
        </p>
      </div>
      <div className="text-white max-md:mx-8 md:mt-0 md:pb-10">
        <Slider
          {...settings}
          beforeChange={(current, next) =>
            calculateMaxHeight(
              document.getElementById(`slide-${next}`)?.clientHeight || 0,
            )
          }
        >
          {contributors &&
            (contributors.length === 0 ? (
              <p className="font-bold text-primary-orange">
                No contributors yet
              </p>
            ) : (
              contributors.map((support) => (
                <div
                  key={support.id}
                  className="slide group cursor-pointer rounded-3xl px-2 transition-transform duration-500"
                >
                  <div className="relative flex h-[270px] w-full flex-col items-center overflow-hidden rounded-xl bg-[#F6F6F6] py-8 text-black hover:bg-gradient-to-r hover:from-secondary-orange hover:to-primary-orange md:px-4">
                    <div className="flex justify-between">
                      <p className="absolute right-0 top-0 mr-2 mt-2 px-3 pt-1 text-xs font-light text-[#9A9A9A] group-hover:text-white">
                        {moment(support.created_at).fromNow()}
                      </p>
                      <p className="absolute left-0 top-0 mr-2 mt-2 px-3 pt-1 text-xs font-light text-[#9A9A9A] group-hover:text-white">
                        {support.country_code}
                      </p>
                    </div>
                    <img
                      src="/assets/svgs/donation.svg"
                      className="visible absolute left-0 right-0 top-5 m-auto mt-5 h-10 w-10 group-hover:invisible"
                      alt=""
                    />
                    <img
                      src="/assets/svgs/donation-hover.svg"
                      className="invisible absolute left-0 right-0 top-5 m-auto mt-5 h-10 w-10 group-hover:visible"
                      alt=""
                    />
                    <div className="my-4 flex flex-col items-center justify-center">
                      <p className="text-md pt-10 text-center font-bold duration-200 group-hover:text-white">
                        {support.name}
                      </p>
                      <div className="mt-2 rounded-xl bg-primary-orange/20 px-8 py-2 text-center font-bold text-primary-orange group-hover:bg-white/20 group-hover:text-white">
                        £{support.amount}
                      </div>
                    </div>
                    <div
                      className="scrollable-content relative flex h-[350px] flex-col items-center"
                      style={{ overflowX: "hidden", overflowY: "auto" }}
                    >
                      <p className="font-200 px-3 text-center text-sm group-hover:text-white">
                        {support.words_of_support}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default WordsOfSupport;
