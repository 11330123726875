import {
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  labelClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  loading?: boolean;
};

const CustomToggle = ({ loading, labelClass, ...props }: TProps) => {
  const [checked, setChecked] = useState(
    props.checked || props.defaultChecked || false,
  );
  return (
    <>
      <label
        htmlFor={props.id}
        className={twMerge(
          `flex h-5 w-10 items-center rounded-full p-px duration-200 ${
            loading
              ? "animate-pulse bg-primary-orange"
              : props.checked || props.defaultChecked
              ? "bg-green-600/75"
              : "bg-primary-gray"
          } ${
            props.disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
          }`,
          labelClass,
        )}
      >
        <div
          className={`aspect-square h-full rounded-full bg-white duration-200 ${
            loading
              ? "mx-[25%]"
              : props.checked || props.defaultChecked
              ? "mr-[50%]"
              : "ml-[50%]"
          }`}
        ></div>
      </label>
      <input
        hidden
        type="checkbox"
        {...props}
        checked={
          typeof props.defaultChecked !== "undefined"
            ? undefined
            : props.checked || checked
        }
        onChange={(e) => {
          e.target.checked
            ? setChecked((_prev) => (_prev = true))
            : setChecked((_prev) => (_prev = false));
          props.onChange && props.onChange(e);
        }}
      />
    </>
  );
};

export default CustomToggle;
