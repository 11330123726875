import SvgWarning from "../../../assets/svgs/icons/SvgWarning";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";

const Apply = () => {
  return (
    <section
      id="partner-apply"
      className="section-margin container flex flex-col items-center gap-6"
    >
      <h2 className="section-heading text-center capitalize text-primary-navy lg:px-20">
        If you would like to access
        <span className="text-primary-orange">special benefits</span>, please
        share <span className="text-primary-orange">your story</span> with our
        Head office for valuation
      </h2>
      <ArrowedLink
        title="Apply now"
        className="bg-primary-blue font-bold"
        to="mailto:tripsupport@explorenearme.co.uk"
      >
        Apply Now
      </ArrowedLink>
      <div className="flex flex-wrap items-center justify-center gap-2 rounded-lg bg-red-200 px-5 py-3">
        <SvgWarning className="h-6 w-6 justify-self-center" />
        <p className="text-center">
          Partnership is free! but only few will qualify for special Explore
          Near Me support
        </p>
      </div>
    </section>
  );
};

export default Apply;
