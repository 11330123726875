import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BottomBar = () => {
  const { t } = useTranslation();
  return (
    <section
      id="footer-bottom-bar"
      className="container flex flex-wrap-reverse justify-between gap-4 py-4"
    >
      <p className="font-medium text-white max-md:text-center">
        {t("footer.copyrights")}&nbsp;
        <span className="font-bold uppercase text-primary-orange">
          {t("footer.exploreNearMe")}
        </span>
        &nbsp;{t("footer.rightsReserved")}
      </p>
      <div className="flex flex-wrap items-center gap-1.5 text-white">
        <Link
          to="/terms-and-condition?type=client"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="hover-link"
        >
          {t("footer.termsConditions")}
        </Link>
        <span className="font-bold text-primary-orange">|</span>
        <Link to="/privacy-policy" className="hover-link">
          {t("footer.privacyPolicy")}
        </Link>
        <span className="font-bold text-primary-orange">|</span>
        <Link to="/about" className="hover-link text-sm">
          {t("footer.aboutUs")}
        </Link>
      </div>
    </section>
  );
};

export default BottomBar;
