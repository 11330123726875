const Family = () => {
  return (
    <section
      id="about-family"
      className="section-margin container grid items-center gap-x-20 gap-y-10 lg:grid-cols-2"
    >
      <h2 className="section-heading heading-bar capitalize text-primary-navy after:bg-primary-navy">
        Explore near me family
      </h2>
      <div className="grid place-items-center">
        <div className="relative z-0 before:absolute before:inset-0 before:-rotate-3 before:rounded-3xl before:border-4 before:border-primary-navy before:content-['']">
          <img
            loading="lazy"
            src="/assets/images/pages/about/family-team.webp"
            alt="Klev team"
            className="relative z-10 w-full max-w-3xl rounded-2xl"
          />
        </div>
      </div>
    </section>
  );
};

export default Family;
