import { CircularProgress } from "@mui/material";
import { ButtonHTMLAttributes, DetailedHTMLProps, useRef } from "react";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { RootState } from "../store/store";

type TProps = { loading?: boolean } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
const PrimaryButton = ({ loading, ...props }: TProps) => {
  const isLoading = useSelector(
    (state: RootState) => state.globalStateReducer.showButtonLoader,
  );
  const ref = useRef<HTMLButtonElement>(null);
  const width = ref !== null ? ref.current?.offsetWidth : 0;
  const height = ref !== null ? ref.current?.offsetHeight : 0;
  return (
    <button
      ref={ref}
      style={{
        minHeight: typeof loading !== "undefined" ? `${height}px` : undefined,
        minWidth: typeof loading !== "undefined" ? `${width}px` : undefined,
      }}
      disabled={isLoading || loading}
      {...props}
      className={twMerge(
        "[&:not(:disabled)]:hover-link flex w-full items-center justify-center rounded-full bg-primary-orange px-6 py-3 text-white disabled:bg-gray-300",
        props.className,
      )}
    >
      {isLoading || loading ? (
        <CircularProgress className="leading-0 !h-5 !w-5 [&_circle]:stroke-primary-orange" />
      ) : (
        props.children
      )}
    </button>
  );
};

export default PrimaryButton;
