import { TSvgComponent } from "../../types/utils/component";

const CrownSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="#FFD54E"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.1667 18.3333H5.83333C5.49166 18.3333 5.20833 18.0499 5.20833 17.7083C5.20833 17.3666 5.49166 17.0833 5.83333 17.0833H14.1667C14.5083 17.0833 14.7917 17.3666 14.7917 17.7083C14.7917 18.0499 14.5083 18.3333 14.1667 18.3333Z" />
      <path d="M16.9583 4.59995L13.625 6.98328C13.1833 7.29995 12.55 7.10828 12.3583 6.59995L10.7833 2.39995C10.5167 1.67495 9.49167 1.67495 9.225 2.39995L7.64167 6.59162C7.45 7.10828 6.825 7.29995 6.38333 6.97495L3.05 4.59162C2.38333 4.12495 1.5 4.78328 1.775 5.55828L5.24167 15.2666C5.35833 15.6 5.675 15.8166 6.025 15.8166H13.9667C14.3167 15.8166 14.6333 15.5916 14.75 15.2666L18.2167 5.55828C18.5 4.78328 17.6167 4.12495 16.9583 4.59995ZM12.0833 12.2916H7.91667C7.575 12.2916 7.29167 12.0083 7.29167 11.6666C7.29167 11.325 7.575 11.0416 7.91667 11.0416H12.0833C12.425 11.0416 12.7083 11.325 12.7083 11.6666C12.7083 12.0083 12.425 12.2916 12.0833 12.2916Z" />
    </svg>
  );
};

export default CrownSvg;
