import { TSvgComponent } from "../../../types/utils/component";

const SvgMagnifyingGlass: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.2915 8.16675C1.2915 4.36979 4.36955 1.29175 8.1665 1.29175C11.9635 1.29175 15.0415 4.36979 15.0415 8.16675C15.0415 11.9637 11.9635 15.0417 8.1665 15.0417C4.36955 15.0417 1.2915 11.9637 1.2915 8.16675ZM8.1665 0.041748C3.67919 0.041748 0.0415039 3.67943 0.0415039 8.16675C0.0415039 12.6541 3.67919 16.2917 8.1665 16.2917C10.1852 16.2917 12.0319 15.5556 13.4529 14.337L16.0579 16.942C16.302 17.1861 16.6977 17.1861 16.9418 16.942C17.1859 16.6979 17.1859 16.3022 16.9418 16.0581L14.3368 13.4531C15.5553 12.0322 16.2915 10.1854 16.2915 8.16675C16.2915 3.67943 12.6538 0.041748 8.1665 0.041748Z"
      />
    </svg>
  );
};

export default SvgMagnifyingGlass;
