import { TSvgComponent } from "../../types/utils/component";

const LogoHandsSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 113 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M56.5547 67.2667V56.1597L93.4902 93.0952L56.5547 67.2667Z"
          fill="#131C23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.8878 87.8032C87.3904 88.8591 89.2179 87.0316 88.162 85.529L68.2831 57.1015C67.8973 56.5329 67.8973 55.7817 68.2831 55.2334L93.4415 19.2319L57.4399 44.3903C56.8714 44.7761 56.1201 44.7761 55.5719 44.3903L27.1443 24.5113C25.6417 23.4555 23.8143 25.2829 24.8702 26.7855L44.7491 55.2131C45.1349 55.7816 45.1349 56.5329 44.7491 57.0812L24.8702 85.5087C24.6265 85.8539 24.5452 86.1991 24.5655 86.5443V86.5849C24.667 87.722 26.0072 88.5951 27.1443 87.8032L55.5719 67.9243C56.1404 67.5385 56.8917 67.5385 57.4399 67.9243L85.8675 87.8032H85.8878Z"
          fill="url(#paint0_linear_109_10620)"
        />
        <path
          d="M19.5996 19.2264L56.5351 56.1619V45.0345L19.5996 19.2061V19.2264Z"
          fill="#131C23"
        />
      </g>
      <path
        d="M65.9081 66.8916C66.03 66.2215 66.5579 65.6935 67.228 65.5717L111.169 57.7948C112.976 57.4699 112.976 54.8911 111.169 54.5662L67.228 46.7689C66.5579 46.6471 66.03 46.1191 65.9081 45.449L58.1109 1.50822C57.786 -0.298963 55.2072 -0.298963 54.8823 1.50822L47.1053 45.449C46.9835 46.1191 46.4556 46.6471 45.7855 46.7689L1.84464 54.5662C0.0374629 54.8911 0.0374629 57.4699 1.84464 57.7948L45.7855 65.5717C46.4556 65.6935 46.9835 66.2215 47.1053 66.8916L54.8823 110.832C55.2072 112.64 57.786 112.64 58.1109 110.832L65.9081 66.8916Z"
        fill="url(#paint1_linear_109_10620)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.3355 44.3942L94.2046 91.6449C94.9965 93.0865 93.4128 94.6501 91.9914 93.8582L44.7204 67.9891L18.8513 20.7181C18.0594 19.2765 19.6433 17.713 21.0646 18.5049L68.3152 44.3739L68.3355 44.3942Z"
        fill="url(#paint2_linear_109_10620)"
      />
      <path
        d="M61.8986 49.9977C65.1069 53.206 65.1069 58.3838 61.8986 61.5515C58.6904 64.7597 53.5125 64.7597 50.3449 61.5515C47.1569 58.3432 47.1366 53.1653 50.3449 49.9977C53.5531 46.8098 58.731 46.7894 61.8986 49.9977Z"
        fill="url(#paint3_linear_109_10620)"
      />
      <path
        d="M59.9451 51.9754C62.0569 54.0872 62.0569 57.4984 59.9451 59.6102C57.8333 61.722 54.4221 61.722 52.3104 59.6102C50.1986 57.4984 50.1986 54.0872 52.3104 51.9754C54.4221 49.8637 57.8333 49.8637 59.9451 51.9754Z"
        fill="#131C23"
      />
      <defs>
        <linearGradient
          id="paint0_linear_109_10620"
          x1="59.0238"
          y1="19.2319"
          x2="59.0238"
          y2="88.1281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#131C23" />
          <stop offset="1" stopColor="#7E7E7E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_109_10620"
          x1="56.5271"
          y1="-7.56829"
          x2="56.5271"
          y2="119.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#939393" />
          <stop offset="1" stopColor="#131C23" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_109_10620"
          x1="56.5381"
          y1="18.3221"
          x2="56.5381"
          y2="94.1019"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8A32" />
          <stop offset="1" stopColor="#D65009" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_109_10620"
          x1="56.1319"
          y1="47.6017"
          x2="56.1319"
          y2="63.9678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5F2900" />
          <stop offset="1" stopColor="#D66714" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoHandsSvg;
