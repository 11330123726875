import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store/redux";
import { selectedCart } from "../../../../store/Cart/ActionCart";
import { useTranslation } from "react-i18next";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../../store/currency";

interface OrderConfirmationProps {
  selectedTotal: number;
  selectedItems: any[];
  fees: number;
  priceWithoutFees: number;
}

const OrderConfirmation: React.FC<OrderConfirmationProps> = ({
  selectedTotal,
  selectedItems,
  fees,
  priceWithoutFees,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedCurrency = useAtomValue(currencyAtom);
  const cartItem = useSelector(
    (state: RootState) => state.cartReducer.cartItem,
  );
  const [checkoutError, setCheckoutError] = useState(false);

  useEffect(() => {}, [cartItem]);
  const goToCheckout = () => {
    if (selectedItems.length > 0) {
      setCheckoutError(false);
      const selectedCartIds = selectedItems.map((item) => item.id);
      dispatch(selectedCart(selectedCartIds))
        .then(() => {
          navigate("/checkout");
        })
        .catch(() => {});
    } else {
      setCheckoutError(true);
    }
  };
  return (
    <div className="container px-4">
      <div className="rounded-3xl bg-white px-5 py-10 shadow-lg">
        <h3 className="text-xl font-bold text-[#131C23]">
          {t("shopCart.orderConfirmation")}
        </h3>
        <div className="mt-5 rounded-3xl bg-[#3F8CFF]/10 p-5">
          <div className="flex items-center justify-between text-lg text-[#131C23]">
            <div className="flex">
              <span className="font-bold">
                {selectedItems.length} {t("shopCart.item")}
              </span>
            </div>
            <div>
              <p className="text-lg font-bold text-[#3F8CFF]">
                {selectedCurrency.symbol}{" "}
                {(priceWithoutFees * +selectedCurrency.exchange_rate).toFixed(
                  2,
                )}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center justify-between text-lg text-[#131C23]">
            <div className="group relative z-50">
              <div className="flex items-center gap-2">
                <p className="text-sm font-bold">
                  {" "}
                  {t("shopCart.serviceFees")}{" "}
                </p>
                <img
                  src="/assets/svgs/common/message-icon.svg"
                  alt="Message Icon"
                  className="w-4 cursor-pointer group-hover:opacity-75"
                />
              </div>
              <div className="absolute left-0 hidden w-max translate-y-1 border border-gray-300 bg-white p-4 font-bold shadow-md group-hover:block md:max-w-xs">
                <h3 className="text-md"> {t("shopCart.serviceTitle")}</h3>
                <div className="my-1 border-b-2 border-[#3F8CFF] opacity-[0.5]"></div>
                <p className="text-xs text-[#3F8CFF]">
                  {t("shopCart.serviceText")}
                </p>
              </div>
            </div>
            <p className="text-lg font-bold text-[#3F8CFF]">
              {selectedCurrency.symbol}{" "}
              {(fees * +selectedCurrency.exchange_rate).toFixed(2)}
            </p>
          </div>
          <div className="mt-3 flex items-center justify-between text-lg text-[#131C23]">
            <p className="pr-1 font-medium">{t("shopCart.totalPrice")}: </p>{" "}
            <p className="text-lg font-bold text-[#3F8CFF]">
              {selectedCurrency.symbol}{" "}
              {(selectedTotal * +selectedCurrency.exchange_rate).toFixed(2)}
            </p>
          </div>
        </div>
        <div
          className={`mt-5 flex justify-center ${
            selectedItems.length > 0
              ? ""
              : "opacity-50 disabled:cursor-not-allowed"
          }`}
          onClick={goToCheckout}
        >
          <p className="flex w-full cursor-pointer items-center justify-center rounded-full bg-[#FF913F] py-2 text-white hover:bg-[#FF913F]/80 disabled:cursor-not-allowed">
            <span className="px-1">{t("shopCart.checkout")}</span>
            <img
              src="/assets/svgs/common/white-arrow.svg"
              className="w-4"
              alt=""
            />
          </p>
        </div>
        {checkoutError && (
          <p className="flex items-center py-3 text-sm font-bold text-[#FF913F]">
            <img
              src="/assets/svgs/common/error.svg"
              className="h-8 w-8 pr-2"
              alt=""
            />
            {t("shopCart.checkoutError")}
          </p>
        )}
        <div className="mt-5 flex items-center">
          <img src="/assets/svgs/common/payments.svg" alt="" />
          <div className="pl-3">
            <p className="text-md font-bold">{t("shopCart.securePayments")}</p>
            <p className="text-md">
              {t("shopCart.payment")}
              <span className="font-bold text-[#65CC93]">
                {" "}
                {t("shopCart.percentage")}
              </span>{" "}
              {t("shopCart.secure")}
            </p>
          </div>
        </div>
        <div className="flex items-center pt-4">
          <img src="/assets/svgs/common/cost.svg" alt="" />
          <div className="pl-3">
            <p className="text-md font-bold"> {t("shopCart.hiddenCost")}</p>
            <p className="text-md"> {t("shopCart.fees")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
