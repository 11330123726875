import { TSvgComponent } from "../../../types/utils/component";

const SvgLightning: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 13 19"
      fill="#FF873F"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73356 0.87131C8.73356 0.598898 8.55446 0.3589 8.29332 0.281363C8.03217 0.203827 7.75113 0.307203 7.60247 0.535476L1.03819 10.6152C0.914984 10.8044 0.905264 11.0459 1.01286 11.2444C1.12047 11.4428 1.32811 11.5665 1.55388 11.5665H5.04115V17.6709C5.04115 17.9433 5.22025 18.1833 5.48139 18.2608C5.74253 18.3384 6.02358 18.235 6.17224 18.0067L12.7365 7.92697C12.8597 7.73778 12.8694 7.49632 12.7618 7.29784C12.6542 7.09936 12.4466 6.97574 12.2208 6.97574H8.73356V0.87131Z"
      />
    </svg>
  );
};

export default SvgLightning;
