import { useEffect, useState } from "react";
import TabComponent from "../../../component/TabComponent";
import { Link, useNavigate } from "react-router-dom";
import CreateWishlist from "./CreateWishlist";
import { useAppDispatch, useAppSelector } from "../../../store/redux";
import { useSelector } from "react-redux";
import {
  deleteCategory,
  getWishlistsCategories,
} from "../../../store/Wishlist/ActionWishlist";
import { RootState } from "../../../store/store";
import { CategoryType, IEventUserType } from "../../../store/Wishlist/types";
import EventCard from "../../../component/EventCard";
import { IEventsData } from "../../../networking/events/types";
import AddEventCategory from "./AddEventCategory";
import {
  setAllEventsCategories,
  setWishlistCategories,
} from "../../../store/Wishlist/WishlistSlice";
import { useTranslation } from "react-i18next";
import {
  getNextURL,
  scrollToTop,
  turnEventIdToSlug,
} from "../../../utils/functions";

const WishList = () => {
  const dispatch = useAppDispatch();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );
  const { categories, all } = useSelector(
    (state: RootState) => state.wishlistReducer,
  );
  const [activeTab, setActiveTab] = useState(0);
  const auth = useAppSelector((state) => state.authReducer);
  const { t } = useTranslation();
  const categoriesAll: CategoryType[] = useSelector((state: RootState) =>
    state.wishlistReducer.categories.map((category) => ({
      ...category,
      label: category.title,
    })),
  );
  const tabCategories = [
    { id: 0, label: "All Categories" },
    ...categoriesAll.map((category) => ({
      id: category.id,
      label: category.title,
    })),
  ];
  const handleTabClicked = (
    title: { id: number; label: string },
    tabId: number,
  ) => {
    setActiveTab(tabId);
    setSelectedCategoryId(title.id);
  };
  const navigate = useNavigate();
  const handleFindThingsClick = () => {
    navigate(getNextURL({
      nextRedirect: `${selectedLanguage}/categories`,
    }));
  };
  const handleRemoveFromCategory = (eventId: number) => {
    const updatedAllEvents = all.filter((event) => event.id !== eventId);
    const updatedCategories = categories.map((category) => ({
      ...category,
      user_liked_events: category.user_liked_events.filter(
        (eventType) => eventType.event.id !== eventId,
      ),
    }));
    dispatch(setAllEventsCategories(updatedAllEvents));
    dispatch(setWishlistCategories(updatedCategories));
  };
  const handleDeleteCategory = (categoryId: number | null) => {
    if (categoryId !== null) {
      dispatch(deleteCategory(categoryId));
      setSelectedCategoryId(null);
      setActiveTab(0);
      dispatch(getWishlistsCategories());
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(getWishlistsCategories());
  }, [auth.token, dispatch]);

  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );

  return (
    <>
      <div className="container">
        <div className="pt-10 md:flex md:justify-between">
          <h3 className="text-lg font-bold 2xl:text-xl">Your Wishlist</h3>
          <div className="flex justify-between gap-2 pt-5 md:pt-0">
            {activeTab !== 0 && (
              <button
                className="rounded-full border-2 border-[#FF913F] px-3 py-2 font-medium"
                onClick={() => handleDeleteCategory(selectedCategoryId)}
              >
                Delete Category
              </button>
            )}

            <CreateWishlist />
            <div>
              {selectedCategoryId !== null && selectedCategoryId !== 0 && (
                <AddEventCategory
                  allEvents={all}
                  selectedCategoryId={selectedCategoryId}
                  categories={categories}
                />
              )}
            </div>
          </div>
        </div>
        <div className="my-10">
          <div className="flex justify-between border-b-2">
            <TabComponent
              titles={tabCategories}
              activeTab={activeTab}
              handleTabClick={handleTabClicked}
            />
          </div>
          {selectedCategoryId === null || selectedCategoryId === 0 ? (
            <div className="my-10 gap-10 md:grid md:grid-cols-4">
              {all.map((event: IEventsData) => (
                <Link
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  to={getNextURL({
                    nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
                      event.title,
                      event.id,
                    )}`,
                  })}
                  key={event.id}
                >
                  <EventCard key={event.id} event={event} />
                </Link>
              ))}
            </div>
          ) : selectedCategoryId &&
            selectedCategoryId !== 0 &&
            categories.find((category) => category.id === selectedCategoryId)
              ?.user_liked_events.length === 0 ? (
            <div className="flex h-[50vh] items-center justify-center">
              <div className="text-center text-2xl font-bold text-[#FF913F]">
                {t("events")}
              </div>
            </div>
          ) : (
            <div className="gap-10 md:grid md:grid-cols-4">
              {categories
                .find((category) => category.id === selectedCategoryId)
                ?.user_liked_events.map((eventType: IEventUserType) => (
                  <Link
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    to={getNextURL({
                      nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
                        eventType.event.title,
                        eventType.event.id,
                      )}`,
                    })}
                    key={eventType.event.id}
                  >
                    <EventCard
                      key={eventType.event.id}
                      event={eventType.event}
                      removeFromCategory={() =>
                        handleRemoveFromCategory(eventType.event.id)
                      }
                    />
                  </Link>
                ))}
            </div>
          )}
        </div>
        {auth.token && selectedCategoryId === null && all.length === 0 ? (
          <div className="empty grid place-items-center pb-20">
            <img
              loading="lazy"
              src="/assets/images/empty.png"
              alt="Empty Wishlist"
            />
            <h3 className="px-4 text-lg font-bold 2xl:px-7 2xl:text-xl">
              Your Wishlist is Empty
            </h3>
            <p className="pt-4 font-medium text-[#131C23]/40">
              Save activities to your wishlist by clicking on the heart icon.
            </p>
            <button
              className="mt-3 flex items-center justify-center rounded-full bg-[#FF913F] px-14 py-2 text-center text-white"
              onClick={handleFindThingsClick}
            >
              <span className="px-1 font-medium">Explore for more</span>
              <img
                loading="lazy"
                src="/assets/svgs/common/white-arrow.svg"
                className="w-4"
                alt="Find Things"
              />
            </button>
          </div>
        ) : null}
        {!auth.token ? (
          <div className="empty grid place-items-center pb-20">
            <p className="pt-4 text-xl font-medium text-[#131C23]/60">
              You need to Log in to see your wishlist.
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default WishList;
