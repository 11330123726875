import {
  addAdditionalInformationAPI,
  checkStripeConnectionAPI,
  createAgeAPI,
  createEventAPI,
  generateStripeAccountAPI,
  getAdditionalInformationAPI,
  getAllDropListEventsAPI,
  getBalanceStatisticsAPI,
  getDraftAPI,
  getEventByIdAPI,
  getEventsAPI,
  getEventViewsAPI,
  getGeneratedDatesAPI,
  getHistoryEventsAPI,
  getPaidEventsAPI,
  saveDraftAPI,
  successConnectionStripeAPI,
  successPromotionAPI,
  updateEventAPI,
} from "../../networking/BusinessDashboard/eventCreation";
import { AppDispatch } from "../store";
import { BusinessDashboardSlice } from "./BusinessDashboardSlice";
import { AdditionalInformation, IAgeObject } from "./types";
import {
  IAgeType,
  IGenerateDates,
} from "../../networking/BusinessDashboard/types";

import { GlobalStateSLice } from "../GlobalState/GlobalStateSlice";
import {
  toggleBackDrop,
  toggleShowButtonLoader,
} from "../GlobalState/ActionGlobalState";
import { toast } from "sonner";

export const getEventsData = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getEventsAPI();
    dispatch(BusinessDashboardSlice.actions.setTypes(response?.types));
    dispatch(BusinessDashboardSlice.actions.setInterests(response?.interests));
    dispatch(BusinessDashboardSlice.actions.setAges(response?.ages));
    dispatch(BusinessDashboardSlice.actions.setCommision(response?.commission));
    dispatch(
      BusinessDashboardSlice.actions.setCategories(response?.categories),
    );
    dispatch(
      BusinessDashboardSlice.actions.setExclusive(response?.exclusive_price),
    );
    dispatch(
      BusinessDashboardSlice.actions.setPromotion(response?.promo_price),
    );
  } catch (error) {
    throw error;
  }
};

export const createEventData =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
      const formData = new FormData();
      // Iterate through the keys of the payload object
      for (const key of Object.keys(payload)) {
        const value = payload[key];
        if (Array.isArray(value)) {
          // Handle arrays, including objects
          value.forEach((item, index) => {
            if (typeof item === "object") {
              // Assuming item is an object with properties like "title," "price," etc.
              Object.keys(item).forEach((prop) => {
                formData.append(`${key}[${index}][${prop}]`, item[prop]);
              });
            } else {
              // Handle other types in the array
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else {
          // Handle normal keys
          if (key !== "") formData.append(key, value);
        }
      }
      await createEventAPI(formData, {
        onUploadProgress: (progressEvent: any) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(
            BusinessDashboardSlice.actions.setCreationEventPercentatge(
              Number(progress.toFixed(0)),
            ),
          );
        },
      });
      toast.success("Event Created Successfully");
      dispatch(BusinessDashboardSlice.actions.setErrors({}));
    } catch (error: any) {
      dispatch(
        BusinessDashboardSlice.actions.setErrors(error?.response?.data.errors),
      );
      throw error;
    } finally {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    }
  };

export const setCreateEventData =
  (name: string, value: Object | string | number) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(BusinessDashboardSlice.actions.setEventData({ name, value }));
    } catch {}
  };

export const clearCreateEventData = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(BusinessDashboardSlice.actions.clearEventDataObject());
  } catch {}
};

export const createAge =
  (payload: IAgeObject, ages: IAgeType[]) => async (dispatch: AppDispatch) => {
    try {
      const response = await createAgeAPI(payload);
      dispatch(BusinessDashboardSlice.actions.setAges([...ages, response]));
      return response;
    } catch (error) {
      throw error;
    }
  };

export const getEventById =
  (eventId: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
      dispatch(GlobalStateSLice.actions.toggleLoading(true));
      const response = await getEventByIdAPI(eventId);
      dispatch(BusinessDashboardSlice.actions.setEventDataById(response));
    } catch (error) {
      throw error;
    } finally {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
      dispatch(GlobalStateSLice.actions.toggleLoading(false));
    }
  };

export const getDraftEvent = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
    dispatch(GlobalStateSLice.actions.toggleLoading(true));
    const response = await getDraftAPI();
    if (response !== null)
      dispatch(BusinessDashboardSlice.actions.setEventDataById(response));
  } catch (error) {
    throw error;
  } finally {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    dispatch(GlobalStateSLice.actions.toggleLoading(false));
  }
};

export const saveDraft = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const finalPayload = payload;
    delete finalPayload["images"];
    await saveDraftAPI(finalPayload);
  } catch (error: any) {
    throw error;
  } finally {
  }
};

export const updateEvent =
  (eventId: number, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
      const formData = new FormData();
      // Iterate through the keys of the payload object
      for (const key of Object.keys(payload)) {
        const value = payload[key];
        if (Array.isArray(value)) {
          // Handle arrays, including objects
          value.forEach((item, index) => {
            if (typeof item === "object") {
              // Assuming item is an object with properties like "title," "price," etc.
              Object.keys(item).forEach((prop) => {
                formData.append(`${key}[${index}][${prop}]`, item[prop]);
              });
            } else {
              // Handle other types in the array
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else {
          // Handle normal keys
          if (payload[key] !== null) formData.append(key, value);
        }
      }
      await updateEventAPI(
        eventId,
        formData,
        //   , {
        //   onUploadProgress: (progressEvent: any) => {
        //     const progress = (progressEvent.loaded / progressEvent.total) * 100;
        //   },
        // }
      );
      toast.success("Event Updated Successfully");
      dispatch(BusinessDashboardSlice.actions.setErrors({}));
    } catch (error: any) {
      dispatch(
        BusinessDashboardSlice.actions.setErrors(error?.response?.data.errors),
      );
      throw error;
    } finally {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    }
  };

export const addAdditionalInformation =
  (payload: AdditionalInformation) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleShowButtonLoader(true));
      await addAdditionalInformationAPI(payload);
      toast.success("Payment Information Added");
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    } finally {
      dispatch(toggleShowButtonLoader(false));
    }
  };

export const getAdditionalInformation = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getAdditionalInformationAPI();
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  } finally {
    dispatch(toggleBackDrop(false));
  }
};

export const getBalanceStatistics =
  (payload: Object) => async (dispatch: AppDispatch) => {
    try {
      const response = await getBalanceStatisticsAPI(payload);
      dispatch(BusinessDashboardSlice.actions.setBusinessBalance(response));
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    } finally {
    }
  };

export const getEventViews =
  (payload?: number) => async (dispatch: AppDispatch) => {
    try {
      const response = await getEventViewsAPI(payload);
      dispatch(BusinessDashboardSlice.actions.setEventViews(response));
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    }
  };

export const getAllDropListEvents = () => async () => {
  try {
    const response = await getAllDropListEventsAPI();
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  }
};

export const getPaidEvents =
  (payload: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleBackDrop(true));
      const response = await getPaidEventsAPI(payload);
      dispatch(
        BusinessDashboardSlice.actions.setBusinessHistory({
          name: "historyData",
          value: response.data,
        }),
      );
      dispatch(
        BusinessDashboardSlice.actions.setBusinessHistory({
          name: "current_page",
          value: response.current_page,
        }),
      );
      dispatch(
        BusinessDashboardSlice.actions.setBusinessHistory({
          name: "per_page",
          value: response.per_page,
        }),
      );
      dispatch(
        BusinessDashboardSlice.actions.setBusinessHistory({
          name: "total",
          value: response.total,
        }),
      );
      dispatch(
        BusinessDashboardSlice.actions.setBusinessHistory({
          name: "last_page",
          value: response.last_page,
        }),
      );
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    } finally {
      dispatch(toggleBackDrop(false));
    }
  };

export const getGeneratedDates =
  (payload: IGenerateDates) => async (dispatch: AppDispatch) => {
    try {
      const response = await getGeneratedDatesAPI(payload);
      dispatch(
        BusinessDashboardSlice.actions.setEventData({
          name: "working_days",
          value: response,
        }),
      );
      return response;
    } catch (error: any) {
      return error;
    } finally {
    }
  };

export const succesPromotion =
  (payload: string) => async (dispatch: AppDispatch) => {
    try {
      await successPromotionAPI(payload);
      toast.success("Payment Success");
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    }
  };

export const getHistoryEvents =
  (date: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
      const response = await getHistoryEventsAPI(date);
      return response;
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    } finally {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    }
  };

export const generateStripeAccount = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(true));
    const response = await generateStripeAccountAPI();
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  } finally {
    dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
  }
};

export const successConnectionStripe = () => async (dispatch: AppDispatch) => {
  try {
    const response = await successConnectionStripeAPI();
    dispatch(BusinessDashboardSlice.actions.setConnectedToStripe(1));
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  }
};

export const checkStripeConnection = () => async (dispatch: AppDispatch) => {
  try {
    const response = await checkStripeConnectionAPI();
    dispatch(BusinessDashboardSlice.actions.setConnectedToStripe(response));
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  }
};
