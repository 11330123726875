import { useTranslation } from "react-i18next";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";

export const Partners = () => {
  const { t } = useTranslation();
  return (
    <section id="home-partners" className="section-margin container">
      <div className="container grid gap-x-20 gap-y-10 rounded-3xl bg-primary-navy py-10 xl:grid-cols-2">
        <div className="my-10 content-center text-white lg:px-10">
          <h4 className="rounded-text mb-6 w-fit text-center">
            {t("partners.tag")}
          </h4>
          <h2 className="section-heading mb-6 max-w-md text-white">
            {t("partners.title")}
          </h2>
          <p className="mb-10">{t("partners.description")}</p>
          <ArrowedLink title="Partners" to="/partners">
            {t("partners.button_text")}
          </ArrowedLink>
        </div>
        <img
          loading="lazy"
          src="/assets/images/pages/home/partners.webp"
          alt="Events collage"
          title="Events collage"
          className="w-full max-w-xl justify-self-center object-contain max-xl:row-start-1"
        />
      </div>
    </section>
  );
};
export default Partners;
