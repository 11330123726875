import PrimaryButton from "../../../component/PrimaryButton";
import { useProfileImage } from "../_hooks";

const ProfileImage = () => {
  const { mutate, url, user, isPending, disabled, handleChange } =
    useProfileImage();
  return (
    <section
      id="profile-change-image"
      className="dashboard-card gray content-center"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          !disabled && mutate(new FormData(e.currentTarget));
        }}
        className="flex flex-wrap items-center gap-4 rounded-2xl bg-white p-4"
      >
        <input
          type="file"
          hidden
          id="profile-image"
          name="profile_pic"
          accept="image/*"
          onChange={handleChange}
        />
        <label
          className="shadow-card hover-link grid h-16 w-16 cursor-pointer place-content-center overflow-hidden rounded-full bg-white"
          htmlFor="profile-image"
        >
          <img
            src={
              ((url && url.toString()) ||
                (user && user.profile_pic?.toString())) ??
              ""
            }
            alt={user?.name ?? "User profile"}
          />
        </label>
        <div>
          <h4 className="text-lg font-bold">Your Profile Picture</h4>
          <p>
            Required: <span className="font-cold">100x100px</span>
          </p>
        </div>
        <PrimaryButton
          loading={isPending}
          disabled={disabled || isPending}
          title="Upload image"
          className="ml-auto w-fit"
        >
          Upload
        </PrimaryButton>
      </form>
    </section>
  );
};

export default ProfileImage;
