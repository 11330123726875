import { TSvgComponent } from "../../../types/utils/component";

const SvgX: TSvgComponent = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="black"
      {...props}
    >
      <g>
        <path
          d="M498.3,417.7c20.5,24,17.7,60-6.2,80.6c-21.4,18.3-52.9,18.3-74.3,0L256,336.6L94.3,498.3c-24,20.5-60,17.7-80.6-6.2
		c-18.3-21.4-18.3-52.9,0-74.3L175.4,256L13.7,94.3C-6.8,70.3-4,34.3,20,13.7c21.4-18.3,52.9-18.3,74.3,0L256,175.4L417.7,13.7
		c24-20.5,60-17.7,80.6,6.2c18.3,21.4,18.3,52.9,0,74.3L336.6,256L498.3,417.7z"
        />
      </g>
    </svg>
  );
};

export default SvgX;
