import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TCategories, TEvents, TInterests} from "./types";
import {IEventCategory, IEventsData} from "../../networking/events/types";

export const initialState: TEvents = {
    isLoading: false,
    allEvents: [],
    searchEvents: [],
    large_events: [],
    large_events_categories: [],
    promotional_events: [],
    promotional_events_categories: [],
    exclusive_events: [],
    exclusive_events_categories: [],
    exclusiveEvents: [],
    selectedCategoryId: null,
    selectedFilters: [],
    googleMapMarkers: [],
    liked_by_user: false,
    categories: [],
    interests:[],
    searchedEvents : [],
    next_page_url: null,
    promotionalEventsNextPage:null,
    last_page:null,
    current_page:null,
}

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        // todo : add your reducers to change and manipulate the state
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setSearchedEvents(state, action: PayloadAction<IEventsData[]>) {
            state.searchedEvents = action.payload;
        },
        setAllLargeEvents(state, action: PayloadAction<IEventsData[]>) {
            state.large_events = action.payload;
        },
        setCategoriesLargeEvents(state, action: PayloadAction<IEventCategory[]>) {
            state.large_events_categories = action.payload;
        },
        setLastPage(state, action: PayloadAction<number>) {
            state.last_page = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.current_page = action.payload;
        },
        setAllPromotionalEvents(state, action: PayloadAction<IEventsData[]>) {
            state.promotional_events = action.payload;
        },
        setPromotionalEvents(state, action: PayloadAction<IEventsData[]>) {
            state.promotional_events = action.payload;
        },
        setPromotionalEventsNextPage(state, action: PayloadAction<string | null>) {
            state.promotionalEventsNextPage = action.payload;
        },
        setCategoriesPromotionalEvents(state, action: PayloadAction<IEventCategory[]>) {
            state.promotional_events_categories = action.payload;
        },
        setAllExclusiveEvents(state, action: PayloadAction<IEventsData[]>) {
            state.exclusive_events = action.payload;
        },
        setCategoriesExclusiveEvents(state, action: PayloadAction<IEventCategory[]>) {
            state.exclusive_events_categories = action.payload;
        },
        setAllEvents(state, action: PayloadAction<IEventsData[]>) {
            state.allEvents = action.payload;
        },
        setSearchEvents(state, action: PayloadAction<IEventsData[]>) {
            state.searchEvents = action.payload;
        },
        setSelectedCategoryId(state, action: PayloadAction<number | null>) {
            state.selectedCategoryId = action.payload;
        },
        setSelectedFilters(state, action: PayloadAction<boolean[]>) {
            state.selectedFilters = action.payload;
        },
        setGoogleMapEvents(state, action: PayloadAction<IEventsData[]>) {
            state.googleMapMarkers = action.payload;
        },
        setLikedEvent(state, action: PayloadAction<number>) {
            const eventId = action.payload;
            const eventToUpdate = state.allEvents.find(event => event.id === eventId);
            if (eventToUpdate) {
                eventToUpdate.liked_by_user = !eventToUpdate.liked_by_user;
            }
        },
        setCategories(state, action: PayloadAction<TCategories[]>) {
            state.categories = action.payload;
        },
        setInterests(state, action: PayloadAction<TInterests[]>) {
            state.interests = action.payload;
        },

    }
})

export default eventsSlice.reducer;
