"use client";
import Select from "react-select";
import ArrowedButton from "../../common/buttons/ArrowedButton";
import { useHeaderLocations } from "./_hooks";

const LocationSearch = () => {
  const { data, handleSubmit, handleSelect, location } = useHeaderLocations();
  //   const { data, handleSubmit, handleSelect, location } = useLocationSearch();
  if (!data?.success) return null;
  return (
    <form
      onSubmit={handleSubmit}
      className="z-20 flex items-center gap-2 rounded-full bg-gray-200 p-1 text-sm"
    >
      <Select
        styles={{
          control: (base) => ({
            ...base,
            minHeight: "32px",
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor:
              state.isFocused || state.isSelected ? "#FF913F" : "white",
            color: state.isFocused || state.isSelected ? "#FFFFFF" : "#141D24",
          }),
        }}
        placeholder="Available locations"
        name="location"
        className="min-w-[300px]"
        options={Object.keys(data.data.cities).map((loc) => ({
          label: loc,
          value: loc,
        }))}
        value={location}
        onChange={(loc) =>
          handleSelect(loc as { label: string; value: string })
        }
      />
      <ArrowedButton
        title="Search by location"
        type="submit"
        disabled={!location}
        className="px-2.5 py-1.5"
      >
        Explore
      </ArrowedButton>
    </form>
  );
};

export default LocationSearch;
