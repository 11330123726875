import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../store/redux";
import CustomInput from "../../../../components/common/inputs/Input";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { setUserInformation } from "../../../../store/Cart/ActionCart";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PhoneInput from "react-phone-number-input";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
type props = {
  handleNext: () => void;
};
const PersonalDetails = (props: props) => {
  const data = useSelector(
    (state: RootState) => state.cartReducer.checkoutState.userData,
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userInfo =
    JSON?.parse(localStorage.getItem("userInfo") as string) ?? null;
  const handleChangeInput = (event: any) => {
    dispatch(setUserInformation(event.target.name, event.target.value));
  };
  const checkFieldsIsEmpty = (values: any) => {
    for (const key in values) {
      if (
        (values[key] === "" || values[key] === undefined) &&
        key !== "your_wish"
      ) {
        return true;
      }
    }
    return false;
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleNextButton = () => {
    if (!checkFieldsIsEmpty(data)) props.handleNext();
  };
  useEffect(() => {
    dispatch(setUserInformation("name", userInfo?.name));
    dispatch(setUserInformation("email", userInfo?.email));
    dispatch(setUserInformation("phone_number", userInfo?.phone_number));
    //eslint-disable-next-line
  }, []);
  return (
    <div className="grid grid-cols-2 gap-3">
      <div>
        <div className="mt-5">
          <label className="text-sm font-bold">
            {t("checkout.personalDetails.fullName")}
          </label>
          <CustomInput
            name="name"
            value={data.name}
            type="text"
            placeholder="Full Name"
            onChange={handleChangeInput}
            icon={<PersonIcon sx={{ color: "#d0d2d3" }} />}
          />
        </div>
        <div className="mt-5">
          <label className="text-sm font-bold">
            {t("checkout.personalDetails.email")}
          </label>
          <CustomInput
            value={data.email}
            name="email"
            type="text"
            placeholder="Your Email"
            onChange={handleChangeInput}
            icon={<EmailIcon sx={{ color: "#d0d2d3" }} />}
          />
        </div>
        <div className="mt-5">
          <label className="text-sm font-bold">
            {t("checkout.personalDetails.phoneNumber")}
          </label>
          <div
            className={
              "mt-3 flex h-[40px] justify-center rounded-lg bg-[#F3F3F4] px-3"
            }
          >
            <PhoneInput
              value={data.phone_number}
              initialValue
              Format="national"
              placeholder="Enter phone number"
              className="w-full [&_.PhoneInputInput]:bg-[#F3F3F4]"
              onChange={(val) =>
                dispatch(setUserInformation("phone_number", val?.toString()))
              }
            />
          </div>
        </div>
        <div className="flex w-full justify-center">
          <button
            disabled={checkFieldsIsEmpty(data) || !emailRegex.test(data.email)}
            onClick={handleNextButton}
            className={`mt-10 flex items-center justify-center rounded-full ${
              checkFieldsIsEmpty(data) || !emailRegex.test(data.email)
                ? "bg-[#d0d2d3]"
                : "bg-primary-orange"
            } w-full py-2 text-center text-white`}
          >
            <span className="px-1">{t("checkout.nextStep")}</span>
            <img
              src="/assets/svgs/common/white-arrow.svg"
              className="w-4"
              alt="Arrow"
            />
          </button>
        </div>
      </div>
      <div>
        <div className="mt-5">
          <label className="text-sm font-bold">
            {t("checkout.personalDetails.additionalRequests")}
          </label>
          <TextareaAutosize
            value={data.your_wish}
            className="mt-3 w-full  rounded-lg bg-[#131C23]/5 p-5 focus-visible:border-[#131C23]"
            placeholder={t("checkout.personalDetails.additionalRequests")}
            onChange={(e) => {
              dispatch(setUserInformation("your_wish", e.target.value));
            }}
            minRows={8}
            maxLength={1000}
          />
        </div>
      </div>
    </div>
  );
};
export default PersonalDetails;
