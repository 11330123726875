import { useEffect } from "react";
import { createPortal } from "react-dom";

type TProps = {
  loading: boolean;
};

const PageLoader = ({ loading = false }: TProps) => {
  useEffect(() => {
    if (loading) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [loading]);

  return createPortal(
    <section
      id="page-loader"
      style={{ direction: "ltr" }}
      className="single-grid fixed inset-0 z-50 h-full w-full overflow-hidden"
    >
      <div className="single-grid-item grid grid-cols-2">
        <aside
          className={`grid items-center justify-end overflow-x-hidden bg-black bg-opacity-80 duration-1000 ${
            !loading && "-translate-x-full"
          }`}
        >
          <div className="h-[140px] w-[140px] translate-x-1/2 rounded-full bg-white bg-opacity-50"></div>
        </aside>
        <aside
          className={`grid items-center justify-start overflow-x-hidden bg-black bg-opacity-80 duration-1000 ${
            !loading && "translate-x-full"
          }`}
        >
          <div className="h-[140px] w-[140px] -translate-x-1/2 rounded-full bg-white bg-opacity-50"></div>
        </aside>
      </div>
      <div
        className="single-grid single-grid-item place-items-center overflow-hidden rounded-full duration-300"
        style={{ opacity: loading ? 1 : 0, zIndex: loading ? 100 : -10 }}
      >
        <svg
          className="single-grid-item h-32 w-32 animate-spinner-loader invert [animation-duration:5s]"
          viewBox="0 0 44 44"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.55619 13.8699C0.552283 16.386 0 19.1296 0 22C0 34.1327 9.87111 44 22 44C24.8704 44 27.614 43.4477 30.1301 42.4438L29.5373 41.851C27.1939 42.744 24.653 43.2333 22 43.2333C10.2949 43.2333 0.770578 33.7089 0.770578 22.0039C0.770578 19.3509 1.25984 16.81 2.15288 14.4666L1.55619 13.8699ZM41.255 30.9412C42.5217 28.2233 43.2294 25.1946 43.2294 22.0039C43.2294 10.2988 33.7051 0.774435 22 0.774435C18.8092 0.774435 15.7805 1.48218 13.0626 2.7489L12.4808 2.16708C15.3628 0.778518 18.592 0 22 0C34.1327 0 44 9.86725 44 22C44 25.408 43.2215 28.6372 41.8329 31.5192L41.255 30.9412Z"
          />
        </svg>
        <svg
          className="single-grid-item h-24 w-24 animate-spin invert [animation-duration:5s]"
          viewBox="0 0 39 40"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.1869 29.579C28.6406 29.8964 29.1939 29.3433 28.8767 28.8894L22.6642 20.0009C22.6642 20.0008 22.6642 20.0008 22.6642 20.0008L22.6642 20.0008C22.6642 20.0008 22.6642 20.0008 22.6642 20.0008V20.0008C22.6642 20.0008 22.6642 20.0008 22.6642 20.0008L30.4793 8.8194C30.4793 8.81939 30.4793 8.81938 30.4793 8.81937V8.81937L30.4793 8.81937C30.4793 8.81936 30.4793 8.81936 30.4793 8.81935V8.81935C30.4793 8.81935 30.4793 8.81935 30.4793 8.81934L30.4793 8.81934C30.4793 8.81934 30.4793 8.81933 30.4793 8.81934L19.5816 16.4362C19.4112 16.5553 19.1845 16.5553 19.0141 16.4362L10.4093 10.4219C9.95541 10.1047 9.40228 10.658 9.71965 11.1118L15.7379 19.7169C15.8572 19.8874 15.8572 20.1142 15.7379 20.2847L9.72103 28.8879C9.65007 28.9893 9.62263 29.0958 9.62786 29.1963C9.6279 29.1971 9.6277 29.1978 9.62728 29.1984L9.62152 29.2068C9.61997 29.2091 9.623 29.2117 9.62499 29.2098V29.2098C9.62639 29.2084 9.62872 29.2093 9.62888 29.2113C9.65782 29.5535 10.0634 29.8206 10.4108 29.5776L19.014 23.5607C19.1844 23.4415 19.4112 23.4415 19.5817 23.5607L28.1869 29.579Z"
            />
          </g>
          <path d="M22.1444 23.243C22.1807 23.0382 22.341 22.878 22.5458 22.8417L35.8436 20.4877C36.3889 20.3912 36.389 19.6089 35.8438 19.5123L22.5456 17.1542C22.341 17.1179 22.1807 16.9577 22.1444 16.753L19.7864 3.45485C19.6897 2.90967 18.9074 2.90978 18.8109 3.455L16.4569 16.7529C16.4207 16.9576 16.2604 17.1179 16.0557 17.1542L2.75356 19.5123C2.20834 19.6089 2.20846 20.3912 2.7537 20.4877L16.0555 22.8417C16.2603 22.8779 16.4207 23.0383 16.4569 23.2431L18.8109 36.5449C18.9074 37.0902 19.6897 37.0903 19.7864 36.5451L22.1444 23.243Z" />
        </svg>
        <svg
          className="single-grid-item h-16 w-16 animate-spin invert-0 [animation-direction:reverse] [animation-duration:2s]"
          viewBox="0 0 23 24"
          fill="#FF8A32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0392 8.42621C15.0392 8.42622 15.0392 8.42624 15.0392 8.42625L22.8716 22.7297C23.1093 23.1638 22.6336 23.6396 22.1995 23.4021L7.89138 15.5741C7.89136 15.5741 7.89135 15.574 7.89137 15.574V15.574C7.89138 15.574 7.89138 15.574 7.89138 15.574L0.063364 1.2659C-0.17417 0.831734 0.301707 0.356031 0.735784 0.593727L15.0392 8.42615C15.0393 8.42616 15.0393 8.42619 15.0392 8.42621V8.42621Z"
          />
        </svg>
        <div className="single-grid-item h-3 w-3 animate-pulse rounded-full bg-white"></div>
      </div>
    </section>,
    document.body,
  );
};

export default PageLoader;
