import { ComponentProps } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import ArrowLineSvg from "../../../component/SvgComponents/ArrowLineSvg";

type TProps = ComponentProps<typeof Link>;

const ArrowedLink = (props: TProps) => {
  return (
    <Link
      {...props}
      className={twMerge(
        "hover-link group flex w-max items-center gap-2 rounded-full bg-white px-5 py-2.5 text-black",
        props.className,
      )}
    >
      {props.children}
      <ArrowLineSvg className="box-content h-3 w-3 rounded-full bg-black p-1.5 duration-300 group-hover:rotate-[360deg]" />
    </Link>
  );
};

export default ArrowedLink;
