import SvgKey from "../../../../assets/svgs/icons/SvgKey";
import SvgCopy from "../../../../assets/svgs/icons/SvgCopy";
import { toast } from "sonner";

const Code = () => {
  const code = "# 3123125 123";
  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    toast.success("Text coppied!");
  };
  return (
    <section
      id="referral-code"
      className="dashboard-card gray flex flex-col justify-between gap-6"
    >
      <div className="flex flex-wrap items-center justify-between gap-4">
        <h3 className="section-title">Your Refferial Code</h3>
        <p className="rounded-full bg-green-50 px-5 py-1.5 text-center text-sm text-green-500">
          You Get Discount: <b>2%</b>
        </p>
      </div>
      <div>
        <p>
          This is your <b className="text-purple-600">personalized</b> referral
          code
        </p>
        <div className="mt-5 flex flex-wrap items-center justify-between gap-2 rounded-xl bg-white p-[3px]">
          <div className="flex items-center gap-3 px-3 py-2">
            <SvgKey className="h-4 w-4" />
            <p>{code}</p>
          </div>
          <button
            title="Copy code"
            type="button"
            onClick={handleCopy}
            className="ml-auto flex items-center gap-2 rounded-xl bg-primary-orange/10 px-6 py-1.5 font-bold"
          >
            <SvgCopy className="h-4 w-4" />
            Copy
          </button>
        </div>
      </div>
    </section>
  );
};
export default Code;
