import axios from "axios";
import { ILoginData, NotficationObject, UserData } from "./types";
import { UpatePassword, UserLogin } from "../../store/Auth/types";

export const SignUpAPI = async (userData: UserData) => {
  try {
    const response = await axios.post<{ data: ILoginData }>(
      "register",
      userData,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const SignIn = async (loginPayload: UserLogin) => {
  try {
    const response = await axios.post<{ data: ILoginData }>(
      "login",
      loginPayload,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const loginWithGoogleAPI = async (data: {
  token: string;
  email: string;
  id: string;
  name: string;
}) => {
  try {
    const response = await axios.post("social-media/callback/google", data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const loginWithAppleAPI = async (data: {
  token: string;
  id: string;
  email?: string;
  name?: string;
}) => {
  try {
    const response = await axios.post("social-media/callback/apple", data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const GenerateGuestTokenAPI = async () => {
  try {
    const response = await axios.get("guest/token-generate");
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const verifyEmailAPI = async (otp: string) => {
  try {
    const response = await axios.post("verify-email", { otp: otp });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const resendOTPAPI = async () => {
  try {
    const response = await axios.post("resend-email-verification");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getInterestAPI = async () => {
  try {
    const response = await axios.get(`interest`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const setInterestUserAPI = async (payload: number[]) => {
  try {
    const response = await axios.post(`interest`, { interests: payload });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const logoutUserAPI = async () => {
  try {
    const response = await axios.post("logout");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const updatePasswordAPI = async (payload: UpatePassword) => {
  try {
    const response = await axios.post("change-password", payload);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const toggleNotificationAPI = async (notificationId: number) => {
  try {
    const response = await axios.post(`notifications/${notificationId}/toggle`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordEmailAPI = async (email: string) => {
  try {
    const response = await axios.post("forgot-password-email", {
      email: email,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const checkOtpAPI = async (otp: string, email: string) => {
  try {
    const response = await axios.post("check-otp", {
      otp: otp,
      email: email,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const createNewPasswordAPI = async (
  password: string,
  password_confirmation: string,
  email: string,
  otp: string,
) => {
  try {
    const response = await axios.post("create-new-password", {
      password: password,
      password_confirmation: password_confirmation,
      email: email,
      otp: otp,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
