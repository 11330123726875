import { z } from "zod";

export const zodRequiredString = (field: string, message?: string) =>
  z
    .string({
      required_error: message
        ? message
        : `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
    })
    .min(
      1,
      message
        ? message
        : `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
    );
