import { atomWithStorage } from "jotai/utils";
import { TLanguage } from "../types/language";

export const languageAtom = atomWithStorage<
  TLanguage | { code: string; title: string }
>(
  "language",
  {
    code: "en",
    title: "English",
  },
  undefined,
  {
    getOnInit: true,
  },
);
