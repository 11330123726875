const Banner = () => {
  return (
    <section id="about-banner" className="single-grid h-[500px]">
      <img
        className="single-grid-item h-full w-full object-cover"
        src="/assets/images/pages/about/banner-background.webp"
        alt="Flying airplane wing"
      />
      <div className="single-grid-item section-margin container flex items-end justify-center">
        <h2 className="capitalize text-white">Your online travel agency</h2>
      </div>
    </section>
  );
};

export default Banner;
