import LockIcon from "@mui/icons-material/Lock";
import CustomInput from "../../../components/common/inputs/Input";
import PrimaryButton from "../../../component/PrimaryButton";
import { useProfileChangePassword } from "../_hooks";

const ChangePassword = () => {
  const {
    handleSubmit,
    t,
    disabled,
    handleChange,
    register,
    resError,
    errorMessage,
    isPending,
  } = useProfileChangePassword();
  return (
    <section id="profile-change-password" className="dashboard-card gray">
      <h3 className="section-title">
        {t("userProfile.profileSettings.changePassword.title")}
      </h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        onChange={disabled ? handleChange : undefined}
      >
        <CustomInput
          inputBgColor="bg-white"
          type="text"
          placeholder={t("userProfile.profileSettings.changePassword.password")}
          icon={<LockIcon sx={{ color: "#d0d2d3" }} />}
          {...register("current_password")}
        />
        <p className="text-red-600">
          {resError?.errors?.current_password ||
            errorMessage("current_password")}
        </p>
        <CustomInput
          inputBgColor="bg-white"
          type="text"
          placeholder="New Password"
          {...register("password")}
        />
        <p className="text-red-600">
          {resError?.errors?.current_password || errorMessage("password")}
        </p>
        <CustomInput
          inputBgColor="bg-white"
          type="text"
          placeholder="Confirm New Password"
          {...register("password_confirmation")}
        />
        <p className="text-red-600">
          {resError?.errors?.current_password ||
            errorMessage("password_confirmation")}
        </p>
        <PrimaryButton
          disabled={disabled || isPending}
          loading={isPending}
          type="submit"
          title="Change password"
          className="ml-auto w-fit px-5 py-2.5"
        >
          Change
        </PrimaryButton>
      </form>
    </section>
  );
};
export default ChangePassword;