import { ChangeEvent, useState } from "react";
import SvgCopy from "../../../../assets/svgs/icons/SvgCopy";
import SvgKey from "../../../../assets/svgs/icons/SvgKey";

const Become = () => {
  const [code, setCode] = useState("");
  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    setCode((_prev) => (_prev = text));
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode((_prev) => (_prev = e.target.value));
  };
  return (
    <section
      id="referral-become"
      className="dashboard-card gray flex flex-col justify-between gap-6"
    >
      <h3 className="section-title">Become Refferial</h3>
      <div>
        <p>Enter a unique referral code in the field</p>
        <div className="mt-5 flex flex-wrap items-center justify-between gap-2 rounded-xl bg-white p-[3px]">
          <div className="flex flex-grow items-center gap-3 px-3 py-2">
            <SvgKey className="h-4 w-4" />
            <input
              type="text"
              value={code}
              onChange={handleChange}
              className="flex-grow"
            />
          </div>
          <button
            title="Copy code"
            type="button"
            onClick={handlePaste}
            className="ml-auto flex items-center gap-2 rounded-xl bg-blue-100 px-6 py-1.5 font-bold"
          >
            <SvgCopy className="h-4 w-4 fill-blue-600" />
            Paste
          </button>
        </div>
      </div>
    </section>
  );
};
export default Become;
