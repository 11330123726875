import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useAppDispatch } from "../../../../store/redux";
import {
  generateStripeAccount,
  successConnectionStripe,
} from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import PrimaryButton from "../../../../component/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const StripeAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const urlParams = useLocation();
  const stripeSuccess = new URLSearchParams(urlParams.search).get("stripe");
  const connectedToStripe = useSelector(
    (state: RootState) => state.businessDashboardReducer?.connectedToStripe,
  );

  useEffect(() => {
    if (stripeSuccess === "success") {
      dispatch(successConnectionStripe());
    }
    //eslint-disable-next-line
  }, [urlParams]);
  const handleRegisterAccount = () => {
    dispatch(generateStripeAccount())
      .then((data) => {
        window.location.href = data;
      })
      .catch(() => {});
  };

  return (
    <section id="profile-stripe" className="dashboard-card gray">
      <h3 className="section-title mb-4">
        {t("businessProfile.setting.paymentInformation.stripeAccount")}
      </h3>
      <PrimaryButton
        disabled={connectedToStripe === 1 || connectedToStripe === 0}
        onClick={handleRegisterAccount}
        className={`mt-5 w-fit rounded-full border-none px-6 py-2 ${
          connectedToStripe === 1 ? "" : "bg-red-600 text-white"
        }`}
      >
        {connectedToStripe === null
          ? t("businessProfile.setting.paymentInformation.registerStripe")
          : connectedToStripe === 0
          ? t("businessProfile.setting.paymentInformation.stripeNotAvailable")
          : t(
              "businessProfile.setting.paymentInformation.alreadyConnectedToStripe",
            )}
      </PrimaryButton>
      {/* <div className="rounded-full bg-[#FF585F]/5 px-4 py-1 text-sm text-[#FF585F]">
              <span className="font-medium">
                {t("businessProfile.setting.paymentInformation.stripeMessage")}
              </span>
            </div> */}
    </section>
  );
};
export default StripeAccount;
