import { Link } from "react-router-dom";
import LogoSvg from "../../../component/SvgComponents/LogoSvg";
import LocationSearch from "./LocationSearch";
import { useHeader } from "./_hooks";
import GlobalSearch from "./GlobalSearch";
import Languages from "./Languages";
import Currencies from "./Currencies";
import { useMe } from "../../../utils/hooks";
import SvgHeart from "../../../assets/svgs/icons/Heart";
import Cart from "./Cart";
import { Avatar } from "@mui/material";
import AuthComponent from "../../../component/Header/component/AuthComponent";

const Header = () => {
  const {
    absolute,
    token,
    handleOpenAuthModal,
    handleNavigateUser,
    t,
    user,
    closeModal,
    searchParams,
    openAuthModal,
  } = useHeader();
  const { data: loggedIn } = useMe();
  return (
    <header
      id="site-header"
      className={`max-lg:hidden ${absolute ? "header-absolute" : ""}`}
    >
      <div className="container flex items-center justify-between gap-6 py-10">
        <div className="flex items-center gap-4">
          <Link to="/" title="Home" className="hover-link">
            <LogoSvg
              className={`max-w-44 h-10 ${
                absolute ? "" : "[&_path[fill='white']]:fill-primary-black"
              }`}
            />
          </Link>
          <LocationSearch />
        </div>
        <div className="flex items-center gap-4">
          <GlobalSearch />
          <Languages />
          <Currencies />
          {loggedIn?.success && (
            <>
              <Link
                to={process.env.NEXT_PUBLIC_REACT_WEBSITE_URL ?? "/"}
                title="Whishlist"
                className="hover-link"
              >
                <SvgHeart className="svg-stroke h-5 w-5 fill-transparent" />
              </Link>
              <Cart />
            </>
          )}
          {!token ? (
            <button
              type="button"
              title={t("signIn")}
              className="hover-link"
              onClick={handleOpenAuthModal}
            >
              {t("signIn")}
            </button>
          ) : (
            <button
              type="button"
              title="Profile"
              className="hover-link flex items-center gap-2"
              onClick={handleNavigateUser}
            >
              <img
                className="h-10 w-10 rounded-full"
                alt={user?.name}
                src={user?.profile_pic}
                width={40}
                height={40}
              />
              <p>{user?.name}</p>
            </button>
          )}
        </div>
      </div>
      <AuthComponent
        openModal={!!searchParams.get("nextRedirect") || openAuthModal}
        onClose={closeModal}
      />
    </header>
  );
};

export default Header;
