import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../store/redux";
import React from "react";

interface AboutEventProps {
  title: string;
  averageRating: number | null;
  businessId: number;
  description: string;
  duration: string;
  duration_type: string;
  displayAge: string;
  language: string;
  selectedLanguage: string;
  business: {
    id: number;
    name: string;
    profile_pic: string;
  };
}

const AboutEvent: React.FC<AboutEventProps> = ({
  averageRating,
  duration,
  displayAge,
  language,
  selectedLanguage,
  business,
}) => {
  const { t } = useTranslation();
  const { eventDetails } = useAppSelector((state) => state.eventDetailsReducer);
  const content = eventDetails.translations?.[selectedLanguage];

  return (
    <div className="">
      <div className="flex items-center gap-2">
        <p className="font-medium text-[#131C23]/40">{t("operator")}:</p>
        <img
          loading="lazy"
          src={business.profile_pic}
          className="aspect-square h-[35px] w-[35px] rounded-full object-cover"
          alt="event"
        />
        <p> {business.name}</p>
      </div>
      <h2 className="py-2">{content?.title}</h2>
      <div className="flex gap-5">
        <div className="flex items-center">{/*Review Images goes here*/}</div>
        {eventDetails.total_reviews && (
          <div className="flex items-center">
            <p>{eventDetails.total_reviews} </p>{" "}
            <span className="ml-2 text-[#131C23]/40"> {t("reviews")}:</span>
          </div>
        )}
        <div className="flex items-center">
          <img loading="lazy" src="/assets/svgs/common/star.svg" alt="rating" />
          <p className="ml-2 text-sm font-bold text-[#131C23]">
            {averageRating}
          </p>
        </div>
        {/*<div className="flex items-center bg-[#FF585F]/25 px-5 py-2 rounded-full">*/}
        {/*    <img src="/assets/svgs/eventDetails/recomended-heart.svg" alt="recommended event"/>*/}
        {/*    <p className="text-sm font-bold text-[#131C23] ml-1">94% </p> <span className="text-[#131C23]/40 font-medium ml-2">Recommended</span>*/}
        {/*</div>*/}
      </div>
      <h3 className="mb-3 pt-4 text-2xl font-bold text-[#131C23]">
        {t("aboutEvents")}
      </h3>

      <textarea
        value={content?.description}
        className="h-[500px] w-[100%] text-base text-[#131C23]"
        disabled
      ></textarea>

      <div className="border-b-[1px] border-[#131C23]/30 py-4"></div>
      <div className="items-center justify-between py-5 md:flex">
        <div className="my-4 flex items-center text-sm md:my-0">
          <img
            loading="lazy"
            src="/assets/svgs/eventDetails/duration.svg"
            alt="time"
          />
          <p className="font-sm ml-2 text-[#131C23]/40">{t("duration")}:</p>
          <p className="ml-1 font-bold">
            {duration} {eventDetails.duration_type}
          </p>
        </div>
        <div className="my-4 flex items-center  text-sm md:my-0">
          <img
            loading="lazy"
            src="/assets/svgs/eventDetails/mobile.svg"
            alt="ticket"
          />
          <p className="font-sm ml-2 text-[#131C23]/40">{t("ticket")}: </p>
          <p className="ml-1 font-bold">Mobile</p>
        </div>
        <div className="my-4 flex items-center  text-sm md:my-0">
          <img
            loading="lazy"
            src="/assets/svgs/eventDetails/age.svg"
            alt="age"
          />
          <p className="font-sm ml-3 text-[#131C23]/40">{t("ages")}: </p>
          <p className="ml-1 font-bold">{displayAge}</p>
        </div>
        <div className="my-4 flex items-center  text-sm md:my-0">
          <img
            loading="lazy"
            src="/assets/svgs/eventDetails/language.svg"
            alt="language"
          />
          <p className="ml-2 font-medium text-[#131C23]/40">
            {t("language")}:{" "}
          </p>
          <p className="ml-1 font-bold">{language}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutEvent;
