import { TSvgComponent } from "../../../types/utils/component";

const SvgUser: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 14 18"
      fill="#DB3FFF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99935 0.875C4.81322 0.875 3.04102 2.64721 3.04102 4.83333C3.04102 7.01946 4.81322 8.79167 6.99935 8.79167C9.18548 8.79167 10.9577 7.01946 10.9577 4.83333C10.9577 2.64721 9.18548 0.875 6.99935 0.875ZM4.49935 10.875C2.31322 10.875 0.541016 12.6472 0.541016 14.8333C0.541016 16.099 1.56703 17.125 2.83268 17.125H11.166C12.4317 17.125 13.4577 16.099 13.4577 14.8333C13.4577 12.6472 11.6855 10.875 9.49935 10.875H4.49935Z"
      />
    </svg>
  );
};

export default SvgUser;
