import { AppDispatch } from "../store";
import { GlobalStateSLice } from "./GlobalStateSlice";
import { getCountriesAPI } from "../../networking/GlobalEndPoints/globalEndPoints";


export const toggleLoading =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleLoading(loaderValue));
    } catch {}
  };

export const toggleShowButtonLoader =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleButtonLoader(loaderValue));
    } catch {}
  };
export const toggleBackDrop =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(loaderValue));
    } catch {}
  };

export const getCountries = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getCountriesAPI();
    dispatch(GlobalStateSLice.actions.setCountries(response));
  } catch {}
};


