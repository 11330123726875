import { useEffect } from "react";
import SupportCommunities from "./components/SupportCommunities";
import PartOfJourney from "./components/PartOfJourney";
import WordsOfSupport from "./components/WordsOfSupport";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store/redux";
import { stripePayment } from "../../store/Crowdfunding/ActionCrowdfunding";
import BannerImage from "../../components/section/BannerImage";
import { removeBodyBackground } from "../../utils/functions";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../types/utils/laravel";
import Ambition from "./components/Ambition";
import BeTheChange from "./components/BeTheChange";
import { TContributionResponse } from "../../types/contribution";
import { toast } from "sonner";

const CrowdfundingPage = () => {
  const dispatch = useAppDispatch();
  const urlParams = useLocation();
  const paymentIntent = new URLSearchParams(urlParams.search).get(
    "payment_intent",
  );

  useEffect(() => {
    const contributionId = localStorage.getItem("contribution_id") || "";

    if (!paymentIntent) {
      return;
    }
    const payment = stripePayment(contributionId, paymentIntent);
    dispatch(payment)
      .then(() => {
        toast.success("Payment Success");
        removeAllQueryParams();
      })
      .catch((error: any) => {
        toast.error(error?.response?.data.message ?? error?.message);
        removeAllQueryParams();
      });
    //eslint-disable-next-line
  }, [urlParams]);

  const removeAllQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = "";
    window.history.replaceState({}, "", url.toString());
  };

  const { data } = useQuery({
    queryKey: ["crowdfunding"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<TContributionResponse>>(
          "contributions",
        );
      return res.data;
    },
  });
  useEffect(() => removeBodyBackground(), []);

  return (
    <main id="crowdfunding-content">
      <BannerImage
        id="crowdfunding"
        src="/assets/images/pages/crowdfunding/banner-background.webp"
        title="We are here to make a difference"
      />
      <Ambition
        goal={data?.success ? data.data.goal : undefined}
        raised={data?.success ? data.data.raised : undefined}
      />
      <BeTheChange />
      <SupportCommunities
        contributors={data?.success ? data.data.recent_contributors : undefined}
      />
      <WordsOfSupport
        contributors={data?.success ? data.data.slider_contributors : undefined}
      />
      <PartOfJourney />
    </main>
  );
};
export default CrowdfundingPage;
