import CustomSelect from "../../../../../../components/common/inputs/Select";
import { TEventInterest } from "../../../../../../types/event";
import { useEventCreationIntrests } from "../../_hooks";

const Intrests = () => {
  const { data, fields, handleChange } = useEventCreationIntrests();
  return (
    <>
      <CustomSelect
        required
        id="event-intrests"
        isMulti
        getOptionValue={(option) => (option as TEventInterest).id.toString()}
        getOptionLabel={(option) => (option as TEventInterest).name}
        options={data?.interests ?? []}
        value={fields ?? []}
        onChange={handleChange}
        placeholder="Select event user interest"
      />
      {fields &&
        fields.map((int, index) => {
          if (int)
            return (
              <input
                key={`event_creation_interest_${int?.id}`}
                type="text"
                name={`interests[${index}]`}
                defaultValue={int?.id}
                hidden
              />
            );
          return null;
        })}
    </>
  );
};

export default Intrests;
