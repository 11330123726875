import PageLoader from "../../../../component/PageLoader";
import { convertPrices, toastFallback } from "../../../../utils/functions";
import Modal from "../../../../components/common/Modal";
import { useTicketDisplay } from "../_hooks";
import SvgLogoCompass from "../../../../assets/svgs/logo/SvgLogoCompass";
import SvgLocationPin from "../../../../assets/svgs/icons/SvgLocationPin";
import SvgUser from "../../../../assets/svgs/icons/SvgUser";
import SvgCalendar from "../../../../assets/svgs/icons/SvgCalendar";
import { useState } from "react";
import SvgArrowLine from "../../../../assets/svgs/arrows/SvgArrowLine";

const TicketModal = () => {
  const { t, isLoading, data, handleClose, searchParams, selectedCurrency } =
    useTicketDisplay();
  const [index, setIndex] = useState(0);
  if (isLoading) return <PageLoader loading />;
  if (!data?.success) {
    toastFallback();
    return null;
  }
  const handlePrevious = () => {
    if (index > 0) {
      setIndex((_prev) => (_prev = index - 1));
    }
  };
  const handleNext = () => {
    if (index < data.data.length - 1) {
      setIndex((_prev) => (_prev = index + 1));
    }
  };
  return (
    <Modal
      onClick={handleClose}
      open={!!searchParams.get("ticket-id")}
      modalClass="p-2 rounded-2xl"
    >
      {data.data[index] ? (
        <>
          <div className="mb-2 flex items-center justify-between gap-4">
            <div className="flex items-center gap-2">
              <SvgLogoCompass className="h-9 w-9" />
              <h3 className="text-2xl font-bold">Your Ticket</h3>
            </div>
            {data.data.length > 1 && (
              <div className="mx-2 flex items-center gap-2">
                <p>
                  Ticket: {index + 1} of {data.data.length}
                </p>
                {index > 0 && (
                  <button
                    type="button"
                    title="Previous ticket"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePrevious();
                    }}
                  >
                    <SvgArrowLine className="h-6 w-6 rotate-90 fill-primary-gray" />
                  </button>
                )}
                {index < data.data.length - 1 && (
                  <button
                    type="button"
                    title="Next ticket"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNext();
                    }}
                  >
                    <SvgArrowLine className="h-6 w-6 -rotate-90 fill-primary-gray" />
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="grid rounded-t-xl bg-primary-navy md:grid-cols-[1.5fr_1fr]">
            <div className="container border-dashed border-white/50 py-6 text-white md:border-r-8">
              <div className="mb-4">
                <div className="mb-2 flex flex-wrap items-center gap-2">
                  <p className="text-primary-orange">Operator:</p>
                  {data.data[index].age.event_age.business.profile_pic && (
                    <img
                      className="h-10 w-10 object-contain"
                      src={data.data[index].age.event_age.business.profile_pic}
                      alt="Operator"
                    />
                  )}
                  <p>{data.data[index].age.event_age.business.name}</p>
                </div>
                <div className="mb-2 flex flex-wrap items-center gap-2">
                  <p className="text-primary-orange">Phone Number:</p>
                  <p>{data.data[index].age.event_age.business.phone_number}</p>
                </div>
              </div>
              {data.data[index].age.event.title && (
                <h4 className="mb-4 text-2xl">
                  {data.data[index].age?.event.title}
                </h4>
              )}
              {data.data[index].age.event.address && (
                <div className="mb-4 flex gap-4">
                  <SvgLocationPin className="box-content h-7 w-7 rounded-xl bg-blue-600/25 fill-blue-600 p-4" />
                  <div className="py-1">
                    <h6 className="font-bold">
                      {t("userProfile.tickets.address")}
                    </h6>
                    <p>{data.data[index].age.event.address}</p>
                  </div>
                </div>
              )}
              {data.data[index].age.event_age.label && (
                <div className="mb-4 flex gap-4">
                  <SvgUser className="box-content h-7 w-7 rounded-xl bg-purple-600/25 fill-purple-600 p-4" />
                  <div className="py-1">
                    <h6 className="font-bold">
                      {t("userProfile.tickets.people")}
                    </h6>
                    <p>{data.data[index].age.event_age.label}</p>
                  </div>
                </div>
              )}
              {data.data[index].date && (
                <div className="mb-4 flex gap-4">
                  <SvgCalendar className="box-content h-7 w-7 rounded-xl bg-green-600/25 fill-green-600 p-4" />
                  <div className="py-1">
                    <h6 className="font-bold">
                      {t("userProfile.tickets.date")}
                    </h6>
                    <p>{data.data[index].date}</p>
                  </div>
                </div>
              )}
              {typeof data.data[index].paid_amount === "number" && (
                <div className="flex items-center justify-between gap-2 rounded-xl bg-primary-orange/25 px-4 py-2 text-xl">
                  <h4> {t("userProfile.tickets.paid")}:</h4>
                  <span className="text-primary-orange">
                    {convertPrices(
                      data.data[index].paid_amount,
                      selectedCurrency,
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="section-padding row-start-1 grid place-content-center px-10 md:col-start-2">
              <img
                className="object-contain"
                src={data.data[index].qr_code}
                alt={`QR Code ${data.data[index].id}`}
              />
            </div>
          </div>
          <div className="rounded-b-xl bg-primary-orange px-6 py-2 text-white">
            <p>
              Thank you for using Explore Near Me Ltd, by purchasing this ticket
              you have contributed a portion of tickets cost towards explore
              near me mission in helping orphans
            </p>
          </div>
        </>
      ) : (
        <div className="m-10">
          <p>Something went wrong...</p>
        </div>
      )}
    </Modal>
  );
};

export default TicketModal;
