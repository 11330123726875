import { AppDispatch } from "../store";
import { TCart, TEventData } from "./types";
import {
  PaymentSuccessAPI,
  SinglePaymentAPI,
  addToCartAxios,
  deleteEventCartAxios,
  getCartItemsAxios,
  selectedCartAxios,
  guestInformationAPI,
  guestPaymentAPI,
  SplitPaymentAPI,
  getSelectedCartAPI,
  getTicketAPI,
  submitTicketAPI,
  buyNowAxios,
  fetchCartItemCountAPI,
} from "../../networking/eventDetails/cart";
import {
  addToCartItem,
  setCartItem,
  setClientSecretKey,
  removeFromCart,
  setCartTotal,
  setLoading,
  setPaymentSelection,
  setUserData,
  setSelectedCart,
  setClientCode,
  setSelectedCartItem,
  setSingleTicket,
  setCartItemCount,
} from "./CartSlice";

import { GlobalStateSLice } from "../GlobalState/GlobalStateSlice";
import {
  toggleBackDrop,
  toggleShowButtonLoader,
} from "../GlobalState/ActionGlobalState";
import { toast } from "sonner";

export const addToCart = (item: TCart) => async (dispatch: AppDispatch) => {
  try {
    const feeQuantities = item.fees.map((fee) => ({
      event_fees_id: fee.fee_id,
      quantity: fee.selectedFees,
    }));

    const addToCartEventResponse = await addToCartAxios(
      item.eventId,
      item.ages,
      item.date,
      item.time,
      feeQuantities,
    );

    if (addToCartEventResponse) {
      dispatch(addToCartItem(addToCartEventResponse.cart));
      toast.success("Item Added to Cart");
    } else {
      console.error("Error adding item to cart.");
    }
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  } finally {
    dispatch(setLoading(false));
  }
};

export const buyNow = (item: TCart) => async (dispatch: AppDispatch) => {
  try {
    const feeQuantities = item.fees.map((fee) => ({
      event_fees_id: fee.fee_id,
      quantity: fee.selectedFees,
    }));

    const addToCartEventResponse = await buyNowAxios(
      item.eventId,
      item.ages,
      item.date,
      item.time,
      feeQuantities,
    );

    if (addToCartEventResponse) {
      dispatch(addToCartItem(addToCartEventResponse.cart));
      toast.success("Success");
      window.location.href = "/checkout";
    } else {
      console.error("Error adding item to cart.");
    }
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCartItems = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getCartItemsAxios();
    dispatch(setSelectedCartItem([]));
    dispatch(setCartTotal(response.cartTotal));
    dispatch(setCartItem(response.events));
    dispatch(setUserData({ name: "total_price", value: response.cartTotal }));
    dispatch(
      setUserData({ name: "initial_total_price", value: response.cartTotal }),
    );
  } catch (error) {
    toast.error("Somwthing went wrong");
  } finally {
    dispatch(setLoading(false));
    dispatch(toggleBackDrop(false));
  }
};

export const getSelectedCartItems = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getSelectedCartAPI();
    dispatch(setSelectedCartItem(response.events));
    dispatch(setCartTotal(response.cartTotal));
    dispatch(setUserData({ name: "total_price", value: response.cartTotal }));
    dispatch(
      setUserData({ name: "initial_total_price", value: response.cartTotal }),
    );
  } catch (error) {
    toast.error("Something went wrong");
  } finally {
    dispatch(setLoading(false));
    dispatch(toggleBackDrop(false));
  }
};

export const updateCartItem =
  (data: TEventData[]) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCartItem(data));
    } catch (error) {
    } finally {
    }
  };

export const setTotalPrice =
  (value: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setUserData({ name: "total_price", value: value }));
    } catch (error) {
      throw error;
    }
  };

export const deleteEventFromCart =
  (eventId: number) => async (dispatch: AppDispatch) => {
    try {
      await deleteEventCartAxios(eventId);
      dispatch(removeFromCart(eventId));
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      dispatch(setLoading(false));
    }
  };

export const selectedCart =
  (cartIds: number[]) => async (dispatch: AppDispatch) => {
    try {
      const clientCode = localStorage.getItem("clientToken");
      const formattedCart = clientCode
        ? { carts: cartIds, guest_token: clientCode }
        : { carts: cartIds };
      const response = await selectedCartAxios(formattedCart);
      dispatch(setSelectedCart(response.cart));
    } catch (error) {
      toast.error("Something went wrong");
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setUserInformation =
  (name: string, value: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setUserData({ name, value }));
    } catch {}
  };

export const setPayment = (value: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setPaymentSelection(value));
  } catch {}
};

export const SinglePaymentHandler =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleShowButtonLoader(true));
      const response = await SinglePaymentAPI(payload);
      dispatch(setClientSecretKey(response));
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    } finally {
      dispatch(toggleShowButtonLoader(false));
    }
  };

export const SplitPaymentHandler =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleShowButtonLoader(true));
      const response = await SplitPaymentAPI(payload);
      if (response === null) window.location.href = "/history";
      else {
        dispatch(setCartItem(response?.events));
        dispatch(setClientSecretKey(response?.client_secret));
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(toggleShowButtonLoader(false));
    }
  };

export const PaymentSuccess =
  (payload: string) => async (dispatch: AppDispatch) => {
    try {
      await PaymentSuccessAPI(payload);
      toast.success("Payment Success");
    } catch (error) {
      throw error;
    }
  };

export const guestInformation =
  (payload: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleBackDrop(true));
      const response = await guestInformationAPI(payload);
      dispatch(setCartItem(response?.events));
      dispatch(setClientSecretKey(response?.client_secret));
      dispatch(
        setUserData({ name: "total_price", value: response?.cartTotal }),
      );
      dispatch(setClientCode(payload));
    } catch (error) {
      throw error;
    } finally {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(false));
    }
  };

export const guestPayment =
  (payload: string, payment_intent: string) =>
  async (dispatch: AppDispatch) => {
    try {
      await guestPaymentAPI(payload, payment_intent);
      toast.success("Payment Success");
    } catch (error: any) {
      return error;
    }
  };

export const getTicket = (payload: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getTicketAPI(payload);
    dispatch(setSingleTicket(response));
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data.message ?? error?.message);
  } finally {
    dispatch(setLoading(false));
    dispatch(toggleBackDrop(false));
  }
};

export const submitTicket =
  (payload: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleBackDrop(true));
      const response = await submitTicketAPI(payload);
      dispatch(setSingleTicket(response));
      toast.success("Ticket Confirmed");
    } catch (error: any) {
      toast.error(error?.response?.data.message ?? error?.message);
    } finally {
      dispatch(setLoading(false));
      dispatch(toggleBackDrop(false));
    }
  };
export const fetchCartItemCount = () => async (dispatch: AppDispatch) => {
  try {
    const count = await fetchCartItemCountAPI();
    dispatch(setCartItemCount(count));
  } catch (error) {
    console.error("Error:", error);
  }
};
