import { TSvgComponent } from "../../types/utils/component";

const ArrowLineSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 8 6"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.0378 3.07567L7.0756 3.07567M7.0756 3.07567L4.81142 0.81149M7.0756 3.07567L4.81142 5.33984"
        stroke="white"
        strokeWidth="1.13209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLineSvg;
