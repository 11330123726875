import { TSvgComponent } from "../../../types/utils/component";

const SvgUpload: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 12 15"
      fill="#FF913F"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.33333 10.8333V5.83333H0L5.83333 0L11.6667 5.83333H8.33333V10.8333H3.33333ZM0 14.1667V12.5H11.6667V14.1667H0Z" />
    </svg>
  );
};

export default SvgUpload;
