import axios from "axios";
import {ITypes} from "./types";
export const fetchTypesAxios = async () => {
    try {
        const response = await axios.get<{data: { max_price:number , types: ITypes[]}}>(
            'events/filters'
        );
        return response.data.data

    } catch (error) {
        console.error("Error:", error);
        return null
    }
};

