import React, { useEffect, useState } from "react";
import Person2Icon from "@mui/icons-material/Person2";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomInput from "../../../../components/common/inputs/Input";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  toggleVerificationModal,
  userSignUp,
} from "../../../../store/Auth/ActionAuth";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { useAppDispatch } from "../../../../store/redux";
import PrimaryButton from "../../../PrimaryButton";
import { useTranslation } from "react-i18next";
import AuthTabs from "../AuthTabs";
import { Link } from "react-router-dom";

type SignUpProps = {
  activeTab: string;
  handleRegisterUser: () => void;
  onLoginCLick: () => void;
  isClient: boolean;
};

const SignUpBusiness: React.FC<SignUpProps> = ({
  activeTab,
  handleRegisterUser,
  onLoginCLick,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const fieldsError = useSelector(
    (state: RootState) => state.authReducer?.errors,
  );
  const showVerificationModal = useSelector(
    (state: RootState) => state.authReducer?.showVerificationModal,
  );
  const [initialValues, setInitialValues] = useState({
    name: "",
    address: null,
    organization_name: null,
    affiliate_code: localStorage.getItem("affiliateCode") ?? "",
    email: "",
    password: "",
    password_confirmation: "",
    phone_number: "",
    country_code: "",
    is_client: false,
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(t("nameRequired")),
    email: Yup.string().required(t("emailRequired")),
    address: Yup.string().required(t("addressRequired")),
    organization_name: Yup.string().required(t("organizationRequired")),
    affiliate_code: Yup.string().required(t("affiliateCodeRequired")),
    phone_number: Yup.string().required(t("phoneRequired")),
    password: Yup.string()
      .required(t("passwordRequired"))
      .min(8, t("requiredCharacters"))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-.#?!@$%^&_*=()+]).{8,}$/,
        t("specialCharacter"),
      ),
    password_confirmation: Yup.string()
      .required(t("confirmationPassword"))
      .oneOf([Yup.ref("password"), ""], t("passwordMatch")),
  });
  const handleSignUpClick = async (values: typeof initialValues) => {
    const cleanedCountryCode = values.country_code.replace(/\D/g, "");
    let data = {
      ...values,
      phone_number: `+${cleanedCountryCode}${values.phone_number}`,
    };
    dispatch(userSignUp(data))
      .then(() => {
        handleRegisterUser();
        localStorage.setItem("otpVerified", "false");
        dispatch(toggleVerificationModal(true));
      })
      .catch(() => {});
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const affiliateCode = queryParams.get("affiliateCode");
    if (affiliateCode && affiliateCode.trim() !== "") {
      localStorage.setItem("affiliateCode", affiliateCode);
      setInitialValues((prevValues) => ({
        ...prevValues,
        affiliate_code: affiliateCode,
      }));
    }
  }, []);

  return (
    <div className="max-h-[70vh] overflow-y-scroll">
      <AuthTabs activeTab={activeTab} />
      <div className="py-3 text-center">
        <h3 className="text-2xl font-bold ">{t("welcome")}</h3>
        <p className="font-medium text-[#131C23]/40"> {t("logInOrSignUp")}</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSignUpClick(values);
        }}
      >
        {({ handleChange, values, errors, touched }) => (
          <Form className="">
            <div>
              <CustomInput
                type="text"
                placeholder={t("fullName")}
                icon={<Person2Icon />}
                value={values.name}
                name="name"
                label=""
                onChange={handleChange}
              />
              {errors.name && touched.name && (
                <div className="ml-2 text-[12px] text-red-600">
                  {errors.name}
                </div>
              )}
            </div>
            <div>
              <CustomInput
                type="text"
                placeholder={t("organizationName")}
                icon={<AssignmentIcon />}
                value={values.organization_name ?? ""}
                name="organization_name"
                label=""
                onChange={handleChange}
              />
              {errors.organization_name && touched.organization_name && (
                <div className="ml-2 text-[12px] text-red-600">
                  {errors.organization_name}
                </div>
              )}
              {fieldsError["organization_name"] && (
                <span className="my-3 ml-2 text-[12px] text-red-600">
                  {fieldsError["organization_name"][0]}
                </span>
              )}
            </div>
            <div>
              <CustomInput
                type="text"
                placeholder={t("address")}
                icon={<LocationOnIcon />}
                value={values.address ?? ""}
                name="address"
                label=""
                onChange={handleChange}
              />
              {errors.address && touched.address && (
                <div className="ml-2 text-[12px] text-red-600">
                  {errors.address}
                </div>
              )}
              {fieldsError["address"] && (
                <span className="my-3 ml-2 text-[12px] text-red-600">
                  {fieldsError["address"][0]}
                </span>
              )}
            </div>
            <div className="grid grid-cols-[12fr_1fr] items-center gap-2">
              <CustomInput
                type="text"
                placeholder={t("affiliateCode")}
                icon={<IntegrationInstructionsIcon />}
                value={
                  localStorage.getItem("affiliateCode") || values.affiliate_code
                }
                name="affiliate_code"
                label=""
                onChange={handleChange}
              />
              <div className="group relative">
                <img
                  loading="lazy"
                  src="/assets/svgs/common/message-icon.svg"
                  alt="Message Icon"
                  className="cursor-pointer group-hover:opacity-75"
                />
                <p className="absolute right-0 hidden border border-gray-300 bg-white p-4 text-[10px] text-primary-orange shadow-md group-hover:block">
                  To Register you need Unique access code, if your code has not
                  been supplied by Explore near me HQ, please contact us on
                  tripsupport@explorenearme.co.uk with your request and we will
                  get back to you.
                </p>
              </div>
            </div>
            {errors.affiliate_code && touched.affiliate_code && (
              <div className="ml-2 text-[12px] text-red-600">
                {errors.affiliate_code}
              </div>
            )}
            {fieldsError["affiliate_code"] && (
              <span className="my-3 ml-2 text-[12px] text-red-600">
                {fieldsError["affiliate_code"][0]}
              </span>
            )}
            <div>
              <CustomInput
                type="text"
                placeholder={t("mail")}
                icon={<EmailIcon />}
                value={values.email}
                name="email"
                label=""
                onChange={handleChange}
              />
              {errors.email && touched.email && (
                <div className="ml-2 text-[12px] text-red-600">
                  {errors.email}
                </div>
              )}
              {fieldsError["email"] && (
                <span className="my-3 ml-2 text-[12px] text-red-600">
                  {fieldsError["email"][0]}
                </span>
              )}
            </div>
            <div className="m-0 grid grid-cols-[4fr_8fr] gap-2  border-none p-0">
              <CustomInput
                type="string"
                placeholder={t("code")}
                icon={<PhoneIcon />}
                name="country_code"
                label=""
                onChange={handleChange}
              />
              <CustomInput
                type="number"
                placeholder={t("phoneNumber")}
                name="phone_number"
                label=""
                onChange={handleChange}
              />
            </div>
            {fieldsError["phone_number"] && (
              <span className="ml-2 mt-3 text-[12px]  text-red-600">
                {fieldsError["phone_number"][0]}
              </span>
            )}
            <ErrorMessage
              name="phone_number"
              component="div"
              className="ml-2 text-[12px] text-red-600"
            />
            <div className="my-4 flex items-center rounded-lg bg-[#131C23]/5 p-2 text-[#131C23]/30">
              <LockIcon className="mr-2 text-gray-400" />
              <Field
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={t("password")}
                className="w-full border-none bg-transparent text-gray-700 outline-none"
                onChange={handleChange}
              />
              {showPassword ? (
                <VisibilityOffIcon
                  className="cursor-pointer text-gray-400"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  className="cursor-pointer text-gray-400"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            <ErrorMessage
              name="password"
              component="div"
              className="ml-2 text-[12px] text-red-600"
            />

            <div className="my-4 flex items-center rounded-lg bg-[#131C23]/5 p-2 text-[#131C23]/30">
              <LockIcon className="mr-2 text-gray-400" />
              <Field
                type={showConfirmPassword ? "text" : "password"}
                name="password_confirmation"
                placeholder={t("passwordConfirmation")}
                className="w-full border-none bg-transparent text-gray-700 outline-none"
                onChange={handleChange}
              />
              {showConfirmPassword ? (
                <VisibilityOffIcon
                  className="cursor-pointer text-gray-400"
                  onClick={() => setShowConfirmPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  className="cursor-pointer text-gray-400"
                  onClick={() => setShowConfirmPassword(true)}
                />
              )}
            </div>
            <ErrorMessage
              name="password_confirmation"
              component="div"
              className="ml-2 text-[12px] text-red-600"
            />
            <div className="flex items-center gap-1 text-sm">
              <input
                type="checkbox"
                checked={termsChecked}
                className="checked:bg-primary-orange"
                onChange={() => setTermsChecked(!termsChecked)}
              />
              <p className="">Read and agreed to </p>
              <Link
                to={"/terms-and-condition?type=business"}
                target={"_blank"}
                className="cursor-pointer font-bold text-primary-orange underline"
              >
                terms and conditions
              </Link>
            </div>
            <div className="py-4 text-center font-medium">
              {t("alreadyHaveAccount")}
              <span
                onClick={onLoginCLick}
                className="cursor-pointer px-2 text-[#FF913F]"
              >
                {t("signIn")}
              </span>
            </div>
            <PrimaryButton className="gap-2" disabled={!termsChecked}>
              {t("signUp")}
              <img
                loading="lazy"
                src="/assets/svgs/common/white-arrow.svg"
                className="w-4"
              />
            </PrimaryButton>
          </Form>
        )}
      </Formik>
      {/*<div className="grid grid-cols-3 items-center py-4">*/}
      {/*    <div className="border-b-[1px] border-[#131C23]/40"></div>*/}
      {/*    <p className="text-center text-[#131C23]/50">Or Log in with</p>*/}
      {/*    <div className="border-b-[1px] border-[#131C23]/40"></div>*/}
      {/*</div>*/}
      {/*<div className="flex items-center justify-center">*/}
      {/*    <img*/}
      {/*        src="/assets/svgs/common/apple.svg"*/}
      {/*        className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
      {/*    />*/}
      {/*    <img*/}
      {/*        src="/assets/svgs/common/google.svg"*/}
      {/*        className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
      {/*    />*/}
      {/*</div>*/}
    </div>
  );
};

export default SignUpBusiness;
