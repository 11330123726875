import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../types/utils/laravel";
import { TCurrency } from "../types/currency";
import { useSearchParams } from "react-router-dom";
import { TUser, TUserRole } from "../types/user";
import { useAtomValue } from "jotai";
import { clientTokenAtom } from "../store/user";

export const useCurrencies = () => {
  const { data } = useQuery({
    queryKey: ["currencies"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<{ currencies: Array<TCurrency> }>>(
          "currency",
        );
      return res.data;
    },
  });
  return { data };
};

export const useNextRedirect = () => {
  const [params] = useSearchParams();
  return params.get("nextRedirect");
};

export const useMe = () => {
  const { data } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<{ user: TUser; role: TUserRole }>>(
          "me",
        );
      return res.data;
    },
  });
  return { data };
};

export const useHeaderCart = () => {
  const clientToken = useAtomValue(clientTokenAtom);
  const { data } = useQuery({
    queryKey: ["cart-count"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<number>>(
        `cart/count${clientToken ? `/${clientToken}` : ""}`,
      );
      return res.data;
    },
  });
  return { data };
};
