import { useTranslation } from "react-i18next";
import HeartFilledSvg from "../../../component/SvgComponents/HeartFilledSvg";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";
import { getNextURL } from "../../../utils/functions";
import { useAppSelector } from "../../../store/redux";
export const Exclusive = () => {
  const { t } = useTranslation();
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );
  return (
    <section id="home-exclusive-banner" className="section-margin container">
      <div className="container relative grid gap-10 rounded-3xl bg-gradient-to-r from-secondary-orange to-[#FF8A3F] to-60% py-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center max-lg:relative lg:px-10 2xl:relative">
          <h4 className="rounded-text mb-4 w-fit text-center text-white">
            {t("exploreNearMe.tag")}
          </h4>
          <h2 className="section-heading mb-8 max-w-md text-white">
            {t("exploreNearMe.title")}
          </h2>
          <p className="mb-6 font-poppins-light text-white">
            {t("exploreNearMe.slogan")} -&nbsp;
            <HeartFilledSvg className="inline h-5 w-5" /> -&nbsp;
            {t("exploreNearMe.love")}
          </p>
          <ArrowedLink
            title="Exclusive events"
            to={getNextURL({
              nextRedirect: `${selectedLanguage}/categories?isExclusive=true`,
            })}
          >
            {t("exploreNearMe.button_text")}
          </ArrowedLink>
          <img
            loading="lazy"
            src="/assets/images/common/recommended.webp"
            alt="Recommended sticker"
            className="absolute bottom-0 right-0 max-xl:w-32 max-lg:top-0 max-lg:-translate-y-full 2xl:top-0"
          />
        </div>
        <img
          loading="lazy"
          src="/assets/images/pages/home/exclusive.webp"
          alt="Events collage"
          className="w-full max-w-2xl justify-self-center max-lg:row-start-1"
        />
      </div>
    </section>
  );
};

export default Exclusive;
