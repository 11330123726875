import React from "react";
import { Button, ThemeProvider, createTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SearchButtonProps {
  onClick: () => void;
}
const SearchButton: React.FC<SearchButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const theme = createTheme({
    palette: {
      warning: {
        main: "#FF913F",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onClick}
        variant="contained"
        color="warning"
        sx={{
            width: "170px",
            borderRadius: "45px",
            textTransform: "none",
            color: "#FFFFFF",
            fontWeight: "bold",
            fontSize: "16px",
            height: "max-content",
            "@media (max-width: 767px)": {
                width: "300px",
            },
        }}
        className="rounded-[200px] text-white"
      >
        {t("search")}
      </Button>
    </ThemeProvider>
  );
};

export default SearchButton;
