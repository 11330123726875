import { useTranslation } from "react-i18next";
import { TContributionResponse } from "../../../types/contribution";

type TProps = {
  goal: TContributionResponse["goal"] | undefined;
  raised: TContributionResponse["raised"] | undefined;
};

const Ambition = ({ goal, raised }: TProps) => {
  const { t } = useTranslation();
  return (
    <section
      id="crowdfunding-ambition"
      className="section-margin container grid gap-10 lg:grid-cols-2"
    >
      <div className="relative after:absolute after:inset-0 after:-z-10 after:-rotate-3 after:rounded-2xl after:border-2 after:border-black">
        <img
          src="/assets/images/pages/crowdfunding/ambition.webp"
          alt="Small girl eating bread"
          className="w-full rounded-3xl object-cover"
        />
      </div>
      <div className="flex h-full flex-col py-2">
        <h2 className="section-heading mb-2 font-poppins-bold">
          {t("crowdfunding.highestAmbition")}
        </h2>
        <h5 className="section-subtitle">{t("crowdfunding.builtCommunity")}</h5>
        <p className="my-10">{t("crowdfunding.highestAmbitionDescription")}</p>
        {goal && raised && (
          <div className="mt-auto grid w-fit rounded-xl bg-primary-orange text-center text-white max-lg:mx-auto sm:grid-cols-2">
            <p className="relative place-items-center content-center px-12 py-4 after:absolute after:bottom-0 after:right-1/4 after:h-1 after:w-1/2 after:rounded-full after:bg-white sm:after:right-0 sm:after:top-1/4 sm:after:h-1/2 sm:after:w-1">
              {t("crowdfunding.contributionGoal")}
              <br />
              {goal}
            </p>
            <p className="content-center px-12 py-4">
              {t("crowdfunding.contributionRaised")}
              <br />
              {raised}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
export default Ambition;
