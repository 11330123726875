import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SellIcon from "@mui/icons-material/Sell";
import GroupIcon from "@mui/icons-material/Group";
import { Calendar } from "react-multi-date-picker";
import { FormControl, MenuItem, Select } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useEffect, useState } from "react";
import MapPicker from "react-google-map-picker";

import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import MapIcon from "@mui/icons-material/Map";
import TranslateIcon from "@mui/icons-material/Translate";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import ConfirmationNumberRoundedIcon from "@mui/icons-material/ConfirmationNumberRounded";
import { useTranslation } from "react-i18next";
import PaymentsIcon from "@mui/icons-material/Payments";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useAppDispatch, useAppSelector } from "../../../../store/redux";
import { IAgeObject } from "../../../../store/BusinessDashboard/types";
import { RootState } from "../../../../store/store";
import { BusinessDashboardSlice } from "../../../../store/BusinessDashboard/BusinessDashboardSlice";
import { divideTimeIntoSlots, formatTime } from "../../../../utils/date";
import {
  createAge,
  getGeneratedDates,
  setCreateEventData,
} from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import CustomInput from "../../../../components/common/inputs/Input";
import Toggle from "../../../../component/Inputs/Toggle";
import AlertDialog from "../../../../component/AlertDialog";
import { modalDialogAtom } from "../../../../store/business";
import { useAtom } from "jotai";
import { useCurrencies } from "../../../../utils/hooks";

type IFeesInterface = {
  title: string;
  price: number;
};
type ISlot = {
  start: any;
  end: any;
};
type IAge = {
  id: number | undefined;
  price: number | undefined;
};

const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;
const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY as string;
const BasicInfo = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [diagloadType, setDialogType] = useState("");
  const [eventFeesValue, setEventFeesValue] = useState(0);
  const [eventFeesIndex, setEventFeesIndex] = useState(0);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [showQuantitySelection, setShowQuantitySelection] = useState(false);
  const [showQuantitySelectionPerSection, setShowQuantitySelectionPerSection] =
    useState(false);
  const [start_time, setStartTime] = useState<string | Dayjs | null>(null);
  const [end_time, setEndTime] = useState<string | Dayjs | null>(null);
  const [eventType, setEventType] = useState<number[]>([]);
  const [userSlot, setUserSlot] = useState<ISlot[]>([]);
  const [extraFees, setExtraFees] = useState<IFeesInterface[]>([]);
  const [workingDays, setWorkingDays] = useState<String[]>([]);
  const [selectedAge, setSelectedAge] = useState<number | string>(0);
  const [selectedPrice, setSelectedPrice] = useState<number | string>("");
  const [agesList, setAgeList] = useState<IAge[]>([]);
  const [comissionResetted, setComissionResetted] = useState(false);
  const [disableDurationOption, setDisableDurationOption] = useState(false);
  const [ageListObject, setAgeListObject] = useState({
    id: 0,
    price: 0,
  });
  const [createAgePayload, setCreateAge] = useState<IAgeObject>({
    label: "",
    min_age: "",
    max_age: "",
  });
  const [zoom, setZoom] = useState(DefaultZoom);
  const eventDuration = [
    { id: 1, name: "Hours", value: "h" },
    { id: 2, name: "Days", value: "d" },
  ];
  const typeOfEvent = [
    {
      id: 1,
      name: t("businessProfile.eventCreation.basicInfo.custonDayEvent"),
      value: "custom",
    },
    {
      id: 2,
      name: t("businessProfile.eventCreation.basicInfo.repetitveEvent"),
      value: "repetitive",
    },
  ];
  const weekDays = [
    {
      id: 0,
      name: "Sunday",
    },
    {
      id: 1,
      name: "Monday",
    },
    {
      id: 2,
      name: "Tuesday",
    },
    {
      id: 3,
      name: "Wednesday",
    },
    {
      id: 4,
      name: "Thursday",
    },
    {
      id: 5,
      name: "Friday",
    },
    {
      id: 6,
      name: "Saturday",
    },
  ];
  const fieldsError = useSelector(
    (state: RootState) => state.businessDashboardReducer?.errors,
  );
  const eventData = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );
  const isLoading = useSelector(
    (state: RootState) => state.globalStateReducer.isLoading,
  );
  const ages = useSelector(
    (state: RootState) => state.businessDashboardReducer?.eventData?.ages,
  );
  const eventTypes = useSelector(
    (state: RootState) => state.businessDashboardReducer?.eventData?.types,
  );
  const userCategories = useSelector(
    (state: RootState) => state.businessDashboardReducer?.eventData?.categories,
  );
  const commission = useSelector((state: RootState) =>
    parseFloat(state.businessDashboardReducer?.eventData?.commission),
  );

  const { data: currencies } = useCurrencies();

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "GOOGLE_MAP",
  });

  const { isLoaded } = useJsApiLoader({
    // id: "google-map-script",
    googleMapsApiKey: googleKey,
    // libraries: ["places"],
  });
  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const selectedLocation = { lat, lng };
      setDefaultLocation(selectedLocation);
      const countryComponent = results[0].address_components.find((component) =>
        component.types.includes("country"),
      );
      if (countryComponent) {
        const country = countryComponent.long_name;
        const countryCenter = await getCountryCenter(country);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async function getCountryCenter(
    country: string,
  ): Promise<{ lat: number; lng: number } | null> {
    try {
      const results = await getGeocode({ address: country });
      const { lat, lng } = await getLatLng(results[0]);
      return { lat, lng };
    } catch (error) {
      console.error("Error getting coordinates for country:", error);
      return null;
    }
  }

  // remove error key function
  const handleRemoveErrorKey = (name: string[]) => {
    let errorfields = { ...fieldsError };
    name?.map((item) => {
      delete errorfields[item];
    });
    dispatch(BusinessDashboardSlice.actions.setErrors(errorfields));
  };
  //

  // text input function
  const handleChangeInput = (name: string, value: any) => {
    if (name === "duration") {
      if (start_time !== "" && end_time !== "") {
        let array = divideTimeIntoSlots(
          start_time,
          end_time,
          (eventData["duration"] as number) * 60,
        );
        setUserSlot(array);
      }
      dispatch(setCreateEventData(name, value));
      if (value == 0) {
        setStartTime(null);
        setEndTime(null);
      }
      if (value > 24) dispatch(setCreateEventData(name, 24));
      else if (value < 0) dispatch(setCreateEventData(name, 0));
    } else {
      dispatch(setCreateEventData(name, value));
    }
    let array = [];
    array.push(name);
    handleRemoveErrorKey(array);
  };
  //

  // extra fees functionalities
  const handleAddFees = () => {
    setExtraFees((prevFees) => [...prevFees, { title: "", price: 0 }]);
  };
  const handleDeleteFees = (index: number) => {
    let array = [...extraFees];
    array.splice(index, 1);
    setExtraFees(array);
    dispatch(setCreateEventData("event_fees", array));
  };
  const handleChangeInputFees = (name: string, index: number, value: any) => {
    let array = [...extraFees];
    array[index] = { ...array[index], [name]: value };
    setExtraFees(array);
    setEventFeesIndex(index);
    if (name === "price") setEventFeesValue(value);
    let finalArray = array.filter(({ price, title }) => {
      return price !== 0 && title !== "";
    });
    dispatch(setCreateEventData("event_fees", finalArray));
  };
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );

  //
  // start time/end time function
  const handleChangeTime = (name: string, value: any) => {
    switch (name) {
      case "start_time":
        setStartTime(value);
        break; // Add break to exit the switch block after setting the value

      case "end_time":
        setEndTime(value);
        break; // Add break to exit the switch block after setting the value

      default:
        console.log(value);
        break; // Add break for the default case as well
    }
    const formattedTime = formatTime(value, false);
    dispatch(setCreateEventData(name, formattedTime));
  };
  //
  // drop list function
  const handleChangeDropList = (id: any, index: any) => {
    setSelectedAge(id);
    setSelectedPrice("");
    setAgeListObject({ price: 0, id: id });
  };
  //
  // drop list function
  const handleChangeCurrency = (id: any, index: any) => {
    dispatch(setCreateEventData("currency_id", id));
  };
  const handleChangeDurationOption = (id: any, index: any) => {
    dispatch(setCreateEventData("duration_type", id));
  };
  const handleChangeEventOption = (id: any, index: any) => {
    dispatch(setCreateEventData("duration_option", id));
    dispatch(setCreateEventData("working_days", []));
    dispatch(setCreateEventData("start_weekday", " "));
    dispatch(setCreateEventData("end_weekday", " "));
    setWorkingDays([]);
    if (id === "repetitive") {
      dispatch(setCreateEventData("duration_type", "h"));
      setDisableDurationOption(true);
    } else setDisableDurationOption(false);
  };
  const handleChangeWeekDay = (id: number, day: string) => {
    if (day === "start_weekday") {
      dispatch(setCreateEventData(day, id));
      dispatch(setCreateEventData("end_weekday", " "));
      handleRemoveErrorKey(["start_weekday"]);
    } else {
      dispatch(setCreateEventData(day, id));
      handleRemoveErrorKey(["end_weekday"]);
    }
  };
  //

  // checkbox function
  const handleCheckBox = (event: any) => {
    let array = [...eventData["event_types"]];
    let val = Number(event.target.value);
    if (array.includes(val)) {
      const index = array.indexOf(val);
      array.splice(index, 1);
    } else {
      array.push(val);
    }
    setEventType(array);
    handleRemoveErrorKey(["event_types"]);
    dispatch(setCreateEventData("event_types", array));
  };
  const handleCategoryCheckbox = (event: any) => {
    let array = [...(eventData["categories"] ?? [])];
    let val = Number(event.target.value);
    if (array.includes(val)) {
      const index = array.indexOf(val);
      array.splice(index, 1);
    } else {
      array.push(val);
    }
    setEventType(array);
    handleRemoveErrorKey(["categories"]);
    dispatch(setCreateEventData("categories", array));
  };
  //
  //Modal Functionalities
  const handleSubmitModal = (data: Object) => {
    if (diagloadType === "google") {
      setValue("");
      dispatch(setCreateEventData("latitude", defaultLocation.lat));
      dispatch(setCreateEventData("longitude", defaultLocation.lng));
      handleRemoveErrorKey(["longitudeLatitude"]);
    } else {
      dispatch(createAge(createAgePayload, ages)).then((res: any) => {
        handleChangeDropList(res.id, 0);
        setCreateAge({
          label: "",
          min_age: "",
          max_age: "",
        });
      });
    }
  };
  const handleChangeLocation = (lat: any, lng: any) => {
    setDefaultLocation({ lat: lat, lng: lng });
  };
  const handleChangeZoom = (newZoom: any) => {
    setZoom(newZoom);
  };
  const getCurrentLocation = () => {
    const successCallback = (position: any) => {
      setDefaultLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };
    const errorCallback = (error: any) => {
      console.log(error);
    };
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };
  const handleChangeModalInput = (name: string, value: any) => {
    if (name === "max_age") {
      setCreateAge({ ...createAgePayload, [name]: value });
      setTimeout(() => {
        if (value < createAgePayload["min_age"])
          setCreateAge({
            ...createAgePayload,
            ["max_age"]: createAgePayload["min_age"],
          });
      }, 1500);
    } else setCreateAge({ ...createAgePayload, [name]: value });
  };
  //

  useEffect(() => {
    if (start_time !== null && end_time !== null) {
      if (eventData.duration_type === "h") {
        let array = divideTimeIntoSlots(
          start_time,
          end_time,
          (eventData["duration"] as number) * 60,
        );
        setUserSlot(array);
      } else {
        let array = [
          {
            start: new Date(start_time as string),
            end: new Date(end_time as string),
          },
        ];
        setUserSlot(array);
      }
      handleRemoveErrorKey(["start_time", "end_time"]);
    } else setUserSlot([]);
  }, [start_time, end_time, eventData.duration_type]);

  useEffect(() => {
    if (eventData.start_weekday !== " " || eventData.end_weekday !== " ") {
      dispatch(
        getGeneratedDates({
          start_weekday: eventData.start_weekday as number,
          end_weekday: eventData.end_weekday as number,
        }),
      ).then((res) => {
        setWorkingDays(res);
        handleRemoveErrorKey(["working_days"]);
      });
    }
  }, [eventData.start_weekday, eventData.end_weekday]);

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (params.eventId) {
      let updatedAges = eventData["ages"]?.map((item) => ({
        id: item.id,
        price: item.price,
      }));
      let updatedFees = eventData["event_fees"]?.map((item) => ({
        title: item.title,
        price: item.price,
      }));

      setStartTime(dayjs(new Date(`2022-04-17T${eventData["start_time"]}`)));
      setEndTime(dayjs(new Date(`2022-04-17T${eventData["end_time"]}`)));
      setAgeList(updatedAges);
      dispatch(setCreateEventData("ages", updatedAges));
      setExtraFees(updatedFees);
      dispatch(setCreateEventData("event_fees", updatedFees));
      setWorkingDays(eventData["working_days"]);
      setComissionResetted(true);
    } else {
      setStartTime(
        eventData.start_time
          ? dayjs(new Date(`2022-04-17T${eventData["start_time"]}`))
          : null,
      );
      setEndTime(
        eventData.end_time
          ? dayjs(new Date(`2022-04-17T${eventData["end_time"]}`))
          : null,
      );
      setAgeList(eventData["ages"] ?? []);
      setExtraFees(eventData["event_fees"] ?? []);
      setWorkingDays([]);
      // if(!eventData['working_days']) dispatch(setCreateEventData('working_days',[]))
    }
  }, [isLoading, params, commission]);

  const calculateCommission = (commission: number) => {
    if (agesList.length === 0) return "0.00";
    const maxPrice = Math.max(...agesList.map((item) => item.price || 0));
    const totalCommission = maxPrice + (maxPrice * commission) / 100;
    return totalCommission.toFixed(2);
  };

  const getCurrencySymbol = () => {
    let currency;
    if (currencies?.success) {
      currency = currencies.data.currencies.find(
        (item) => item.id === eventData.currency_id,
      );
    }
    return currency ? currency.symbol : "";
  };
  const [, setOpen] = useAtom(modalDialogAtom);
  return (
    <>
      <div className={"mb-4 rounded-lg bg-[#f3f3f4] p-5"}>
        <div className="flex w-full items-center gap-3">
          <p className=" text-xl font-bold">
            {t("businessProfile.eventCreation.basicInfo.title")}
          </p>
          <div className="rounded-full bg-[#FF585F]/5 px-4 py-1 text-sm text-primary-orange">
            <span className="font-medium">
              {t("businessProfile.eventCreation.basicInfo.required")}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-6">
          <div>
            <p className="text-xs text-[#83888B] lg:mt-3">
              {t("businessProfile.eventCreation.basicInfo.operatorEvents")}
            </p>
            {/* title input */}
            <div className="mt-2">
              <CustomInput
                inputBgColor={`bg-white !my-0 ${
                  fieldsError["title"] && " border-red-500 border-2"
                }`}
                name="title"
                value={eventData["title"]}
                type="text"
                onChange={(e: any) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.eventName",
                )}
                icon={<></>}
              />
              {fieldsError["title"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError["title"][0]}
                </span>
              )}
            </div>
            {/* map input */}
            <div
              onClick={() => {
                setOpen(true);
                setDialogType("google");
              }}
              className="mt-5 cursor-pointer"
            >
              <CustomInput
                inputBgColor={`bg-white !my-0 ${
                  (fieldsError["latitude"] || fieldsError["longitude"]) &&
                  " border-red-500 border-2"
                }`}
                name="location"
                value={
                  eventData["latitude"] &&
                  eventData["longitude"] &&
                  `${eventData["latitude"]},${eventData["longitude"]}`
                }
                type="text"
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.location",
                )}
                disabled={true}
                icon={
                  <img
                    loading="lazy"
                    src="/assets/svgs/dashboard/businessProfile/location-icon.svg"
                  />
                }
              />
              {fieldsError["longitudeLatitude"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {t(
                    "businessProfile.eventCreation.basicInfo.locationRequired",
                  )}
                </span>
              )}
            </div>
            {/* address input */}
            <div className="mt-5">
              <CustomInput
                inputBgColor={`bg-white !my-0 ${
                  fieldsError["address"] && " border-red-500 border-2"
                }`}
                value={eventData["address"]}
                name="address"
                type="text"
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.address",
                )}
                onChange={(e: any) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                icon={<MapIcon sx={{ fill: "#D0D2D3" }} />}
              />
              {fieldsError["address"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError["address"][0]}
                </span>
              )}
            </div>
            {/* language input */}
            <div className="mt-5">
              <CustomInput
                value={eventData["language"]}
                inputBgColor={`bg-white !my-0 ${
                  fieldsError["language"] && " border-red-500 border-2"
                }`}
                name="language"
                type="text"
                onChange={(e: any) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.language",
                )}
                icon={<TranslateIcon sx={{ fill: "#D0D2D3" }} />}
              />
              {fieldsError["language"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError["language"][0]}
                </span>
              )}
            </div>
            {/*type of event input */}
            <div className="mt-5 rounded-lg bg-white px-2 py-1">
              <FormControl
                sx={{ border: "none" }}
                className="w-fit rounded-lg  bg-white !px-[8px]"
                variant="standard"
                fullWidth
              >
                <Select
                  sx={{ border: "none", color: "#83888B" }}
                  className=" border-none"
                  id={"duration-selection"}
                  value={eventData.duration_option}
                  disableUnderline
                >
                  <MenuItem disabled value={0}>
                    {t(
                      "businessProfile.eventCreation.basicInfo.selectEventType",
                    )}
                  </MenuItem>
                  {typeOfEvent.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleChangeEventOption(item.value, index)}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* duration input */}
            <div className="mt-5 grid grid-cols-2 items-center gap-3 lg:grid-cols-[7fr_5fr]">
              <CustomInput
                inputBgColor={`bg-white !my-0 ${
                  fieldsError["duration"] && " border-red-500 border-2"
                }`}
                value={eventData["duration"]}
                min={0}
                max={24}
                name="duration"
                onChange={(e: any) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                type="number"
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.duration",
                )}
                icon={<WatchLaterIcon sx={{ fill: "#D0D2D3" }} />}
              />
              <div
                className="
                 rounded-lg bg-white px-2 py-1"
              >
                <FormControl
                  sx={{ border: "none" }}
                  className="w-fit"
                  disabled={disableDurationOption}
                  variant="standard"
                  fullWidth
                >
                  <Select
                    sx={{ border: "none", color: "#83888B" }}
                    className=" border-none"
                    id={"duration-selection"}
                    value={eventData.duration_type}
                    disableUnderline
                  >
                    <MenuItem disabled value={0}>
                      {t(
                        "businessProfile.eventCreation.basicInfo.selectDuration",
                      )}
                    </MenuItem>
                    {eventDuration.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() =>
                          handleChangeDurationOption(item.value, index)
                        }
                        value={item.value}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {fieldsError["duration"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError["duration"][0]}
                </span>
              )}
            </div>
          </div>
          <div>
            {eventData.duration_option === "repetitive" && (
              <>
                <div className="flex items-center gap-2 lg:mt-3 ">
                  <p className={"text-xs text-[#83888B]"}>Add Day Time</p>
                </div>
                {/* start time end time input */}
                <div className="mt-2 grid w-full grid-cols-2 items-center gap-3 text-[#131C23]/30 ">
                  <div className="w-full rounded-xl bg-white  py-[5px]  focus:border-2 focus:border-red-500">
                    <FormControl
                      sx={{ border: "none" }}
                      className={`rounded-lg  bg-white !px-[8px] ${
                        fieldsError["start_weekday"] &&
                        " border-2 border-red-500"
                      }`}
                      variant="standard"
                      fullWidth
                    >
                      <Select
                        sx={{ border: "none", color: "#83888B" }}
                        className="border-none"
                        id={"start-day-selection"}
                        value={eventData.start_weekday}
                        disableUnderline
                      >
                        <MenuItem disabled value={" "}>
                          {t(
                            "businessProfile.eventCreation.basicInfo.selectStartDay",
                          )}
                        </MenuItem>
                        {weekDays.map((item, index) => (
                          <MenuItem
                            key={index}
                            onClick={() =>
                              handleChangeWeekDay(item.id, "start_weekday")
                            }
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="w-full rounded-xl bg-white  py-[5px]  focus:border-2 focus:border-red-500">
                    <FormControl
                      sx={{ border: "none" }}
                      className={`rounded-lg  bg-white !px-[8px] ${
                        fieldsError["end_weekday"] && " border-2 border-red-500"
                      }`}
                      variant="standard"
                      fullWidth
                      disabled={eventData.start_weekday === ""}
                    >
                      <Select
                        sx={{ border: "none", color: "#83888B" }}
                        className=" border-none"
                        id={"end-day-selection"}
                        value={eventData.end_weekday}
                        disableUnderline
                      >
                        <MenuItem disabled value={" "}>
                          {t(
                            "businessProfile.eventCreation.basicInfo.selectEndDay",
                          )}
                        </MenuItem>
                        {weekDays.map((item, index) => (
                          <MenuItem
                            key={index}
                            disabled={
                              item.id <= Number(eventData.start_weekday)
                            }
                            onClick={() =>
                              handleChangeWeekDay(item.id, "end_weekday")
                            }
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {(fieldsError["start_weekday"] ||
                  fieldsError["end_weekday"]) && (
                  <span className="ml-2 text-[12px] text-red-600">
                    The start weekday and end weekday field are required
                  </span>
                )}
              </>
            )}
            <div className="mt-3 flex items-center gap-2">
              <p className={"text-xs text-[#83888B]"}>
                {t("businessProfile.eventCreation.basicInfo.addVisitTime")}{" "}
              </p>
            </div>
            {/* start time end time input */}
            <div className="mt-2 flex items-center gap-3 text-[#131C23]/30 ">
              <div
                className={`py-[5px]] w-full rounded-xl bg-white px-3 py-[5px] ${
                  fieldsError["start_time"] && " border-2 border-red-500"
                }`}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampm={false}
                    ampmInClock={false}
                    disabled={
                      eventData["duration"] == 0 ||
                      eventData["duration"] === "" ||
                      eventData["duration"] === null
                    }
                    value={start_time}
                    onChange={(e: any) => handleChangeTime("start_time", e)}
                    slotProps={{
                      textField: {
                        placeholder: t(
                          "businessProfile.eventCreation.basicInfo.from",
                        ),
                      },
                    }}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </div>
              <div
                className={`w-full rounded-xl bg-white px-3  py-[5px] ${
                  fieldsError["end_time"] && " border-2 border-red-500"
                }`}
              >
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampm={false}
                    ampmInClock={false}
                    disabled={
                      eventData["duration"] == 0 ||
                      eventData["duration"] === "" ||
                      eventData["duration"] === null
                    }
                    value={end_time}
                    onChange={(e: any) => handleChangeTime("end_time", e)}
                    slotProps={{ textField: { placeholder: "To:" } }}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {(fieldsError["start_time"] || fieldsError["end_time"]) && (
              <span className="ml-2 text-[12px] text-red-600">
                The start time and end time field are required
              </span>
            )}
            {/* time slots */}
            <div className="mt-5 flex min-w-[100%] gap-2 overflow-x-auto py-1">
              {userSlot.length > 0 ? (
                userSlot?.map((item, index) => (
                  <div
                    key={index}
                    className="flex w-fit items-center gap-3  rounded-full bg-white px-3 py-1 text-sm font-medium text-[#898D91]"
                  >
                    <WatchLaterIcon
                      sx={{ fill: "#FF913F", width: "15px", height: "15px" }}
                    />
                    {formatTime(item.start, false)}
                  </div>
                ))
              ) : (
                <>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="flex w-fit items-center gap-3  rounded-full bg-white px-3 py-1 text-sm font-medium text-[#898D91]"
                  >
                    <WatchLaterIcon
                      sx={{ fill: "#FF913F", width: "15px", height: "15px" }}
                    />
                    {t("businessProfile.eventCreation.basicInfo.slot")} 1
                  </div>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="flex w-fit items-center gap-3  rounded-full bg-white px-3 py-1 text-sm font-medium text-[#898D91]"
                  >
                    <WatchLaterIcon
                      sx={{ fill: "#FF913F", width: "15px", height: "15px" }}
                    />
                    {t("businessProfile.eventCreation.basicInfo.slot")} 2
                  </div>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="flex w-fit items-center gap-3  rounded-full bg-white px-3 py-1 text-sm font-medium text-[#898D91]"
                  >
                    <WatchLaterIcon
                      sx={{ fill: "#FF913F", width: "15px", height: "15px" }}
                    />
                    {t("businessProfile.eventCreation.basicInfo.slot")} 3
                  </div>
                </>
              )}
            </div>
            {/* display age section */}
            <div className="mt-5">
              <CustomInput
                inputBgColor={`bg-white  !my-0 ${
                  fieldsError["display_age"] && " border-red-500 border-2"
                }`}
                value={eventData["display_age"]}
                name="display_age"
                type="text"
                onChange={(e: any) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.ageLimit",
                )}
                icon={<GroupIcon sx={{ fill: "#D0D2D3" }} />}
              />
              {fieldsError["display_age"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError["display_age"][0]}
                </span>
              )}
            </div>
            <div className="mt-5 grid grid-cols-[7fr_5fr] items-center gap-3">
              <div
                className={`flex items-center gap-2 rounded-lg bg-white px-2 py-1 ${
                  fieldsError["ages"] && " !border-2 !border-red-500"
                }`}
              >
                <GroupIcon sx={{ fill: "#D0D2D3" }} />
                <FormControl
                  sx={{ border: "none" }}
                  className={`w-fit`}
                  variant="standard"
                  fullWidth
                >
                  <Select
                    sx={{ border: "none", color: "#83888B" }}
                    className=" border-none"
                    id={"chart_component"}
                    value={selectedAge}
                    disableUnderline
                  >
                    <MenuItem disabled value={0}>
                      {t("businessProfile.eventCreation.basicInfo.selectAge")}
                    </MenuItem>
                    {ages.map((item, index) => (
                      <MenuItem
                        key={index}
                        disabled={agesList.some(
                          (elemet) => elemet.id == item.id,
                        )}
                        onClick={() => handleChangeDropList(item.id, index)}
                        value={item.id}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        setOpen(true);
                        setDialogType("");
                      }}
                    >
                      {t("businessProfile.eventCreation.basicInfo.other")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className="
                 rounded-lg bg-white px-2 py-1"
              >
                <FormControl
                  sx={{ border: "none" }}
                  className="w-fit"
                  variant="standard"
                  fullWidth
                >
                  <Select
                    sx={{ border: "none", color: "#83888B" }}
                    className=" border-none"
                    id={"currency_selection"}
                    value={eventData.currency_id}
                    disableUnderline
                  >
                    <MenuItem disabled value={0}>
                      {t(
                        "businessProfile.eventCreation.basicInfo.selectCurrency",
                      )}
                    </MenuItem>
                    {currencies?.success &&
                      currencies.data.currencies.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleChangeCurrency(item.id, index)}
                          value={item.id}
                        >
                          {item.abbreviation} - {item.symbol}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="mt-5 grid grid-cols-[10fr_2fr] items-center gap-3">
              <CustomInput
                disabled={selectedAge === 0}
                inputBgColor={`bg-white !my-0 ${
                  fieldsError["ages"] && " border-red-500 border-2"
                }`}
                onChange={(e: any) => {
                  setAgeListObject({
                    ...ageListObject,
                    price: Number(e.target.value),
                  });
                  let array = [...agesList];
                  let updatedArray = array.map((item) => {
                    if (item.id === selectedAge) {
                      return { ...item, price: Number(e.target.value) };
                    }
                    return item; // Return unchanged items
                  });
                  setAgeList(updatedArray);
                  setSelectedPrice(e.target.value);
                }}
                name="price"
                type="number"
                value={selectedPrice}
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.pricePerAge",
                )}
                icon={<SellIcon sx={{ fill: "#D0D2D3" }} />}
              />
              <div className="flex gap-1">
                <DoneIcon
                  onClick={() => {
                    if (selectedPrice !== "") {
                      let array = [...agesList];
                      array.push(ageListObject);
                      setSelectedAge(0);
                      setSelectedPrice("");
                      setAgeList(array);
                      dispatch(setCreateEventData("ages", array));
                      handleRemoveErrorKey(["ages"]);
                    }
                  }}
                  className="cursor-pointer"
                  sx={{ fill: "green", width: "30px", height: "30px" }}
                />

                <DeleteForeverIcon
                  onClick={() => {
                    setSelectedAge(0);
                    setSelectedPrice("");
                    setAgeListObject({
                      id: 0,
                      price: 0,
                    });
                  }}
                  className="cursor-pointer"
                  sx={{ fill: "#FF585F", width: "30px", height: "30px" }}
                />
              </div>
            </div>
            {fieldsError["ages"] && (
              <span className="ml-2 text-[12px] text-red-600">
                {fieldsError["ages"][0]}
              </span>
            )}
            <div>
              <div className="flex w-full min-w-[100%] items-center gap-2 overflow-x-auto py-1">
                {agesList.map((item, index) => (
                  <div
                    className="flex items-center gap-3 rounded-full bg-white px-3 py-1 text-sm font-medium text-[#898D91]"
                    key={index}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <RemoveIcon
                      onClick={() => {
                        let array = [...agesList];
                        array.splice(index, 1);
                        setAgeList(array);
                        dispatch(setCreateEventData("ages", array));
                      }}
                      sx={{
                        fill: "#FF913F",
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                    />
                    {ages.find((element) => element.id === item.id)?.label}:{" "}
                    {item.price}
                  </div>
                ))}
              </div>
            </div>
            {/*  */}
          </div>
          <div>
            <div className="flex items-center gap-2  lg:mt-3">
              <p className={"text-xs text-[#83888B]"}>
                {" "}
                {t("businessProfile.eventCreation.basicInfo.selectWorkingDays")}
              </p>
            </div>
            {/* calendar section */}
            <div className="mt-2 w-full">
              <Calendar
                value={eventData["working_days"]}
                onChange={(event: any) => {
                  let array = event.map((item: any) => {
                    const year = item.year;
                    const month = (item.monthIndex + 1)
                      .toString()
                      .padStart(2, "0"); // Add 1 to monthIndex because it's zero-based
                    const day = item.day.toString().padStart(2, "0");

                    return `${year}-${month}-${day}`;
                  });

                  dispatch(setCreateEventData("working_days", array));
                  handleRemoveErrorKey(["working_days"]);
                }}
                multiple
                minDate={new Date()}
              />
              {fieldsError["working_days"] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError["working_days"][0]}
                </span>
              )}
            </div>
          </div>
        </div>
        <p className="ml-2 mt-5 text-primary-orange ">
          {t("businessProfile.eventCreation.basicInfo.message")}{" "}
          <span className="mx-1 rounded-md border-2 border-primary-orange bg-white px-2 py-1 font-bold">
            {" "}
            {commission}%{" "}
          </span>
          {t("businessProfile.eventCreation.basicInfo.commission")}
        </p>
        <p className="px-2 pt-3">
          {t("businessProfile.eventCreation.basicInfo.totalCommission")}:{" "}
          <span className="rounded-md border-2 border-primary-orange bg-white px-2 py-1 font-bold text-primary-orange">
            {calculateCommission(commission)} {getCurrencySymbol()}
          </span>{" "}
        </p>
        <div className="grid grid-cols-1 gap-x-5 gap-y-3 lg:grid-cols-2">
          {/* quantity per duration input */}
          <div className="ml-3 mt-3 flex gap-3">
            <Toggle
              handleToggle={(e) => {
                dispatch(setCreateEventData("quantity_per_duration", ""));
                setShowQuantitySelection(!showQuantitySelection);
                setShowQuantitySelectionPerSection(false);
              }}
              checked={showQuantitySelection}
            />
            <CustomInput
              containerClass="w-full"
              inputBgColor="bg-white"
              name="quantity"
              min={0}
              disabled={!showQuantitySelection}
              endText={t("businessProfile.eventCreation.basicInfo.total")}
              value={eventData["quantity"] ?? ""}
              onChange={(e: any) =>
                handleChangeInput(e.target.name, e.target.value)
              }
              className="text-sm"
              type="number"
              placeholder={t(
                "businessProfile.eventCreation.basicInfo.quantity",
              )}
              icon={<ConfirmationNumberRoundedIcon sx={{ fill: "#D0D2D3" }} />}
            />
          </div>
          {/* quantity input */}
          <div className="ml-3 mt-3 flex gap-3">
            <Toggle
              handleToggle={(e) => {
                dispatch(setCreateEventData("quantity", ""));
                setShowQuantitySelectionPerSection(
                  !showQuantitySelectionPerSection,
                );
                setShowQuantitySelection(false);
              }}
              checked={showQuantitySelectionPerSection}
            />
            <CustomInput
              containerClass="w-full"
              inputBgColor="bg-white"
              name="quantity_per_duration"
              min={0}
              endText={t(
                "businessProfile.eventCreation.basicInfo.totalPerDuration",
              )}
              disabled={!showQuantitySelectionPerSection}
              value={eventData["quantity_per_duration"] ?? ""}
              onChange={(e: any) =>
                handleChangeInput(e.target.name, e.target.value)
              }
              className="text-sm"
              type="number"
              placeholder={t(
                "businessProfile.eventCreation.basicInfo.quantityPerDuration",
              )}
              icon={<ConfirmationNumberRoundedIcon sx={{ fill: "#D0D2D3" }} />}
            />
          </div>
        </div>
        <div className="mb-5 mt-5 flex w-full items-center gap-3">
          <p className=" text-xl font-bold ">
            {t("businessProfile.eventCreation.basicInfo.extraFees")}
          </p>
          <div>
            <AddCircleOutlineIcon
              onClick={handleAddFees}
              sx={{ fill: "#FF913F", cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
          {/* extra fees section */}
          {extraFees.map((item, index) => (
            <div key={index} className="grid w-full grid-cols-2 gap-2">
              <div className="">
                <CustomInput
                  inputBgColor="bg-white"
                  name="title"
                  value={item.title}
                  onChange={(e) =>
                    handleChangeInputFees(e.target.name, index, e.target.value)
                  }
                  type="text"
                  placeholder={t(
                    "businessProfile.eventCreation.basicInfo.feesTitle",
                  )}
                  icon={<DescriptionIcon sx={{ fill: "#D0D2D3" }} />}
                />
              </div>
              <div className="flex items-center gap-2 ">
                <CustomInput
                  inputBgColor="bg-white"
                  name="price"
                  value={item.price}
                  onChange={(e) =>
                    handleChangeInputFees(e.target.name, index, e.target.value)
                  }
                  type="number"
                  placeholder={t(
                    "businessProfile.eventCreation.basicInfo.price",
                  )}
                  icon={<PaymentsIcon sx={{ fill: "#D0D2D3" }} />}
                />
                <DeleteForeverIcon
                  onClick={() => handleDeleteFees(index)}
                  className="cursor-pointer"
                  sx={{ fill: "#FF585F", width: "30px", height: "30px" }}
                />
              </div>
            </div>
          ))}
        </div>
        {/* envent type checkbox */}
        <div className="mt-3 grid w-full grid-cols-[4fr_8fr] items-center rounded-md bg-[#EAEAEB] px-5 py-2">
          <div className="text-sm">
            {t("businessProfile.eventCreation.basicInfo.eventType")}
          </div>
          <div className="flex items-center gap-6 overflow-x-auto py-2">
            {eventTypes.map((item, index) => (
              <div key={index} className="checkbox-container flex items-center">
                <input
                  id={`bordered-checkbox-${index}`}
                  type="checkbox"
                  value={item.id}
                  checked={eventData["event_types"]?.includes(item.id)}
                  name="bordered-checkbox"
                  onChange={handleCheckBox}
                  className=" checbox-input rounded border-gray-300 bg-gray-300 text-white accent-[#FF913F] focus:ring-transparent"
                />
                <label
                  htmlFor={`bordered-checkbox-${index}`}
                  className="checkboxdivstom-checkbox ml-2 w-max text-xs font-medium text-[#131C23]"
                >
                  {item.translations && item.translations[selectedLanguage]
                    ? item.translations[selectedLanguage].title
                    : item.title}
                </label>
              </div>
            ))}
          </div>
        </div>
        {fieldsError["event_types"] && (
          <span className="ml-5 text-[12px] text-red-600">
            {fieldsError["event_types"][0]}
          </span>
        )}
        <div className="mt-5 grid grid-cols-[4fr_8fr] items-center rounded-md bg-[#EAEAEB] px-5 py-2">
          <div className="text-sm">Select Categories</div>
          <div className="overflow-x-scroll pb-2">
            <div className="grid grid-flow-col grid-cols-[repeat(auto-fill,minmax(250px,1fr))] grid-rows-3 gap-x-6 gap-y-2">
              {userCategories?.map((item, index) => (
                <div
                  key={index}
                  className="checkbox-container flex items-center"
                >
                  <input
                    id={`bordered-category-checkbox-${index}`}
                    type="checkbox"
                    value={item.id}
                    checked={eventData["categories"]?.includes(item.id)}
                    name="bordered-category-checkbox"
                    onChange={handleCategoryCheckbox}
                    className=" checbox-input rounded border-gray-300 bg-gray-300 text-white accent-[#FF913F] focus:ring-transparent"
                  />
                  <label
                    htmlFor={`bordered-category-checkbox-${index}`}
                    className="checkboxdivstom-checkbox ml-2 w-max text-xs font-medium capitalize text-[#131C23]"
                  >
                    {item.translations && item.translations[selectedLanguage]
                      ? item.translations[selectedLanguage].title
                      : item.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {fieldsError["categories"] && (
            <span className="text-[12px] text-red-600">
              {fieldsError["categories"][0]}
            </span>
          )}
        </div>
      </div>

      <AlertDialog
        handleSubmitModalDialog={handleSubmitModal}
        dialogTitle={
          diagloadType === "google" ? "Select Your Location" : "Event Ages"
        }
      >
        {diagloadType === "google" ? (
          <>
            {isLoaded ? (
              <Combobox className="z-2000 w-full" onSelect={handleSelect}>
                <ComboboxInput
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  disabled={!isLoaded}
                  className="relative mb-3 w-1/2 rounded-2xl border-2 border-primary-orange bg-gray-200 px-2 py-1 outline-0"
                  placeholder="Event Location?"
                />
                <div
                  className="mt-2 rounded-lg bg-white"
                  style={{ position: "absolute", zIndex: 1000 }}
                >
                  <ComboboxList>
                    {data.map(({ place_id, description }) => (
                      <ComboboxOption key={place_id} value={description} />
                    ))}
                  </ComboboxList>
                </div>
              </Combobox>
            ) : null}
            <MapPicker
              defaultLocation={defaultLocation}
              zoom={zoom}
              style={{ height: "50vh", width: "100%" }}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey={googleKey}
            />
          </>
        ) : (
          <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
            <div>
              <label className="text-sm">Title</label>
              <CustomInput
                inputBgColor="bg-[#131C23]/5"
                name="label"
                type="text"
                placeholder="Adult"
                onChange={(e: any) =>
                  handleChangeModalInput(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label className="text-sm">
                {t("businessProfile.eventCreation.basicInfo.minAge")}
              </label>
              <CustomInput
                inputBgColor="bg-[#131C23]/5"
                name="min_age"
                value={createAgePayload["min_age"]}
                type="number"
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.minAge",
                )}
                onChange={(e: any) =>
                  handleChangeModalInput(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label className="text-sm">
                {t("businessProfile.eventCreation.basicInfo.maxAge")}
              </label>
              <CustomInput
                inputBgColor="bg-[#131C23]/5"
                name="max_age"
                value={createAgePayload["max_age"]}
                type="number"
                placeholder={t(
                  "businessProfile.eventCreation.basicInfo.maxAge",
                )}
                onChange={(e: any) =>
                  handleChangeModalInput(e.target.name, e.target.value)
                }
              />
            </div>
          </div>
        )}
      </AlertDialog>
    </>
  );
};
export default BasicInfo;
