import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../store/redux";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  TLaravelPagination,
  TLaravelResponse,
} from "../../../types/utils/laravel";
import { TTicket, TTicketDetails } from "../../../types/ticket";
import axios from "axios";
import { scrollToTop } from "../../../utils/functions";
import {
  TClientAchievement,
  TClientLevel,
  TClientTask,
} from "../../../types/client";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../store/currency";

export const useTickets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const selectedCurrency = useAtomValue(currencyAtom);
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery({
    queryKey: ["client-tickets", page],
    queryFn: async () => {
      const res = await axios.get<
        TLaravelResponse<TLaravelPagination<Array<TTicket>>>
      >(`users/profile/tickets?page=${page}`);
      return res.data;
    },
  });
  const handlePrevious = () => {
    if (data?.success && data.data.prev_page_url) {
      scrollToTop();
      setPage((_prev) => (_prev = page - 1));
    }
  };
  const handleNext = () => {
    if (data?.success && data.data.next_page_url) {
      scrollToTop();
      setPage((_prev) => (_prev = page + 1));
    }
  };
  return {
    t,
    isLoading,
    data,
    searchParams,
    setSearchParams,
    selectedCurrency,
    selectedLanguage,
    handlePrevious,
    handleNext,
    page,
  };
};

export const useTicketDisplay = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useQuery({
    queryKey: ["client-ticket", searchParams.get("ticket-id")],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<Array<TTicketDetails>>>(
        `users/profile/tickets/${searchParams.get("ticket-id")}`,
      );
      return res.data;
    },
  });
  const handleClose = () => {
    searchParams.delete("ticket-id");
    setSearchParams((_prev) => (_prev = searchParams));
  };
  const selectedCurrency = useAtomValue(currencyAtom);
  return { t, isLoading, data, handleClose, searchParams, selectedCurrency };
};

export const useGamification = () => {
  const { data } = useQuery({
    queryKey: ["user-gamification"],
    queryFn: async () => {
      const res = await axios.get<
        TLaravelResponse<{
          achievements: Array<TClientAchievement>;
          user_level: TClientLevel;
          user_tasks: {
            done: Array<TClientTask>;
            progress: Array<TClientTask>;
          };
        }>
      >("users/profile/gamification");
      return res.data;
    },
  });
  return { data };
};
