import { TSvgComponent } from "../../types/utils/component";

const BookSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="#FF913F"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.8333 2.82931V9.76514C12.8333 10.331 12.3725 10.8501 11.8067 10.9201L11.6258 10.9435C10.6692 11.0718 9.32167 11.4685 8.23667 11.9235C7.8575 12.081 7.4375 11.7951 7.4375 11.381V3.26681C7.4375 3.05098 7.56 2.85264 7.7525 2.74764C8.82 2.17014 10.4358 1.65681 11.5325 1.56348H11.5675C12.2675 1.56348 12.8333 2.12931 12.8333 2.82931Z" />
      <path d="M6.24749 2.74764C5.17999 2.17014 3.56416 1.65681 2.46749 1.56348H2.42666C1.72666 1.56348 1.16083 2.12931 1.16083 2.82931V9.76514C1.16083 10.331 1.62166 10.8501 2.18749 10.9201L2.36833 10.9435C3.32499 11.0718 4.67249 11.4685 5.75749 11.9235C6.13666 12.081 6.55666 11.7951 6.55666 11.381V3.26681C6.55666 3.04514 6.43999 2.85264 6.24749 2.74764ZM2.91666 4.51514H4.22916C4.46833 4.51514 4.66666 4.71348 4.66666 4.95264C4.66666 5.19764 4.46833 5.39014 4.22916 5.39014H2.91666C2.67749 5.39014 2.47916 5.19764 2.47916 4.95264C2.47916 4.71348 2.67749 4.51514 2.91666 4.51514ZM4.66666 7.14014H2.91666C2.67749 7.14014 2.47916 6.94764 2.47916 6.70264C2.47916 6.46348 2.67749 6.26514 2.91666 6.26514H4.66666C4.90583 6.26514 5.10416 6.46348 5.10416 6.70264C5.10416 6.94764 4.90583 7.14014 4.66666 7.14014Z" />
    </svg>
  );
};

export default BookSvg;
