import CustomInput from "../../../../../../components/common/inputs/NewInput";
import { useEventCreationVideo } from "../../_hooks";

const Video = () => {
  const { t, videoUrl, handleVideoChange, error } = useEventCreationVideo();
  return (
    <>
      <div className="mb-4 flex flex-wrap items-center gap-4">
        <h3 className="section-title">
          {t("businessProfile.eventCreation.imageSection.videoLink")}
        </h3>
        <span className="rounded-2xl bg-primary-gray/25 px-4 py-1 text-xs font-bold capitalize">
          Preview visible up on successful link upload
        </span>
      </div>
      <CustomInput
        containerClass="mb-4"
        placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        value={videoUrl ?? ""}
        onChange={handleVideoChange}
        name="video_url"
        type="url"
        title="Youtube video URL"
        error={error}
      />
      {videoUrl && !error && (
        <iframe
          className="h-[400px] w-full rounded-lg"
          src={videoUrl ?? ""}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      )}
    </>
  );
};

export default Video;
