
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from "../StripeForm";
const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
const clientRedirection =  process.env.REACT_APP_CLIENT_REDIRECTION as string
const guestRedirection =  process.env.REACT_APP_GUEST_REDIRECTION as string
const stripePromise = loadStripe(key)
const SinglePayment = () => {
    const clientKey = useSelector((state: RootState) => state.cartReducer.checkoutState.clientSecretKey)
    const clientToken = localStorage.getItem('clientToken')
    const appearance = {
        variables: {
            padding: '0 15px',
            colorPrimary: '#0570de',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            spacingUnit: '3px',
            borderRadius: '15px',
            spacingGridRow: '15px'
        },
        rules: {
            '.Tab': {
                border: '1px solid #E0E6EB',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },
            '.p-PaymentDetails': { backgroundColor: 'red' },
            '.Tab:hover': {
                color: 'var(--colorText)',
            },
            '.Tab--selected': {
                borderColor: '#FF913F',
                boxShadow: '0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F',
            },
            ".Tab--selected:focus": {
                borderColor: '#FF913F',
                boxShadow: '0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F',
            },
            '.Tab:focus': {
                borderColor: '#FF913F',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f',
            },

            '.Input:focus': {
                borderColor: '#FF913F',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f',
            },
            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },
            '.CheckboxInput--checked': {
                backgroundColor: '#FF913F',
                borderColor: '#FF913F'
            },
            '.CheckboxInput:focus': {
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f',
                borderColor: '#FF913F'
            },
            '.DropdownItem:active': {
                backgroundColor: 'red'
            },
            '.Input::selection': {
                backgroundColor: 'red'
            },
            '.Menu': {
                backgroundColor: 'red'
            }



        }
    };
    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientKey ?? '',
        appearance: appearance
    };

    return (
        <div className="p-5">
            <Elements stripe={stripePromise} options={options} >
                <div className="stripe-form">
                    <StripeForm navigateURL= {!clientToken ? clientRedirection : guestRedirection}/>
                </div>
            </Elements>
        </div>
    );
};
export default SinglePayment;
