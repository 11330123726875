import { useTranslation } from "react-i18next";
import { TClientAchievement } from "../../../../types/client";
import SvgWarning from "../../../../assets/svgs/icons/SvgWarning";
import SvgLock from "../../../../assets/svgs/icons/SvgLock";
import SvgChecked from "../../../../assets/svgs/icons/SvgChecked";

type TProps = { achievements: Array<TClientAchievement> | undefined };

const Achievements = ({ achievements }: TProps) => {
  const { t } = useTranslation();
  if (!achievements) return null;
  return (
    <section id="gamification-achievements" className="dashboard-card gray">
      <div className="mb-4 flex items-center gap-2">
        <h3 className="section-title">
          {t("userProfile.gamification.levelAchievements.title")}
        </h3>
        <SvgWarning className="h-5 w-5" />
      </div>
      <ul className="grid grid-cols-3 gap-4">
        {achievements.map(({ id, image, name, is_achieved, description }) => {
          return (
            <li
              key={`gamification_achievement_${id}`}
              className="relative rounded-lg bg-white p-2 text-center"
            >
              <div className="absolute right-1 top-1">
                {is_achieved ? (
                  <SvgChecked className="h-4 w-4 rounded-full bg-green-400 p-px [&_path]:stroke-white" />
                ) : (
                  <SvgLock className="h-4 w-4" />
                )}
              </div>
              <img
                src={image}
                alt={name}
                className="mx-auto h-20 w-20 object-contain drop-shadow-xl"
              />
              <h5 className="mb-2 text-sm">{name}</h5>
              <p className="text-xs">{description}</p>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
export default Achievements;

{
  /*
<div className={"mt-5 grid grid-cols-2 gap-4 md:grid-cols-3"}>
        {achievements?.map((level: any, index: number) =>
          level.is_achieved ? (
            <div
              key={index ?? null}
              className={
                "relative grid h-full flex-col items-center  justify-center rounded-[30px] bg-white px-1 py-3 text-center"
              }
            >
              <CheckCircleIcon
                sx={{ color: "#52E0AB" }}
                className={"absolute -top-2 right-0"}
              />
              <img className="w-full" src={level.image} alt="" />
              <p className={" text-sm text-[#898d91]"}>
                {level.name}:{" "}
                <b className={"text-black"}>{level.description}</b>
              </p>
            </div>
          ) : (
            <div
              key={index ?? null}
              className={
                "relative grid items-center justify-center rounded-[30px] bg-white px-[0.5px] py-3 text-center opacity-40"
              }
            >
              <LockIcon
                sx={{ color: "black" }}
                className={"absolute -top-2 right-0"}
              />
              <img loading="lazy" className="w-full" src={level.image} alt="" />
              <p className={" text-sm text-[#898d91]"}>
                {level.name}:{" "}
                <b className={"text-black"}>{level.description}</b>
              </p>
            </div>
          ),
        )}
      </div> */
}
