import { useTranslation } from "react-i18next";
import Operator from "./operator/_index";
import Age from "./Age";
import Additional from "./additional/_index";

const BasicInfo = () => {
  const { t } = useTranslation();
  return (
    <section id="create-event-basic-info" className="dashboard-card gray">
      <div className="mb-4 flex flex-wrap items-center gap-4">
        <h3 className="section-title">
          {t("businessProfile.eventCreation.basicInfo.title")}
        </h3>
        <span className="rounded-md bg-red-100 px-4 py-1 text-xs text-red-600">
          {t("businessProfile.eventCreation.basicInfo.required")}
        </span>
      </div>
      <Operator />
      <Age />
      <Additional />
    </section>
  );
};

export default BasicInfo;
