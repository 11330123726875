import ChangePassword from "../common/ChangePassword";
import Details from "../common/Details";
import Notifications from "../common/Notifications";
import ProfileImage from "../common/ProfileImage";

const ClientProfilePage = () => {
  return (
    <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3">
      <Details />
      <div className="col-span-1 col-start-1 row-start-2 flex flex-col-reverse gap-4 md:col-span-2 xl:col-span-1 xl:col-start-2 xl:row-start-1 xl:flex-col">
        <Notifications />
        <ProfileImage />
      </div>
      <ChangePassword />
    </div>
  );
};
export default ClientProfilePage;
