import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "../pages/ErrorHandeling/404";
import HomePage from "../pages/home/_index";
import Layout from "../pages/Dashboard/Layout/_index";
import EventDetails from "../pages/EventDetails/index";
import Token from "../pages/Dashboard/user/token/_index";
import SeeAllEvents from "../pages/AllEvents/SeeAllEvents";
import Wishlist from "../pages/Dashboard/Wishlist/_index";
import PartnersPage from "../pages/partners";
import CartItems from "../pages/Payment/ShoppingCart/CartItems";
import Checkout from "../pages/Payment/Checkout";
import GuestCheckout from "../pages/Payment/GuestCheckout";
import { BusinessRoute, ClientRoute } from "./ProtectedRoutes";
import Maintenance from "../pages/ErrorHandeling/503";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import OurMission from "../pages/OurMission/OurMission";
import Crowdfunding from "../pages/Crowdfunding/_index";
import { Toaster } from "sonner";
import BusinessEventsPage from "../pages/events/business/_index";
import EventsListPage from "../pages/Dashboard/BusinessProfile/list/_index";
import TicketConfirmation from "../pages/Dashboard/user/ticket/Confirmation";
import GamificationPage from "../pages/Dashboard/user/gamifications/_index";
import RefferalPage from "../pages/Dashboard/user/referral/_index";
import TicketsPage from "../pages/Dashboard/user/ticket/Tickets";
import HistoryPage from "../pages/Dashboard/user/History";
import ClientProfilePage from "../pages/Dashboard/user/Profile";
import BusinessProfilePage from "../pages/Dashboard/BusinessProfile/profile/_index";
import NotificationsPage from "../pages/Dashboard/BusinessProfile/Notifications";
import DocumentsPage from "../pages/Dashboard/BusinessProfile/Documents";
import OverviewPage from "../pages/Dashboard/BusinessProfile/overview/_index";
import AboutPage from "../pages/about/_index";
import LayoutPage from "../components/layout/LayoutPage";
import UnauthorizedPage from "../pages/ErrorHandeling/401";
import EventCreationPage from "../pages/Dashboard/BusinessProfile/eventsCreation/_index";
import EventCreation from "../pages/Dashboard/BusinessProfile/EventCreation/_index";

function AppRouter() {
  return (
    <BrowserRouter>
      <Toaster richColors />
      <Routes>
        <Route element={<LayoutPage />}>
          <Route path="/crowdfunding" element={<Crowdfunding />} />
          <Route
            path="/allEvents/:eventType/:categoryId?"
            element={<SeeAllEvents filters />}
          />
          <Route
            path="/eventDetails/:eventId/:pageNumber?"
            element={<EventDetails />}
          />
          <Route path="/ticket-confirmation" element={<TicketConfirmation />} />
          <Route path="/terms-and-condition/" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/our-mission" element={<OurMission />} />
          <Route path="/guest-checkout" element={<GuestCheckout />} />
          <Route path="/payment" element={<CartItems />} />
          <Route path="/checkout" element={<Checkout />} />
        </Route>

        {/* Refactor */}
        <Route path="401" element={<UnauthorizedPage />} />
        <Route path="503" element={<Maintenance />} />
        <Route element={<LayoutPage />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/partners/:partnerId" element={<PartnersPage />} />
          <Route
            path="/events/business/:businessId"
            element={<BusinessEventsPage />}
          />
          <Route element={<ClientRoute />}>
            <Route element={<Layout type="user" />}>
              <Route path="/user-profile" element={<ClientProfilePage />} />
              <Route path="/history" element={<HistoryPage />} />
              <Route path="/gamification" element={<GamificationPage />} />
              <Route path="/token" element={<Token />} />
              <Route path="/referral-program" element={<RefferalPage />} />
              <Route path="/tickets" element={<TicketsPage />} />
            </Route>
            {/* Not refactored */}
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/payment" element={<CartItems />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/guest-checkout" element={<GuestCheckout />} />
          </Route>
          <Route element={<BusinessRoute />}>
            <Route element={<Layout type="business" />}>
              <Route
                path="/business-profile"
                element={<BusinessProfilePage />}
              />
              <Route path="/overview" element={<OverviewPage />} />
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route path="/documents" element={<DocumentsPage />} />
              {/* Not refactored */}
              <Route path="/event-creation/">
                <Route path="" element={<EventCreation />} />
                <Route path="custom" element={<EventCreationPage />} />
                <Route path="repetitive" element={<EventCreationPage />} />
                <Route path="multi-stop" element={<EventCreationPage />} />
                <Route path="multi-day" element={<EventCreationPage />} />
              </Route>
              <Route
                path="/event-update/:eventId"
                element={<EventCreationPage />}
              />
              <Route path="/event-list/accepted" element={<EventsListPage />} />
              <Route path="/event-list/pending" element={<EventsListPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
