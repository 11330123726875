import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAppSelector } from "../../../store/redux";
import StripeForm from "../../Payment/Checkout/PaymentSection/StripeForm";

const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

const stripePromise = loadStripe(key);

const StripeDonation = () => {
  const clientSecretKey = useAppSelector(
    (state) => state.crowdfundingReducer.clientSecretKey,
  );
  const stripeRedirection = process.env.REACT_APP_GUEST_REDIRECTION as string;
  const appearance = {
    variables: {
      padding: "0 15px",
      colorPrimary: "#0570de",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      spacingUnit: "3px",
      borderRadius: "15px",
      spacingGridRow: "15px",
    },
    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".p-PaymentDetails": { backgroundColor: "red" },
      ".Tab:hover": {
        color: "var(--colorText)",
      },
      ".Tab--selected": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F",
      },
      ".Tab--selected:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F",
      },
      ".Tab:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
      },

      ".Input:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
      },
      ".Input--invalid": {
        boxShadow:
          "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
      },
      ".CheckboxInput--checked": {
        backgroundColor: "#FF913F",
        borderColor: "#FF913F",
      },
      ".CheckboxInput:focus": {
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
        borderColor: "#FF913F",
      },
      ".DropdownItem:active": {
        backgroundColor: "red",
      },
      ".Input::selection": {
        backgroundColor: "red",
      },
      ".Menu": {
        backgroundColor: "red",
      },
    },
  };

  const options = {
    clientSecret: clientSecretKey ?? "",
    appearance: appearance,
  };

  if (!clientSecretKey) {
    return <div>Error: Client Secret is not available.</div>;
  }

  return (
    <div className="mt-8 rounded-xl bg-white p-6 text-[#1B1B1B]">
      <Elements stripe={stripePromise} options={options}>
        <div className="stripe-form">
          <StripeForm navigateURL={stripeRedirection + "crowdfunding"} />
        </div>
      </Elements>
    </div>
  );
};

export default StripeDonation;
