import CustomInput from "../../../../../../../components/common/inputs/NewInput";
import SvgTrash from "../../../../../../../assets/svgs/icons/SvgTrash";
import { useEventCreationServices } from "../../../_hooks";

const Services = () => {
  const {
    t,
    handleAdd,
    fees,
    handleChangeTitle,
    handleChangePrice,
    handleRemoveFee,
  } = useEventCreationServices();
  return (
    <div className="mb-6">
      <div className="mb-4 flex items-center gap-4">
        <h3 className="section-title">
          {t("businessProfile.eventCreation.basicInfo.extraFees")}
        </h3>
        <button
          onClick={handleAdd}
          className="hover-link grid h-6 w-6 place-items-center rounded-full border border-primary-orange text-primary-orange"
          type="button"
          title="Add service"
        >
          +
        </button>
      </div>
      {fees.length > 0 && (
        <ul className="grid gap-x-12 gap-y-4 lg:grid-cols-3">
          {fees.map(({ title, price }, index) => {
            return (
              <li
                className="flex items-center gap-3"
                key={`event_creation_fee_${index}`}
              >
                <CustomInput
                  required
                  placeholder="Title"
                  containerClass="basis-full"
                  name={`event_fees[${index}][title]`}
                  type="text"
                  value={title}
                  className="flex-grow"
                  onChange={(e) => handleChangeTitle(e, index)}
                />
                <CustomInput
                  required
                  placeholder="Price"
                  name={`event_fees[${index}][price]`}
                  type="number"
                  value={price}
                  containerClass="basis-24"
                  onChange={(e) => handleChangePrice(e, index)}
                />
                <button
                  type="button"
                  title="Delete fee"
                  className="hover-link"
                  onClick={(e) => handleRemoveFee(e, index)}
                >
                  <SvgTrash className="h-5 w-5" />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Services;
