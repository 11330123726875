import { useTranslation } from "react-i18next";
import { TLaravelPagination } from "../../../../../types/utils/laravel";
import { TEvent } from "../../../../../types/event";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../store/redux";
import EventLocation from "../../../../../components/common/EventLocation";
import StarSvg from "../../../../../component/SvgComponents/StarSvg";
import SvgClock from "../../../../../assets/svgs/icons/SvgClock";
import SvgTrash from "../../../../../assets/svgs/icons/SvgTrash";
import SvgEdit from "../../../../../assets/svgs/icons/SvgEdit";
import { useDeleteEvent } from "../../_hooks";
import SvgLoadingInfinity from "../../../../../assets/svgs/loading/SvgLoadingInfinity";
import { getNextURL, turnEventIdToSlug } from "../../../../../utils/functions";

type TProps = {
  data: TLaravelPagination<Array<TEvent>> | null;
  isLoading: boolean;
};

const Table = ({ data, isLoading }: TProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  return (
    <table className="dashboard-table min-w-[1000px]">
      <thead>
        <tr>
          <td>{t("businessProfile.eventList.sideBar.image")}</td>
          <td>{t("businessProfile.eventList.sideBar.title")}</td>
          <td>{t("businessProfile.eventList.sideBar.location")}</td>
          <td>{t("businessProfile.eventList.sideBar.rating")}</td>
          <td>{t("businessProfile.eventList.sideBar.tags")}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={6}>
              <p className="my-4">Loading...</p>
            </td>
          </tr>
        ) : data?.data && data?.data.length < 1 ? (
          <tr>
            <td colSpan={6}>
              <p className="my-4">No events</p>
            </td>
          </tr>
        ) : (
          data?.data.map(
            ({
              id,
              translations,
              images,
              location,
              reviews_count,
              reviews,
              reviews_avg_stars,
              duration,
              duration_type,
              types,
            }) => {
              return (
                <tr key={`event_list_${id}`}>
                  <td>
                    <Link
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      to={getNextURL({
                        nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
                          translations.en.title,
                          id,
                        )}`,
                      })}
                      title={translations?.[selectedLanguage].title}
                      className="hover-link inline-block"
                    >
                      <img
                        src={images[0].image_path.toString()}
                        alt={translations?.[selectedLanguage]?.title}
                        title={translations?.[selectedLanguage]?.title}
                        className="h-16 w-16 object-contain"
                      />
                    </Link>
                  </td>
                  <td>
                    <p>{translations?.[selectedLanguage].title}</p>
                  </td>
                  <td>
                    <EventLocation location={location} />
                  </td>
                  <td>
                    {reviews_count > 0 && (
                      <div className="flex items-center gap-4">
                        <div className="flex items-center gap-4">
                          <div className="flex">
                            <img
                              className="h-8 w-8 rounded-full border border-white"
                              src={reviews[0].user.profile_pic}
                              alt={reviews[0].user.name}
                              title={reviews[0].user.name}
                            />
                            {reviews_count > 1 && (
                              <img
                                className="-ml-4 h-8 w-8 rounded-full border border-white"
                                src={reviews[1].user.profile_pic}
                                alt={reviews[1].user.name}
                                title={reviews[1].user.name}
                              />
                            )}
                          </div>
                          <p className="font-poppins-light">
                            <span className="font-poppins-bold">
                              {reviews_count > 1
                                ? reviews_count - 1 + "+"
                                : reviews_count}
                            </span>
                            &nbsp;Review{reviews_count > 1 && "s"}
                          </p>
                        </div>
                        <div className="flex items-center gap-1.5">
                          <StarSvg className="h-4 w-4" />
                          <span className="font-bold">
                            {parseFloat(reviews_avg_stars ?? "0")}
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="flex flex-wrap gap-2">
                      <div className="flex w-fit items-center gap-1 rounded-3xl bg-primary-gray/25 px-4 py-1">
                        <SvgClock className="h-4 w-4" />
                        <p>{duration + " " + duration_type}</p>
                      </div>
                      {types.length > 0 &&
                        types.map((type) => {
                          return (
                            <div
                              key={`event_${id}_type_${type.id}`}
                              className="flex w-fit items-center gap-1 rounded-3xl bg-primary-gray/25 px-4 py-1"
                            >
                              <p>{type.title}</p>
                            </div>
                          );
                        })}
                    </div>
                  </td>
                  <td>
                    <CrudButtons
                      id={id}
                      title={translations?.[selectedLanguage].title}
                    />
                  </td>
                </tr>
              );
            },
          )
        )}
      </tbody>
    </table>
  );
};

export default Table;

type TCrud = {
  id: TEvent["id"];
  title: TEvent["translations"][0]["title"];
};

const CrudButtons = ({ id, title }: TCrud) => {
  const { deleteEvent, isPending } = useDeleteEvent();
  return (
    <div className="flex items-center gap-2">
      <button
        disabled={isPending}
        className="[&:not(:disabled)]:hover-link single-grid"
        title={`Delete ${title}`}
        type="button"
        onClick={() => {
          deleteEvent(id);
        }}
      >
        <SvgTrash
          className={`single-grid-item h-6 w-6 transition-opacity duration-500 ${
            isPending ? "opacity-0" : "opacity-100"
          }`}
        />
        <SvgLoadingInfinity
          className={`single-grid-item h-6 w-6 scale-110 transition-opacity duration-500 ${
            isPending ? "opacity-100" : "opacity-0"
          }`}
        />
      </button>
      <Link to={`/event-update/${id}`} className="hover-link inline-block">
        <SvgEdit className="h-6 w-6" />
      </Link>
    </div>
  );
};
