import { Link } from "react-router-dom";
import { usePartner } from "../_hooks";
import SvgVerified from "../../../assets/svgs/icons/SvgVerified";

const Details = () => {
  const {
    data,
    isLoading,
    selectedLanguage,
    selectImage,
    selected,
    emptySelected,
  } = usePartner();
  return (
    <section id="partner-details" className="section-margin container">
      {isLoading ? (
        <p>Loading...</p>
      ) : data?.success ? (
        <div className="grid gap-10 xl:grid-cols-[3fr_2fr]">
          <article className="rounded-2xl bg-white p-4 shadow-xl lg:p-10">
            <div className="mb-4 flex items-center gap-4">
              <div className="w-fit rounded-full bg-primary-orange p-3">
                <SvgVerified className="h-10 w-10" />
              </div>
              <div>
                <Link
                  title={`${data.data.name} events`}
                  to={`/events/business/${data.data.business_id}`}
                  className="hover-link inline-block"
                >
                  <h4 className="text-2xl font-bold">{data.data.name}</h4>
                </Link>
                <p className="font-bold text-primary-orange">
                  Arrive a tourist, Leave a local adventurer!
                </p>
              </div>
            </div>
            <p className="font-bold">
              {data.data.translations[selectedLanguage].description}
            </p>
          </article>
          <div className="row-start-1 xl:col-start-2">
            <ul className="mb-6 grid grid-cols-3 gap-6">
              {data.data.images.map((image, index) => {
                return (
                  <li
                    key={`partner_image_${image.id}`}
                    className={`overflow-hidden rounded-lg border border-primary-orange${
                      index === 0
                        ? " col-span-3 min-h-[300px]"
                        : " min-h-[50px]"
                    }`}
                  >
                    {image && (
                      <button
                        title="View image"
                        type="button"
                        onClick={() => selectImage(index)}
                        className="h-full w-full"
                      >
                        <img
                          src={image.image}
                          alt={data.data.name + image.id}
                          className="h-full w-full object-cover"
                        />
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
            <Link
              title={`${data.data.name} events`}
              to={`/events/business/${data.data.business_id}`}
              className="hover-link inline-block hover:drop-shadow-lg"
            >
              <article className="flex items-center gap-4">
                <div className="relative h-20 w-20 rounded-full bg-white p-4 shadow-xl">
                  <img
                    loading="lazy"
                    src={data.data.logo}
                    alt={data.data.name}
                    title={data.data.name}
                    className="h-full w-full object-contain"
                  />
                  <SvgVerified className="absolute right-0 top-0 h-10 w-10 -translate-y-1/4 translate-x-1/4 rounded-full bg-primary-orange p-1.5" />
                </div>
                <h4 className="text-xl">{data.data.name}</h4>
              </article>
            </Link>
          </div>

          <button
            className={`fixed inset-0 z-30 grid h-full w-full origin-top place-items-center duration-200 ${
              selected === 0 || selected !== null
                ? "scale-100 bg-black/75"
                : "scale-0 bg-black/10"
            }`}
            title="Hide image"
            onClick={emptySelected}
          >
            <div className="grid place-items-center">
              {selected === 0 || selected !== null ? (
                <img
                  className="h-full max-h-[80dvh] w-full max-w-[90dvw] rounded-xl object-contain"
                  src={data.data.images[selected].image.toString()}
                  alt={`Selected ${selected}`}
                />
              ) : (
                <div className="h-full w-full bg-primary-gray"></div>
              )}
            </div>
          </button>
        </div>
      ) : (
        <p className="text-red-600">Something went wrong...</p>
      )}
    </section>
  );
};

export default Details;
