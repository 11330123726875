import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../types/utils/laravel";
import { TPartner, TPartnerDisplay } from "../../types/partner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../store/redux";
import { useEffect, useState } from "react";
import { TEvent } from "../../types/event";
import { TUser } from "../../types/user";
import { removeBodyBackground } from "../../utils/functions";

export const usePartners = () => {
  const { pathname } = useLocation();
  const { partnerId } = useParams();
  const { isSuccess, isLoading, data } = useQuery({
    queryKey: ["partners"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<Array<TPartnerDisplay>>>("partners");
      return res.data;
    },
    staleTime: 240_000,
  });
  const navigate = useNavigate();
  !partnerId &&
    isSuccess &&
    data.success &&
    navigate(`/partners/${data.data[0].id}`);
  return { data, isLoading, pathname };
};

export const usePartner = () => {
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const { partnerId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["partner", partnerId],
    queryFn: async () => {
      if (partnerId) {
        const res = await axios.get<TLaravelResponse<TPartner>>(
          `partners/${partnerId}`,
        );
        return res.data;
      }
      return null;
    },
  });
  const [selected, setselected] = useState<number | null>(null);
  const selectImage = (index: number) => {
    setselected((_prev) => (_prev = index));
  };
  const emptySelected = () => {
    setselected((_prev) => (_prev = null));
  };
  return {
    data,
    isLoading,
    selectedLanguage,
    selectImage,
    selected,
    emptySelected,
  };
};

export const usePartnerEvents = () => {
  const { businessId } = useParams();
  const { data } = useQuery({
    queryKey: ["partner-events", businessId],
    queryFn: async () => {
      const res = await axios.get<
        TLaravelResponse<{
          business: TUser & { partner: TPartner };
          events: Array<TEvent>;
          partner_image: string | null;
        }>
      >(`partners/${businessId}/events`);
      return res.data;
    },
  });
  useEffect(() => removeBodyBackground(), []);
  return { data };
};
