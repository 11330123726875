import PrimaryButton from "../../../../component/PrimaryButton";
import CustomInput from "../../../../components/common/inputs/NewInput";
import CustomSelect from "../../../../components/common/inputs/Select";
import { TCountry } from "../../../../types/country";
import { useAddress } from "../_hooks";

const Address = () => {
  const {
    countries,
    handleSubmit,
    user,
    submittable,
    isPending,
    handleChange,
  } = useAddress();
  return (
    <section id="profile-address" className="dashboard-card gray sm:col-span-2">
      <h3 className="section-title mb-4">Address</h3>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-2 items-start gap-4"
      >
        <CustomSelect
          placeholder={user.country ?? "Country"}
          defaultValue={{ name: user.country } ?? undefined}
          required
          id="country"
          name="country"
          options={countries?.success ? countries.data : []}
          getOptionLabel={(option) => (option as TCountry).name}
          getOptionValue={(option) => (option as TCountry).name}
          onChange={handleChange}
        />
        <CustomInput
          type="text"
          defaultValue={user.city ?? undefined}
          className="rounded-2xl"
          autoComplete="city"
          name="city"
          placeholder="City"
          onChange={handleChange}
        />
        <CustomInput
          required
          defaultValue={user.detailed_address ?? undefined}
          className="rounded-2xl"
          type="text"
          autoComplete="address"
          placeholder="Detailed address"
          name="detailed_address"
          onChange={handleChange}
        />
        <CustomInput
          type="text"
          defaultValue={user.postal_code ?? undefined}
          className="rounded-2xl"
          autoComplete="postal"
          placeholder="Postal code"
          name="postal_code"
          onChange={handleChange}
        />
        <PrimaryButton
          loading={isPending}
          disabled={!submittable || isPending}
          title="Submit address"
          type="submit"
          className="col-start-2 ml-auto w-fit"
        >
          Submit
        </PrimaryButton>
      </form>
    </section>
  );
};

export default Address;
