import BottomBar from "./BottomBar";
import Contact from "./Contact";
import Family from "./Family";
import Identity from "./Identity";
import PaymentSystems from "./PaymentSystems";

const Footer = () => {
  return (
    <footer
      id="site-footer"
      className="bg-[url(/public/assets/images/footer-bg.png)] bg-cover bg-no-repeat"
    >
      <div className="container grid gap-x-10 gap-y-14 py-14 md:grid-cols-2 xl:grid-cols-4">
        <Identity />
        <Contact />
        <Family />
        {/* <Company />
          <Support /> */}
        <PaymentSystems />
      </div>
      <div className="h-px w-full rounded-xl bg-white/50"></div>
      <BottomBar />
    </footer>
  );
};

export default Footer;
