import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useAppDispatch } from "../../../../../store/redux";
import { toggleShowButtonLoader } from "../../../../../store/GlobalState/ActionGlobalState";
import PrimaryButton from "../../../../../component/PrimaryButton";
import { toast } from "sonner";
type props = {
  navigateURL: string;
};
const StripeForm = (props: props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const handleSubmit = async (event: any) => {
    dispatch(toggleShowButtonLoader(true));

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
    } catch {}
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: props.navigateURL as string,
      },
    });

    if (result.error) {
      dispatch(toggleShowButtonLoader(false));
      toast.error(result.error.message ?? "Error While Submitting");
    } else {
      dispatch(toggleShowButtonLoader(false));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="flex w-full justify-start">
        <PrimaryButton className={` mt-5  w-1/2 py-2`}>
          <span className="px-1">Pay</span>
          <img
            src="/assets/svgs/common/white-arrow.svg"
            className="w-4"
            alt="Arrow"
          />
        </PrimaryButton>
      </div>
    </form>
  );
};
export default StripeForm;
