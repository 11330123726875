import AboutEvent from "./components/AboutEvent";
import AdditionalDetails from "./components/AdditionalDetails";
import FAQS from "./components/FAQS";
import { useEffect, useState } from "react";
import EventCard from "../../component/EventCard";
import BookingEvent from "./components/BookingEvent";
import CustomerReviews from "./components/CustomerReviews";
import AllReviews from "./components/AllReviews";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { TEventReview } from "../../store/EventDetails/types";
import { useTranslation } from "react-i18next";
import { suggestedEventsAxios } from "../../networking/eventDetails/eventDetails";
import { IEventsData } from "../../networking/events/types";
import SingleEventMap from "./components/GoogleMaps/SingleEventMap";
import { getEventDetails } from "../../store/EventDetails/ActionEventDetails";
import GalleryImages from "./components/GalleryImages";
import {
  getNextURL,
  scrollToTop,
  turnEventIdToSlug,
} from "../../utils/functions";

const EventDetails = () => {
  const { eventId } = useParams();
  const { eventDetails } = useAppSelector((state) => state.eventDetailsReducer);
  const [reviews] = useState<TEventReview[]>([]);
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );
  const [suggestedEvents, setSuggestedEvents] = useState<
    IEventsData[] | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleSuggestedEventClick = (eventId: number) => {
    dispatch(getEventDetails(eventId, currentPage));
    scrollToTop();
  };
  useEffect(() => {
    if (eventId) {
      dispatch(getEventDetails(Number(eventId), currentPage));
      suggestedEventsAxios(Number(eventId)).then((data) => {
        setSuggestedEvents((prev) => (prev = data));
      });
    }
  }, [eventId, currentPage, dispatch]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const firstImageURL =
    eventDetails.images && eventDetails.images.length > 0
      ? eventDetails.images[0]?.image_path
      : "/default-image-url.jpg";
  useEffect(() => {}, [eventId]);
  return (
    <section id="event-details">
      <div className="container pt-10">
        <div>
          <GalleryImages
            images={eventDetails.images}
            videoUrl={eventDetails.video_url}
          />
          <div className="pt-10 md:grid md:grid-cols-[2fr_1fr]">
            <div>
              <AboutEvent
                title={eventDetails.title}
                businessId={eventDetails.business_id}
                averageRating={eventDetails.average_rating}
                description={eventDetails.description}
                duration={eventDetails.duration}
                displayAge={eventDetails.display_age}
                duration_type={eventDetails.duration_type}
                business={{
                  id: eventDetails.business?.id || 0,
                  name: eventDetails.business?.name || "",
                  profile_pic: eventDetails.business?.profile_pic || "",
                }}
                language={eventDetails.language}
                selectedLanguage={selectedLanguage}
              />
              <AdditionalDetails
                highlights={eventDetails.highlights}
                important_information={eventDetails.important_information}
                includes={eventDetails.includes}
                selectedLanguage={selectedLanguage}
              />
              <SingleEventMap
                latitude={eventDetails.latitude}
                longitude={eventDetails.longitude}
                image={firstImageURL}
                eventDetails={eventDetails}
              />
              <FAQS faqs={eventDetails.faqs} />
              <CustomerReviews
                reviews={reviews}
                customerReviews={eventDetails}
              />
              <AllReviews
                pageNumber={currentPage}
                hanldePaginationChange={handlePageChange}
                currentEventId={parseInt(eventId || "0")}
              />
            </div>
            <div className="md:container">
              {eventDetails.ages ? (
                <BookingEvent
                  quantity={eventDetails.quantity}
                  remainingTime={eventDetails.remaining_time}
                  fees={eventDetails.fees}
                  ages={eventDetails.ages}
                  visitTime={eventDetails}
                  workingDays={eventDetails.working_days}
                  title={eventDetails.title}
                  durationType={eventDetails.duration_type}
                  quantityPerDuration={eventDetails?.quantity_per_duration}
                  quantityLeft={eventDetails?.quantity_left}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        {suggestedEvents ? (
          suggestedEvents.length > 0 ? (
            <div>
              <h3 className="pt-4 text-2xl font-bold text-[#131C23]">
                {t("alsoLike")}.....
              </h3>
              <div className="grid grid-cols-1 gap-4 py-10 md:grid-cols-2 lg:grid-cols-4">
                {suggestedEvents.map((suggestedEvent) => (
                  <Link
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    to={getNextURL({
                      nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
                        suggestedEvent.title,
                        suggestedEvent.id,
                      )}`,
                    })}
                    key={suggestedEvent.id}
                  >
                    <div
                      onClick={() =>
                        handleSuggestedEventClick(suggestedEvent.id)
                      }
                    >
                      <EventCard
                        key={suggestedEvent.id}
                        event={suggestedEvent}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )
        ) : (
          <p>Loading</p>
        )}
      </div>
    </section>
  );
};
export default EventDetails;
