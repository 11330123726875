import SvgBell from "../../assets/svgs/icons/SvgBell";
import SvgClipboard from "../../assets/svgs/icons/SvgClipboard";
import SvgFile from "../../assets/svgs/icons/SvgFile";
import SvgGear from "../../assets/svgs/icons/SvgGear";
import SvgHexagon from "../../assets/svgs/icons/SvgHexagon";
import SvgSuitcase from "../../assets/svgs/icons/SvgSuitcase";

export const businessProfileSideBar = [
  {
    id: "settings",
    path: "/business-profile",
    title: "Profile",
    Svg: SvgGear,
  },
  {
    id: "overview",
    path: "/overview",
    title: "Overview",
    Svg: SvgHexagon,
  },
  {
    id: "eventList",
    path: "/event-list/accepted",
    title: "Event List",
    Svg: SvgClipboard,
  },
  {
    id: "eventCreation",
    path: "/event-creation/custom",
    title: "Event Creation",
    Svg: SvgSuitcase,
  },
  {
    id: "notifications",
    path: "/notifications",
    title: "Notification",
    Svg: SvgBell,
  },
  {
    id: "documents",
    path: "/documents",
    title: "Documents",
    Svg: SvgFile,
  },
];
