import GlobeSvg from "../../../component/SvgComponents/GlobeSvg";
import SvgLocationPin from "../../../assets/svgs/icons/SvgLocationPin";

const HowWeDiffer = () => {
  return (
    <section id="about-how-we-differ" className="section-margin container">
      <div className="single-grid relative rounded-3xl before:absolute before:inset-0 before:-z-10 before:-rotate-2 before:rounded-3xl before:border-4 before:border-black before:content-['']">
        <img
          loading="lazy"
          src="/assets/images/pages/about/how-we-differ-background.webp"
          alt="Road surrounded by rocks"
          className="single-grid-item h-full w-full rounded-3xl bg-no-repeat object-cover object-center"
        />
        <div className="single-grid-item container relative z-10 flex flex-col items-center gap-4 py-12 text-center text-white">
          <h4 className="rounded-text text-lg capitalize">How we differ</h4>
          <h3 className="font-poppins-light text-lg md:text-3xl">
            We're not funded by billionaire investors or big banks.
          </h3>
          <h2 className="text-2xl font-normal text-white md:text-4xl">
            <span className="font-extrabold">Explore near me</span>
            &nbsp;is Built
          </h2>
          <div className="rounded-xl bg-black/50 px-6 py-2.5 lg:bg-primary-orange">
            <h2 className="text-2xl font-normal text-white md:text-4xl">
              by the community, for the community.
            </h2>
          </div>
        </div>
        <div className="shadow-card absolute -top-2 left-0 z-10 flex -rotate-12 items-center gap-2 rounded-full bg-white px-5 py-2.5 text-sm">
          <SvgLocationPin className="h-4 w-4" />
          <p className="capitalize">Explore near me universe</p>
        </div>
        <GlobeSvg className="absolute -bottom-10 right-0 h-20 w-20 -rotate-6 lg:-bottom-4 lg:right-[10%] lg:h-32 lg:w-32" />
      </div>
    </section>
  );
};

export default HowWeDiffer;
