import { useTranslation } from "react-i18next";
import { TClientLevel } from "../../../../types/client";
import SvgWarning from "../../../../assets/svgs/icons/SvgWarning";
import SvgAward from "../../../../assets/svgs/icons/SvgAward";

type TProps = {
  account: TClientLevel | undefined;
};

const Account = ({ account }: TProps) => {
  const { t } = useTranslation();
  if (!account) return null;
  return (
    <section
      id="gamification-account"
      className="dashboard-card bg-primary-orange/10"
    >
      <div className="mb-4 flex items-center gap-2">
        <h3 className="section-title">
          {t("userProfile.gamification.levelAccount.title")}
        </h3>
        <SvgWarning className="h-5 w-5" />
      </div>
      <article className="mb-6 flex flex-wrap rounded-xl bg-primary-navy p-1">
        <div className="grid place-items-center rounded-xl bg-white p-2">
          <img
            src={account.current_level_image}
            alt="Current level"
            className="h-28 w-28 object-contain"
          />
        </div>
        <div className="basis-80 p-4 text-white">
          <div className="mb-4 flex w-fit items-center gap-2 rounded-full border border-primary-orange bg-primary-orange/20 px-3 py-1 text-primary-orange">
            <SvgAward className="h-4 w-4 fill-primary-orange" />
            <p>{account.current_level}</p>
          </div>
          <p>
            <span className="text-3xl">{account.current_exp}</span>
            &nbsp;/&nbsp;
            {account.next_level_at}
          </p>
        </div>
      </article>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-wrap gap-2">
          <SvgAward className="mt-1 h-6 w-6 fill-primary-orange" />
          <div className="basis-48">
            <h6 className="text-lg font-bold">
              {t("userProfile.gamification.levelAccount.levelOne.label")}
            </h6>
            <p>
              {t("userProfile.gamification.levelAccount.levelOne.description")}
              &nbsp;
              <b className="text-primary-orange">
                {t("userProfile.gamification.levelAccount.levelOne.tokens")}
              </b>
            </p>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          <SvgAward className="mt-1 h-6 w-6 fill-primary-orange" />
          <div className="basis-48">
            <h6 className="text-lg font-bold">
              {t("userProfile.gamification.levelAccount.levelTwo.label")}
            </h6>
            <p>
              {t("userProfile.gamification.levelAccount.levelOne.description")}
              &nbsp;
              <b className="text-primary-orange">
                {t("userProfile.gamification.levelAccount.levelTwo.tokens")}
              </b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Account;
