import React, {useState, useRef, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useAppDispatch} from "../../../../store/redux";
import {
    checkOtp,
    createNewPassword,
    getInterests,
    resendOTP,
    setInterestUser,
    setShowForgotPasswordContent,
    setShowResetPassword,
    setShowSignInContent,
    setShowVerificationPassword,
    toggleVerificationModal, toggleVerificationModalPassword,
    verifyEmail
} from "../../../../store/Auth/ActionAuth";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import PrimaryButton from "../../../PrimaryButton";
import ResetPassword from "./ResetPassword";

type VerificationEmailProps = {
    onClose: () => void;
    open: boolean;
};
const VerificationEmailForPassword: React.FC<VerificationEmailProps> = ({onClose, open}) => {
    const dispatch = useAppDispatch()
    const [verificationCode, setVerificationCode] = useState(Array(5).fill(""));
    const userInfo = useSelector((state: RootState) => state.authReducer?.userInfo);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const [resendTimer, setResendTimer] = useState(59);
    const showResetPasswordModal = useSelector((state: RootState) => state.authReducer.showResetPasswordModal)
    const [error, setError] = useState<string | null>(null);

    const handleVerificationCodeChange = (value: string, index: number) => {
        if (/^\d*$/.test(value)) {
            const updatedCode = [...verificationCode];
            updatedCode[index] = value;
            setVerificationCode(updatedCode);
            if (value && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };
    // const handleContinueClick = () => {
    //     const email = localStorage.getItem('verificationEmail');
    //     if (email !== null) {
    //         const otp = verificationCode.join('');
    //         dispatch(checkOtp(verificationCode.join(''), email))
    //             .then(() => {
    //                 localStorage.setItem('otpVerified', 'true');
    //                 localStorage.setItem('otp', otp);
    //                 dispatch(toggleVerificationModal(false));
    //                 dispatch(setShowResetPassword(true));
    //             })
    //             .catch(() => {
    //             });
    //     }
    // };
    const handleContinueClick = async () => {
        const email = localStorage.getItem('verificationEmail');
        if (email !== null) {
            const otp = verificationCode.join('');
            dispatch(checkOtp(verificationCode.join(''), email))
                .then(() => {
                    localStorage.setItem('otpVerified', 'true');
                    localStorage.setItem('otp', otp);
                    dispatch(toggleVerificationModal(false));
                    dispatch(setShowResetPassword(true));
                })
                .catch((error: any) => {
                    if (error.response && error.response.status === 422 && error.response.data && error.response.data.email) {
                        setError(error.response.data.message);
                    } else {
                        setError(error.response.data.message);
                    }
                });
        }
    };

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    useEffect(() => {
        if (resendTimer > 0 && open) {
            const interval = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (resendTimer === 0) {
            // Handle timer reaching zero, reset timer
        }
    }, [resendTimer, open]);

    const handleResendCode = () => {
        dispatch(resendOTP()).then((res) => {
            setResendTimer(59)
        }).catch(() => {

        })
    }
    const handleShowEmailRequest = () => {
        dispatch(toggleVerificationModalPassword(false))
        dispatch(setShowForgotPasswordContent(true))
    };
    if (showResetPasswordModal) {
        return <ResetPassword onClose={() => dispatch(setShowResetPassword(false))}
                              open={showResetPasswordModal}
        />
    }
    return (
        <>
            <div id="modal-title">
                <div className="flex justify-center pb-5">
                    <img loading="lazy" src="/assets/images/pages/black-logo.png" className="w-70 h-10"/>
                </div>
                <h3 className="font-bold text-2xl text-center"> Verification Mail</h3>
                <div className="flex items-center justify-center text-sm">
                    <p className="text-[#131C23]/40 font-medium"> Your code was sent </p> <span
                    className="text-[#131C23] font-bold ml-2">{userInfo?.email}</span>
                </div>
            </div>
            <div className="verification-code-input space-x-2 py-3 flex justify-center items-center">
                {verificationCode.map((code, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={code}
                        onChange={(e) => handleVerificationCodeChange(e.target.value, index)}
                        ref={(input) => (inputRefs.current[index] = input)}
                        className="verification-input"
                    />
                ))}
            </div>
            <p
                className="cursor-pointer flex justify-center items-center"
            >
                <span className="text-[#131C23]/40 py-3">Resend code: </span>
                {resendTimer == 0 ?
                    <span onClick={handleResendCode} className="text-[#3F87FF] font-bold">Send</span> :
                    <span className="text-[#3F87FF] font-bold">{resendTimer}s</span>}

            </p>
            {error && (
                <div className="ml-2 text-[12px] text-red-600">
                    {error}
                </div>
            )}
            <div className="flex items-center justify-between gap-4 pt-5">
                <PrimaryButton onClick={handleContinueClick}>
                    <span className="px-1">Continue</span>
                    <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4"/>
                </PrimaryButton>
            </div>
        </>
    );
};

export default VerificationEmailForPassword;
