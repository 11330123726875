import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TLaravelResponse } from "../../../types/utils/laravel";
// import { useNavigate } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TEventGlobalSearch } from "../../../types/event";
import { TLanguage } from "../../../types/language";
import { useAtom, useAtomValue } from "jotai";
import i18n from "../../../i18n/i18n";
import { useAppDispatch, useAppSelector } from "../../../store/redux";
import { setLanguage } from "../../../store/Language/LanguageSlice";
import { TCurrency, TCurrencyAtom } from "../../../types/currency";
import { toast } from "sonner";
import { useCurrencies } from "../../../utils/hooks";
import { languageAtom } from "../../../store/language";
import { currencyAtom } from "../../../store/currency";
import {
  setAuthModal,
  toggleVerificationModal,
} from "../../../store/Auth/ActionAuth";
import { useTranslation } from "react-i18next";
import { userAtom } from "../../../store/user";

export const useHeader = () => {
  const stickyPaths = [
    "/",
    "/about",
    "/partners",
    "/events/business/:businessId",
  ];
  const { pathname } = useLocation();
  const absolute = stickyPaths.includes(pathname);
  const token = localStorage?.getItem("token");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const roleType = useAppSelector((state) => state.authReducer?.userRole);
  const user = useAtomValue(userAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const openAuthModal: boolean = useAppSelector(
    (state) => state.authReducer?.openAuthModal,
  );

  const handleOpenAuthModal = () => {
    dispatch(setAuthModal(true));
  };
  const handleNavigateUser = () => {
    if (localStorage.getItem("otpVerified") == "false") {
      dispatch(toggleVerificationModal(true));
    } else navigate(roleType?.id === 1 ? "/user-profile" : "/business-profile");
  };
  const closeModal = () => {
    dispatch(setAuthModal(false));
    searchParams.delete("nextRedirect");
    setSearchParams(searchParams);
  };
  return {
    absolute,
    token,
    t,
    user,
    searchParams,
    openAuthModal,
    handleOpenAuthModal,
    handleNavigateUser,
    closeModal,
  };
};

export const useHeaderLocations = () => {
  const { data } = useQuery({
    queryKey: ["events-filters"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse>("events/filters");
      return res.data;
    },
  });
  //   const navigate = useNavigate();
  const [location, setLocation] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const handleSelect = (loc: { label: string; value: string }) => {
    setLocation(loc);
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // navigate(
    //   `/${lang}/categories?location=${new FormData(e.currentTarget).get(
    //     "location",
    //   )}#events-grid`,
    // );
  };
  return { data, location, handleSubmit, handleSelect };
};

export const useHeaderGlobal = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<TEventGlobalSearch[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setIsLoading(true);
        axios
          .get<TLaravelResponse<TEventGlobalSearch[]>>(
            `global-search?search=${searchTerm}`,
          )
          .then((res) => {
            setIsLoading(false);
            if (res?.data.success) {
              setData(res.data.data);
            }
          });
      } else {
        setData(null);
      }
    }, 2500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") setData(null);
  };
  return { searchTerm, data, isLoading, handleChange };
};

export const useHeaderLanguages = () => {
  const { data } = useQuery({
    queryKey: ["languages"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TLanguage[]>>("languages");
      return res.data;
    },
  });
  const [language, setSelectedLanguage] = useAtom(languageAtom);
  const dispatch = useAppDispatch();
  const selectLanguage = (lang: TLanguage) => {
    i18n.changeLanguage(lang.code);
    document.documentElement.setAttribute("lang", lang.code);
    if (lang.code === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
    dispatch(setLanguage(lang.code));
    setSelectedLanguage(lang);
  };
  const isLinked = (
    item: TLanguage | { code: string; title: string },
  ): item is TLanguage => (item as TLanguage).id !== undefined;
  if (data?.success && !isLinked(language)) setSelectedLanguage(data.data[0]);
  return { data, language, selectLanguage, isLinked };
};

export const useHeaderCurrencies = () => {
  const [currency, setCurrency] = useAtom(currencyAtom);
  const [selected, setSelected] = useState<TCurrency | null>(null);
  const { data } = useCurrencies();
  const isLinked = (curr: TCurrencyAtom): curr is TCurrency =>
    (curr as TCurrency).id !== undefined;
  if (data?.success && !isLinked(currency))
    setCurrency(data.data.currencies[0]);
  const { mutate } = useMutation({
    mutationFn: async (id: number) => {
      const res = await axios.post<TLaravelResponse<[]>>(`currency/${id}}`);
      return res.data;
    },
    onSuccess: () => {
      if (selected) setCurrency(selected);
      toast.success("Currency selected");
    },
    onError: () => {
      toast.error("Failed to select currency...");
    },
  });
  const handleSelect = async (curr: TCurrency) => {
    if (localStorage.getItem("token")) mutate(curr.id);
    setSelected(curr);
  };

  return { data, currency, handleSelect, isLinked };
};
