import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import CheckoutEventCard from "../../../component/Checkout/CheckoutEventCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getSelectedCartItems } from "../../../store/Cart/ActionCart";
import PersonalDetails from "./PersonalDetails";
import { Divider } from "@mui/material";
import PaymentSelection from "./PaymentSelection";
import PaymentSection from "./PaymentSection";
import { useTranslation } from "react-i18next";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../store/currency";
const Checkout = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const events = useSelector(
    (state: RootState) => state.cartReducer?.cartItem.selectedCart,
  );
  const totalPrice = useSelector(
    (state: RootState) =>
      state.cartReducer?.checkoutState?.userData.total_price,
  );
  const currency = useAtomValue(currencyAtom);
  const steps = ["Personal", "Payment Selection", "Final Payment"];
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState<{
    [k: number]: boolean;
  }>({});
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  useEffect(() => {
    dispatch(getSelectedCartItems());
    //eslint-disable-next-line
  }, []);
  return (
    <div className="container mt-10 min-h-[calc(100vh-120px)]">
      <div className="flex items-center gap-3">
        <h3 className="text-xl font-bold text-[#131C23]">
          {t("checkout.personalDetails.title")}{" "}
        </h3>
        <div className="rounded-full bg-[#65CC93]/5 px-4 py-1 text-sm text-[#65CC93]">
          {t("checkout.fastSecure")}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <div className="mt-6">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "#FF913F", // circle color (ACTIVE)
                  },
                }}
              >
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <PersonalDetails handleNext={handleNext} />
          ) : activeStep === 1 ? (
            <>
              <PaymentSelection handleNext={handleNext} />
            </>
          ) : (
            <>
              <PaymentSection handleChangeSection={() => setActiveStep(1)} />
            </>
          )}
        </div>
        <div className="container mb-10 mt-6 flex w-full flex-col gap-3  rounded-lg bg-white shadow-md">
          <div className="max-h-[275px] overflow-y-scroll lg:max-h-[calc(100vh-290px)]">
            {events?.map((item: any, index: any) => (
              <>
                <CheckoutEventCard item={item} />
                {index < events.length - 1 && <Divider />}
              </>
            ))}
          </div>

          <div className="mb-7 flex w-full items-center justify-between rounded-full bg-[#F5F9FF] px-4 py-2">
            <div className="">
              Subtotal:{" "}
              <span className="font-bold">
                {events.length} {events.length === 1 ? "item" : "items"}
              </span>
            </div>
            <div className="text-2xl text-[#3F8CFF]">
              {currency.symbol}{" "}
              {(totalPrice * +currency.exchange_rate).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
