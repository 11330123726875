import { ComponentProps, DetailedHTMLProps, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import SvgLocationPin from "../../assets/svgs/icons/SvgLocationPin";

type TProps = {
  className?: DetailedHTMLProps<
    HTMLAttributes<HTMLElement>,
    HTMLElement
  >["className"];
  svgClassName?: ComponentProps<typeof SvgLocationPin>["className"];
  location: string;
};

const EventLocation = ({ location, className, svgClassName }: TProps) => {
  return (
    <address className={twMerge("flex items-center gap-1", className)}>
      <SvgLocationPin
        className={twMerge("inline h-4 w-4 fill-[#3F8CFF]", svgClassName)}
      />
      {location}
    </address>
  );
};

export default EventLocation;
