import { toggleModal } from "../../../utils/functions";
import Modal from "../../layout/Modal";
import { useHeaderLanguages } from "./_hooks";

const Languages = () => {
  //   const { path, isClient, data, lang } = useLanguages();
  //   if (!data?.success || !isClient) return null;
  //   const language = data.data.find(
  //     (language) => language.code === path.substring(1, 3),
  //   );
  const { language, data, selectLanguage, isLinked } = useHeaderLanguages();
  return (
    <div>
      <button
        type="button"
        onClick={() => toggleModal("languages")}
        title="Select a language"
        className="modal-button hover-link flex items-center gap-2"
      >
        {isLinked(language) && (
          <img
            src={language.logo}
            alt={language.title}
            title={language.title}
            className="h-6 w-6 object-contain"
          />
        )}
        {language?.title}
      </button>
      <Modal
        id="languages"
        heading={<h2 className="section-title">Select a language</h2>}
      >
        <ul className="grid grid-cols-2 gap-10 md:grid-cols-4">
          {data?.success &&
            data.data.map((language, index) => {
              return (
                <li key={`modal_language_${index}`}>
                  <button
                    onClick={() => selectLanguage(language)}
                    title={language.title}
                    className="hover-link flex items-center gap-2"
                  >
                    <img
                      src={language.logo}
                      alt={language.title}
                      title={language.title}
                      className="h-8 w-8 object-contain"
                    />
                    {language.title}
                  </button>
                </li>
              );
            })}
        </ul>
      </Modal>
    </div>
  );
};

export default Languages;
