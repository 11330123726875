import {
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?:
    | DetailedHTMLProps<
        LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
      >["children"]
    | true;
  labelClass?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  containerClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  blockClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  error?: string | null;
  showPassword?: boolean;
};

const inputClass =
  "w-full rounded-xl bg-white px-4 py-[7px] duration-500 placeholder:text-primary-gray focus:[outline:none] focus-visible:shadow-[0_0_1px_1px] focus-visible:shadow-primary-orange focus-visible:[outline:none] disabled:bg-gray-300";

const CustomInput = forwardRef<HTMLInputElement, TProps>(function CustomInput(
  {
    label,
    labelClass,
    containerClass,
    error,
    showPassword,
    blockClass,
    ...props
  },
  ref,
) {
  return (
    <div className={containerClass}>
      <label
        hidden={!!!label}
        htmlFor={
          props.id ? props.id : props.name ? `${props.name}-input` : undefined
        }
        className={twMerge("mb-0.5 block", labelClass)}
      >
        {label === true ? props.title : label}
        {props.required && label && " *"}
      </label>
      <div className={blockClass}>
        <input
          id={props.name ? `${props.name}-input` : undefined}
          {...props}
          className={twMerge(inputClass, props.className)}
          ref={ref}
        />
        <div
          className={`flex items-center gap-1.5 overflow-y-hidden duration-500 ${
            error ? "max-h-[100px]" : "max-h-0"
          }`}
        >
          <span className="mt-1 text-sm text-red-600">{error && error}</span>
        </div>
      </div>
    </div>
  );
});

export default CustomInput;
