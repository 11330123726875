import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./types";
import { NotficationObject, UserInfo, UserRole } from "../../networking/auth/types";

// interface AuthState {
//     isLoggingIn: boolean;
//     role: "client" | "business" | null;
// }


const userInfo = JSON?.parse(localStorage.getItem("userInfo") as string) ?? null
const userRole = JSON?.parse(localStorage.getItem("userRole") as string) ?? null
const token = localStorage?.getItem('token') ?? '';

const initialState: AuthState = {
    showSignUpContent:false,
    showForgotPasswordContent:false,
    showVerificationModalPassword:false,
    showSendEmail:false,
    showSignInContent:false,
    showResetPasswordModal:false,
    activeTab:'client',
    openAuthModal:false,
    showVerificationModal: false,
    isLoggingIn: false,
    role: null,
    errors: {},
    userInfo: userInfo,
    userRole: userRole,
    interests:[],
    notifications: [],
    token: token,
    forgotPasswordEmail:"",
    logInGoogle:""
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state) => {
            state.isLoggingIn = true;
        },
        logout: (state) => {
            state.isLoggingIn = false;
            state.role = null;
        },
        loginWithGoogle: (state, action: PayloadAction<{ token: string; user: UserInfo;role:UserRole }>) => {
            state.token = action.payload.token;
            state.userInfo = action.payload.user;
            state.userRole=action.payload.role;
            state.isLoggingIn = false;
        },
        setRole: (state, action: PayloadAction<"client" | "business">) => {
            state.role = action.payload;
        },
        setUserData: (state, action: PayloadAction<UserInfo | null>) => {
            state.userInfo = action.payload;
        },
        setInterests: (state, action: PayloadAction<[]>) => {
            state.interests = action.payload;
        },
        setVerificationModal: (state, action: PayloadAction<boolean>) => {
            state.showVerificationModal = action.payload;
        },
        setVerificationModalPassword: (state, action: PayloadAction<boolean>) => {
            state.showVerificationModalPassword = action.payload;
        },
        setShowResetPasswordModal: (state, action: PayloadAction<boolean>) => {
            state.showResetPasswordModal = action.payload;
        },
        setOpenAuthModal: (state, action: PayloadAction<boolean>) => {
            state.openAuthModal = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload;
        },
        setSignUpContent: (state, action: PayloadAction<boolean>) => {
            state.showSignUpContent = action.payload;
        },
        setShowSignInContent: (state, action: PayloadAction<boolean>) => {
            state.showSignInContent = action.payload;
        },
        setShowSendEmailContent: (state, action: PayloadAction<boolean>) => {
            state.showSendEmail = action.payload;
        },
        setForgotPasswordContent: (state, action: PayloadAction<boolean>) => {
            state.showForgotPasswordContent = action.payload;
        },
        setUserRole: (state, action: PayloadAction<UserRole | null>) => {
            state.userRole = action.payload;
        },
        setErrors(state, action: PayloadAction<Object | null>) {
            state.errors = action.payload;
        },
        setToken: (state, action: PayloadAction<string | null>) => {
            state.token = action.payload;
        },
        setNotificationList: (state, action: PayloadAction<NotficationObject[]>) => {
            state.notifications = action.payload;
        },
        setForgotPasswordEmail :(state , action: PayloadAction<string>) =>{
            state.forgotPasswordEmail =action.payload;
        }
    },
});

// export const { login, logout, setRole,setUserData } = authSlice.actions;

export default authSlice.reducer;
