import {
  DetailedHTMLProps,
  DialogHTMLAttributes,
  HTMLAttributes,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  DialogHTMLAttributes<HTMLDialogElement>,
  HTMLDialogElement
> & {
  modalClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
};

const Modal = ({ modalClass, ...props }: TProps) => {
  useEffect(() => {
    if (props.open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [props.open]);

  return createPortal(
    <dialog {...props}>
      <div
        className={twMerge(
          "max-h-full max-w-5xl overflow-y-auto rounded-xl bg-white",
          modalClass,
        )}
      >
        {props.children}
      </div>
    </dialog>,
    document.body,
  );
};

export default Modal;
