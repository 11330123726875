"use client";
import { DetailedHTMLProps, DialogHTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { createPortal } from "react-dom";
import { TModal } from "../../types/modal";
import { toggleModal } from "../../utils/functions";
import SvgX from "../../assets/svgs/icons/SvgX";

type TProps = DetailedHTMLProps<
  DialogHTMLAttributes<HTMLDialogElement>,
  HTMLDialogElement
> & {
  id: TModal;
  children: ReactNode;
  heading?: ReactNode;
};

const Modal = ({ id, children, heading, ...props }: TProps) => {
  return createPortal(
    <dialog
      id={`${id}-modal`}
      {...props}
      className={twMerge(
        "modal-dialog container fixed inset-0 z-40 mx-auto min-w-[260px] max-w-3xl content-center rounded-xl bg-white py-5 shadow-lg",
        props.className,
      )}
    >
      <div className="mb-4 flex flex-wrap-reverse items-center justify-between">
        <div>{heading && heading}</div>
        <button
          onClick={() => toggleModal(id)}
          autoFocus={false}
          title="Close modal"
          className="group"
        >
          <SvgX className="h-3.5 w-3.5 duration-200 group-hover:rotate-180" />
        </button>
      </div>
      <div className="py-1">{children}</div>
    </dialog>,
    document.body,
  );
};

export default Modal;
