import Become from "./Become";
import Code from "./Code";
import List from "./List";

const RefferalPage = () => {
  return (
    <main
      id="referral-content"
      className="grid gap-6 lg:grid-cols-2"
    >
      <Code />
      <Become />
      <List />
    </main>
  );
};
export default RefferalPage;

// {t("userProfile.referralProgram.comingSoon")}
