import Carousel from "./components/Carousel";
import BecomeUnique from "./components/BecomeUnique";
import Apply from "./components/Apply";
import Details from "./components/Details";
import { useParams } from "react-router-dom";
import Banner from "./components/Banner";

const PartnersPage = () => {
  const { partnerId } = useParams();
  return (
    <>
      <Banner />
      <Carousel />
      {partnerId && <Details />}
      <BecomeUnique />
      <Apply />
    </>
  );
};

export default PartnersPage;
