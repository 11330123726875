import { TSvgComponent } from "../../../types/utils/component";

const SvgLocationPin: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 15 17"
      fill="#FF6655"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0900375 7.97846C-0.354136 3.85925 2.6476 0.655426 6.68167 0.220433C10.7157 -0.214561 14.3315 2.2757 14.7757 6.39491C15.1144 9.53603 13.958 11.9937 12.5253 13.6764C11.8108 14.5156 11.0242 15.1668 10.3104 15.6219C9.61425 16.0658 8.93275 16.3577 8.42762 16.4121C7.92249 16.4666 7.19443 16.3267 6.4197 16.0415C5.62528 15.749 4.71792 15.2804 3.84098 14.6128C2.08241 13.2742 0.428744 11.1196 0.0900375 7.97846ZM7.71686 9.82264C8.96466 9.68809 9.86712 8.56748 9.73257 7.31969C9.59802 6.0719 8.47741 5.16944 7.22962 5.30399C5.98183 5.43854 5.07937 6.55914 5.21392 7.80693C5.34847 9.05473 6.46907 9.95719 7.71686 9.82264Z"
      />
    </svg>
  );
};

export default SvgLocationPin;
