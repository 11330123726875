import axios from "axios";
import { IEventCategory, IEventsData, IHomeEvents } from "./types";
import { TEventsFilters } from "../../store/events/types";

export const getEventsAxios = async (): Promise<IHomeEvents | null> => {
  try {
    const response = await axios.get("events");
    return {
      large_events: response.data.data.large_events?.all,
      large_events_categories: response.data.data.large_events?.categories,
      promotional_events: response.data.data.promotional_events,
      promotional_events_categories:
        response.data.data.promotional_events.categories,
    };
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
export const fetchGoogleMapEventsAxios = async (
  latitude: number,
  longitude: number,
  filterParams: any,
): Promise<IEventsData[] | null> => {
  try {
    const response = await axios.get("events/closest", {
      params: {
        latitude,
        longitude,
        max_price: filterParams.maxPrice,
        category_id: filterParams.selectedCategory,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
        date: filterParams.selectedDate,
        stars: filterParams.selectedStar,
      },
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
};
export const likeEventAxios = async (
  eventId: number,
): Promise<IEventsData[] | null> => {
  try {
    const response = await axios.post(`events/${eventId}/like`, {});
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getSearchEventsAPI = async (search: string) => {
  try {
    const response = await axios.get(`global-search?search=${search}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getCategoriesAxios = async () => {
  try {
    const response = await axios.get<{ data: IEventCategory[] }>(`categories`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getInterestsAxios = async () => {
  try {
    const response = await axios.get(`interests`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const searchEventsApi = async (search: TEventsFilters, url: string) => {
  try {
    const response = await axios.get<{ data: IEventsData[] }>(url, {
      params: {
        ...search,
        type_id:
          search.type_id.length > 0 ? JSON.stringify(search.type_id) : "",
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
