import { useAppSelector } from "../../../store/redux";
import { useTranslation } from "react-i18next";

type AdditionalDetailsProps = {
  highlights: string[];
  includes: string[];
  important_information: string[];
  selectedLanguage: string;
};

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
  highlights,
  includes,
  important_information,
  selectedLanguage,
}) => {
  const { eventDetails } = useAppSelector((state) => state.eventDetailsReducer);
  const content = eventDetails.translations?.[selectedLanguage];
  const { t } = useTranslation();

  return (
    <div className="gap-10 py-4 md:grid md:grid-cols-3">
      <div className="mb-5 max-h-[300px] overflow-y-auto rounded-2xl border-2 p-4 md:mb-0">
        <div className="mb-4 flex items-center">
          <h3 className="ml-2 text-base font-bold text-[#131C23]">
            {t("highlights")}
          </h3>
        </div>
        <ul className="list-disc px-5 text-xs font-medium text-[#131C23]/80">
          {highlights?.map((highlight, index) => (
            <li key={index}>
              {content && content.highlights
                ? content?.highlights[index]
                : highlight}
            </li>
          ))}
        </ul>
      </div>
      <div className="mb-5 max-h-[300px] overflow-y-auto rounded-2xl border-2 p-4 md:mb-0">
        <div className="mb-4 flex items-center">
          <h3 className="ml-2 text-base font-bold text-[#131C23]">
            {t("includes")}
          </h3>
        </div>
        <ul className="list-disc px-5 text-xs font-medium text-[#131C23]/80">
          {includes?.map((include, index) => (
            <li key={index}>
              {content && content.includes ? content?.includes[index] : include}
            </li>
          ))}
        </ul>
      </div>
      <div className="mb-5 max-h-[300px] overflow-y-auto rounded-2xl border-2 p-4 md:mb-0">
        <div className="mb-4 flex items-center">
          <h3 className="ml-2 text-base font-bold text-[#131C23]">
            {t("information")}
          </h3>
        </div>
        <ul className="list-disc px-5 text-xs font-medium text-[#131C23]/80">
          {important_information?.map((info, index) => (
            <li key={index}>
              {content && content.important_information
                ? content?.important_information[index]
                : info}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdditionalDetails;
