import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { modalDialogAtom } from "../../store/business";
type props = {
  dialogTitle?: string;
  dialogContent?: string;
  children?: React.ReactNode;
  handleSubmitModalDialog: (data: Object) => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    width: "100%",
  },
  "& .MuiPaper-root": {
    width: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function AlertDialog(props: props) {
  const [open, setOpen] = useAtom(modalDialogAtom);
  const handleClose = (type: string) => {
    if (type === "submit") props.handleSubmitModalDialog({ title: "awd" });
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="flex items-center justify-between">
          <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
          <IconButton
            onClick={() => handleClose("cancel")}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers>{props.children}</DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("cancel")}>Cancel</Button>
          <Button onClick={() => handleClose("submit")} autoFocus>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
