import { Button, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CustomInput from "../../components/common/inputs/Input";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "../../store/redux";
import { setCreateEventData } from "../../store/BusinessDashboard/ActionBusinessDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BusinessDashboardSlice } from "../../store/BusinessDashboard/BusinessDashboardSlice";
type props = {
  icon?: React.ReactNode;
  title?: string;
  name: string;
};
type IEventData = {
  title?: string;
};
const EventCardInformation = (props: props) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const eventData = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );
  const isLoading = useSelector(
    (state: RootState) => state.globalStateReducer.isLoading,
  );
  const [informationList, setInformationList] = useState<IEventData[]>([]);
  const [showInput, setShowInput] = useState(false);

  const fieldsError = useSelector(
    (state: RootState) => state.businessDashboardReducer?.errors,
  );

  const handleRemoveErrorKey = (name: string[]) => {
    let errorfields = { ...fieldsError };
    name?.map((item) => {
      delete errorfields[item];
    });
    dispatch(BusinessDashboardSlice.actions.setErrors(errorfields));
  };
  const handleAddToList = (name: string) => {
    let array = informationList.filter((item) => item.title !== "");
    let data = array.map((item) => item.title);
    setInformationList(array);
    dispatch(setCreateEventData(name, data));
    handleRemoveErrorKey([props.name]);
    setShowInput(false);
  };
  const handleAddInput = () => {
    setInformationList((prevValue) => [...prevValue, { title: "" }]);
    setShowInput(true);
  };
  const handleChangeInput = (event: any, index: number) => {
    let data = [...informationList];
    data[index]["title"] = event.target.value;
    setInformationList(data);
  };
  const handleDeleteItem = (index: number) => {
    let array = [...informationList];
    array.splice(index, 1);
    if (array.length === 0) setShowInput(false);
    let data = array.map((item) => item.title);
    setInformationList(array);
    dispatch(setCreateEventData(props.name, data));
    handleRemoveErrorKey([props.name]);
  };
  useEffect(() => {
    const propertyValue = eventData[props.name];
    if (params.eventId) {
      if (Array.isArray(propertyValue)) {
        setInformationList(propertyValue.map((item: any) => ({ title: item })));
      }
    } else {
      if (eventData[props.name]) {
        if (Array.isArray(propertyValue)) {
          setInformationList(
            propertyValue.map((item: any) => ({ title: item })),
          );
        }
      } else setInformationList([]);
    }
  }, [isLoading, params]);
  return (
    <div className="min-h-[200px] rounded-lg border border-[#E8E9E9] p-4 ">
      <div className="mb-2 flex items-center gap-2 text-sm font-medium">
        {props.icon}
        {props.title}
      </div>
      {!showInput ? (
        <div>
          {informationList.length > 0 ? (
            <ul>
              {informationList.map((item, index) => (
                <li key={index} className="ml-2 font-normal text-[#888D90]">
                  <CircleIcon sx={{ width: "7px", height: "7px" }} />{" "}
                  {item.title}{" "}
                  <CloseIcon
                    onClick={() => handleDeleteItem(index)}
                    className="cursor-pointer"
                    sx={{ fill: "red", width: "18px", height: "18px" }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p className="ml-2 mt-3 text-sm font-medium">
              {t(
                "businessProfile.eventCreation.aboutEvents.eventCardInformation.no",
              )}{" "}
              {props.title}{" "}
              {t(
                "businessProfile.eventCreation.aboutEvents.eventCardInformation.available",
              )}
            </p>
          )}
        </div>
      ) : (
        <>
          {informationList.map((item, index) => (
            <div className="flex items-center gap-3">
              <CustomInput
                inputBgColor=" bg-[#131C23]/5"
                name={props.name}
                value={item.title}
                type="text"
                placeholder={props.title}
                onChange={(e) => handleChangeInput(e, index)}
              />
              <CloseIcon
                onClick={() => handleDeleteItem(index)}
                className="cursor-pointer"
                sx={{ fill: "red", width: "18px", height: "18px" }}
              />
            </div>
          ))}
          <div className="flex items-center justify-end gap-3">
            <span
              onClick={() => handleAddToList(props.name)}
              className="cursor-pointer rounded-full border border-emerald-600 px-3 py-1 text-sm text-emerald-600"
            >
              {t(
                "businessProfile.eventCreation.aboutEvents.eventCardInformation.save",
              )}
            </span>
          </div>
        </>
      )}
      <div className="flex w-full justify-end mt-2">
        <span
          onClick={handleAddInput}
          className="cursor-pointer text-sm border border-[#3F87FF] px-3 py-1 rounded-full text-[#3F87FF]"
        >
          +{" "}
          {t(
            "businessProfile.eventCreation.aboutEvents.eventCardInformation.add",
          )} {props.title}
        </span>
      </div>
    </div>
  );
};

export default EventCardInformation;
