import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { TEvent } from "../../../types/event";
import { Link } from "react-router-dom";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";
import HeartFillSvg from "../../../component/SvgComponents/HeartFillSvg";
import ClockSvg from "../../../component/SvgComponents/ClockSvg";
import StarSvg from "../../../component/SvgComponents/StarSvg";
import EventLocation from "../../../components/common/EventLocation";
import SvgLoadingInfinity from "../../../assets/svgs/loading/SvgLoadingInfinity";
import { useHomePromotionEvents, useLikeEvent } from "../_hooks";
import {
  convertPrices,
  getNextURL,
  turnEventIdToSlug,
} from "../../../utils/functions";
import { TLang } from "../../../types/language";
import { TCurrencyAtom } from "../../../types/currency";

const PromotionEvents = () => {
  const { t, data, selectedLanguage, selectedCurrency, isLoading } =
    useHomePromotionEvents();
  return (
    <section id="home-promotion-events" className="section-margin container">
      <div className="mb-6 flex flex-wrap items-center justify-between gap-2">
        <h2 className="section-heading heading-bar font-poppins-bold text-primary-navy after:bg-primary-navy">
          {t("promotionEvents")}
        </h2>
        <ArrowedLink
          className="ml-auto border border-primary-orange"
          title="All events"
          to={`allEvents/large`}
        >
          See All
        </ArrowedLink>
      </div>
      <Swiper
        spaceBetween={50}
        breakpoints={{
          0: { slidesPerView: 1 },
          1028: { slidesPerView: 2 },
          1400: { slidesPerView: 3 },
        }}
      >
        {!isLoading ? (
          data?.success ? (
            data.data.map((event) => {
              return (
                <SwiperSlide key={`home_promotional_event_${event.id}`}>
                  <PromotionEventCard
                    event={event}
                    selectedCurrency={selectedCurrency}
                    selectedLanguage={selectedLanguage}
                  />
                </SwiperSlide>
              );
            })
          ) : (
            <p className="text-red-600">Something went wrong...</p>
          )
        ) : (
          <p>Loading...</p>
        )}
      </Swiper>
    </section>
  );
};
export default PromotionEvents;

type TProps = {
  event: TEvent;
  selectedLanguage: TLang;
  selectedCurrency: TCurrencyAtom;
};

const PromotionEventCard = ({
  event: {
    id,
    images,
    translations,
    location,
    display_price,
    duration,
    duration_type,
    types,
    reviews_count,
    reviews,
    reviews_avg_stars,
    liked_by_user,
  },
  selectedLanguage,
  selectedCurrency,
}: TProps) => {
  const { isPending, mutate, data } = useLikeEvent();
  return (
    <Link
      target="_blank"
      rel="noreferrer noopener nofollow"
      to={getNextURL({
        nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
          translations.en.title,
          id,
        )}`,
      })}
      className="hover-link inline-block h-full rounded-3xl hover:shadow-md"
      title={translations[selectedLanguage].title}
    >
      <article className="grid h-full gap-2 rounded-3xl bg-white md:grid-cols-[1fr_2fr]">
        <div className="relative">
          <img
            loading="lazy"
            className="h-full w-full content-end rounded-3xl border object-cover object-center"
            src={images[0].image_path.toString()}
            alt={translations[selectedLanguage].title}
            title={translations[selectedLanguage].title}
          />
          <p className="absolute left-3 top-3 rounded-full bg-gray-500/50 px-3 py-1.5 text-white backdrop-blur-lg">
            {types[0].translations[selectedLanguage].title}
          </p>
        </div>
        <div className="container flex flex-col justify-between py-6">
          <div>
            <div className="mb-4 flex flex-wrap-reverse items-center justify-between gap-2">
              <EventLocation location={location} />
              <button
                disabled={isPending}
                className="[&:not(:disabled)]:hover-link single-grid"
                title={
                  data?.message === "Liked" || liked_by_user ? "Unlike" : "Like"
                }
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  mutate(id);
                }}
              >
                <HeartFillSvg
                  className={`single-grid-item h-7 w-7 transition-opacity duration-500 ${
                    isPending ? "opacity-0" : "opacity-100"
                  }`}
                  filled={data?.message === "Liked" || liked_by_user}
                />
                <SvgLoadingInfinity
                  className={`single-grid-item h-7 w-7 scale-110 transition-opacity duration-500 ${
                    isPending ? "opacity-100" : "opacity-0"
                  }`}
                />
              </button>
            </div>
            <h3 className="mb-4 font-bold capitalize">
              {translations?.[selectedLanguage].title}
            </h3>
            {reviews_count > 0 && (
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-4">
                  <div className="flex">
                    <img
                      className="h-8 w-8 rounded-full border border-white"
                      src={reviews[0].user.profile_pic}
                      alt={reviews[0].user.name}
                      title={reviews[0].user.name}
                    />
                    {reviews_count > 1 && (
                      <img
                        className="-ml-4 h-8 w-8 rounded-full border border-white"
                        src={reviews[1].user.profile_pic}
                        alt={reviews[1].user.name}
                        title={reviews[1].user.name}
                      />
                    )}
                  </div>
                  <p className="font-poppins-light">
                    <span className="font-poppins-bold">
                      {reviews_count > 1
                        ? reviews_count - 1 + "+"
                        : reviews_count}
                    </span>
                    &nbsp;Review{reviews_count > 1 && "s"}
                  </p>
                </div>
                <div className="flex items-center gap-1.5">
                  <StarSvg className="h-4 w-4" />
                  <span className="font-bold">
                    {parseFloat(reviews_avg_stars ?? "0")}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-wrap items-center justify-between gap-2">
            <p>
              Cost:&nbsp;
              <span className="font-bold">
                {convertPrices(display_price, selectedCurrency)}
              </span>
            </p>
            <div className="flex items-center gap-2 rounded-full bg-primary-gray/50 px-3 py-1">
              <ClockSvg className="h-5 w-5" />
              <p>{duration + duration_type}</p>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};
