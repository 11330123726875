import CrownSvg from "../../../component/SvgComponents/CrownSvg";
import ArrowedButton from "../../../components/common/buttons/ArrowedButton";
import HeartsCard from "../../../components/common/cards/HeartsCard";
import { useHomeBecomePartner } from "../_hooks";

export const BecomePartner = () => {
  const { t, handleButtonClick } = useHomeBecomePartner();
  return (
    <section id="home-become-partner" className="section-margin container">
      <div className="container relative grid gap-10 rounded-3xl bg-gradient-to-r from-secondary-orange to-[#FF8A3F] to-60% py-10 lg:grid-cols-2">
        <div className="content-center text-white lg:px-10">
          <div className="rounded-text mb-8 flex w-fit items-center gap-2">
            <CrownSvg className="h-4 w-4" />
            <h5>{t("becomeOurPartner.tag")}</h5>
          </div>
          <h2 className="section-heading mb-4 text-white">
            {t("becomeOurPartner.title")}
          </h2>
          <p className="mb-6">{t("becomeOurPartner.description")}</p>
          <ul className="mb-8 list-disc pl-5">
            <li className="marker:text-[#00ffa2]">
              {t("becomeOurPartner.statistical")}
            </li>
            <li className="marker:text-[#00ffa2]">
              {t("becomeOurPartner.summary")}
            </li>
          </ul>
          <ArrowedButton onClick={handleButtonClick} title="Register ">
            {t("becomeOurPartner.button_text")}
          </ArrowedButton>
        </div>
        <div className="relative grid max-lg:row-start-1">
          <img
            loading="lazy"
            src="/assets/images/common/revenue-graph.webp"
            alt="Revenue cards"
            title="Revenue cards"
            className="mt-6 w-full max-w-xl rotate-6 place-self-center"
          />
          <HeartsCard className="absolute -top-1/4 left-0 -rotate-[30deg] md:top-[5%]" />
          <HeartsCard className="absolute bottom-[5%] right-[5%] rotate-[30deg]" />
        </div>
      </div>
    </section>
  );
};
export default BecomePartner;
