import { TSvgComponent } from "../../../types/utils/component";

const SvgGear: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 18 16"
      fill="#898d91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.75 5.68385C14.2417 5.68385 13.625 4.61718 14.375 3.30885C14.8083 2.55052 14.55 1.58385 13.7917 1.15052L12.35 0.325517C11.6917 -0.06615 10.8417 0.167183 10.45 0.825517L10.3583 0.98385C9.60832 2.29218 8.37499 2.29218 7.61666 0.98385L7.52499 0.825517C7.14999 0.167183 6.29999 -0.06615 5.64166 0.325517L4.19999 1.15052C3.44166 1.58385 3.18332 2.55885 3.61666 3.31718C4.37499 4.61718 3.75832 5.68385 2.24999 5.68385C1.38332 5.68385 0.666656 6.39218 0.666656 7.26718V8.73385C0.666656 9.60052 1.37499 10.3172 2.24999 10.3172C3.75832 10.3172 4.37499 11.3838 3.61666 12.6922C3.18332 13.4505 3.44166 14.4172 4.19999 14.8505L5.64166 15.6755C6.29999 16.0672 7.14999 15.8339 7.54166 15.1755L7.63332 15.0172C8.38332 13.7089 9.61666 13.7089 10.375 15.0172L10.4667 15.1755C10.8583 15.8339 11.7083 16.0672 12.3667 15.6755L13.8083 14.8505C14.5667 14.4172 14.825 13.4422 14.3917 12.6922C13.6333 11.3838 14.25 10.3172 15.7583 10.3172C16.625 10.3172 17.3417 9.60885 17.3417 8.73385V7.26718C17.3333 6.40052 16.625 5.68385 15.75 5.68385ZM8.99999 10.7088C7.50832 10.7088 6.29166 9.49218 6.29166 8.00052C6.29166 6.50885 7.50832 5.29218 8.99999 5.29218C10.4917 5.29218 11.7083 6.50885 11.7083 8.00052C11.7083 9.49218 10.4917 10.7088 8.99999 10.7088Z" />
    </svg>
  );
};

export default SvgGear;
