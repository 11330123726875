import CustomInput from "../../../../../../../components/common/inputs/NewInput";
import CustomToggle from "../../../../../../../components/common/inputs/Toggle";
import { useEventCreationChoices } from "../../../_hooks";

const Choices = () => {
  const { t, handleCheck, choice, draft, edit } = useEventCreationChoices();
  return (
    <div className="mb-4 grid gap-10 md:grid-cols-2">
      <div className="flex items-center justify-between gap-4">
        <CustomInput
          type="number"
          name="quantity"
          containerClass="flex-grow"
          disabled={choice !== "quantity"}
          defaultValue={edit?.quantity ?? draft?.quantity}
          placeholder={t("businessProfile.eventCreation.basicInfo.quantity")}
        />
        <CustomToggle
          value="quantity"
          labelClass="h-7 w-14"
          id="tickets-available-activity"
          onChange={handleCheck}
          checked={choice === "quantity"}
        />
      </div>
      <div className="flex items-center justify-between gap-4">
        <CustomInput
          type="number"
          name="quantity_per_duration"
          containerClass="flex-grow"
          disabled={choice !== "quantity_per_duration"}
          defaultValue={
            edit?.quantity_per_duration ?? draft?.quantity_per_duration
          }
          placeholder={t(
            "businessProfile.eventCreation.basicInfo.quantityPerDuration",
          )}
        />
        <CustomToggle
          value="quantity_per_duration"
          labelClass="h-7 w-14"
          id="tickets-available-duration"
          onChange={handleCheck}
          checked={choice === "quantity_per_duration"}
        />
      </div>
    </div>
  );
};

export default Choices;
