type TProps = { image: string };

const CustomMarker = ({ image }: TProps) => {
  return (
    <div className="relative grid place-items-center after:absolute after:bottom-0 after:-z-10 after:h-6 after:w-6 after:rotate-45 after:bg-primary-orange">
      <img
        src={image}
        className="h-14 w-14 rounded-full border-2 border-primary-orange bg-white object-cover p-px"
        alt=""
      />
    </div>
  );
};

export default CustomMarker;
