import { useSelector } from "react-redux";
import {
  clearCreateEventData,
  createEventData,
  getDraftEvent,
  getEventById,
  getEventsData,
  // saveDraft,
  setCreateEventData,
  updateEvent,
} from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useAppDispatch } from "../../../../store/redux";
import AboutEvents from "./About";
import BasicInfo from "./BasicInfo";
import FAQS from "./FAQs";
import ImageSection from "./ImageSection";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useEffect } from "react";
import { RootState } from "../../../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import { scrollToTop } from "../../../../utils/functions";
const EventCreation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const finalPayload = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );
  const fieldsError = useSelector(
    (state: RootState) => state.businessDashboardReducer?.errors,
  );
  const eventData = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );
  const exclusivePrice = useSelector(
    (state: RootState) =>
      state.businessDashboardReducer?.eventData?.exclusive_price,
  );
  const promoPrice = useSelector(
    (state: RootState) =>
      state.businessDashboardReducer?.eventData?.promo_price,
  );
  useEffect(() => {
    dispatch(getEventsData());
    scrollToTop();
    if (params.eventId) dispatch(getEventById(Number(params.eventId)));
    else {
      dispatch(clearCreateEventData());
      localStorage.removeItem("eventData");
      dispatch(getDraftEvent());
      // const interval = setInterval(() => {
      //   const finalData = JSON.parse(
      //     localStorage.getItem("eventData") as string,
      //   );
      //   if (finalData) dispatch(saveDraft(finalData));
      // }, 90000);
      // return () => clearInterval(interval);
    }
    //eslint-disable-next-line
  }, [params]);

  const handleCheckBox = (name: string) => {
    if (name === "request_exclusive")
      dispatch(setCreateEventData(name, eventData[name] === 1 ? 0 : 1));
    if (name === "request_promote")
      dispatch(setCreateEventData(name, eventData[name] === 1 ? 0 : 1));
  };
  const handleCreate = () => {
    const emptyImages = finalPayload.images.filter(
      (image) => image.image !== "",
    );
    const payloadWithNonEmptyImages = {
      ...finalPayload,
      images: emptyImages,
    };

    if (params.eventId) {
      dispatch(updateEvent(Number(params.eventId), payloadWithNonEmptyImages))
        .then(() => {
          navigate("/overview");
        })
        .catch(() => {});
    } else {
      dispatch(createEventData(payloadWithNonEmptyImages))
        .then(() => {
          navigate("/overview");
        })
        .catch(() => {});
    }
  };
  return (
    <>
      <div className="mb-4">
        <BasicInfo />
        <ImageSection />
        <AboutEvents />
        <FAQS />
      </div>
      <div className="mb-11 flex w-full flex-col  items-center justify-center gap-x-10 gap-y-3 lg:flex-row">
        {!params.eventId && (
          <>
            <div className="flex flex-col gap-2 font-bold">
              <p className="ml-2 text-sm text-primary-orange"></p>
              <div className="checkbox-container flex items-center justify-between gap-3 rounded-full bg-[#EAEAEB] px-5 py-3 max-lg:w-full">
                <Tooltip title={`${exclusivePrice} GBP for 30 days`}>
                  <InfoIcon className="!h-[20px] !w-[20px] cursor-pointer" />
                </Tooltip>

                <label
                  htmlFor={`exclusive-member`}
                  className="checkboxdivstom-checkbox ml-2 w-max text-xs font-medium text-[#131C23]"
                >
                  {t(
                    "businessProfile.eventCreation.basicInfo.requestExclusive",
                  )}
                </label>
                <input
                  id={`exclusive-member`}
                  type="checkbox"
                  value={1}
                  checked={eventData["request_exclusive"] === 1}
                  name="bordered-checkbox"
                  onChange={() => handleCheckBox("request_exclusive")}
                  className=" checbox-input rounded border-gray-300 bg-gray-300 text-white accent-[#FF913F] focus:ring-transparent"
                />
              </div>
            </div>
          </>
        )}
        <button
          disabled={Object.keys(fieldsError).length !== 0}
          onClick={handleCreate}
          className="mt-auto rounded-full border border-[#FF913F] px-6 py-2 disabled:cursor-not-allowed disabled:bg-gray-200 max-lg:order-3"
        >
          {t("businessProfile.eventCreation.send")}
          <ArrowCircleRightIcon className="ml-3" />
        </button>
        {!params.eventId && (
          <>
            <div className="flex flex-col gap-2">
              <p className="ml-2 text-sm font-bold text-primary-orange"></p>
              <div className="checkbox-container flex  items-center justify-between gap-3 rounded-full bg-[#EAEAEB] px-5 py-3 max-lg:w-full">
                <Tooltip title={`${promoPrice} GBP for 10 days`}>
                  <InfoIcon className="!h-[20px] !w-[20px] cursor-pointer" />
                </Tooltip>
                <label
                  htmlFor={`request-promote`}
                  className="checkboxdivstom-checkbox ml-2 w-max text-xs font-medium text-[#131C23]"
                >
                  {t("businessProfile.eventCreation.basicInfo.requestPromote")}
                </label>
                <input
                  id={`request-promote`}
                  type="checkbox"
                  value={1}
                  checked={eventData["request_promote"] === 1}
                  name="bordered-checkbox"
                  onChange={() => handleCheckBox("request_promote")}
                  className=" checbox-input rounded border-gray-300 bg-gray-300 text-white accent-[#FF913F] focus:ring-transparent"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default EventCreation;
