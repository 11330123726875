import { paymentSystemsData } from "../../../constants/footer/paymentSystems";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const PaymentSystems = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const widget = document.getElementById("tp-widget-wrapper");
    if (widget) {
      widget.style.textAlign = "start";
    }
  }, []);

  return (
    <section id="footer-payment-systems">
      <h3 className="mb-2 text-lg text-white">{t("footer.payment")}</h3>
      <ul className="mb-6 flex flex-wrap gap-2">
        {paymentSystemsData.map((paymentSystem) => {
          return (
            <li
              key={`footer_payment_${paymentSystem.id}`}
              className="cursor-pointer list-none uppercase transition-transform duration-500 hover:-translate-y-1 hover:translate-x-1"
            >
              <Link
                to={paymentSystem.path}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="h-1/2 w-1/2"
              >
                <img
                  loading="lazy"
                  src={paymentSystem.image}
                  key={`social_${paymentSystem.id}`}
                  alt={`Payment ${paymentSystem.id}`}
                />
              </Link>
            </li>
          );
        })}
      </ul>
      <div
        className="trustpilot-widget [&_#tp-widget-wrapper]:!m-0"
        data-locale="en-GB"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="65abee1451242ae96f523354"
        data-style-width="100%"
      >
        <Link
          to="https://uk.trustpilot.com/review/explorenearme.co.uk"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Trustpilot
        </Link>
      </div>
    </section>
  );
};
export default PaymentSystems;
