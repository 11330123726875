import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const OurMissionSummary = () => {
  const { t } = useTranslation();

  return (
    <main className="max-md:mt-20 md:my-10 md:pt-10">
      <div className="t mt-5 flex items-center rounded-b-xl rounded-t-3xl md:p-5">
        <div className="container place-items-center">
          <div className="max-md:text-center md:grid md:grid-cols-2">
            <img
              src="/assets/images/mission-1.png"
              className="mb-5 max-md:block md:hidden"
              alt=""
            />
            <div className="text-black max-md:pb-5 md:mr-10 md:py-5">
              <h1 className="flex items-center gap-2 text-xl font-bold max-md:justify-center md:text-3xl">
                {t("missionTitle")}
                <div className="mt-2 w-[60px] rounded-2xl border-b-[3px] border-black"></div>
              </h1>
              <p className="font-bold text-primary-orange"> {t("story")}</p>
              <p className="text-lg text-black opacity-80 max-md:text-center">
                <h3 className="pt-8">{t("missionText")}</h3>
              </p>
            </div>
            <img
              src="/assets/images/mission-1.png"
              className="max-md:hidden md:block"
              alt=""
            />
          </div>
          <div className="gap-5 md:grid md:grid-cols-2 md:pt-10">
            <img
              alt=""
              src="/assets/images/mission-2.png"
              className="max-md:hidden md:block"
            />
            <img
              alt=""
              src="/assets/images/mission-2-mobile.png"
              className="max-md:block md:hidden"
            />
            <h3 className="mx-md:text-center pt-8">{t("missionSecondText")}</h3>
          </div>
          <div className="relative my-14 place-items-center rounded-2xl bg-[#141D24] p-10 text-white md:grid md:grid-cols-[2fr_1fr_1fr]">
            <div>
              <h1 className="flex items-center gap-2 text-xl font-bold max-md:justify-center max-md:text-center md:text-3xl">
                {t("missionSecondTitle")}
              </h1>
              <h3 className="pt-8 max-md:text-center">
                {t("missionThirdText")}
              </h3>
            </div>
            <img
              alt=""
              src="/assets/images/mission-3-1.png"
              className="max-h-[300px] max-w-[300px] translate-y-10 -rotate-12 transform rounded-2xl border-2 max-md:hidden md:block"
            />
            <img
              alt=""
              src="/assets/images/mission-3.png"
              className="max-h-[300px] max-w-[300px] -translate-y-10 rotate-12 rounded-2xl border-2 max-md:hidden md:block"
            />
            <img
              alt=""
              src="/assets/images/mission-3.png"
              className="max-h-[250px] max-w-[250px] translate-y-10 rotate-12 rounded-2xl border-2 max-md:block md:hidden"
            />
            <img
              alt=""
              src="/assets/images/mission-3-1.png"
              className="max-h-[250px] max-w-[250px] translate-y-20 -rotate-12 transform rounded-2xl border-2 max-md:block md:hidden"
            />
          </div>
          <div className="place-items-center gap-5 md:grid md:grid-cols-2">
            <img
              alt=""
              src="/assets/images/mission-4.png"
              className="max-md:hidden md:block"
            />
            <div>
              <h3 className="pt-8 max-md:text-center">
                {t("missionFourthText")}
                <span className="font-bold">{t("missionSlogan")}</span>
              </h3>
              <img
                alt=""
                src="/assets/images/mission-4.png"
                className="max-md:block md:hidden"
              />
              <div className="mt-10 max-md:flex max-md:justify-center">
                <Link to="/crowdfunding">
                  <button className="flex items-center justify-center gap-1 rounded-full bg-[#93DBDF] py-3 font-bold text-black max-md:px-10 md:px-5">
                    <span className="font-bold">{t("missionButton")}</span>{" "}
                    {t("missionButtonText")}
                    <img alt="" src="/assets/svgs/home/arrow-black.svg" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(/assets/images/mission-bg.png)` }}
            className="mt-20 flex flex-col justify-center bg-no-repeat bg-origin-border p-20 text-center text-white max-md:hidden md:bg-contain xl:aspect-[16/3] xl:bg-center"
          >
            <h3 className="flex items-center gap-2 text-xl font-light max-md:justify-center md:px-24">
              {t("missionStory")}
            </h3>
            <p className="font-bold md:text-3xl">{t("missionStorySlogan")}</p>
          </div>
          <div className="my-20 mt-20 items-center rounded-[30px] bg-primary-orange bg-gradient-to-r from-secondary-orange to-primary-orange text-center text-white max-md:flex max-md:flex-col max-md:py-8 max-md:pl-14 max-md:pr-[3.9rem] md:hidden md:p-14">
            <img alt="" src="/assets/svgs/slogan.svg" className="h-20 w-20" />
            <p className="flex items-center gap-2 pt-2 text-lg font-light max-md:justify-center">
              {t("missionSecondStory")}
            </p>
            <p className="text-2xl font-bold">{t("missionSecondSlogan")}</p>
          </div>
        </div>
      </div>
    </main>
  );
};
export default OurMissionSummary;
