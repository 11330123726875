import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  addEventToCategory,
  getWishlistsCategories,
} from "../../../store/Wishlist/ActionWishlist";
import { IEventsData } from "../../../networking/events/types";
import { CategoryType } from "../../../store/Wishlist/types";
import { useAppDispatch } from "../../../store/redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const AddEventCategory = ({
  allEvents,
  selectedCategoryId,
  categories,
}: {
  allEvents: IEventsData[];
  selectedCategoryId: number | null;
  categories: CategoryType[];
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEventClick = (category: number | null, event_id: number) => {
    if (category !== null) {
      dispatch(addEventToCategory(category, event_id));
      handleClose();
      dispatch(getWishlistsCategories());
    } else {
      console.error("selectedCategoryId is null.");
    }
  };
  const eventsNotInCategory = allEvents.filter((event) => {
    const category = categories.find((c) => c.id === selectedCategoryId);
    if (category) {
      return !category.user_liked_events.some(
        (eventType) => eventType.event.id === event.id,
      );
    }
    return true;
  });

  return (
    <>
      <div className="flex justify-end">
        <button
          className="rounded-full border-2 border-[#FF913F] px-3 py-2 font-medium"
          onClick={handleOpen}
        >
          Add Event
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={"text-center"}
          >
            <span className="text-2xl font-bold text-[#131C23]">
              Add Event To Category{" "}
            </span>
            <p className="text-sm font-bold text-[#131C23]/40">
              Your Liked Events
            </p>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {eventsNotInCategory.length === 0 ? (
              <p className="text-center text-xl font-bold text-primary-orange">
                You don't have any events to add to this category.
              </p>
            ) : (
              eventsNotInCategory.map((event: IEventsData) => (
                <div
                  className="group my-3 flex cursor-pointer rounded-md bg-primary-orange/10 px-2 py-3 transition duration-200 hover:bg-[#FF913F] hover:text-white"
                  key={event.id}
                  onClick={() =>
                    handleEventClick(selectedCategoryId, event.pivot.id)
                  }
                >
                  <div className="duration-200 before:mr-2 before:text-[#FF913F] before:content-['\2022'] group-hover:before:text-white"></div>
                  <div className="text-sm text-[#131C2388]">
                    <h4 className="text-[#FF913F] duration-200 group-hover:text-white">
                      {event.title}
                    </h4>
                    <p>Location: {event.location}</p>
                    <p>Price: {event.display_price}</p>
                  </div>
                </div>
              ))
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default AddEventCategory;
