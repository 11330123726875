import { TSvgComponent } from "../../../types/utils/component";

const SvgSuitcase: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.6963 4.52497C15.0233 3.78081 13.8992 3.40872 12.2683 3.40872H12.0783V3.37706C12.0783 2.04706 12.0783 0.400391 9.10168 0.400391H7.89834C4.92168 0.400391 4.92168 2.05497 4.92168 3.37706V3.41664H4.73168C3.09293 3.41664 1.97668 3.78872 1.30376 4.53289C0.520009 5.40372 0.543759 6.57539 0.622926 7.37497L0.630843 7.43039L0.692162 8.07424C0.703454 8.19281 0.767313 8.29991 0.866977 8.36513C1.05682 8.48936 1.37455 8.69417 1.56501 8.79997C1.67584 8.87122 1.79459 8.93456 1.91334 8.99789C3.26709 9.74206 4.75543 10.2408 6.26751 10.4862C6.33876 11.2304 6.66334 12.1012 8.39709 12.1012C10.1308 12.1012 10.4713 11.2383 10.5267 10.4704C12.1417 10.2091 13.7013 9.64706 15.1104 8.82372C15.1579 8.79997 15.1896 8.77622 15.2292 8.75247C15.5432 8.57496 15.8691 8.35622 16.1661 8.14198C16.2556 8.07743 16.3128 7.97763 16.325 7.86798L16.3375 7.75497L16.3771 7.38289C16.385 7.33539 16.385 7.29581 16.3929 7.24039C16.4563 6.44081 16.4404 5.34831 15.6963 4.52497ZM9.36293 9.94789C9.36293 10.7871 9.36293 10.9137 8.38918 10.9137C7.41543 10.9137 7.41543 10.7633 7.41543 9.95581V8.95831H9.36293V9.94789ZM6.05376 3.40872V3.37706C6.05376 2.03122 6.05376 1.53247 7.89834 1.53247H9.10168C10.9463 1.53247 10.9463 2.03914 10.9463 3.37706V3.41664H6.05376V3.40872Z" />
      <path d="M15.5248 9.87312C15.8048 9.73991 16.1272 9.9618 16.0992 10.2705L15.815 13.4006C15.6488 14.984 14.9996 16.599 11.5163 16.599H5.48376C2.00042 16.599 1.35126 14.984 1.18501 13.4086L0.915278 10.4415C0.887537 10.1364 1.20259 9.91474 1.48176 10.041C2.38379 10.4492 4.04878 11.1732 5.07729 11.4426C5.20737 11.4767 5.31258 11.5698 5.36953 11.6916C5.85005 12.7192 6.89222 13.2661 8.39709 13.2661C9.88709 13.2661 10.9424 12.6982 11.4245 11.668C11.4816 11.5461 11.5869 11.453 11.717 11.4188C12.8092 11.1314 14.5814 10.322 15.5248 9.87312Z" />
    </svg>
  );
};

export default SvgSuitcase;
