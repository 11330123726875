import { useTranslation } from "react-i18next";
import CustomCheckboxInput from "../../../../../components/common/inputs/Checkbox";
import { useAtom } from "jotai";
import {
  eventCreationDataAtom,
  eventCreationDraftAtom,
  eventUpdateAtom,
} from "../_atom";
import { currencyAtom } from "../../../../../store/currency";
import HeartsCard from "../../../../../components/common/cards/HeartsCard";
import SvgLogoCompass from "../../../../../assets/svgs/logo/SvgLogoCompass";

const Requests = () => {
  const { t } = useTranslation();
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const [data] = useAtom(eventCreationDataAtom);
  const [currency] = useAtom(currencyAtom);
  return (
    <section id="event-creation-requests" className="grid gap-8 lg:grid-cols-2">
      <div className="relative rounded-xl bg-gradient-to-r from-secondary-orange to-primary-orange p-6 text-white shadow-md">
        <img
          src="/assets/images/common/recommended.webp"
          alt=""
          className="absolute right-0 top-0 aspect-square w-1/5 -translate-y-6 max-sm:hidden"
        />
        {data?.exclusive_price && (
          <p className="absolute -right-2 bottom-6 w-fit -rotate-12 rounded-lg bg-white px-6 py-2 text-primary-navy shadow-xl backdrop-blur-lg max-sm:hidden">
            {currency.symbol + " " + data.exclusive_price}
          </p>
        )}
        <div className="max-w-sm">
          <h3 className="section-title mb-4 font-bold">
            <span className="text-primary-navy">Elevate</span> your event
            to&nbsp;
            <span className="text-primary-navy">VIP status!</span> Secure&nbsp;
            <span className="text-primary-navy">prime advertising</span> space
            on Explore near me Exclusive Offers page
          </h3>
          {data?.exclusive_price && (
            <p className="mb-4 w-fit rounded-full bg-white/20 px-4 py-1 backdrop-blur-lg">
              {data.exclusive_price + currency.symbol} for 30 days
            </p>
          )}
          <CustomCheckboxInput
            defaultChecked={
              edit?.requested_exclusive === 1 ??
              draft?.request_exclusive === "1"
            }
            value={1}
            name="request_exclusive"
            label={t(
              "businessProfile.eventCreation.basicInfo.requestExclusive",
            )}
          />
        </div>
      </div>
      <div className="relative rounded-xl bg-primary-navy p-6 text-white shadow-md">
        <HeartsCard className="right-10 top-1/3 rotate-12 max-sm:hidden" />
        <SvgLogoCompass className="absolute bottom-0 right-24 h-14 w-14 -rotate-12 rounded-xl bg-white p-1.5 shadow-lg max-sm:hidden" />
        <div className="max-w-sm">
          <h3 className="section-title mb-4 font-bold">
            <span className="text-primary-orange">Boost</span> your activity's
            visibility!&nbsp;
            <span className="text-primary-orange">Stand out</span>to thousands
            {data?.promo_price ? (
              <>
                for just&nbsp;
                <span className="text-primary-orange">prime advertising</span>
              </>
            ) : (
              "!"
            )}
          </h3>
          {data?.exclusive_price && (
            <p className="mb-4 w-fit rounded-full bg-white/20 px-4 py-1 backdrop-blur-lg">
              {data.exclusive_price + currency.symbol} for 10 days
            </p>
          )}
          <CustomCheckboxInput
            defaultChecked={
              edit?.requested_promo === 1 ?? draft?.request_promote === "1"
            }
            value={1}
            name="request_promote"
            label={t("businessProfile.eventCreation.basicInfo.requestPromote")}
          />
        </div>
      </div>
    </section>
  );
};

export default Requests;
