import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import CheckoutEventCard from "../../../component/Checkout/CheckoutEventCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { guestInformation } from "../../../store/Cart/ActionCart";
import { Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "../Checkout/PaymentSection/StripeForm";
import { useTranslation } from "react-i18next";

const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

const guestRedirection = process.env.REACT_APP_GUEST_REDIRECTION as string;
const stripePromise = loadStripe(key);
const GuestCheckout = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const events = useSelector(
    (state: RootState) => state.cartReducer?.cartItem.data,
  );
  const totalPrice = useSelector(
    (state: RootState) =>
      state.cartReducer?.checkoutState?.userData.total_price,
  );
  const clientKey = useSelector(
    (state: RootState) => state.cartReducer.checkoutState.clientSecretKey,
  );
  const steps = [
    t("checkout.personal"),
    t("checkout.paymentSelection"),
    t("checkout.finalPayment"),
  ];

  const appearance = {
    variables: {
      padding: "0 15px",
      colorPrimary: "#0570de",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      spacingUnit: "3px",
      borderRadius: "15px",
      spacingGridRow: "15px",
    },
    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".p-PaymentDetails": { backgroundColor: "red" },
      ".Tab:hover": {
        color: "var(--colorText)",
      },
      ".Tab--selected": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F",
      },
      ".Tab--selected:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F",
      },
      ".Tab:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
      },

      ".Input:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
      },
      ".Input--invalid": {
        boxShadow:
          "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
      },
      ".CheckboxInput--checked": {
        backgroundColor: "#FF913F",
        borderColor: "#FF913F",
      },
      ".CheckboxInput:focus": {
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
        borderColor: "#FF913F",
      },
      ".DropdownItem:active": {
        backgroundColor: "red",
      },
      ".Input::selection": {
        backgroundColor: "red",
      },
      ".Menu": {
        backgroundColor: "red",
      },
    },
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientKey,
    appearance: appearance,
  };
  const urlParams = useLocation();
  const code = new URLSearchParams(urlParams.search).get("code");
  const [showPayment, setShowPayment] = useState(false);
  const [activeStep] = useState(2);
  const [completed] = useState<{
    [k: number]: boolean;
  }>({});
  // const totalSteps = () => {
  //   return steps.length;
  // };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  // const isLastStep = () => {
  //   return activeStep === totalSteps() - 1;
  // };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  useEffect(() => {
    if (code) {
      dispatch(guestInformation(code))
        .then(() => {
          setShowPayment(true);
        })
        .catch(() => {});
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className="container mt-10 min-h-[calc(100vh-120px)]">
      <div className="flex items-center gap-3">
        <h3 className="text-xl font-bold text-[#131C23]">
          {" "}
          {t("checkout.personalDetails.title")}{" "}
        </h3>
        <div className="rounded-full bg-[#65CC93]/5 px-4 py-1 text-sm text-[#65CC93]">
          {t("checkout.fastSecure")}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <div className="mt-6">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "#FF913F", // circle color (ACTIVE)
                  },
                }}
              >
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          {showPayment && (
            <div className="mt-5 rounded-lg bg-[#F3F3F4] p-5">
              {" "}
              <Elements stripe={stripePromise} options={options}>
                <div className="stripe-form">
                  <StripeForm
                    navigateURL={`${guestRedirection}?code=${code}`}
                  />
                </div>
              </Elements>{" "}
            </div>
          )}
        </div>
        <div className="container mb-10 mt-6 flex w-full flex-col gap-3  rounded-lg bg-white shadow-md">
          <div className="max-h-[275px] overflow-y-scroll lg:max-h-[calc(100vh-290px)]">
            {events?.map((item, index) => (
              <>
                <CheckoutEventCard item={item} />
                {index < events.length - 1 && <Divider />}
              </>
            ))}
          </div>

          <div className="mb-7 flex w-full items-center justify-between rounded-full bg-[#F5F9FF] px-4 py-2">
            <div className="">
              {t("subTotal")}:{" "}
              <span className="font-bold">
                {events.length} {events.length === 1 ? "item" : "items"}
              </span>
            </div>
            <div className="text-2xl text-[#3F8CFF]">{totalPrice}$</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuestCheckout;
