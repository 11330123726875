import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./style.css";
import CongratulationsModal from "../VerificationEmail/Congratulations/index";
import UserInterest from "./UserInterest";
import PrimaryButton from "../../../../PrimaryButton";
import { useVerificationEmail } from "../../../../../pages/home/_hooks";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 6,
  width: 400,
  textAlign: "center",
  justifyItems: "center",
};

interface VerificationEmailProps {
  open: boolean;
  onClose: () => void;
}

const VerificationEmail = ({ open, onClose }: VerificationEmailProps) => {
  const {
    userInfo,
    resendTimer,
    showCongratulationsModal,
    showInterestModal,
    verificationCode,
    inputRefs,
    handleVerificationCodeChange,
    handleOpenVerificationModal,
    handleResendCode,
    closeCongratulationModal,
    closeUserInterest,
  } = useVerificationEmail(open);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            <h3 className="text-xl font-bold"> Verification Mail</h3>
            <div className="flex flex-col items-center justify-center py-4 text-sm">
              <p className="font-medium text-[#131C23]/40">
                {" "}
                Your code was sent to{" "}
              </p>{" "}
              <span className="ml-2 font-bold text-[#131C23]">
                {userInfo?.email}
              </span>
            </div>
          </Typography>
          <div className="verification-code-input flex justify-center space-x-2">
            {verificationCode.map((code, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={code}
                onChange={(e) =>
                  handleVerificationCodeChange(e.target.value, index)
                }
                ref={(input) => (inputRefs.current[index] = input)}
                className="verification-input"
              />
            ))}
          </div>
          <p className="cursor-pointer py-3">
            <span className="py-3 text-[#131C23]/40">Resend code: </span>
            {resendTimer === 0 ? (
              <span
                onClick={handleResendCode}
                className="font-bold text-[#3F87FF]"
              >
                Send
              </span>
            ) : (
              <span className="font-bold text-[#3F87FF]">{resendTimer}s</span>
            )}
          </p>
          <div className="flex justify-center">
            <PrimaryButton onClick={handleOpenVerificationModal}>
              <span className="px-1">Continue</span>
              <img
                loading="lazy"
                src="/assets/svgs/common/white-arrow.svg"
                className="w-4"
              />
            </PrimaryButton>
          </div>
        </Box>
      </Modal>
      <CongratulationsModal
        open={showCongratulationsModal}
        onClose={closeCongratulationModal}
      />
      <UserInterest open={showInterestModal} onClose={closeUserInterest} />
    </div>
  );
};

export default VerificationEmail;
