import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../store/redux";
import {
  setActiveTab,
  setAuthModal,
  setShowSignUpContent,
} from "../../../store/Auth/ActionAuth";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";

const Family = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <section id="footer-family" className="text-white">
      <h3 className="mb-2 text-lg">{t("footer.becomeFamily.title")}</h3>
      <ul className="mb-4 grid cursor-pointer gap-2 text-base text-primary-gray">
        <li>
          <button
            title={t("footer.becomeFamily.becomeExplorer")}
            type="button"
            className="hover-link"
            onClick={() => {
              dispatch(setActiveTab("client"));
              dispatch(setAuthModal(true));
              dispatch(setShowSignUpContent(true));
            }}
          >
            {t("footer.becomeFamily.becomeExplorer")}
          </button>
        </li>
        <li>
          <Link
            title={t("footer.becomeFamily.request")}
            className="hover-link inline-block"
            to="mailto:HQ@explorenearme.co.uk?subject=Request%20to%20become%20one%20of%20our%20Vendors"
          >
            {t("footer.becomeFamily.request")}
          </Link>
        </li>
        <li>
          <Link
            title={t("footer.becomeFamily.becomeSeo")}
            className="hover-link inline-block"
            to="mailto:HQ@explorenearme.co.uk?subject=Become%20one%20of%20our%20SEO%20Partners"
          >
            {t("footer.becomeFamily.becomeSeo")}
          </Link>
        </li>
      </ul>
      <ArrowedLink to="/our-mission" className="bg-primary-orange py-2">
        Join our mission
      </ArrowedLink>
    </section>
  );
};

export default Family;
