import { TSvgComponent } from "../../../types/utils/component";

const SvgCopy: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="#FF913F"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.3332 9.74935V13.2493C12.3332 16.166 11.1665 17.3327 8.24984 17.3327H4.74984C1.83317 17.3327 0.666504 16.166 0.666504 13.2493V9.74935C0.666504 6.83268 1.83317 5.66602 4.74984 5.66602H8.24984C11.1665 5.66602 12.3332 6.83268 12.3332 9.74935Z" />
      <path
        opacity="0.4"
        d="M13.2498 0.666016H9.7498C6.8748 0.666016 5.70814 1.80768 5.6748 4.62435H8.2498C11.7498 4.62435 13.3748 6.24935 13.3748 9.74935V12.3243C16.1915 12.291 17.3331 11.1243 17.3331 8.24935V4.74935C17.3331 1.83268 16.1665 0.666016 13.2498 0.666016Z"
      />
    </svg>
  );
};

export default SvgCopy;
