import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CategoryType, IEventUserType, TWishlists} from "./types";
import {IEventsData} from "../../networking/events/types";

export const initialState: TWishlists = {
    isLoading: false,
    title: "",
    categories: [],
    all: [],
};

export const WishlistSlice = createSlice({
    name: "WishlistSlice",
    initialState,
    reducers: {
        setCreateWishlist(state, action: PayloadAction<TWishlists>) {
            state.title = action.payload.title;
        },
        setWishlistCategories(state, action: PayloadAction<CategoryType[]>) {
            state.categories = action.payload;
        },
        setAllEventsCategories(state, action: PayloadAction<IEventsData[]>) {
            state.all = action.payload;
        },
        setCategories(state, action: PayloadAction<CategoryType[]>) {
            state.categories = action.payload;
        },
        setEventToCategory(
            state,
            action: PayloadAction<{ eventId: number; categoryId: number }>,
        ) {
            const {eventId, categoryId} = action.payload;
            const categoryToUpdate = state.categories.find(
                (category) => category.id === categoryId,
            );
            if (categoryToUpdate) {
                const eventToAdd = state.all.find((event) => event.id === eventId);
                if (eventToAdd) {
                    const eventType: IEventUserType = {event: eventToAdd};
                    categoryToUpdate.user_liked_events.push(eventType);
                }
            }
        },
        deleteCategory(state, action: PayloadAction<number>) {
            const categoryId = action.payload;
            state.categories = state.categories.filter(category => category.id !== categoryId);
        },
    },
});

export const {
    setCreateWishlist,
    setWishlistCategories,
    setAllEventsCategories,
    setCategories,
    setEventToCategory,
} = WishlistSlice.actions;

export default WishlistSlice.reducer;
