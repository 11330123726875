import { useEventsList } from "../_hooks";
import Table from "./components/Table";
import Tabs from "./components/Tabs";

const EventsListPage = () => {
  const { data, isLoading } = useEventsList();
  return (
    <section id="events-pending">
      <Tabs />
      <Table data={data?.success ? data.data : null} isLoading={isLoading} />
    </section>
  );
};

export default EventsListPage;
