import { GoogleMap, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import { ComponentProps, useEffect, useState } from "react";
import { mapStyle } from "../../../utils/map";
import { twMerge } from "tailwind-merge";
import { TEvent, TEventMap } from "../../../types/event";
import CustomMarker from "./Marker";
import { useAppSelector } from "../../../store/redux";
import EventLocation from "../EventLocation";
import { Link } from "react-router-dom";
import SvgX from "../../../assets/svgs/icons/SvgX";
import { getNextURL, turnEventIdToSlug } from "../../../utils/functions";

type TProps = ComponentProps<typeof GoogleMap> & {
  events?: Array<TEventMap | TEvent>;
};

const CustomMap = ({ events, ...props }: TProps) => {
  const [selectedEvent, setSelectedEvent] = useState<TEvent | TEventMap | null>(
    null,
  );
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const handleSelect = (event: TEvent | TEventMap | null) => {
    setSelectedEvent((_prev) => (_prev = event));
  };
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 34.01207890141392,
    lng: 100.65496676648927,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
    // libraries: ["places"],
  });
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setDefaultLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
      },
      () => alert("Please enable location permission to view events near you"),
    );
  }, []);
  return (
    <>
      {isLoaded && (
        <GoogleMap
          zoom={10}
          {...props}
          center={props.center ?? defaultLocation}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: true,
            disableDefaultUI: true,
            styles: mapStyle,
            ...props.options,
          }}
          mapContainerClassName={twMerge(
            "rounded-3xl w-full h-[600px] [&_iframe+div]:rounded-3xl overflow-hidden",
            props.mapContainerClassName,
          )}
        >
          {events?.map(
            (event) =>
              event.latitude &&
              event.longitude && (
                <OverlayView
                  key={`event_marker_${event.id}`}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  position={{
                    lat: parseFloat(event.latitude),
                    lng: parseFloat(event.longitude),
                  }}
                >
                  <div className="relative w-fit">
                    <button
                      type="button"
                      title="View event"
                      onClick={() => handleSelect(event)}
                    >
                      <CustomMarker
                        image={
                          (event as TEventMap).image_path ||
                          (event as TEvent).images[0].image_path
                        }
                      />
                    </button>
                    {selectedEvent && selectedEvent.id === event.id && (
                      <div
                        role="dialog"
                        className="absolute right-1/2 top-0 w-max min-w-[150px] -translate-y-[calc(100%+10px)] translate-x-1/2 rounded-xl bg-white text-lg"
                      >
                        <div className="relative mb-1 grid max-w-xs place-items-center p-4 after:absolute after:-bottom-2 after:-z-10 after:h-10 after:w-10 after:rotate-45 after:bg-white">
                          <button
                            type="button"
                            title="Close popup"
                            className="z-10 -mb-1 ml-auto"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelect(null);
                            }}
                          >
                            <SvgX className="h-2.5 w-2.5" />
                          </button>
                          <EventLocation
                            className="mb-1 justify-center text-base"
                            location={selectedEvent.location}
                          />
                          <Link
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            to={getNextURL({
                              nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
                                selectedEvent.translations.en.title,
                                selectedEvent.id,
                              )}`,
                            })}
                            className="hover-link inline-block text-base font-bold"
                          >
                            {selectedEvent.translations[selectedLanguage].title}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </OverlayView>
              ),
          )}
        </GoogleMap>
      )}
      {loadError && (
        <p className="my-10 text-center text-red-600">
          Something went wrong loading the map...
        </p>
      )}
    </>
  );
};

export default CustomMap;
