import { useTranslation } from "react-i18next";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";

const BeTheChange = () => {
  const { t } = useTranslation();
  return (
    <section className="section-margin container">
      <h2 className="section-heading heading-bar font-poppins-bold">
        {t("crowdfunding.beTheChange")}
      </h2>
      <h5 className="section-subtitle">{t("crowdfunding.beTheChangeTag")}</h5>
      <p className="my-6">{t("crowdfunding.beTheChangeDescription")}</p>
      <ArrowedLink title="Our mission" className="bg-primary-orange" to="/">
        {t("ourMission")}
      </ArrowedLink>
    </section>
  );
};

export default BeTheChange;
