import SvgTrash from "../../../../../../assets/svgs/icons/SvgTrash";
import { useEventCreationImages } from "../../_hooks";

const Images = () => {
  const { t, images, handleImageChange, handleAdd, handleRemove } =
    useEventCreationImages();
  return (
    <div className="mb-8">
      <div className="mb-4 flex flex-wrap items-center gap-4">
        <h3 className="section-title">
          {t("businessProfile.eventCreation.imageSection.title")}
        </h3>
        <span className="rounded-md bg-red-100 px-4 py-1 text-xs text-red-600">
          {t("businessProfile.eventCreation.imageSection.infoMessage")}
        </span>
      </div>
      <ul className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {images?.map((item, index) => {
          return (
            <li key={`event_creation_image_${index}`}>
              <label
                htmlFor={`event-creation-image-${index}`}
                className="hover-link group grid h-48 w-full cursor-pointer place-items-center rounded-2xl bg-white duration-500 hover:bg-primary-orange/20"
              >
                {item?.image ? (
                  <div className="single-grid place-items-center">
                    <img
                      src={
                        item.url?.toString() ??
                        (typeof item.image === "string"
                          ? item.image
                          : undefined)
                      }
                      alt={(index + 1).toString()}
                      className="single-grid-item h-48 w-full object-contain"
                    />
                    {images.length > 4 && (
                      <button
                        onClick={(e) => handleRemove(e, index)}
                        className="single-grid-item rounded-lg bg-black/50 p-2"
                        type="button"
                        title="Remove"
                      >
                        <SvgTrash className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="grid h-8 w-8 place-items-center rounded-full border-2 border-black">
                    <span className="text-xl font-bold duration-500 group-hover:rotate-180">
                      +
                    </span>
                    <button
                      onClick={() => {
                        console.log("first");
                      }}
                    ></button>
                  </div>
                )}
              </label>
              <input
                id={`event-creation-image-${index}`}
                name={
                  typeof item?.image === "string"
                    ? undefined
                    : `images[${index}][image]`
                }
                type="file"
                onChange={(e) => handleImageChange(e, index)}
                hidden
              />
              {typeof item?.image === "string" && (
                <input
                  id={`event-creation-image-${index}`}
                  name={`images[${index}][image]`}
                  defaultValue={item.image}
                  type="text"
                  onChange={(e) => handleImageChange(e, index)}
                  hidden
                />
              )}
              {item?.image && (
                <input
                  type="text"
                  hidden
                  name={`images[${index}][order]`}
                  defaultValue={index}
                />
              )}
            </li>
          );
        })}
      </ul>
      <div className="flex items-center justify-between">
        <button
          className="hover-link flex items-center gap-2 font-bold text-blue-600"
          type="button"
          title="Add image"
          onClick={handleAdd}
        >
          <span className="grid aspect-square h-6 w-6 place-items-center rounded-full border border-blue-600 text-sm">
            +
          </span>
          Add
        </button>
        {images.length > 4 && (
          <button
            className="hover-link flex items-center gap-2 font-bold text-blue-600"
            type="button"
            title="Remove image"
            onClick={handleRemove}
          >
            <span className="grid aspect-square h-5 w-5 place-items-center rounded-full border border-blue-600 text-sm">
              -
            </span>
            Remove
          </button>
        )}
      </div>
    </div>
  );
};

export default Images;
