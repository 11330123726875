import { useAtom } from "jotai";
import { useEventCreationType } from "../_hooks";
import { eventCreationDraftAtom } from "../_atom";

const EventType = () => {
  const eventType = useEventCreationType();
  const [edit] = useAtom(eventCreationDraftAtom);
  return (
    <input
      type="text"
      hidden
      value={edit?.duration_option ?? eventType}
      readOnly
      name="duration_option"
    />
  );
};

export default EventType;
