import { useAtom } from "jotai";
import CustomInput from "../../../../../../../components/common/inputs/NewInput";
import Time from "../Time";
import Address from "./Address";
import Currency from "./Currency";
import { eventCreationDraftAtom, eventUpdateAtom } from "../../../_atom";
import { useTranslation } from "react-i18next";

const Operator = () => {
  const [edit] = useAtom(eventUpdateAtom);
  const [draft] = useAtom(eventCreationDraftAtom);
  const { t } = useTranslation();
  return (
    <div className="mb-4 grid gap-10 2xl:grid-cols-3">
      <div>
        <h6 className="mb-2">
          {t("businessProfile.eventCreation.basicInfo.operatorEvents")}
        </h6>
        <div className="grid gap-3">
          <CustomInput
            required
            placeholder="Title of Activity"
            name="title"
            title="Title of Activity"
            autoComplete="title"
            type="text"
            defaultValue={edit?.title ?? draft?.title}
          />
          <CustomInput
            required
            placeholder="Language"
            name="language"
            autoComplete="language"
            type="text"
            defaultValue={edit?.language ?? draft?.language}
            title="Language"
          />
          <CustomInput
            required
            placeholder="Country"
            name="country"
            type="text"
            autoComplete="country"
            defaultValue={edit?.country ?? draft?.country}
            title="Country"
          />
          <CustomInput
            required
            placeholder="City"
            name="city"
            autoComplete="address"
            type="text"
            defaultValue={edit?.city ?? draft?.city}
            title="City"
          />
          <CustomInput
            required
            placeholder="Address"
            name="address"
            type="text"
            autoComplete="address"
            defaultValue={edit?.address ?? draft?.address}
            title="Address"
          />
          <Address />
          <Currency />
        </div>
      </div>
      <Time />
    </div>
  );
};

export default Operator;
