import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CustomInput from "../../../components/common/inputs/Input";
import dayjs, { Dayjs } from "dayjs";
import PrimaryButton from "../../../component/PrimaryButton";
import CustomDatePicker from "../../../components/common/inputs/DatePicker";
import SvgEdit from "../../../assets/svgs/icons/SvgEdit";
import SvgX from "../../../assets/svgs/icons/SvgX";
import CustomPhoneNumber from "../../../components/common/inputs/PhoneNumber";
import { useProfileDetails } from "../_hooks";
import SvgSuitcase from "../../../assets/svgs/icons/SvgSuitcase";

const Details = () => {
  const {
    handleSubmit,
    t,
    toggleEdit,
    isEditing,
    user,
    setBirthDate,
    birthDate,
    isPending,
    role,
  } = useProfileDetails();
  return (
    <section id="profile-details" className="dashboard-card gray flex-grow">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex items-center justify-between gap-4">
          <h3 className="section-title">
            {t("userProfile.profileSettings.userDetails.title")}
          </h3>
          <button
            title="Edit"
            onClick={toggleEdit}
            type="button"
            className="single-grid [&:not(:disabled)]:hover-link"
          >
            <SvgEdit
              className={`single-grid-item h-4 w-4 duration-200 ${
                isEditing ? "opacity-0" : "opacity-100"
              }`}
            />
            <SvgX
              className={`single-grid-item h-4 w-4 duration-200 ${
                isEditing ? "opacity-100" : "opacity-0"
              }`}
            />
          </button>
        </div>
        <CustomInput
          disabled={!isEditing}
          inputBgColor="bg-white"
          name="name"
          type="text"
          autoComplete="name"
          defaultValue={user.name}
          placeholder={t("userProfile.profileSettings.userDetails.username")}
          icon={<PersonIcon sx={{ color: "#d0d2d3" }} />}
        />
        <CustomInput
          disabled={!isEditing}
          inputBgColor="bg-white"
          name="email"
          type="email"
          autoComplete="email"
          defaultValue={user.email}
          placeholder={t("userProfile.profileSettings.userDetails.email")}
          icon={<EmailIcon sx={{ color: "#d0d2d3" }} />}
        />
        {role.name === "client" ? (
          <CustomDatePicker
            className="!mb-3"
            disabled={!isEditing}
            onChange={(newValue) => {
              const formattedDate = dayjs(newValue as Dayjs | null).format(
                "D-M-YYYY",
              );
              setBirthDate((_prev) => (_prev = formattedDate));
            }}
            value={birthDate || user.birth_date ? dayjs(user.birth_date) : null}
          />
        ) : (
          <CustomInput
            disabled={!isEditing}
            inputBgColor="bg-white"
            name="organization_name"
            type="text"
            defaultValue={user.organization_name ?? ""}
            placeholder={t("userProfile.profileSettings.userDetails.email")}
            icon={<SvgSuitcase className="h-6 w-6 fill-[#d0d2d3]" />}
          />
        )}
        <CustomPhoneNumber
          className="mb-3"
          disabled={!isEditing}
          placeholder={t("userProfile.profileSettings.userDetails.phone")}
          defaultValue={user.phone_number}
          name="phone_number"
        />
        <PrimaryButton
          loading={isPending}
          disabled={isPending || !isEditing}
          title="Submit"
          type="submit"
          className={`ml-auto w-fit origin-center px-5 py-2.5 duration-75 ${
            isEditing ? "scale-100" : "scale-0"
          }`}
        >
          Submit
        </PrimaryButton>
      </form>
    </section>
  );
};
export default Details;
