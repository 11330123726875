import React, { useEffect, useState } from "react";
import { TEventAge, TEventFee } from "../../../store/EventDetails/types";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../store/redux";
import {
  addToCart,
  buyNow,
  fetchCartItemCount,
} from "../../../store/Cart/ActionCart";
import { TAges, TCart } from "../../../store/Cart/types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { divideTimeIntoSlots, formatTime } from "../../../utils/date";
import dayjs from "dayjs";
import { likeEvent } from "../../../store/events/ActionEvents";
import { Calendar } from "react-multi-date-picker";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../store/currency";

interface BookingEventProps {
  fees: TEventFee[];
  ages: TEventAge[];
  visitTime: {
    duration: string;
    start_time: string;
    end_time: string;
  };
  title: string;
  workingDays: string[];
  durationType: string;
  quantity: number;
  quantityPerDuration: number | null;
  quantityLeft: [];
  remainingTime: [];
  updateState?: (values: {
    selectedDate: string;
    peopleCounts: number[];
    selectedTimeSlot: string;
    selectedFees: number[];
  }) => void;
}

type ISlot = {
  start: any;
  end: any;
};

const BookingEvent: React.FC<BookingEventProps> = ({
  fees,
  ages,
  visitTime,
  title,
  workingDays,
  durationType,
  quantity,
  quantityPerDuration,
  quantityLeft,
  remainingTime,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [agesError, setAgesError] = useState("");
  // const roleType = useSelector(
  //   (state: RootState) => state.authReducer?.userRole,
  // );
  const { eventDetails } = useAppSelector((state) => state.eventDetailsReducer);
  const auth = useAppSelector((state) => state.authReducer);
  const [timeSlotError, setTimeSlotError] = useState("");
  const [isFavorite, setIsFavorite] = useState<boolean>();
  // const [visitingTime, setVisitingTime] = useState("");
  const [userSlot, setUserSlot] = useState<ISlot[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    workingDays[0] || "",
  );
  const [peopleCounts, setPeopleCounts] = useState(ages.map(() => 0));
  const { eventId } = useParams();
  const eventIdNumber = eventId ? parseInt(eventId) : 0;
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>("");
  const [updateWorkingDays, setUpdatedWorkingDays] = useState<any>({});
  const [quantityRemainingPerDuration, setQuantityRemainingPerDuration] =
    useState<number | null>(0);
  const [quantityRemaining, setQuantityRemaining] = useState(0);
  const [selectedFees, setSelectedFees] = useState<number[]>(fees.map(() => 0));
  const [feeQuantities, setFeeQuantities] = useState<number[]>(
    fees.map(() => 0),
  );
  const [, setConvertedPrices] = useState<number | null>(null);
  const selectedCurrency = useAtomValue(currencyAtom);
  const [agePlusButtonClicked, setAgePlusButtonClicked] = useState(
    Array(ages.length).fill(false),
  );
  const [ageMinusButtonClicked, setAgeMinusButtonClicked] = useState(
    Array(ages.length).fill(false),
  );
  const [feePlusButtonClicked, setFeePlusButtonClicked] = useState(
    Array(fees.length).fill(false),
  );
  const [feeMinusButtonClicked, setFeeMinusButtonClicked] = useState(
    Array(fees.length).fill(false),
  );
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );
  const emptyFields = () => {
    // Reset the values selected when eventId is changing in the params
    setAgesError("");
    setSelectedDate(workingDays[0] || "");
    setPeopleCounts(ages.map(() => 0));
    setFeeQuantities(fees.map(() => 0));
    setSelectedTimeSlot("");
    setSelectedFees(fees.map(() => 0));
    setConvertedPrices(null);
    setAgePlusButtonClicked(Array(ages.length).fill(false));
    setAgePlusButtonClicked(Array(ages.length).fill(false));
    setFeePlusButtonClicked(Array(ages.length).fill(false));
    setFeeMinusButtonClicked(Array(ages.length).fill(false));
  };
  const toggleImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const updatedLikedStatus = !isFavorite;
    setIsFavorite(updatedLikedStatus);
    dispatch(likeEvent(eventIdNumber));
  };
  const increment = (index: number, type: string) => {
    if (type === "age") {
      setPeopleCounts((prevCounts) => {
        const timeIndex = remainingTime?.findIndex(
          (obj: any) => obj?.date === selectedDate,
        );
        const quantityPerDay = quantityLeft?.findIndex(
          (obj: any) => obj?.date === selectedDate,
        );
        const startTime = moment(selectedTimeSlot.start).format("HH:mm:ss");
        const newCounts = [...prevCounts];
        const availableCountPerDay = quantityLeft?.[quantityPerDay]?.["total"];
        const availableCount =
          remainingTime?.[timeIndex]?.["total"]?.[startTime];
        const sum = calculateSumExceptIndex(newCounts, index);
        if (quantityRemainingPerDuration && quantityRemaining === null) {
          if (
            availableCount ??
            0 + newCounts[index] + sum < (quantityRemainingPerDuration ?? 0)
          ) {
            newCounts[index] = newCounts[index] + 1;
            return newCounts; // Return the updated array
          }
        } else if (!quantityRemainingPerDuration && quantityRemaining) {
          if (
            availableCountPerDay ??
            0 + newCounts[index] + sum < (quantityRemaining ?? 0)
          ) {
            newCounts[index] = newCounts[index] + 1;
            return newCounts;
          }
        } else {
          newCounts[index] = newCounts[index] + 1;
          return newCounts;
        }
        return newCounts;
      });
    } else if (type === "fee") {
      setFeeQuantities((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = newCounts[index] + 1;
        return newCounts;
      });
      setSelectedFees((prevClicked) => {
        const click = [...prevClicked];
        click[index] = 1;
        return click;
      });
      setFeePlusButtonClicked((prevClicked) => {
        const click = [...prevClicked];
        click[index] = true;
        return click;
      });
      setFeeMinusButtonClicked((prevClicked) => {
        const click = [...prevClicked];
        click[index] = false;
        return click;
      });
    }
  };
  const decrement = (index: number, type: string) => {
    if (type === "age") {
      if (peopleCounts[index] > 0) {
        setPeopleCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          newCounts[index] = newCounts[index] - 1;
          return newCounts;
        });
        setAgePlusButtonClicked((prevClicked) => {
          const click = [...prevClicked];
          click[index] = false;
          return click;
        });
        setAgeMinusButtonClicked((prevClicked) => {
          const click = [...prevClicked];
          click[index] = true;
          return click;
        });
      }
    } else if (type === "fee") {
      if (feeQuantities[index] > 0) {
        setFeeQuantities((prevCounts) => {
          const newCounts = [...prevCounts];
          newCounts[index] = newCounts[index] - 1;
          return newCounts;
        });
        setSelectedFees((prevClicked) => {
          const click = [...prevClicked];
          click[index] = 0;
          return click;
        });
        setFeePlusButtonClicked((prevClicked) => {
          const click = [...prevClicked];
          click[index] = false;
          return click;
        });
        setFeeMinusButtonClicked((prevClicked) => {
          const click = [...prevClicked];
          click[index] = true;
          return click;
        });
      }
    }
  };
  function calculateSumExceptIndex(array: number[], excludedIndex: number) {
    return array.reduce((accumulator, currentValue, currentIndex) => {
      if (currentIndex !== excludedIndex) {
        return accumulator + currentValue;
      }
      return accumulator;
    }, 0);
  }
  const calculateSubtotal = (index: number) => {
    const agePrice = parseFloat(ages[index]?.pivot?.price.toString()) || 0;
    const count = isNaN(peopleCounts[index]) ? 0 : peopleCounts[index];

    // const selectedFeeTotal = fees
    //   .filter((fee, feeIndex) => selectedFees[feeIndex])
    //   .reduce(
    //     (total, fee) => total + (parseFloat(fee.price.toString()) || 0),
    //     0,
    //   );

    return agePrice * count;
  };
  const handleTimeSlotClick = (timeSlot: any) => {
    setSelectedTimeSlot(timeSlot);
    setPeopleCounts(ages.map(() => 0));
  };
  const total = (
    peopleCounts.reduce(
      (total, count, index) => total + calculateSubtotal(index),
      0,
    ) +
    fees.reduce((total, fee, feeIndex) => {
      return total + (feeQuantities[feeIndex] * fee.price || 0);
    }, 0)
  ).toFixed(2);

  const totalAmount = parseFloat(total);
  const handleAddToCart = (statement: string) => {
    const selectedAges = ages
      .map((age, index) => ({
        age_id: age.id,
        quantity: peopleCounts[index],
        created_at: "",
        updated_at: "",
      }))
      .filter((selectedAge) => selectedAge.quantity > 0);

    if (selectedAges.length === 0) {
      setAgesError(t("requiredAges"));
    } else {
      setAgesError("");
    }

    if (!selectedTimeSlot) {
      setTimeSlotError(t("requiredTime"));
    } else {
      setTimeSlotError("");
    }

    const isFeeSelected = selectedFees.some((fee) => fee > 0);

    if (selectedAges.length > 0 && selectedTimeSlot) {
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      let dateStart = new Date(selectedTimeSlot.start);
      const formattedTime = moment(dateStart).format("HH:mm");
      const selectedFeeData = isFeeSelected
        ? fees.map((fee, index) => ({
            fee_id: fee.id,
            selectedFees: feeQuantities[index],
          }))
        : [];
      const item: TCart = {
        id: 0,
        eventId: eventIdNumber,
        ages: selectedAges as TAges[],
        fees: selectedFeeData,
        date: formattedDate,
        time: formattedTime,
      };
      dispatch(statement === "cart" ? addToCart(item) : buyNow(item));
      dispatch(fetchCartItemCount());
      emptyFields();
    }
  };

  useEffect(() => {
    if (durationType === "h") {
      let array = divideTimeIntoSlots(
        dayjs(new Date(`2022-04-17T${visitTime.start_time}`)),
        dayjs(new Date(`2022-04-17T${visitTime.end_time}`)),
        Number(visitTime.duration) * 60,
      );
      setUserSlot(array);
    } else {
      let array = [
        {
          start: dayjs(new Date(`2022-04-17T${visitTime.start_time}`)),
          end: dayjs(new Date(`2022-04-17T${visitTime.end_time}`)),
        },
      ];
      setUserSlot(array);
    }
    //eslint-disable-next-line
  }, [visitTime.start_time, visitTime.end_time]);

  useEffect(() => {
    setIsFavorite((prev) => (prev = eventDetails.liked_by_user));
    workingDays.forEach((item) => {
      setUpdatedWorkingDays((prevState: any) => {
        // Creating a new object based on the previous state
        const updatedState = { ...prevState };
        // Set the key to an empty string
        updatedState[item] = item;
        // Return the updated state
        return updatedState;
      });
    });
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    emptyFields();
    //eslint-disable-next-line
  }, [eventId, ages, fees]);

  useEffect(() => {
    setQuantityRemainingPerDuration(quantityPerDuration);
    setQuantityRemaining(quantity);
    //eslint-disable-next-line
  }, [quantityPerDuration, quantityLeft]);
  return (
    <div className="">
      <div className="mt-10 rounded-xl border-2 border-primary-orange bg-white p-4 shadow-md md:mt-0">
        <div className="flex justify-between py-3">
          <h3 className="text-2xl font-bold text-[#131C23]">
            {t("bookingEvent")}
          </h3>
          {auth.token && auth.userRole?.id === 1 && (
            <button onClick={toggleImage}>
              <img
                loading="lazy"
                src={
                  isFavorite
                    ? "/assets/svgs/events/red-favorite-icon.svg"
                    : "/assets/svgs/events/favorite-icon.svg"
                }
                alt={isFavorite ? "favorite" : "unFavorite"}
              />
            </button>
          )}
        </div>
        <div className="">
          <div className="my-5">
            <Calendar
              value={selectedDate}
              onChange={(event: any) => {
                const year = event.year;
                const month = (event.monthIndex + 1)
                  .toString()
                  .padStart(2, "0"); // Add 1 to monthIndex because it's zero-based
                const day = event.day?.toString().padStart(2, "0");
                const finalDate = `${year}-${month}-${day}`;
                setSelectedDate(finalDate);
                // emptyFields();
                setPeopleCounts(ages.map(() => 0));
              }}
              mapDays={({ date }) => {
                const year = date.year;
                const month = (date.monthIndex + 1).toString().padStart(2, "0"); // Add 1 to monthIndex because it's zero-based
                const day = date.day?.toString().padStart(2, "0");
                const finalDate = `${year}-${month}-${day}`;
                let isValidDate = updateWorkingDays[finalDate] ? true : false;
                if (!isValidDate)
                  return {
                    disabled: true,
                  };
              }}
            />
          </div>
          <h4 className="my-3 mr-1 font-bold text-[#131C23]">
            {t("visitingTime")}
          </h4>
          <div className="gird grid-cols-3 items-center">
            {userSlot.map((timeSlot, index) => (
              <button
                key={index}
                className={`my-1 mr-5 rounded-full border-2 border-[#131C23]/10 px-3 py-2 font-medium ${
                  selectedTimeSlot === timeSlot
                    ? "mr-5 border-0 bg-[#FF913F] text-white"
                    : ""
                }`}
                onClick={() => handleTimeSlotClick(timeSlot)}
              >
                {formatTime(timeSlot.start, true)}
              </button>
            ))}
            {timeSlotError && (
              <p className="flex items-center pt-3 text-lg font-bold text-[#FF913F]">
                <img
                  loading="lazy"
                  src="/assets/svgs/common/error.svg"
                  className="h-8 w-8 pr-2"
                  alt="Error"
                />
                {timeSlotError}
              </p>
            )}
          </div>
          <div>
            {(quantityRemainingPerDuration || quantityLeft) && (
              <>
                {quantityRemainingPerDuration
                  ? (() => {
                      const time = new Date(selectedTimeSlot.start);
                      const selectedDateTime = moment(time).format("HH:mm:ss");
                      const foundItem = remainingTime.find(
                        (item: any) => item.date === selectedDate,
                      );

                      const remainingTickets = foundItem?.["total"][
                        selectedDateTime
                      ]
                        ? quantityRemainingPerDuration -
                          foundItem?.["total"][selectedDateTime]
                        : quantityRemainingPerDuration;
                      return (
                        <p className="font-medium text-primary-orange">
                          {`x${remainingTickets} Tickets remaining per time selected`}
                        </p>
                      );
                    })()
                  : (() => {
                      const item = quantityLeft?.find(
                        (obj: any) => obj?.date === selectedDate,
                      );

                      const remainingTickets = item?.["total"]
                        ? quantityRemaining - item?.["total"]
                        : quantityRemaining;

                      return (
                        <p className="font-medium text-primary-orange">
                          {`x${remainingTickets} tickets remaining per date selected`}
                        </p>
                      );
                    })()}
              </>
            )}

            {ages.map((age, index) => (
              <div className="flex justify-between py-2" key={index}>
                <div>
                  <p className="text-md font-bold">
                    {age.translations && age.translations[selectedLanguage]
                      ? age.translations[selectedLanguage].label
                      : age.label}
                  </p>
                  <div className="flex items-center text-xs font-medium text-[#131C23]/40">
                    {/*<h4 className="mr-1">Age</h4> <span>{people.age}</span>*/}
                  </div>
                  <p className="text-xs font-bold text-[#3F8CFF]">
                    {selectedCurrency.symbol}{" "}
                    {(
                      age.pivot.price * +selectedCurrency.exchange_rate
                    ).toFixed(2)}
                  </p>
                  {/*<p className="text-[#3F8CFF] font-bold text-xs">*/}
                  {/*    {selectedCurrency?.abbreviation !== "USD" &&*/}
                  {/*    convertedPrices !== null*/}
                  {/*        ? `${convertedPrices.toFixed(2)} ${selectedCurrency.symbol}`*/}
                  {/*        : `${age.pivot.price} $`}*/}
                  {/*</p>*/}
                </div>
                <div className="grid grid-cols-[30px_30px_30px] place-items-center">
                  <button
                    disabled={!selectedTimeSlot || !selectedDate}
                    className={`grid aspect-square w-full place-items-center leading-loose ${
                      ageMinusButtonClicked[index]
                        ? "bg-[#FF913F]"
                        : !selectedTimeSlot || !selectedDate
                        ? "bg-gray-200"
                        : "bg-black"
                    } rounded-full text-white`}
                    onClick={() => decrement(index, "age")}
                  >
                    -
                  </button>
                  <span className="text-xl">{peopleCounts[index]}</span>
                  <button
                    disabled={!selectedTimeSlot || !selectedDate}
                    className={`grid aspect-square w-full place-items-center leading-loose ${
                      agePlusButtonClicked[index]
                        ? "bg-[#FF913F]"
                        : !selectedTimeSlot || !selectedDate
                        ? "bg-gray-200"
                        : "bg-black"
                    } rounded-full text-white`}
                    onClick={() => increment(index, "age")}
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
            {agesError && (
              <p className="flex items-center pt-3 text-lg font-bold text-[#FF913F]">
                <img
                  loading="lazy"
                  src="/assets/svgs/common/error.svg"
                  className="h-8 w-8 pr-2"
                  alt="Error"
                />{" "}
                {agesError}
              </p>
            )}
          </div>
          <div>
            {fees && fees.length > 0 && (
              <h4 className="mr-1 mt-2 font-bold text-[#131C23]">
                {t("businessProfile.eventCreation.basicInfo.extraFees")}
              </h4>
            )}
            {fees.map((fee, index) => (
              <div key={fee.id} className="flex items-center justify-between">
                <div>
                  <p className="text-md font-bold text-[#FF913F]">
                    {fee.translations && fee.translations[selectedLanguage]
                      ? fee.translations[selectedLanguage].title
                      : fee.title}
                  </p>
                  <p className="text-xs font-bold">
                    {selectedCurrency.symbol}{" "}
                    {(fee.price * +selectedCurrency.exchange_rate).toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center py-2">
                  <button
                    className={`grid aspect-square w-full place-items-center leading-loose ${
                      feeMinusButtonClicked[index] ? "bg-[#FF913F]" : "bg-black"
                    } rounded-full px-3 text-white`}
                    onClick={() => decrement(index, "fee")}
                  >
                    -
                  </button>
                  <span className="mx-2 text-xl">{feeQuantities[index]} </span>
                  <button
                    className={`grid aspect-square w-full place-items-center leading-loose ${
                      feePlusButtonClicked[index] ? "bg-[#FF913F]" : "bg-black"
                    } rounded-full px-3 text-white`}
                    onClick={() => increment(index, "fee")}
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 rounded-xl bg-[#3F8CFF]/10 p-3">
            <h4 className="my-3 mr-1 font-bold text-[#131C23]">{title}</h4>
            <div className="calculation">
              {ages &&
                ages?.map((people, index) => {
                  if (peopleCounts[index] > 0) {
                    return (
                      <div
                        className="grid grid-cols-3 items-center justify-between font-medium"
                        key={index}
                      >
                        <p>{people.label}</p>
                        <p className="text-center">x{peopleCounts[index]}</p>
                        <p className="text-right font-bold">
                          {/*{calculateSubtotal(index).toFixed(2)}*/}
                          {selectedCurrency.symbol}{" "}
                          {(
                            calculateSubtotal(index) *
                            +selectedCurrency.exchange_rate
                          ).toFixed(2)}
                        </p>
                      </div>
                    );
                  }
                  return null;
                })}
              {selectedFees && selectedFees.some((fee) => fee > 0) ? (
                <h4 className="mr-1 mt-2 font-bold text-[#131C23]">
                  {t("businessProfile.eventCreation.basicInfo.extraFees")}
                </h4>
              ) : null}
              {fees &&
                fees?.map((fee, index) => {
                  if (feeQuantities[index] > 0) {
                    return (
                      <div
                        className="grid grid-cols-3 items-center justify-between font-medium"
                        key={index}
                      >
                        <p className="text-sm">{fee.title}</p>
                        <p className="text-center">x{feeQuantities[index]}</p>
                        <p className="text-right font-bold">
                          {selectedCurrency.symbol}{" "}
                          {(
                            fee.price *
                            +selectedCurrency.exchange_rate *
                            feeQuantities[index]
                          ).toFixed(2)}
                        </p>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
            {totalAmount > 0 && (
              <div className="grid grid-cols-3 items-center justify-between pt-10 font-medium">
                <p className="text-xl font-medium text-[#131C23]">
                  {t("total")}
                </p>
                <p className="text-center"></p>
                <p className="text-right text-xl font-bold text-[#3F8CFF]">
                  {selectedCurrency.symbol}{" "}
                  {(totalAmount * +selectedCurrency.exchange_rate).toFixed(2)}
                </p>
              </div>
            )}
          </div>
          <div className="text-md flex justify-between py-4 font-medium">
            <div
              className={`rounded-full border-2 border-[#FF913F] px-4 py-2 text-[#131C23] ${
                peopleCounts.every((count) => count === 0) ||
                !selectedTimeSlot ||
                (auth.userRole && auth.userRole.id === 2)
                  ? "cursor-not-allowed opacity-50"
                  : "hover:bg-[#FFAA3F] hover:text-white"
              }`}
            >
              <button
                onClick={() => handleAddToCart("cart")}
                disabled={
                  peopleCounts.every((count) => count === 0) ||
                  !selectedTimeSlot ||
                  (auth.userRole && auth.userRole.id === 2) ||
                  false
                }
                className="disabled:cursor-not-allowed"
              >
                {t("addToCart")}
              </button>
            </div>
            <div
              className={`flex items-center rounded-full bg-[#FF913F] px-5 py-2 text-white hover:bg-[#FF913F]/40 ${
                peopleCounts.every((count) => count === 0) ||
                !selectedTimeSlot ||
                (auth.userRole && auth.userRole.id === 2)
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
            >
              <button
                //TODO: Fix from handle add to cart to go to cart
                onClick={() => handleAddToCart("buyNow")}
                className="disabled:cursor-not-allowed"
                disabled={
                  peopleCounts.every((count) => count === 0) ||
                  !selectedTimeSlot ||
                  (auth.userRole && auth.userRole.id === 2) ||
                  false
                }
              >
                {t("buyNow")}
              </button>
              <img
                loading="lazy"
                src="/assets/svgs/common/white-arrow.svg"
                className="ml-2 w-5"
                alt=""
              />
            </div>
          </div>
          {/*<div className="flex font-bold text-xs py-4">*/}
          {/*    <img src="/assets/svgs/eventDetails/cancelation.svg" className="mr-3" />*/}
          {/*    <p>*/}
          {/*        {t('cancelation')}*/}
          {/*        <span className="text-[#883FFF]">21 August, 2023</span> {t('refund')}*/}
          {/*    </p>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
export default BookingEvent;
