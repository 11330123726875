import ArrowedLink from "../../../components/common/buttons/ArrowedLink";
import { useHomeInformation } from "../_hooks";

export const ImportantInformation = () => {
  const { t, data, selectedLanguage } = useHomeInformation();
  return (
    <section
      id="home-important-information"
      className="section-margin container"
    >
      <div className="grid gap-x-20 gap-y-10 lg:grid-cols-2">
        <div className="content-center">
          <h2 className="section-heading heading-bar mb-2 font-poppins-bold text-primary-navy after:bg-primary-navy">
            {t("importantInformation")}
          </h2>
          <h6 className="mb-8 font-poppins-bold text-xl text-primary-orange">
            {t("message")}
          </h6>
          <p
            className={`relative mb-4 text-lg  before:absolute before:-top-8 before:left-0 before:-z-10 before:mt-10 before:origin-left before:scale-[2] before:text-8xl before:leading-none before:opacity-20 ${
              data?.success
                ? "text-gray-600 before:content-['“']"
                : "text-red-600"
            }`}
          >
            {data?.success
              ? data.data.translations[selectedLanguage].content
              : "Something went wrong..."}
          </p>
          <div className="flex justify-between">
            <span className="font-poppins-bold text-primary-orange">
              {t("author")}
            </span>
            <ArrowedLink
              className="bg-primary-blue"
              title="Our mission"
              to="/our-mission"
            >
              {t("ourMission")}
            </ArrowedLink>
          </div>
        </div>
        <div className="relative flex flex-col content-center rounded-3xl bg-gradient-to-r from-secondary-orange to-[#FF8A3F] to-60% p-10 pb-5 before:absolute before:inset-0 before:-z-10 before:-rotate-3 before:scale-[1.025] before:rounded-3xl before:border-4 before:border-black before:content-['']">
          <iframe
            src="https://www.youtube.com/embed/26zOFiqCC4w?si=xzJQQxFlUZVa5AKS"
            title="Silence"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="mb-4 h-auto min-h-[250px] flex-grow rounded-lg"
          />
          <p className="italic text-white">
            {t("videoText") + " " + t("ordinary")}
          </p>
        </div>
      </div>
    </section>
  );
};
export default ImportantInformation;
