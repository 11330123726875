import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/redux";
import { TEvent } from "../../../types/event";
import {
  convertPrices,
  getNextURL,
  turnEventIdToSlug,
} from "../../../utils/functions";
import EventLocation from "../EventLocation";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../store/currency";

type TProps = { event: TEvent };

const EventCard = ({
  event: { id, display_price, images, location, translations, types },
}: TProps) => {
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const selectedCurrency = useAtomValue(currencyAtom);
  return (
    <Link
      target="_blank"
      rel="noreferrer noopener nofollow"
      to={getNextURL({
        nextRedirect: `${selectedLanguage}/events/${turnEventIdToSlug(
          translations.en.title,
          id,
        )}`,
      })}
      title={translations?.[selectedLanguage].title}
      className="hover-link inline-block h-full w-full"
    >
      <article className="flex h-full flex-col overflow-hidden rounded-3xl bg-white">
        <img
          src={images[0].image_path.toString()}
          alt={translations?.[selectedLanguage].title}
          title={translations?.[selectedLanguage].title}
          onError={(e) => (e.target as HTMLImageElement).remove()}
          className="aspect-[1.25/1] w-full rounded-3xl object-cover p-px shadow-sm"
        />
        <div className="flex flex-grow flex-col justify-between p-6">
          <div>
            <div className="mb-2 flex items-center justify-between">
              <EventLocation location={location} />
              {types.length > 1 && (
                <img
                  src={types[0].logo_path.toString()}
                  alt={types[0].translations[selectedLanguage].title}
                  title={types[0].translations[selectedLanguage].title}
                  className="max-h-8 object-contain"
                />
              )}
            </div>
            <h3 className="mb-4 font-bold capitalize">
              {translations?.[selectedLanguage].title}
            </h3>
          </div>
          <div className="flex justify-between">
            <p>
              Cost: <span className="font-bold">Per person</span>
            </p>
            <span className="font-bold">
              {convertPrices(display_price, selectedCurrency)}
            </span>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default EventCard;
