import axios from "axios";
export const getTermsAndConditionAPI = async (type: string) => {
  try {
    const response = await axios.get(`terms-conditions?type=${type}`);

    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getPrivacyPolicyAPI = async () => {
  try {
    const response = await axios.get("privacy-policy");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getOurMissionAPI = async (selectedLanguage: string) => {
  try {
    const response = await axios.get<{
      data: { id: number; information: string; translations: any };
    }>("our-mission");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
