import ArrowedLink from "../../../components/common/buttons/ArrowedLink";
import HeartsCard from "../../../components/common/cards/HeartsCard";

const BecomePartner = () => {
  return (
    <section id="business-become-partner" className="section-margin container">
      <div className="container grid gap-10 rounded-3xl bg-primary-navy py-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center gap-4 text-white lg:px-10">
          <h2 className="section-heading text-white">
            Become a <span className="text-primary-orange">Unique Partner</span>
          </h2>
          <p>
            If you would like to access special benefits, please share your
            story with our Head office for valuation
          </p>
          <ArrowedLink
            to="/partners"
            className="bg-primary-blue"
            title="Partner"
          >
            Apply Now
          </ArrowedLink>
        </div>
        <div className="relative grid place-content-center max-lg:row-start-1">
          <img
            loading="lazy"
            src="/assets/images/common/revenue-graph.webp"
            alt="Revenue cards"
            title="Revenue cards"
            className="mt-6 rotate-6"
          />
          <HeartsCard className="absolute -top-1/4 left-[5%] -rotate-[30deg] md:top-[5%] lg:-left-[5%]" />
          <HeartsCard className="absolute bottom-[5%] right-[5%] rotate-[30deg]" />
        </div>
      </div>
    </section>
  );
};

export default BecomePartner;
