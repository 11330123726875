import DashboardSideBar from "../../../component/DashboardSideBar/_index";
import { Outlet } from "react-router-dom";
import BecomePartner from "./BecomePartner";
import PartJourney from "./PartJourney";
import { useEffect } from "react";
import { removeBodyBackground } from "../../../utils/functions";

type props = {
  type: "business" | "user";
};
const Layout = ({ type }: props) => {
  useEffect(() => {
    removeBodyBackground("#FBFBFB");
  }, []);
  return (
    <>
      <div className="section-margin container grid gap-10 xl:grid-cols-[1fr_4.5fr]">
        <DashboardSideBar type={type} />
        <main role="main">
          <Outlet />
        </main>
      </div>
      {type === "business" ? <BecomePartner /> : <PartJourney />}
    </>
  );
};
export default Layout;
