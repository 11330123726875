import { usePartnerEvents } from "../../partners/_hooks";
import Banner from "./components/Banner";
import Events from "./components/Events";
import Location from "./components/Location";

const BusinessEventsPage = () => {
  const { data } = usePartnerEvents();
  return (
    <>
      <Banner
        image={data?.success ? data.data.partner_image : null}
        title={data?.success ? data.data.business.name : null}
      />
      <Events
        business={data?.success ? data.data.business : null}
        events={data?.success ? data.data.events : null}
      />
      <Location
        name={data?.success ? data.data.business.name : null}
        events={data?.success ? data.data.events : null}
      />
    </>
  );
};

export default BusinessEventsPage;
