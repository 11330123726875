export const navigationEventTypes = [
  {
    title: "Custom Day Activity",
    path: "custom",
  },
  {
    title: "Repetitive Activity",
    path: "repetitive",
  },
  {
    title: "Multi Stop Activity",
    path: "multi-stop",
  },
  {
    title: "Multi Day Activity",
    path: "multi-day",
  },
];
