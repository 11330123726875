import axios from "axios";
export const addToCartAxios = async (
  eventId: number,
  ages: any[],
  date: string | null,
  time: string,
  fees: any[],
) => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const payload = clientToken
      ? {
          ages: ages,
          date: date,
          time: time,
          event_fees: fees,
          guest_token: clientToken,
        }
      : {
          ages: ages,
          date: date,
          time: time,
          event_fees: fees,
        };
    const response = await axios.post(
      `events/${eventId}/add-to-cart${clientToken ? "/guest" : "/"}`,
      payload,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const buyNowAxios = async (
  eventId: number,
  ages: any[],
  date: string | null,
  time: string,
  fees: any[],
) => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const payload = clientToken
      ? {
          ages: ages,
          date: date,
          time: time,
          event_fees: fees,
          guest_token: clientToken,
        }
      : {
          ages: ages,
          date: date,
          time: time,
          event_fees: fees,
        };
    const response = await axios.post(
      `events/${eventId}/buy-now${clientToken ? "/guest" : "/"}`,
      payload,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const getCartItemsAxios = async () => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const response = await axios.get(
      `cart${clientToken ? `/guest?guest_token=${clientToken}` : "/"}`,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
export const deleteEventCartAxios = async (cartId: number) => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const response = await axios.delete(
      `cart/${cartId}/delete${
        clientToken ? `/guest?guest_token=${clientToken}` : "/"
      }`,
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
};
export const selectedCartAxios = async (formattedCart: { carts: number[] }) => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const response = await axios.post(
      `cart/select${clientToken ? "/guest" : "/"}`,
      formattedCart,
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
};
export const getSelectedCartAPI = async () => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const response = await axios.get(
      `cart/selected${clientToken ? `/guest?guest_token=${clientToken}` : "/"}`,
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
};
export const SinglePaymentAPI = async (payload: any) => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const response = await axios.post(
      `payment/single${clientToken ? "/guest" : "/"}`,
      payload,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const SplitPaymentAPI = async (payload: any[]) => {
  try {
    const response = await axios.post("payment/split", payload);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);

    throw error;
  }
};
export const PaymentSuccessAPI = async (payload: string) => {
  try {
    const clientToken = localStorage.getItem("clientToken");
    const response = await axios.post(
      `payment/success${clientToken ? `/${clientToken}` : "/"}`,
      { payment_intent: payload },
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const guestInformationAPI = async (payload: string) => {
  try {
    const response = await axios.get(
      `guest-checkout/event-information?code=${payload}`,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const guestPaymentAPI = async (
  payload: string,
  payment_intent: string,
) => {
  try {
    const response = await axios.get(
      `guest-checkout/payment-success?code=${payload}&payment_intent=${payment_intent}`,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getReservedTicketAPI = async (reservedTicket: number) => {
  try {
    const response = await axios.get(`users/profile/tickets/${reservedTicket}`);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getTicketAPI = async (code: string) => {
  try {
    const response = await axios.get(`ticket/${code}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const submitTicketAPI = async (code: string) => {
  try {
    const response = await axios.put(`ticket/${code}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const fetchCartItemCountAPI = async (): Promise<number> => {
  try {
    const clientToken = localStorage.getItem("clientToken") as string;
    let url = "cart/count";

    if (clientToken) {
      url += `/${clientToken}`;
    }
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
