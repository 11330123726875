import { Tooltip } from "@mui/material";
import { TEventData } from "../../../store/Cart/types";
import moment from "moment";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {useTranslation} from "react-i18next";
type props = {
    item: TEventData
}
const CheckoutEventCard = (props: props) => {
    const reviewsAvgStars = props?.item?.event.reviews_avg_stars ? parseFloat(props?.item.event.reviews_avg_stars.toString()) : 0;
    const {t}=useTranslation()
    return (
        <section id="CheckoutEventCard" className="md:py-5">
            <div className="flex md:flex-row flex-col gap-7">
                <img loading="lazy" className="h-[140px] w-[140px] rounded-lg" src={props?.item?.event.images[0].image_path} alt="event" />
                <div className="flex flex-col">
                    <div className="text-[#898E91] flex items-center gap-3 text-sm mb-2">
                        {t('operator')}: <span className="text-black flex items-center gap-2">
                            <img loading="lazy" className="w-[20px] h-[20px] rounded-full" src={props.item.event.business?.profile_pic} />
                            {props.item.event.business?.name}
                        </span>
                    </div>
                    <div className="text-[#898E91] flex items-center gap-3 text-sm">
                        <div className="flex items-center gap-2"><WatchLaterIcon sx={{ fill: '#D0D2D3' }} /> {t('visitingTime')}: <span className="text-black font-medium">{props.item.time} ({t('checkout.localTime')})</span> </div>
                    </div>
                    <h1 className="pt-4 font-bold text-lg">{props?.item.event.title}</h1>
                    {props?.item.event.reviews?.length > 0 ? (
                        <div className="flex space-x-2 mt-6">
                            {props?.item.event.reviews.map((reviewData: any, index: any) => (
                                <img
                                    key={index}
                                    src={reviewData.user.profile_pic}
                                    alt={`review-${index}`}
                                    className="w-6 h-6 rounded-full"
                                />
                            ))}
                            <span className="text-black font-bold text-md">
                                {props?.item.event.reviews.length}+
                            </span>
                            <span className="text-[#888D90] px-2 text-md">{t('reviews')}</span>
                            <img loading="lazy" src="/assets/svgs/events/star.svg" alt="review" />
                            <span className="text-black font-bold text-md">
                                {reviewsAvgStars.toFixed(1)}
                            </span>
                        </div>
                    ) : (
                        <p>{t('noReviews')}</p>
                    )}
                </div>
            </div>
            <div className=" grid md:grid-cols-3 grid-cols-1 gap-3 mt-8">
                <div className="flex items-center gap-5 w-full">
                    <div className="bg-[#ECF4FF] p-4 rounded-2xl w-fit">
                        <img loading="lazy" src="/assets/svgs/events/location.svg" alt="icon"
                            className="w-7 h-auto" />
                    </div>
                    <div>
                        <p className="font-bold">{t('address')}</p>
                        <span className="text-sm">{props.item.event.address !== '' ? props.item.event.address : t('noAddress')}</span>
                    </div>

                </div>

                <div className="flex items-center gap-5">
                    <div className="bg-[#FCECFF] p-4 rounded-2xl w-fit">
                        <img loading="lazy" src="/assets/svgs/events/person-icon.svg" alt="icon"
                            className="w-7 h-auto" />
                    </div>
                    <div>
                        <p className="font-bold">{t('person')}</p>
                        {
                            props.item.event_age.length > 1 ? (
                                <Tooltip title={props.item.event_age.map((item) => (
                                    <p className="font-bold text-md">
                                        {item.age.label} - x{item.quantity}
                                        <br />
                                    </p>
                                ))}
                                >
                                    <span className="text-sm">{props.item.event_age[0].age.label} - x{props.item.event_age[0].quantity} ... </span>
                                </Tooltip>) : (<span className="text-sm">{props.item.event_age[0].age.label} - x{props.item.event_age[0].quantity} </span>)
                        }

                    </div>

                </div>

                <div className="flex items-center gap-5">
                    <div className="bg-[#F0FAF5] p-4 rounded-2xl w-fit">
                        <img loading="lazy" src="/assets/svgs/events/green-calendar-icon.svg" alt="icon"
                            className="w-7 h-auto" />
                    </div>
                    <div>
                        <p className="font-bold">{t('Date')}</p>
                        {
                            props.item.event.working_days.length > 1 ? (
                                <Tooltip title={props.item.event.working_days.map((item) => (
                                    <p className="font-bold text-md">
                                        {moment(item).format("YYYY MM DD")}
                                        <br />
                                    </p>
                                ))}
                                >
                                    <span className="text-sm">{moment(props.item.event.working_days[0]).format("YYYY MMMM DD")} ... </span>
                                </Tooltip>) : (<span className="text-sm">{moment(props.item.event.working_days[0]).format("YYYY MMMM DD")} </span>)
                        }
                    </div>

                </div>

            </div>
        </section>
    );
};

export default CheckoutEventCard;
