import { TSvgComponent } from "../../../types/utils/component";

const SvgHeart: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 18 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0838 0.698783C11.0176 0.683356 9.90831 0.996132 9.05554 1.87565C8.19633 0.997697 7.07584 0.698725 6.03782 0.698725C3.49196 0.698725 0.94342 2.80884 0.94342 5.79296C0.94342 8.67728 2.7889 11.0834 4.62265 12.7241C5.54968 13.5535 6.50052 14.212 7.27242 14.6653C7.65814 14.8918 8.00524 15.0707 8.28778 15.195C8.42859 15.2569 8.56002 15.3083 8.67646 15.3452C8.77866 15.3776 8.91838 15.4154 9.05672 15.4154C9.19507 15.4154 9.33478 15.3776 9.43698 15.3452C9.55343 15.3083 9.68486 15.2569 9.82567 15.195C10.1082 15.0707 10.4553 14.8918 10.841 14.6653C11.6129 14.212 12.5638 13.5535 13.4908 12.7241C15.3245 11.0834 17.17 8.67728 17.17 5.79296C17.17 2.80224 14.6159 0.735421 12.0838 0.698783Z"
      />
    </svg>
  );
};

export default SvgHeart;
