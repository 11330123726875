import axios, { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  TLaravelError,
  TLaravelResponse,
  TLaravelSuccess,
} from "../../types/utils/laravel";
import { TCategory, TCategoryInformation } from "../../types/category";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { selectedCategoryAtom } from "../../store/home";
import { TEvent, TEventMap } from "../../types/event";
import { useTranslation } from "react-i18next";
import { THomeInformation } from "../../types/pages/home";
import { useLocation, useNavigate } from "react-router-dom";
import { errorResponseToast, toastFallback } from "../../utils/functions";
import { PaymentSuccess, guestPayment } from "../../store/Cart/ActionCart";
import {
  getInterests,
  resendOTP,
  setActiveTab,
  setAuthModal,
  setInterestUser,
  setShowSignUpContent,
  toggleVerificationModal,
  verifyEmail,
} from "../../store/Auth/ActionAuth";
import { LatLng } from "use-places-autocomplete";
import { TLabel } from "../../types/utils/component";
import { toast } from "sonner";
import { currencyAtom } from "../../store/currency";

export const useGetHomeData = () => {
  const dispatch = useAppDispatch();
  const urlParams = useLocation();
  const { t } = useTranslation();

  // const DefaultLocation = { lat: 10, lng: 106 };
  const paymentIntent = new URLSearchParams(urlParams.search).get(
    "payment_intent",
  );
  const clientCode = new URLSearchParams(urlParams.search).get("code");
  const seoCode = new URLSearchParams(urlParams.search).get("seoCode");

  const showVerificationModal = useAppSelector(
    (state) => state.authReducer?.showVerificationModal,
  );

  const [loading, setLoading] = useState(true);
  const [pageAlreadyLoaded, setPageAlreadyLoaded] = useState(false);
  // const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const setPageLoaded = () => {
    setPageAlreadyLoaded((_prev) => (_prev = true));
  };
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (document.readyState === "complete") {
      setPageAlreadyLoaded((_prev) => (_prev = true));
      setLoading((prev) => (prev = false));
      timeout = setTimeout(setPageLoaded, 2000);
    } else {
      window.onload = () => {
        setLoading((prev) => (prev = false));
        timeout = setTimeout(setPageLoaded, 2000);
      };
    }
    return () => {
      clearTimeout(timeout);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (seoCode) localStorage.setItem("seoCode", seoCode);
    if (!paymentIntent) {
      return;
    }
    const payment = clientCode
      ? guestPayment(clientCode, paymentIntent)
      : PaymentSuccess(paymentIntent);
    dispatch(payment)
      .then(removeAllQueryParams)
      .catch((error: any) => {
        toast.error(error?.response?.data.message ?? error?.message);
        removeAllQueryParams();
      });
    //eslint-disable-next-line
  }, [urlParams]);
  const removeAllQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = "";
    window.history.replaceState({}, "", url.toString());
  };
  const handleCloseVerificationModal = () => {
    dispatch(toggleVerificationModal(false));
  };

  return {
    t,
    showVerificationModal,
    // googleMapMarkers,
    loading,
    pageAlreadyLoaded,
    // defaultLocation,
    handleCloseVerificationModal,
  };
};
export const useHomeBecomePartner = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleButtonClick = async () => {
    dispatch(setActiveTab("business"));
    dispatch(setAuthModal(true));
    dispatch(setShowSignUpContent(true));
  };
  return { t, handleButtonClick };
};
export const useVerificationEmail = (open: boolean) => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector((state) => state.authReducer?.userInfo);
  const userRole = useAppSelector((state) => state.authReducer?.userRole);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const [verificationCode, setVerificationCode] = useState(Array(5).fill(""));
  const [resendTimer, setResendTimer] = useState(59);
  const [showCongratulationsModal, setShowCongratulationsModal] =
    useState(false);
  const [showInterestModal, setShowInterestModal] = useState(false);
  const regex = /^\d*$/;
  const handleVerificationCodeChange = (value: string, index: number) => {
    if (regex.test(value)) {
      const updatedCode = [...verificationCode];
      updatedCode[index] = value;
      setVerificationCode(updatedCode);
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };
  const handleOpenVerificationModal = () => {
    dispatch(verifyEmail(verificationCode.join(""))).then(() => {
      localStorage.setItem("otpVerified", "true");
      dispatch(toggleVerificationModal(false));
      dispatch(getInterests());
      setShowCongratulationsModal(true);
    });
  };
  const handleResendCode = () => {
    dispatch(resendOTP()).then((res) => {
      setResendTimer(59);
    });
  };
  const closeCongratulationModal = () => {
    setShowCongratulationsModal(false);
    if (userRole?.id !== 1) {
      return;
    }
    setShowInterestModal(true);
  };
  const closeUserInterest = (value: number[]) => {
    dispatch(setInterestUser(value));
    setShowInterestModal(false);
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (resendTimer > 0 && open) {
      const interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (resendTimer === 0) {
    }
  }, [resendTimer, open]);
  return {
    userInfo,
    userRole,
    resendTimer,
    showCongratulationsModal,
    showInterestModal,
    verificationCode,
    inputRefs,
    handleVerificationCodeChange,
    handleOpenVerificationModal,
    handleResendCode,
    closeCongratulationModal,
    closeUserInterest,
  };
};
export const useUserInterest = () => {
  const interests = useAppSelector((state) => state.authReducer.interests);
  const [interestSelected, setInterestSelected] = useState<number[]>([]);
  const handleSelectInterest = (id: number) => {
    let array = [...interestSelected];
    if (array.includes(id)) {
      let index = array.findIndex((item) => item === id);
      array.splice(index, 1);
      setInterestSelected(array);
    } else {
      array.push(id);
      setInterestSelected(array);
    }
  };
  return { interests, interestSelected, handleSelectInterest };
};

//Refactor
export const useHomeCategories = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [othersOpen, setOthersOpen] = useState(false);
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const {
    data: filters,
    isSuccess: isFiltersSucces,
    isLoading: isFilterLoading,
  } = useQuery({
    queryKey: ["home-categories-filters"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<Array<TCategory>>>("categories");
      return res.data;
    },
  });
  let otherCategories: Array<TCategory> = [];
  let displayedCategories: Array<TCategory> = [];
  if (isFiltersSucces && filters?.success) {
    !selectedCategoryId &&
      setSelectedCategoryId((_prev) => (_prev = filters.data[0].id));
    displayedCategories = [...filters.data].slice(0, 4);
    otherCategories = [...filters.data].splice(4);
  }
  const navigate = useNavigate();
  const handleSelectCategory = (id: number) => {
    setSelectedCategoryId((_prev) => (_prev = id));
    setOthersOpen((_prev) => (_prev = false));
    navigate({ hash: "home-category-events" });
  };
  const handleToggleOthers = () => {
    setOthersOpen((_prev) => (_prev = !othersOpen));
  };
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["home-categories-events", selectedCategoryId],
    queryFn: async () => {
      if (selectedCategoryId) {
        const res = await axios.get<
          TLaravelResponse<{
            events: Array<TEvent>;
            information: TCategoryInformation;
          }>
        >(`categories/${selectedCategoryId}/events`);
        setSelectedCategory(
          (_prev) =>
            (_prev = res.data.success ? res.data.data.information : null),
        );
        return (
          res.data as TLaravelSuccess<{
            events: Array<TEvent>;
            information: TCategoryInformation;
          }>
        ).data;
      }
      return null;
    },
    staleTime: 120_000_000,
  });
  if (isSuccess && data?.information) {
    setSelectedCategory((_prev) => (_prev = data.information));
  }
  return {
    isFilterLoading,
    selectedCategoryId,
    displayedCategories,
    selectedLanguage,
    otherCategories,
    handleSelectCategory,
    othersOpen,
    handleToggleOthers,
    data,
    isLoading,
    selectedCategory,
  };
};

export const useHomeMap = () => {
  const { t } = useTranslation();
  const [center, setCenter] = useState<LatLng | null>(null);
  const [events, setEvents] = useState<Array<TEventMap>>([]);
  const { data: countries } = useQuery({
    queryKey: ["home-map-countries"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<Array<string>>>("events-locations");
      if (res.data.success) {
        return res.data.data.map((country) => ({
          label: country,
          value: country,
        }));
      }
      return [];
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: async (country: string) => {
      const res = await axios.post<
        TLaravelResponse<{ geo: LatLng; events: Array<TEventMap> }>
      >(`events-locations`, { location: country });
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        setCenter((_prev) => (_prev = res.data.geo));
        setEvents((_prev) => (_prev = res.data.events));
        if (res.data.events.length < 1) {
          toast.warning("No events found");
        }
      }
    },
    onError: errorResponseToast,
  });
  const handleChange = (e: TLabel) => {
    if (e?.value) {
      mutate(e.value);
    }
  };
  return { t, countries, center, handleChange, events, isPending };
};

export const useHomeInformation = () => {
  const { t } = useTranslation();
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const { data } = useQuery({
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<THomeInformation>>("home/information");
      return res.data;
    },
    queryKey: ["home-information"],
    staleTime: 240_000_000,
  });
  return { t, data, selectedLanguage };
};

export const useHomePromotionEvents = () => {
  const { t } = useTranslation();
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const selectedCurrency = useAtomValue(currencyAtom);
  const { data, isLoading } = useQuery({
    queryKey: ["home-promotional-events"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<Array<TEvent>>>("home/events");
      return res.data;
    },
  });
  return { t, data, isLoading, selectedLanguage, selectedCurrency };
};

export const useLikeEvent = () => {
  const { mutate, isPending, data } = useMutation({
    mutationFn: async (id: number) => {
      const res = await axios.post<TLaravelResponse<"Liked" | "Unliked">>(
        `events/${id}/like`,
      );
      return res.data;
    },
    onError: (err: AxiosError<TLaravelError>) => {
      toastFallback(err.response?.data.message);
    },
  });
  return { mutate, isPending, data };
};
