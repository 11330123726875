import { TSvgComponent } from "../../../types/utils/component";

const SvgArrowStraight: TSvgComponent = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 20 33"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path d="M11.25 2C11.25 1.30964 10.6904 0.75 10 0.75C9.30964 0.75 8.75 1.30964 8.75 2L11.25 2ZM9.11612 31.8839C9.60427 32.372 10.3957 32.372 10.8839 31.8839L18.8388 23.9289C19.327 23.4408 19.327 22.6493 18.8388 22.1612C18.3507 21.673 17.5592 21.673 17.0711 22.1612L10 29.2322L2.92893 22.1612C2.44078 21.673 1.64932 21.673 1.16116 22.1612C0.673009 22.6493 0.673009 23.4408 1.16116 23.9289L9.11612 31.8839ZM8.75 2L8.75 31L11.25 31L11.25 2L8.75 2Z" />
    </svg>
  );
};

export default SvgArrowStraight;
