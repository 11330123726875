import { useEffect, useState } from "react";
import OrderConfirmation from "../OrderConfirmation";
import { useSelector } from "react-redux";
import {
  fetchCartItemCount,
  getCartItems,
} from "../../../../store/Cart/ActionCart";
import { RootState } from "../../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../../store/redux";
import { TAges } from "../../../../store/Cart/types";
import { deleteEventFromCart } from "../../../../store/Cart/ActionCart";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../../../store/currency";
import { getNextURL, scrollToTop } from "../../../../utils/functions";

interface CartItem {
  id: number;
  total: number;
  expires_at: string;
  service_fees: number;
  price_without_commission: number;
}

const CartItems = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedCurrency = useAtomValue(currencyAtom);
  const cartItem = useSelector(
    (state: RootState) => state.cartReducer.cartItem,
  );
  const cartItemsData = cartItem.data || [];
  const [selectedItems, setSelectedItems] = useState<CartItem[]>([]);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [totalPriceWithoutFees, selectedTotalPriceWithoutFees] = useState(0);
  const [totalFees, selectedTotalFees] = useState(0);
  const [timers, setTimers] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);
  useEffect(() => {
    scrollToTop();
  }, []);
  useEffect(() => {
    setSelectedTotal(calculateTotal());
    selectedTotalFees(calculateTotalFees());
    selectedTotalPriceWithoutFees(calculatePriceWithoutCommission());
    //eslint-disable-next-line
  }, [selectedItems]);

  const calculateTotal = () => {
    let total = 0;
    selectedItems.forEach((item) => {
      total += item.total;
    });
    return total;
  };
  const calculateTotalFees = () => {
    let total = 0;
    selectedItems.forEach((item) => {
      total += item.service_fees;
    });
    return total;
  };
  const calculatePriceWithoutCommission = () => {
    let total = 0;
    selectedItems.forEach((item) => {
      total += item.price_without_commission;
    });
    return total;
  };
  const handleDeleteEventFromCart = (eventId: number) => {
    dispatch(deleteEventFromCart(eventId));
    dispatch(fetchCartItemCount());
  };
  function calculateHoldSpot(expires_at: string, itemId: number) {
    const currentTime = moment();
    const expirationTime = moment(expires_at);
    const duration = moment.duration(expirationTime.diff(currentTime));

    if (duration.asSeconds() <= 0) {
      setTimers((prevTimers) => ({
        ...prevTimers,
        [itemId]: "0 minutes 0 seconds",
      }));
      // delete the item from the cart
      handleDeleteEventFromCart(itemId);
    } else {
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const holdSpotTime = `${minutes} minutes ${seconds} seconds`;
      setTimers((prevTimers) => ({
        ...prevTimers,
        [itemId]: holdSpotTime,
      }));
      return holdSpotTime;
    }
  }
  const handleCardClick = (item: CartItem) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id,
    );
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id),
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  useEffect(() => {
    const updateHoldSpot = () => {
      cartItemsData?.forEach((item: any) => {
        const holdSpotTime = calculateHoldSpot(item.expires_at, item.id);
        if (holdSpotTime === "0 minutes 0 seconds") {
          handleDeleteEventFromCart(item.id);
        }
      });
    };
    updateHoldSpot();
    const intervalId = setInterval(updateHoldSpot, 1000);
    return () => {
      clearInterval(intervalId);
    };
    //eslint-disable-next-line
  }, [cartItemsData]);

  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );
  return (
    <div className="container mt-10">
      <div className="flex items-center">
        <h3 className="text-xl font-bold text-[#131C23]">
          {" "}
          {t("shopCart.shoppingCart")}{" "}
        </h3>
      </div>
      <div className="py-10 xl:grid xl:grid-cols-[3fr_1fr]">
        <div className="">
          {cartItemsData.length > 0 ? (
            cartItemsData?.map((item: any) => (
              <article
                key={item.id}
                className={`my-10 cursor-pointer rounded-xl bg-white shadow-md hover:bg-[#FF913F]/5 md:my-5 md:grid md:grid-cols-[1fr_4fr] ${
                  selectedItems.some(
                    (selectedItem) => selectedItem.id === item.id,
                  )
                    ? "clicked"
                    : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleCardClick(item);
                }}
              >
                <div className="relative">
                  {item.type && (
                    <div className="absolute left-0 top-0 px-3 pt-4">
                      <button className="m-2 rounded-full bg-white px-5 py-1 text-xs font-medium text-[#131C23] backdrop-blur-xl">
                        {item.type}
                      </button>
                    </div>
                  )}
                  <img
                    loading="lazy"
                    src={item.event.images?.[0]?.image_path}
                    alt="event"
                    className="h-full w-full rounded-xl object-cover object-center max-md:max-h-[200px]"
                  />
                </div>
                <div className="details rounded-r-3xl p-5">
                  <div className="flex items-center justify-between">
                    {item.event.location && (
                      <div className="flex items-center">
                        <img
                          loading="lazy"
                          src="/assets/svgs/events/location.svg"
                          alt="icon"
                          className="h-auto w-5"
                        />
                        <p className="text-sm">{item.event.location}</p>
                      </div>
                    )}
                    <div>
                      <button
                        onClick={() => handleDeleteEventFromCart(item.id)}
                      >
                        <img
                          loading="lazy"
                          src="/assets/svgs/common/trash.svg"
                          alt="Trash"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="justify-between md:flex">
                    <p className="pb-1 text-xl font-bold text-[#131C23]">
                      {item.event.title}
                    </p>
                    <div className="flex w-1/2 items-center justify-center space-x-1 rounded-full bg-[#FF585F]/20 px-5 py-2 text-xs md:w-fit">
                      <p className="hidden text-[#FF585F] md:block">
                        {t("shopCart.holdSpot")}:{" "}
                      </p>
                      <span className="font-bold text-[#FF585F]">
                        {timers[item.id]}
                      </span>
                    </div>
                  </div>
                  <div className="text-md flex pt-3">
                    {item.reviews?.length > 0 ? (
                      <div className="flex space-x-2">
                        <span className="text-md font-bold text-black">
                          {item.reviews.length}+
                        </span>
                        <span className="text-md px-2 text-[#888D90]">
                          {t("reviews")}
                        </span>
                        <img
                          loading="lazy"
                          src="/assets/svgs/events/star.svg"
                          alt="review"
                        />
                        <span className="text-md font-bold text-black">
                          {/*{item.reviews[0].stars}*/}
                        </span>
                      </div>
                    ) : (
                      <p>{t("noReviews")}</p>
                    )}
                  </div>
                  <div className="items-center justify-between pt-2 md:flex">
                    <div className="flex py-1 text-sm md:py-0">
                      <img
                        loading="lazy"
                        src="/assets/svgs/common/durations.svg"
                        alt="Duration"
                      />
                      <span className="px-1 text-[#888D90]">
                        {t("duration")}
                      </span>
                      <p className="font-bold text-[#131C23]">
                        {item.event.duration} {t("hours")}
                      </p>
                    </div>
                    <div className="flex py-1 text-sm md:px-1 md:py-0">
                      <img
                        loading="lazy"
                        src="/assets/svgs/common/language.svg"
                        alt="Language"
                      />
                      <span className="px-1 text-[#888D90]">
                        {t("language")}
                      </span>
                      <p className="font-bold text-[#131C23]">
                        {item.event.language}
                      </p>
                    </div>
                    <div className="flex py-1 text-sm md:px-1 md:py-0">
                      <img
                        loading="lazy"
                        src="/assets/svgs/common/durations.svg"
                        alt="Duration"
                      />
                      <span className="px-1 text-[#888D90]">
                        {t("visitingTime")}:
                      </span>
                      <p className="font-bold text-[#131C23]">
                        {" "}
                        {moment(item.time, "HH:mm:ss").format("HH:mm A")}
                      </p>
                    </div>
                  </div>
                  <div className="my-2 grid grid-cols-2 items-baseline gap-2 space-y-2 text-sm md:flex md:items-center md:space-x-5 md:space-y-0">
                    {item?.event_age.map((event: TAges) => (
                      <div className="flex items-center justify-between rounded-lg bg-[#131C23]/10 px-3 py-2 text-sm">
                        <img
                          loading="lazy"
                          src="/assets/svgs/common/user.svg"
                          alt="User"
                          className="w-4"
                        />
                        <p className="px-2 text-[#888D90]">
                          {event.age?.label}
                        </p>
                        <p className="font-bold text-[#131C23]">
                          {event.quantity}{" "}
                        </p>
                      </div>
                    ))}
                    <div className="flex items-center justify-between rounded-lg bg-[#131C23]/10 px-3 py-2 text-sm">
                      <img
                        loading="lazy"
                        src="/assets/svgs/common/date.svg"
                        alt="Date"
                        className="w-4"
                      />
                      <p className="px-2 text-[#888D90]">{t("date")}:</p>
                      <p className="font-bold text-[#131C23]">{item.date} </p>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between rounded-xl border-2 border-[#131C23]/10 p-2 text-[#131C23]">
                    <p className="font-base text-base text-[#131C23]/60">
                      {t("total")}:
                    </p>
                    <span className="text-base font-bold text-black">
                      {" "}
                      {selectedCurrency.symbol}{" "}
                      {(
                        item.price_without_commission *
                        +selectedCurrency.exchange_rate
                      ).toFixed(2)}{" "}
                    </span>
                  </div>
                </div>
              </article>
            ))
          ) : (
            <>
              <div className="empty grid place-items-center pb-20">
                <img
                  loading="lazy"
                  src="/assets/images/empty.png"
                  alt="Empty data"
                />
                <h3 className="px-4 text-lg font-bold 2xl:px-7 2xl:text-xl">
                  {" "}
                  {t("shopCart.shoppingCartEmpty")}
                </h3>
                <p className="pt-4 font-medium text-[#131C23]/40">
                  {t("shopCart.fillYourCart")}
                </p>
                <button
                  className="mt-3 flex items-center justify-center rounded-full bg-[#FF913F] px-14 py-2 text-center text-white"
                  onClick={() =>
                    navigate(
                      getNextURL({
                        nextRedirect: `${selectedLanguage}/categories`,
                      }),
                    )
                  }
                >
                  <span className="px-1 font-medium">{t("exploreMore")}</span>
                  <img
                    loading="lazy"
                    src="/assets/svgs/common/white-arrow.svg"
                    className="w-4"
                    alt="Arrow"
                  />
                </button>
              </div>
            </>
          )}
        </div>
        <div>
          <OrderConfirmation
            priceWithoutFees={totalPriceWithoutFees}
            fees={totalFees}
            selectedTotal={selectedTotal}
            selectedItems={selectedItems}
          />
        </div>
      </div>
    </div>
  );
};
export default CartItems;
