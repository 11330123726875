import { atomWithStorage } from "jotai/utils";
import { TUser, TUserRole } from "../types/user";

export const userAtom = atomWithStorage<TUser>(
  "userInfo",
  {} as TUser,
  undefined,
  { getOnInit: true },
);

export const roleAtom = atomWithStorage<TUserRole>(
  "userRole",
  {} as TUserRole,
  undefined,
  { getOnInit: true },
);

export const clientTokenAtom = atomWithStorage<string>(
  "clientToken",
  "",
  undefined,
  { getOnInit: true },
);
