import axios, { AxiosResponse } from "axios";
import { ErrorStatus } from "../store/types";

// this is a boot function that will add the backend-url and has an interceptor to handle errors.
export class AxiosMiddleware {
  static boot(): void {
    // add axios default base url
    axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL + "api/";

    // check for token and set it in axios headers
    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      // config.headers["Content-Type"] = "application/json";
      return config;
    });
    axios.interceptors.response.use(
      function (response: AxiosResponse): AxiosResponse {
        return response;
      },
      function (error) {
        switch (error?.response?.status) {
          case ErrorStatus.Forbidden:
            throw error;
          case ErrorStatus["Not Found"]:
            throw error;
          case ErrorStatus["Unauthorized"]:
            // window.location.href = "/";
            // localStorage.removeItem("userRole");
            // localStorage.removeItem("userInfo");
            // localStorage.removeItem("token");
            return;
          case ErrorStatus["Under Maintenance"]:
            window.location.href = "/503";
            throw error;
          default:
            console.log("Internal server error.");
            throw error;
        }
      },
    );
  }
}
