import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { IEventsData } from "../../networking/events/types";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { likeEvent } from "../../store/events/ActionEvents";
import { getWishlistsCategories } from "../../store/Wishlist/ActionWishlist";
import { useTranslation } from "react-i18next";
import { ITypes } from "../../networking/filterByType/types";
import { useAtomValue } from "jotai";
import { currencyAtom } from "../../store/currency";

interface EventCardProps {
  event?: IEventsData;
  filters?: boolean;
  filterTabs?: boolean;
  blur?: boolean;
  selectedCurrency?: any;
  removeFromCategory?: () => void;
}
const EventCard: React.FC<EventCardProps> = ({
  event,
  filters = false,
  filterTabs = false,
  blur,
  removeFromCategory,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedCurrency = useAtomValue(currencyAtom);
  const [types, setTypes] = useState<ITypes[]>([]);
  const [convertedPrices, setConvertedPrices] = useState<number | null>(null);
  const [isFavorite, setIsFavorite] = useState(event?.liked_by_user || false);
  const auth = useAppSelector((state) => state.authReducer);
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );
  const toggleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (event) {
      const updatedLikedStatus = !isFavorite;
      setIsFavorite(updatedLikedStatus);
      dispatch(likeEvent(event.id));
      dispatch(getWishlistsCategories());
    }
  };

  // useEffect(() => {
  //     dispatch(getHomeEvents())
  // }, []);

  const content = event?.translations && event.translations[selectedLanguage];
  //   const reviewsAvgStars = event?.reviews?.length
  //     ? parseFloat(event.reviews_avg_stars.toString())
  //     : 0;

  const calculateConvertedPrices = () => {
    if (selectedCurrency && event) {
      const displayPrice = parseFloat(event.display_price);
      const exchangeRate = selectedCurrency.exchange_rate;

      if (
        !isNaN(displayPrice) &&
        !isNaN(+exchangeRate) &&
        +exchangeRate !== 0
      ) {
        const convertedPrice = displayPrice * +exchangeRate;
        setConvertedPrices(convertedPrice);
      }
    }
  };

  useEffect(() => {
    calculateConvertedPrices();
  }, [selectedCurrency || { exchange_rate: 1 }, event]);

  return (
    <article className="event-card my-4 flex h-[400px] items-center">
      <div className="hover-card flex h-full w-full flex-col justify-between rounded-3xl bg-white shadow-md">
        <div className="relative ">
          <div className="absolute left-0 top-0 flex justify-end px-3 pt-4">
            <button className="px-3 py-1 text-black">
              {/*{event?.types && event.types.length > 0 && (*/}
              {/*  <div className="">*/}
              {/*    {event.types.map((type, index) => (*/}
              {/*      <div*/}
              {/*        className="mb-3 flex items-center space-x-1 rounded-full bg-white px-1 py-1 text-xs"*/}
              {/*        key={index}*/}
              {/*      >*/}
              {/*        <img src={type.logo_path} className="w-3" />*/}
              {/*        /!*<p key={type.id}>{type.title}</p>*!/*/}
              {/*        <p key={type.id}>*/}
              {/*          {type.translations &&*/}
              {/*          type.translations[selectedLanguage] &&*/}
              {/*          type.translations[selectedLanguage].title*/}
              {/*            ? type.translations[selectedLanguage].title*/}
              {/*            : type.title}*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*    ))}*/}
              {/*  </div>*/}
              {/*)}*/}
            </button>
          </div>
          {/*<img src={event.images[0] && event.images[0].image_path} alt="event" className="h-52 w-full object-cover object-center rounded-xl"/>*/}
          <img
            loading="lazy"
            src={event?.images?.[0]?.image_path}
            alt="event"
            className="h-52 w-full rounded-xl object-cover object-center"
          />
          {/*<div className="absolute bottom-0 left-0 right-0 flex space-x-2 p-4">*/}
          {/*    <button className="rounded-full px-4 py-1 text-xs text-white backdrop-blur-xl">*/}
          {/*        {event?.max_per_group} {t("people")}*/}
          {/*    </button>*/}
          {/*</div>*/}
        </div>
        <div className="m-5 overflow-x-hidden">
          <div className="flex items-center justify-between">
            {event?.location && (
              <div className="flex items-center">
                <img
                  loading="lazy"
                  src="/assets/svgs/events/location.svg"
                  alt="icon"
                  className="h-auto w-7"
                />
                <span className="text-sm">{event.location}</span>
              </div>
            )}
            {event?.types && event.types.length > 0 && (
              <div className="flex gap-2">
                {event.types.map((type, index) => (
                  <div className="" key={index}>
                    <img src={type.logo_path} className="h-5 w-5" />
                  </div>
                ))}
              </div>
            )}
            {auth.token && auth.userRole?.id === 1 && (
              <button onClick={(e) => toggleFavorite(e)}>
                <img
                  loading="lazy"
                  src={
                    isFavorite
                      ? "/assets/svgs/events/red-favorite-icon.svg"
                      : "/assets/svgs/events/favorite-icon.svg"
                  }
                  alt={isFavorite ? "favorite" : "unFavorite"}
                />
              </button>
            )}
          </div>
          <div className="cart-title-container flex items-center gap-5 pt-4">
            <h5 className="text-md cart-title w-max font-bold">
              {content?.title}
            </h5>
          </div>
          {/*    <div className="text-md pt-3 md:flex">*/}
          {/*        {event?.reviews && event.reviews?.length > 0 ? (*/}
          {/*            <div className="flex space-x-2">*/}
          {/*                {event.reviews.map((reviewData, index) => (*/}
          {/*                    <img*/}
          {/*                        loading="lazy"*/}
          {/*                        key={index}*/}
          {/*                        src={reviewData.user.profile_pic}*/}
          {/*                        alt={`review-${index}`}*/}
          {/*                        className="aspect-square h-[30px] w-[30px] rounded-full object-cover"*/}
          {/*                    />*/}
          {/*                ))}*/}
          {/*                <span className="text-md font-bold text-black">*/}
          {/*  {event?.reviews.length}+*/}
          {/*</span>*/}
          {/*                <span className="text-md px-2 text-[#888D90]">*/}
          {/*  {t("reviews")}*/}
          {/*</span>*/}
          {/*                <img*/}
          {/*                    loading="lazy"*/}
          {/*                    src="/assets/svgs/events/star.svg"*/}
          {/*                    alt="review"*/}
          {/*                />*/}
          {/*                <span className="text-md font-bold text-black">*/}
          {/*  {reviewsAvgStars !== 0 ? reviewsAvgStars.toFixed(1) : 0}*/}
          {/*</span>*/}
          {/*            </div>*/}
          {/*        ) : (*/}
          {/*            <p>{t("noReviews")}</p>*/}
          {/*        )}*/}
          {/*    </div>*/}
          <div className="text-md flex justify-between pt-3 text-[#888D90] md:pt-7">
            <div className="flex items-center">
              {t("cost")}:{" "}
              <span className="text-md font-bold text-black">
                {event?.costType}{" "}
              </span>
              <p className="text-md px-3 font-bold text-black">
                {selectedCurrency?.abbreviation !== "USD" &&
                convertedPrices !== null
                  ? `${selectedCurrency.symbol} ${convertedPrices.toFixed(2)}`
                  : `${event?.display_price} $`}
              </p>
            </div>
            <div className="rounded-full bg-black/10">
              {event?.duration && (
                <button className="m-2 flex items-center justify-between text-xs text-[#131C23] backdrop-blur-xl">
                  <img
                    loading="lazy"
                    src="/assets/svgs/events/time.svg"
                    alt="time"
                    className="w-4"
                  />
                  {event.duration}{" "}
                  {event.duration_type === "h"
                    ? t("hours")
                    : event.duration_type === "d"
                    ? t("days")
                    : ""}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default EventCard;
