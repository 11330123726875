import { TSvgComponent } from "../../types/utils/component";

const ClockArrowSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 36 36"
      fill="#141D24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.9824 0.4366C14.2651 0.546959 13.0373 0.836655 12.2648 1.09876C9.45058 2.03683 7.47788 3.2646 5.31204 5.43043C3.14621 7.59626 1.91844 9.56896 0.980376 12.3832C-0.730218 17.515 0.0836935 23.1847 3.16 27.7923C4.04289 29.1166 6.48463 31.5584 7.80896 32.4413C10.7749 34.4278 14.334 35.5176 17.8242 35.5176C19.8935 35.5176 20.5005 35.1727 20.5005 34.0139C20.5005 33.5863 20.4177 33.4069 20.1004 33.131C19.7141 32.8137 19.5762 32.7861 17.5759 32.7172C14.9962 32.6344 13.3546 32.2481 11.3267 31.2687C7.32613 29.3098 4.45675 25.8196 3.29796 21.5155C2.96687 20.2878 2.92549 19.8877 2.92549 17.8598C2.92549 15.8319 2.96687 15.4319 3.29796 14.2041C4.71885 8.9206 8.80221 4.82344 14.0857 3.41634C15.3135 3.08526 15.6998 3.04388 17.7414 3.05767C19.7555 3.05767 20.1694 3.09906 21.3971 3.41634C23.0388 3.85779 25.4529 5.01658 26.7221 5.98223C27.8395 6.82374 29.2466 8.31361 30.0053 9.43101L30.5709 10.2587L28.3499 10.3001L26.1289 10.3415L25.7978 10.7278C25.3426 11.252 25.3426 12.0521 25.7978 12.5763L26.1289 12.9626L29.081 12.9626C31.9642 12.9626 32.0332 12.9626 32.7919 12.6039C33.7714 12.1487 34.5163 11.4037 34.9715 10.4243C35.3164 9.66553 35.3302 9.59656 35.3302 6.78235L35.3302 3.89917L34.9439 3.56809C34.4197 3.11285 33.6196 3.11285 33.0954 3.56809L32.7091 3.89917L32.6678 6.13398L32.6264 8.38259L32.1297 7.63765C31.302 6.39609 28.8465 4.05092 27.4118 3.11285C23.6733 0.712501 19.3141 -0.239361 14.9824 0.4366Z" />
      <path d="M18.545 7.47273C17.8552 7.8452 17.8552 7.94177 17.8414 13.6805L17.8414 19.0606L15.1514 21.7783C12.4751 24.4683 12.3785 24.6063 12.4613 25.4202C12.5303 26.1375 13.289 26.6341 14.1167 26.4824C14.503 26.4134 20.0762 20.9782 20.4073 20.3574C20.6694 19.8607 20.697 8.60393 20.4349 8.03833C20.1314 7.37617 19.2209 7.10026 18.545 7.47273Z" />
      <path d="M33.1096 20.1376C32.6268 20.3859 32.4612 20.6756 32.1577 21.862C31.9232 22.7863 31.9646 23.1449 32.406 23.614C32.9854 24.2485 34.158 24.1106 34.5443 23.3519C34.8202 22.8001 35.1375 21.324 35.0409 20.9791C34.8478 20.179 33.8683 19.7651 33.1096 20.1376Z" />
      <path d="M30.3653 26.3295C29.979 26.495 29.2065 27.3227 28.8202 27.9849C28.158 29.1161 29.51 30.468 30.6688 29.8196C30.8343 29.7231 31.2482 29.2816 31.593 28.8402C32.3656 27.8607 32.4207 27.3227 31.8551 26.7019C31.3861 26.2191 30.9171 26.095 30.3653 26.3295Z" />
      <path d="M24.4312 31.2126C23.3552 31.7782 23.0379 32.2196 23.1759 32.9508C23.2724 33.4888 23.6035 33.8199 24.1967 33.9992C24.5554 34.0958 24.8037 34.0268 25.7004 33.5992C26.8178 33.0612 27.1213 32.7163 27.1213 31.9851C27.1213 31.4747 26.2936 30.6884 25.7969 30.6884C25.5762 30.6884 24.9692 30.9229 24.4312 31.2126Z" />
    </svg>
  );
};

export default ClockArrowSvg;
