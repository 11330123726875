import React, {useState} from "react";
import CustomInput from "../../../../components/common/inputs/Input";
import Person2Icon from "@mui/icons-material/Person2";
import PrimaryButton from "../../../PrimaryButton";
import {useTranslation} from "react-i18next";
import {
    forgotPasswordEmail, setShowForgotPasswordContent, setShowSendEmailRequest, setShowSignInContent,
    setShowVerificationEmailContent, setShowVerificationPassword,
    toggleVerificationModal, toggleVerificationModalPassword, verifyEmail
} from "../../../../store/Auth/ActionAuth";
import {useAppDispatch, useAppSelector} from "../../../../store/redux";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import EmailIcon from "@mui/icons-material/Email";
import {UserLogin} from "../../../../store/Auth/types";
import VerificationEmailForPassword from "./VerificationEmailForPassword";

type SendRequestProps = {
    onClose?: () => void;
    onForgotPasswordClick: () => void;
    onBackClick?: () => void;
};

const SendEmailRequest: React.FC<SendRequestProps> = ({onClose, onForgotPasswordClick,onBackClick }) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const showVerificationModal = useSelector((state: RootState) => state.authReducer.showVerificationModalPassword)
    const showSignIn =useSelector((state:RootState) => state.authReducer.showSignInContent)
    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [showVerificationEmail, setShowVerificationEmail] = useState(false);

    const handleShowSignIn = () => {
        dispatch(setShowForgotPasswordContent(false))
        dispatch(setShowSignInContent(true))
    };
    const handleChangeInput = (name: string, value: string) => {
        if (name === "email") {
            setEmail(value);
            setError(null);
        }
    };
    const handleShowAuthentication = async () => {
        try {
            await dispatch(forgotPasswordEmail(email));
            localStorage.setItem('verificationEmail', email);
            setShowVerificationEmail(true);
            dispatch(toggleVerificationModalPassword(true));
            dispatch(setShowVerificationPassword(true));
        } catch (error: any) {
            if (error.response && error.response.status === 422 && error.response.data && error.response.data.email) {
                setError(error.response.data.message);
            } else {
                setError(error.response.data.message);
            }
        }
    };

    if (showVerificationModal) {
        return (
            <VerificationEmailForPassword
                onClose={() => {
                    dispatch(toggleVerificationModalPassword(false));
                    onClose && onClose();
                }}
                open={true}
            />
        );
    }

    return (
        <div className="pt-3">
            <div className="flex justify-center pb-5">
                <img loading="lazy" src="/assets/images/pages/black-logo.png" className="w-70 h-10"/>
            </div>
            <div>
                <h3 className="text-2xl font-bold ">Enter Your Email</h3>
                <CustomInput
                    onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                    }
                    name="email"
                    type="text"
                    placeholder={t('mail')}
                    icon={<EmailIcon/>}
                />
                {error && (
                    <div className="ml-2 text-[12px] text-red-600">
                        {error}
                    </div>
                )}
            </div>
            <div className="flex items-center justify-between gap-4 pt-5">
                <PrimaryButton className="gap-2" onClick={handleShowSignIn}>
                    <p>Back</p>
                    <img
                        src="/assets/svgs/common/white-arrow.svg"
                        className="w-4"
                    />
                </PrimaryButton>
                <PrimaryButton className="gap-2" onClick={handleShowAuthentication}>
                    <p>Send Request </p>
                    <img
                        src="/assets/svgs/common/white-arrow.svg"
                        className="w-4"
                    />
                </PrimaryButton>
            </div>
        </div>
    );
};
export default SendEmailRequest;
