"use client";

import { toggleModal } from "../../../utils/functions";
import Modal from "../../layout/Modal";
import { useHeaderCurrencies } from "./_hooks";

const Currencies = () => {
  const { currency, data, handleSelect, isLinked } = useHeaderCurrencies();
  return (
    <div>
      <button
        type="button"
        onClick={() => toggleModal("currencies")}
        title="Select a currency"
        className="modal-button hover-link flex items-center"
      >
        {isLinked(currency) && (
          <img
            src={currency.logo}
            alt={currency.abbreviation}
            className="mr-2 h-6 w-6 object-contain"
          />
        )}
        <p>
          {currency.abbreviation}
          <span className="text-sm">({currency.symbol})</span>
        </p>
      </button>
      <Modal
        id="currencies"
        heading={<h2 className="section-title">Select a currency</h2>}
      >
        <ul className="grid grid-cols-2 gap-10 md:grid-cols-4">
          {data?.success &&
            data.data.currencies.map((curr, index) => {
              return (
                <li key={`modal_language_${index}`}>
                  <button
                    onClick={() => handleSelect(curr)}
                    title={curr.name}
                    className="hover-link flex items-center gap-2"
                  >
                    <img
                      src={curr.logo}
                      alt={curr.name}
                      title={curr.name}
                      width={32}
                      height={32}
                      className="h-8 w-8 object-contain"
                    />
                    {curr.name}
                  </button>
                </li>
              );
            })}
        </ul>
      </Modal>
    </div>
  );
};

export default Currencies;
