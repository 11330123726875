import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IAgeType, IGenerateDates, IProfileEvents } from "./types";
import {
  AdditionalInformation,
  IAgeObject,
  eventCreationObject,
} from "../../store/BusinessDashboard/types";

export const getEventsAPI = async (): Promise<IProfileEvents> => {
  try {
    const response = await axios.get("business/profile/events");
    let data = response.data.data;
    return {
      types: data.types,
      interests: data.interests,
      ages: data.ages,
      commission: data.commission,
      categories: data.categories,
      exclusive_price: data.exclusive_price,
      promo_price: data.promo_price,
    };
  } catch (error) {
    throw error;
  }
};
export const createEventAPI = async (
  payload: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios.post(
      "business/profile/events",
      payload,
      config,
    );
    return response;
  } catch (error) {
    // Handle errors appropriately
    throw error;
  }
};

export const updateEventAPI = async (
  EventId: number,
  payload: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios.post(
      `business/profile/events/${EventId}/update`,
      payload,
      config,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const createAgeAPI = async (payload: IAgeObject): Promise<IAgeType> => {
  try {
    const response = await axios.post(
      "business/profile/events/ages",
      payload,
    );
    return {
      id: response.data.data.id,
      label: response.data.data.label,
    };
  } catch (error) {
    throw error;
  }
};

export const getEventByIdAPI = async (
  EventId: number,
): Promise<eventCreationObject> => {
  try {
    const response = await axios.get(
      `business/profile/events/${EventId}/show`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const generateStripeAccountAPI = async () => {
  try {
    const response = await axios.post(
      `business/profile/generate-stripe-account`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const successConnectionStripeAPI = async () => {
  try {
    const response = await axios.post(
      `business/profile/success-connection-stripe`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const checkStripeConnectionAPI = async () => {
  try {
    const response = await axios.get(
      `business/profile/connected-to-stripe`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const deleteEventByIdAPI = async (EventId: number) => {
  try {
    const response = await axios.delete(
      `business/profile/events/${EventId}/delete`,
    );
  } catch (error) {
    throw error;
  }
};

export const addAdditionalInformationAPI = async (
  payload: AdditionalInformation,
) => {
  try {
    const response = await axios.post(
      `business/profile/add-additional-information`,
      payload,
    );
  } catch (error) {
    throw error;
  }
};
export const getAdditionalInformationAPI = async () => {
  try {
    const response = await axios.get(
      `business/profile/additional-information`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getBalanceStatisticsAPI = async (payload: Object) => {
  try {
    const response = await axios.get(
      `business/profile/balance-statistics`,
      { params: payload },
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getEventViewsAPI = async (eventId?: number) => {
  try {
    const response = await axios.get(
      eventId
        ? `business/profile/event-views/${eventId}`
        : `business/profile/event-views`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getPaidEventsAPI = async (payload: number) => {
  try {
    const response = await axios.get(
      `business/profile/paid-events?page=${payload}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getDraftAPI = async (): Promise<eventCreationObject> => {
  try {
    const response = await axios.get(`business/profile/events/get-draft`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const saveDraftAPI = async (payload: any) => {
  try {
    const response = await axios.post(
      `business/profile/events/save-draft`,
      payload,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getGeneratedDatesAPI = async (payload: IGenerateDates) => {
  try {
    const response = await axios.post(
      `business/profile/events/generate-dates`,
      payload,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const successPromotionAPI = async (payload: string) => {
  try {
    const response = await axios.post(`success-promotion/${payload}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getAllDropListEventsAPI = async () => {
  try {
    const response = await axios.get(`business/profile/events/all-events`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getHistoryEventsAPI = async (date: string) => {
  try {
    const response = await axios.get(
      `business/profile/history-events?date=${date}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
