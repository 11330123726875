import { useTranslation } from "react-i18next";
import AwardSvg from "../../../component/SvgComponents/AwardSvg";
import BookSvg from "../../../component/SvgComponents/BookSvg";

const BecomeExplorer = () => {
  const { t } = useTranslation();
  return (
    <section id="home-become-explorer" className="section-margin container">
      <h2 className="section-heading heading-bar mb-10 font-poppins-bold text-primary-navy after:bg-primary-navy">
        {t("becomeClient")}
      </h2>
      <div className="grid cursor-none gap-6 md:grid-cols-2 xl:grid-cols-3">
        <div className="hover-link grid items-center gap-x-6 gap-y-4 rounded-3xl border bg-white p-6 duration-200 hover:bg-gray-200 sm:grid-cols-[8rem_auto]">
          <img
            loading="lazy"
            src="/assets/images/pages/home/explorer-tasks.webp"
            alt="Tasks"
            title="Tasks"
            className="h-32 w-32 rounded-full border border-white bg-white object-contain max-sm:justify-self-center"
          />
          <div className="flex flex-col justify-between gap-2 py-4">
            <div className="mb-2 flex w-fit items-center gap-2 rounded-3xl border border-primary-orange px-4 py-1.5 text-sm text-primary-orange">
              <BookSvg className="h-4 w-4" />
              <h5>{t("becomeOurClient.personalTask.slogan")}</h5>
            </div>
            <div>
              <h4 className="mb-1 text-xl font-bold">
                {t("becomeOurClient.personalTask.title")}
              </h4>
              <p className="text-sm">
                {t("becomeOurClient.personalTask.description")}
              </p>
            </div>
          </div>
        </div>
        <div className="hover-link grid items-center gap-x-6 gap-y-4 rounded-3xl border bg-[#EBF9FE] p-6 duration-200 hover:bg-gray-200 sm:grid-cols-[8rem_auto]">
          <img
            loading="lazy"
            src="/assets/images/pages/home/explorer-trophies.webp"
            alt="Trophies"
            title="Trophies"
            className="h-32 w-32 rounded-full border border-white bg-white object-contain max-sm:justify-self-center"
          />
          <div className="flex flex-col justify-between gap-2 py-4">
            <div className="mb-2 flex w-fit items-center gap-2 rounded-3xl border border-[#3FBEFF] px-4 py-1.5 text-sm text-[#3FBEFF]">
              <AwardSvg className="h-4 w-4" />
              <h5>{t("becomeOurClient.gamification.slogan")}</h5>
            </div>
            <div>
              <h4 className="mb-1 text-xl font-bold">
                {t("becomeOurClient.gamification.title")}
              </h4>
              <p className="text-sm">
                {t("becomeOurClient.gamification.description")}
              </p>
            </div>
          </div>
        </div>
        <div className="hover-link grid items-center gap-x-6 gap-y-4 rounded-3xl border bg-[#e9fbf6] p-6 duration-200 hover:bg-gray-200 sm:grid-cols-[8rem_auto]">
          <img
            loading="lazy"
            src="/assets/images/pages/home/explorer-bag.webp"
            alt="Gold bag"
            title="Gold bag"
            className="h-32 w-32 rounded-full border border-white bg-white object-contain max-sm:justify-self-center"
          />
          <div className="flex flex-col justify-between gap-2 py-4">
            <div className="mb-2 flex w-fit items-center gap-2 rounded-3xl border border-[#00d4a2] px-4 py-1.5 text-sm text-[#00d4a2]">
              <AwardSvg className="h-4 w-4 fill-[#00d4a2]" />
              <h5>{t("becomeOurClient.token.slogan")}</h5>
            </div>
            <div>
              <h4 className="mb-1 text-xl font-bold">
                {t("becomeOurClient.token.title")}
              </h4>
              <p className="text-sm">
                {t("becomeOurClient.token.description")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BecomeExplorer;
