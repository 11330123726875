import CustomMap from "../../../../components/common/map/Map";
import { TEvent } from "../../../../types/event";

type TProps = {
  name: string | null;
  events: Array<TEvent> | null;
};

const Location = ({ name, events }: TProps) => {
  if (!events || (!events[0]?.latitude && !events[0]?.longitude)) return null;
  return (
    <section id="events-map" className="section-margin container">
      <h2 className="section-heading heading-bar mb-4 font-poppins-bold text-primary-navy after:bg-primary-navy">
        Location of {name} activities
      </h2>
      <CustomMap
        center={{
          lat: parseFloat(events[0].latitude),
          lng: parseFloat(events[0].longitude),
        }}
        events={events}
      />
    </section>
  );
};

export default Location;
