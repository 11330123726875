import { Link } from "react-router-dom";

export const UnauthorizedPage = () => {
  return (
    <main id="unauthorized-content" className="min-h-screen">
      <section id="unauthorized" className="container my-40">
        <h1 className="font-poppins-bold text-3xl md:text-5xl">Unauthorized</h1>
        <h3 className="mb-6 text-2xl">
          You are not authorized to access this page.
        </h3>
        <p>
          Head back to the&nbsp;
          <Link
            className="hover-link inline-block text-primary-orange"
            title="Homepage"
            to="/"
          >
            home page
          </Link>
        </p>
      </section>
    </main>
  );
};
export default UnauthorizedPage;
