import SvgLogoCompass from "../../../assets/svgs/logo/SvgLogoCompass";
import ArrowedLink from "../../../components/common/buttons/ArrowedLink";
import HeartsCard from "../../../components/common/cards/HeartsCard";

const PartJourney = () => {
  return (
    <section id="user-part-journey" className="section-margin container">
      <div className="grid gap-10 rounded-3xl bg-gradient-to-r from-secondary-orange from-25% to-primary-orange sm:py-2 xl:grid-cols-[1fr_1.25fr]">
        <div className="relative grid sm:grid-cols-2 xl:col-start-2 xl:grid-cols-3">
          <img
            className="hidden h-full w-full -rotate-6 rounded-3xl border border-white object-cover xl:block"
            src="/assets/images/pages/dashboard/user/journey/three-kids.webp"
            alt="Poor children"
          />
          <img
            className="h-full max-h-[200px] w-full rounded-3xl border border-white object-cover sm:max-h-full sm:-translate-y-[10%] sm:rotate-12"
            src="/assets/images/pages/dashboard/user/journey/one-kid.webp"
            alt="Child eating"
          />
          <img
            className="hidden h-full w-full -rotate-12 rounded-3xl border border-white object-cover sm:block"
            src="/assets/images/pages/dashboard/user/journey/two-kids.webp"
            alt="Two little girls"
          />
          <div className="absolute bottom-0 left-1/4 translate-y-1/2 rotate-6 rounded-xl bg-white px-6 py-3.5 text-center sm:bottom-1/4 sm:left-[unset] sm:right-1/2 sm:-rotate-12">
            <p>You can make a difference</p>
          </div>
          <HeartsCard className="left-2/3 hidden rotate-6 sm:block xl:left-[30%] xl:top-1/4" />
          <div className="shadow-card absolute bottom-0 right-1/3 hidden -rotate-12 rounded-xl bg-white p-3 sm:block">
            <SvgLogoCompass className="h-9 w-9" />
          </div>
        </div>
        <div className="container content-center py-10 xl:row-start-1 xl:px-16">
          <h2 className="section-heading mb-2 font-poppins-bold text-white">
            Be a Part of&nbsp;
            <span className="text-primary-navy">Our Journey</span>
          </h2>
          <p className="mb-5 text-lg font-bold text-white">
            Be The Change! <br />
            Our Highest Ambition is to Help People
          </p>
          <ArrowedLink
            to="/crowdfunding"
            className="bg-primary-blue"
            title="Contribute"
          >
            Contribute Now
          </ArrowedLink>
        </div>
      </div>
    </section>
  );
};

export default PartJourney;
