import { TSvgComponent } from "../../../types/utils/component";

const SvgKey: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="#D0D2D3"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.8272 1.84375C12.3605 -0.614583 8.36049 -0.614583 5.91049 1.84375C4.18549 3.55208 3.66882 6.01042 4.33549 8.17708L0.418823 12.0938C0.143823 12.3771 -0.0478436 12.9354 0.0104898 13.3354L0.26049 15.1521C0.352156 15.7521 0.91049 16.3188 1.51049 16.4021L3.32716 16.6521C3.72716 16.7104 4.28549 16.5271 4.56882 16.2354L5.25216 15.5521C5.41882 15.3937 5.41882 15.1271 5.25216 14.9604L3.63549 13.3438C3.39382 13.1021 3.39382 12.7021 3.63549 12.4604C3.87716 12.2187 4.27716 12.2187 4.51882 12.4604L6.14382 14.0854C6.30216 14.2437 6.56882 14.2437 6.72716 14.0854L8.49382 12.3271C10.6522 13.0021 13.1105 12.4771 14.8272 10.7688C17.2855 8.31042 17.2855 4.30208 14.8272 1.84375ZM10.4188 8.32708C9.26882 8.32708 8.33549 7.39375 8.33549 6.24375C8.33549 5.09375 9.26882 4.16042 10.4188 4.16042C11.5688 4.16042 12.5022 5.09375 12.5022 6.24375C12.5022 7.39375 11.5688 8.32708 10.4188 8.32708Z" />
    </svg>
  );
};

export default SvgKey;
