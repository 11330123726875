import VerificationEmail from "../../component/Header/component/SignUp/VerificationEmail";
import PageLoader from "../../component/PageLoader";
import { useGetHomeData } from "./_hooks";
import CategorySelection from "./components/categories/Filters";
import PromotionEvents from "./components/PromotionEvents";
import Exclusive from "./components/Exclusive";
import BecomeExplorer from "./components/BecomeExplorer";
import Partners from "./components/Partners";
import ImportantInformation from "./components/ImportantInformation";
import BecomePartner from "./components/BecomePartner";
import Map from "./components/Map";

const HomePage = () => {
  const {
    showVerificationModal,
    pageAlreadyLoaded,
    loading,
    handleCloseVerificationModal,
  } = useGetHomeData();

  return (
    <main id="home-content">
      {!pageAlreadyLoaded && <PageLoader loading={loading} />}
      <CategorySelection />
      <Map />
      <PromotionEvents />
      <Exclusive />
      <BecomeExplorer />
      <Partners />
      <ImportantInformation />
      <BecomePartner />
      <VerificationEmail
        onClose={handleCloseVerificationModal}
        open={showVerificationModal}
      />
    </main>
  );
};
export default HomePage;
