import HeartsCard from "../../../components/common/cards/HeartsCard";
import { data } from "../data";

const BecomeUnique = () => {
  return (
    <section id="partner-unique" className="section-margin container">
      <div className="container relative rounded-2xl bg-gradient-to-r from-primary-blue to-transparent py-14 lg:px-16">
        <HeartsCard className="right-[15%] top-0 -rotate-12" />
        <h2 className="section-heading mb-6 text-primary-navy">
          How to become a unique partner
        </h2>
        <div className="grid cursor-none gap-10 xl:grid-cols-3">
          {data.uniquePartner.map((text, index) => {
            return (
              <article
                key={`partner_become_unique_${index}`}
                className="hover-link container flex flex-col items-center gap-4 rounded-xl bg-white/25 py-10 duration-200 hover:bg-white/50"
              >
                <div className="grid h-14 w-14 place-content-center rounded-full bg-[#5cb0b3] text-white">
                  <span className="text-lg">{index + 1}</span>
                </div>
                <p className="text-center font-bold">{text}</p>
              </article>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BecomeUnique;
