import { TSvgComponent } from "../../../types/utils/component";

const SvgTrash: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="#FF0013"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.5579 3.35866C15.2163 3.22533 13.8746 3.12533 12.5246 3.05033V3.04199L12.3413 1.95866C12.2163 1.19199 12.0329 0.0419922 10.0829 0.0419922H7.89959C5.95789 0.0419922 5.77456 1.14199 5.64123 1.95033L5.46623 3.01699C4.69123 3.06699 3.91623 3.11699 3.14123 3.19199L1.44123 3.35866C1.09123 3.39199 0.841228 3.70033 0.874562 4.04199C0.907895 4.38366 1.20789 4.63366 1.55789 4.60033L3.2579 4.43366C7.62459 4.00033 12.0246 4.16699 16.4413 4.60866C16.4663 4.60866 16.4829 4.60866 16.5079 4.60866C16.8246 4.60866 17.0996 4.36699 17.1329 4.04199C17.1579 3.70033 16.9079 3.39199 16.5579 3.35866Z" />
      <path d="M15.0249 5.78301C14.8249 5.57467 14.5499 5.45801 14.2666 5.45801H3.73329C3.44995 5.45801 3.16662 5.57467 2.97495 5.78301C2.78329 5.99134 2.67495 6.27467 2.69162 6.56634L3.20829 15.1163C3.29995 16.383 3.41662 17.9663 6.32495 17.9663H11.6749C14.5832 17.9663 14.7 16.3913 14.7916 15.1163L15.3082 6.57467C15.3249 6.27467 15.2166 5.99134 15.0249 5.78301ZM10.3832 13.7913H7.60825C7.26662 13.7913 6.98329 13.508 6.98329 13.1663C6.98329 12.8247 7.26662 12.5413 7.60825 12.5413H10.3832C10.7249 12.5413 11.0082 12.8247 11.0082 13.1663C11.0082 13.508 10.7249 13.7913 10.3832 13.7913ZM11.0832 10.458H6.91662C6.57495 10.458 6.29162 10.1747 6.29162 9.83301C6.29162 9.49134 6.57495 9.20801 6.91662 9.20801H11.0832C11.4249 9.20801 11.7082 9.49134 11.7082 9.83301C11.7082 10.1747 11.4249 10.458 11.0832 10.458Z" />
    </svg>
  );
};

export default SvgTrash;
