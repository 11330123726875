import { TSvgComponent } from "../../../types/utils/component";

const SvgLogoCompass: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="38.5702"
        cy="38.5702"
        r="37.8788"
        fill="white"
        stroke="black"
        strokeWidth="1.30617"
      />
      <g opacity="0.3">
        <path
          d="M37.9424 44.7942V39.2261L56.4588 57.7425L37.9424 44.7942Z"
          fill="#131C23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.6468 55.084C53.4001 55.6134 54.3162 54.6972 53.7869 53.9439L43.8213 39.6927C43.6278 39.4077 43.6278 39.0311 43.8213 38.7562L56.4336 20.708L38.3854 33.3203C38.1004 33.5138 37.7237 33.5138 37.4489 33.3203L23.1977 23.3547C22.4444 22.8253 21.5282 23.7415 22.0576 24.4948L32.0232 38.746C32.2166 39.031 32.2166 39.4077 32.0232 39.6825L22.0576 53.9338C21.9354 54.1068 21.8947 54.2799 21.9048 54.4529V54.4733C21.9557 55.0433 22.6276 55.481 23.1977 55.084L37.4489 45.1184C37.7339 44.925 38.1105 44.925 38.3854 45.1184L52.6366 55.084H52.6468Z"
          fill="url(#paint0_linear_109_10589)"
        />
        <path
          d="M19.418 20.705L37.9344 39.2215V33.6431L19.418 20.6948V20.705Z"
          fill="#131C23"
        />
      </g>
      <path
        d="M42.6306 44.5975C42.6917 44.2616 42.9564 43.9969 43.2923 43.9358L65.3207 40.0371C66.2267 39.8742 66.2267 38.5814 65.3207 38.4185L43.2923 34.5096C42.9564 34.4486 42.6917 34.1839 42.6306 33.8479L38.7217 11.8196C38.5589 10.9136 37.2661 10.9136 37.1032 11.8196L33.2044 33.8479C33.1434 34.1839 32.8787 34.4486 32.5428 34.5096L10.5144 38.4185C9.60847 38.5814 9.60847 39.8742 10.5144 40.0371L32.5428 43.9358C32.8787 43.9969 33.1434 44.2616 33.2044 44.5975L37.1032 66.6258C37.2661 67.5318 38.5589 67.5318 38.7217 66.6258L42.6306 44.5975Z"
        fill="url(#paint1_linear_109_10589)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8402 33.3231L56.8088 57.0107C57.2058 57.7334 56.4119 58.5173 55.6993 58.1203L32.0015 45.1516L19.0329 21.4538C18.6359 20.7311 19.4299 19.9473 20.1424 20.3443L43.83 33.3129L43.8402 33.3231Z"
        fill="url(#paint2_linear_109_10589)"
      />
      <path
        d="M40.6205 36.1234C42.2289 37.7317 42.2289 40.3275 40.6205 41.9155C39.0122 43.5238 36.4164 43.5238 34.8284 41.9155C33.2303 40.3071 33.2201 37.7113 34.8284 36.1234C36.4368 34.5252 39.0325 34.515 40.6205 36.1234Z"
        fill="url(#paint3_linear_109_10589)"
      />
      <path
        d="M39.6361 37.1168C40.6947 38.1754 40.6947 39.8855 39.6361 40.9442C38.5774 42.0029 36.8673 42.0029 35.8086 40.9442C34.75 39.8855 34.75 38.1754 35.8086 37.1168C36.8673 36.0581 38.5774 36.0581 39.6361 37.1168Z"
        fill="#131C23"
      />
      <defs>
        <linearGradient
          id="paint0_linear_109_10589"
          x1="39.1794"
          y1="20.708"
          x2="39.1794"
          y2="55.2469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#131C23" />
          <stop offset="1" stopColor="#7E7E7E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_109_10589"
          x1="37.9277"
          y1="7.2694"
          x2="37.9277"
          y2="71.1964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#939393" />
          <stop offset="1" stopColor="#131C23" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_109_10589"
          x1="37.9259"
          y1="20.2527"
          x2="37.9259"
          y2="58.2424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8A32" />
          <stop offset="1" stopColor="#D65009" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_109_10589"
          x1="37.7296"
          y1="34.9222"
          x2="37.7296"
          y2="43.1268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5F2900" />
          <stop offset="1" stopColor="#D66714" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgLogoCompass;
