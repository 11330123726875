import SvgGear from "../../assets/svgs/icons/SvgGear";
import SvgClipboard from "../../assets/svgs/icons/SvgClipboard";
import SvgAward from "../../assets/svgs/icons/SvgAward";
import SvgTeam from "../../assets/svgs/icons/SvgTeam";
import SvgBanner from "../../assets/svgs/icons/SvgBanner";
import SvgShuriken from "../../assets/svgs/icons/SvgShuriken";

export const userProfileSideBar = [
  {
    id: "settings",
    path: "/user-profile",
    title: "Profile",
    Svg: SvgGear,
  },
  {
    id: "history",
    path: "/history",
    title: "History",
    Svg: SvgClipboard,
  },
  {
    id: "gamification",
    path: "/gamification",
    title: "Gamification",
    Svg: SvgAward,
  },
  {
    id: "token",
    path: "/token",
    title: "Token",
    Svg: SvgShuriken,
  },
  {
    id: "referral_program",
    path: "/referral-program",
    title: "Referral Program",
    Svg: SvgTeam,
  },
  {
    id: "tickets",
    path: "/tickets",
    title: "Tickets",
    Svg: SvgBanner,
  },
];
