import TabComponent from "../../../../component/TabComponent";
import { useState } from "react";
import { TasksStatus } from "../../../../constants/dashboard/TasksStatus";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WalletIcon from "@mui/icons-material/Wallet";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TClientTask } from "../../../../types/client";

type TProps = {
  tasks: { done: Array<TClientTask>; progress: Array<TClientTask> } | undefined;
};

const Tasks = ({ tasks }: TProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabName, setTabName] = useState<"done" | "progress">("progress");
  const handleTabClicked = (item: any, value: number) => {
    setActiveTab(value);
    setTabName(item?.name);
  };
  const { t } = useTranslation();

  if (!tasks) return null;
  return (
    <section id="gamification-tasks" className="lg:col-span-2">
      <div className="mb-5 mt-10 flex items-center gap-2">
        <p className={"text-xl font-bold text-black"}>
          {t("userProfile.gamification.yourTasks.title")}
        </p>
      </div>
      <div className="w-[100%] overflow-x-auto">
        <div className="min-w-[1000px]">
          <div className="grid">
            <TabComponent
              titles={TasksStatus.map((tab) => ({
                id: tab.id,
                label: t(
                  `userProfile.gamification.yourTasks.TasksStatus.${tab.label}`,
                ),
              }))}
              activeTab={activeTab}
              handleTabClick={handleTabClicked}
            />
          </div>
          {tasks[tabName]?.length > 0 ? (
            tasks[tabName].map((task: any, index: any) => (
              <div
                key={index}
                className={
                  "mt-4 grid h-14 grid-cols-[1fr_5fr] items-center gap-3 rounded-xl px-1 " +
                  (!task.is_done ? "bg-[#ecf4ff]" : "bg-[#edf9f3]")
                }
              >
                <div
                  className={
                    "ml-8  " +
                    (task.is_done ? "text-[#42c07f]" : "text-[#3f8cff]")
                  }
                >
                  <li>{task.status}</li>
                </div>
                <div className="grid h-[90%] grid-cols-[5fr_4fr_3fr] items-center rounded-xl  bg-white px-3">
                  <div className="flex items-center">
                    <AssignmentIcon
                      fontSize={"medium"}
                      style={{ color: "#898e91" }}
                    />
                    <div className={"ml-3 text-xs text-[#9fa3a5]"}>
                      {t("userProfile.gamification.yourTasks.task")}:{" "}
                      <b className={"text-black"}>{task.description}</b>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <CalendarMonthIcon
                      fontSize={"medium"}
                      style={{ color: "#898e91" }}
                    />
                    <div className={"ml-3 text-xs text-[#9fa3a5]"}>
                      {t("yourTasks.untilDate")}:{" "}
                      <b className={"text-black"}>
                        {moment(task?.expiration_date).format("LL")}
                      </b>
                    </div>
                  </div>
                  <div
                    className={
                      "flex items-center text-end text-xs text-[#9fa3a5]"
                    }
                  >
                    <WalletIcon
                      fontSize={"medium"}
                      style={{ color: "#898e91" }}
                    />
                    <div className={"ml-3 text-xs text-[#9fa3a5]"}>
                      {t("userProfile.gamification.yourTasks.bonus")}:{" "}
                      <b className={"text-black"}>
                        {task.bonus} {t("gamification.yourTasks.tokens")}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="mt-3 font-medium">
              {t("userProfile.gamification.yourTasks.noTasks")}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
export default Tasks;
