import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const CrowdfundingSlice = createSlice({
  name: "CrowdfundingSlice",
  initialState: { isLoading: false, clientSecretKey: "", isContributionSubmitted: false, subscription:false },
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setClientSecretKey(state, action: PayloadAction<string>) {
      state.clientSecretKey = action.payload;
    },
    setContributionSubmitted(state, action: PayloadAction<boolean>) {
      state.isContributionSubmitted = action.payload;
    },
    setSubscription(state, action: PayloadAction<boolean>) {
      state.subscription = action.payload;
    }
  }
});
export const { setClientSecretKey, setLoading, setContributionSubmitted ,setSubscription} = CrowdfundingSlice.actions;

export default CrowdfundingSlice.reducer;