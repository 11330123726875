import { useTranslation } from "react-i18next";
import SvgWarning from "../../../../../../../assets/svgs/icons/SvgWarning";
import { useAtom } from "jotai";
import {
  eventCreationAgesPricesAtom,
  eventCreationDataAtom,
  eventCreationDraftAtom,
} from "../../../_atom";
import { useEffect, useState } from "react";

const Commission = () => {
  const { t } = useTranslation();
  const [data] = useAtom(eventCreationDataAtom);
  const [draft] = useAtom(eventCreationDraftAtom);
  const [prices] = useAtom(eventCreationAgesPricesAtom);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (data?.commission) {
      const tot =
        Math.min(...prices) * (1 - parseFloat(data?.commission) / 100);
      setTotal(parseFloat(tot.toFixed(2)));
    }
  }, [draft, data, prices]);
  return (
    <div className="flex flex-wrap items-center gap-20">
      <div className="flex items-center gap-2">
        <SvgWarning className="box-content h-4 w-4 rounded-xl bg-primary-orange/20 p-2.5" />
        {data?.commission && (
          <p>
            {t("businessProfile.eventCreation.basicInfo.message")}
            <span className="rounded-lg bg-white px-2 py-1">
              {parseFloat(data?.commission).toFixed(0)}%
            </span>
            &nbsp;
            {t("businessProfile.eventCreation.basicInfo.commission")}
          </p>
        )}
      </div>
      {!isNaN(total) && (
        <h5>
          Total:
          <span className="rounded-lg bg-white px-2 py-1">{total}</span>
        </h5>
      )}
    </div>
  );
};

export default Commission;
