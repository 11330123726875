import { TSvgComponent } from "../../../types/utils/component";

const SvgBell: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 15 19"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.9225 11.6787L13.0475 10.2262C12.8638 9.9025 12.6975 9.29 12.6975 8.93125V6.7175C12.6975 4.66125 11.49 2.885 9.74876 2.05375C9.29376 1.24875 8.45376 0.75 7.49126 0.75C6.53751 0.75 5.68001 1.26625 5.22501 2.08C3.51876 2.92875 2.33751 4.6875 2.33751 6.7175V8.93125C2.33751 9.29 2.17126 9.9025 1.98751 10.2175L1.10376 11.6787C0.753761 12.265 0.675011 12.9125 0.893761 13.5075C1.10376 14.0937 1.60251 14.5488 2.25001 14.7675C3.94751 15.345 5.73251 15.625 7.51751 15.625C9.30251 15.625 11.0875 15.345 12.785 14.7763C13.3975 14.575 13.87 14.1112 14.0975 13.5075C14.325 12.9037 14.2638 12.2387 13.9225 11.6787Z" />
      <path d="M9.97625 16.5088C9.60875 17.5238 8.6375 18.25 7.5 18.25C6.80875 18.25 6.12625 17.97 5.645 17.4713C5.365 17.2088 5.155 16.8587 5.0325 16.5C5.14625 16.5175 5.26 16.5262 5.3825 16.5437C5.58375 16.57 5.79375 16.5962 6.00375 16.6137C6.5025 16.6575 7.01 16.6837 7.5175 16.6837C8.01625 16.6837 8.515 16.6575 9.005 16.6137C9.18875 16.5962 9.3725 16.5875 9.5475 16.5612C9.6875 16.5437 9.8275 16.5263 9.97625 16.5088Z" />
    </svg>
  );
};

export default SvgBell;
