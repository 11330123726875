import { TSvgComponent } from "../../../types/utils/component";

const SvgCart: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36178 3.38516C6.64026 2.18562 7.71574 1.29175 9 1.29175C10.2843 1.29175 11.3597 2.18562 11.6382 3.38516C11.2547 3.37499 10.8139 3.37503 10.2903 3.37507L10.1815 3.37508H7.81854L7.70966 3.37507C7.1861 3.37503 6.74528 3.37499 6.36178 3.38516ZM5.04166 4.79957V5.66675C5.04166 6.01193 5.32149 6.29175 5.66666 6.29175C6.01184 6.29175 6.29166 6.01193 6.29166 5.66675V4.63807C6.67711 4.62601 7.16522 4.62508 7.81854 4.62508H10.1815C10.8348 4.62508 11.3229 4.62601 11.7083 4.63807V5.66675C11.7083 6.01193 11.9882 6.29175 12.3333 6.29175C12.6785 6.29175 12.9583 6.01193 12.9583 5.66675V4.79957C14.3364 5.20076 15.4337 6.25446 15.8875 7.62262C16.0545 8.126 16.1036 8.75441 16.2082 10.243C16.327 11.9326 16.3715 12.6486 16.2543 13.2167C15.9239 14.8195 14.7267 16.1038 13.1511 16.5458C12.5925 16.7025 11.8753 16.7084 10.1815 16.7084H7.81854C6.12473 16.7084 5.40745 16.7025 4.84888 16.5458C3.27328 16.1038 2.07607 14.8195 1.74567 13.2167C1.62854 12.6486 1.67295 11.9326 1.79175 10.243C1.89642 8.75441 1.94547 8.126 2.11245 7.62262C2.56629 6.25446 3.66358 5.20076 5.04166 4.79957ZM5.07237 3.50461C5.31616 1.55234 6.98167 0.0417483 9 0.041748C11.0183 0.0417483 12.6838 1.55234 12.9276 3.50462C12.9955 3.51828 13.0619 3.53339 13.1272 3.55009C14.9835 4.02427 16.4708 5.41063 17.074 7.22906C17.2969 7.90096 17.3529 8.69905 17.4475 10.0467L17.4552 10.1553L17.464 10.2807C17.5715 11.8079 17.635 12.7101 17.4786 13.4691C17.0538 15.5298 15.5145 17.1811 13.4887 17.7493C12.7426 17.9587 11.8381 17.9586 10.3072 17.9584L10.1815 17.9584H7.81854L7.6928 17.9584C6.16191 17.9586 5.25741 17.9587 4.51125 17.7493C2.48548 17.1811 0.946216 15.5298 0.521417 13.4691C0.364949 12.7101 0.428479 11.8079 0.536004 10.2807L0.544832 10.1553L0.552462 10.0467C0.647097 8.69906 0.703141 7.90096 0.926022 7.22906C1.52923 5.41063 3.0165 4.02427 4.87276 3.55009C4.93813 3.53339 5.00454 3.51828 5.07237 3.50461Z"
      />
    </svg>
  );
};

export default SvgCart;
