import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import {
  TLaravelError,
  TLaravelPagination,
  TLaravelResponse,
} from "../../../types/utils/laravel";
import { TEvent } from "../../../types/event";
import { useAtom } from "jotai";
import { clientTokenAtom } from "../../../store/user";
import { errorResponseToast, toastFallback } from "../../../utils/functions";
import EventCard from "../../../components/common/cards/EventCard";
import SvgArrow from "../../../assets/svgs/arrows/SvgArrow";
import SvgLoadingInfinity from "../../../assets/svgs/loading/SvgLoadingInfinity";
import { useMutation, useQuery } from "@tanstack/react-query";

const HistoryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [clientToken] = useAtom(clientTokenAtom);
  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<TLaravelResponse<"Payment Success">>(
        `payment/success${clientToken ? `/${clientToken}` : "/"}`,
        {
          payment_intent: searchParams.get("payment_intent"),
        },
      );
      return res.data;
    },
    onSuccess: (res) => {
      handleDelete();
      toastFallback(res.message, "success", "Payment success");
    },
    onError: (err: AxiosError<TLaravelError>) => {
      errorResponseToast(err);
      if (err.response?.data.message === "Ticket Already Paid") handleDelete();
    },
  });
  const handleDelete = () => {
    searchParams.delete("payment_intent");
    searchParams.delete("payment_intent_client_secret");
    searchParams.delete("redirect_status");
    setSearchParams((_prev) => (_prev = searchParams));
  };
  useEffect(() => {
    searchParams.get("redirect_status") === "succeeded" &&
      searchParams.get("payment_intent") &&
      mutate();
    //eslint-disable-next-line
  }, []);
  const handlePrevious = () => {
    if (data?.success && data.data.prev_page_url) {
      setPage((_prev) => (_prev = page - 1));
    }
  };
  const handleNext = () => {
    if (data?.success && data.data.next_page_url) {
      setPage((_prev) => (_prev = page + 1));
    }
  };
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery({
    queryKey: ["client-history", page],
    queryFn: async () => {
      const res = await axios.get<
        TLaravelResponse<
          TLaravelPagination<
            Array<{
              event: TEvent;
              id: number;
              event_id: number;
              paid_amount: number;
              reserved_date: string;
              reserved_time: string;
            }>
          >
        >
      >(`users/profile/history?page=${page}`);
      return res.data;
    },
  });
  return (
    <section id="history-grid" className="flex h-full w-full flex-col">
      {data?.success ? (
        data.data.data.length > 0 ? (
          <>
            <ul className="events-grid mb-6">
              {data.data.data.map(({ event }) => {
                return (
                  <li key={`client_history_event_${event.id}`}>
                    <EventCard event={event} />
                  </li>
                );
              })}
            </ul>
            {data?.success &&
              (data.data.next_page_url || data.data.prev_page_url) && (
                <div className="grid w-full grid-cols-[1fr_1.5rem_1fr] items-center gap-1">
                  <div className="flex w-full justify-end">
                    {data.data.prev_page_url && (
                      <button
                        disabled={isLoading}
                        onClick={handlePrevious}
                        type="button"
                        className="[&:not(:disabled)]:hover-link grid h-6 w-6 place-items-center rounded-lg bg-primary-orange disabled:opacity-50"
                        title="Previous page"
                      >
                        <SvgArrow className="h-3 w-3 -translate-x-px rotate-90" />
                      </button>
                    )}
                  </div>
                  <div className="grid h-6 w-6 place-items-center rounded-lg bg-primary-orange">
                    <span className="aspect-square text-xs leading-none text-white">
                      {page}
                    </span>
                  </div>
                  <div>
                    {data.data.next_page_url && (
                      <button
                        disabled={isLoading}
                        onClick={handleNext}
                        type="button"
                        className="[&:not(:disabled)]:hover-link grid h-6 w-6 place-items-center rounded-lg bg-primary-orange disabled:opacity-50"
                        title="Next page"
                      >
                        <SvgArrow className="h-3 w-3 translate-x-px -rotate-90" />
                      </button>
                    )}
                  </div>
                </div>
              )}
          </>
        ) : (
          <div className="grid h-full w-full flex-grow place-items-center">
            <p>No events yet...</p>
          </div>
        )
      ) : isLoading ? (
        <div className="my-20 flex flex-grow items-center justify-center">
          <SvgLoadingInfinity className="h-24 w-24" />
        </div>
      ) : (
        <div className="grid h-full w-full flex-grow place-items-center">
          <p className="text-red-600">Something went wrong...</p>
        </div>
      )}
    </section>
  );
};
export default HistoryPage;

// {t(`userProfile.history.historyHeader.${item.id}`)}
