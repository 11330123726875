import React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { TEventDetails, TEventReview } from "../../../store/EventDetails/types";
import { useTranslation } from "react-i18next";

type CustomerReviewsProps = {
  reviews: TEventReview[];
  customerReviews: TEventDetails;
};
const CustomerReviews: React.FC<CustomerReviewsProps> = ({
  customerReviews,
}) => {
  const { t } = useTranslation();

  return (
    <div className="my-10 rounded-3xl bg-[#131C23] md:grid md:grid-cols-2">
      <div className="rounded-3xl bg-white md:m-1 md:px-5">
        <h3 className="mb-3 flex px-3 pt-4 text-xl font-medium text-[#131C23] md:px-0">
          {t("customerReviews")}
          <img
            src="/assets/svgs/eventDetails/warning.svg"
            className="mx-3"
            alt="Warning"
          />
        </h3>
        <div className="my-8 flex justify-between rounded-2xl bg-[#FFD54E]/40 p-4 font-bold">
          <div>
            <p className="mb-5">{t("overallRating")}</p>
            {customerReviews &&
              customerReviews.average_rating !== undefined && (
                <Box
                  sx={{
                    "& > legend": { mt: 2 },
                  }}
                >
                  <Rating
                    name="read-only"
                    value={customerReviews.average_rating}
                    readOnly
                    precision={0.5}
                  />
                </Box>
              )}
            <div className="flex">
              <p className="font-medium text-[#131C23]/40">{t("basedOn")}</p>{" "}
              <span className="ml-2 font-bold">
                {customerReviews.reviews?.total} {t("reviews")}
              </span>
            </div>
          </div>
          <div className="rounded-3xl bg-white p-10">
            <p className="text-center text-3xl font-bold">
              {customerReviews.average_rating}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between gap-3 p-4">
        <h3 className="mb-3 flex text-xl font-medium text-white">
          {t("reviewSummary")}
        </h3>
        {customerReviews.calculatedRatings &&
          Object.entries(customerReviews.calculatedRatings).map(
            ([ratingTitle, ratingValue], index) => {
              const ratingPercentage = (parseFloat(ratingValue) / 5) * 100;
              return (
                <div
                  key={index}
                  className="grid grid-cols-[1fr_3fr_1fr] items-center gap-5 py-1 text-xs font-medium text-white"
                >
                  <div className="mb-1">{ratingTitle}</div>
                  <div className="h-1 w-full rounded-full bg-[#FFD54E]/20">
                    <div
                      className="h-1 rounded-full bg-[#FFD54E]"
                      style={{ width: `${ratingPercentage}%` }}
                    ></div>
                  </div>
                  <p>{ratingValue}</p>
                </div>
              );
            },
          )}
      </div>
    </div>
  );
};

export default CustomerReviews;
