import { TSvgComponent } from "../../../types/utils/component";

const SvgArrow: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 19 12"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8039 1.26715C18.1966 1.65981 18.1966 2.29644 17.8039 2.6891L9.76014 10.7328C9.36748 11.1255 8.73086 11.1255 8.3382 10.7328L0.294447 2.6891C-0.0982133 2.29644 -0.0982132 1.65981 0.294447 1.26715C0.687107 0.87449 1.32373 0.874491 1.71639 1.26715L9.04917 8.59993L16.3819 1.26715C16.7746 0.874491 17.4112 0.874491 17.8039 1.26715Z"
      />
    </svg>
  );
};

export default SvgArrow;
