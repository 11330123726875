import { atom } from "jotai";
import {
  TEventCreationData,
  TEventCreationDraft,
  TEventUpdate,
} from "../../../../types/event";
import { LatLng } from "use-places-autocomplete";

export const eventCreationDataAtom = atom<TEventCreationData | null>(null);
export const eventCreationDraftAtom = atom<TEventCreationDraft>(null);
export const eventUpdateAtom = atom<TEventUpdate | null>(null);
export const eventCreationAgesPricesAtom = atom<Array<number>>([]);
export const eventCreationImagesAtom = atom<
  Array<{ image: File | string; order: number; url?: URL | string } | null>
>([null, null, null, null]);
export const eventCreationAddressAtom = atom<LatLng | null>(null);
