import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const initialState = {
    termsAndCondition: '',
    privacyPolicy:"",
    ourMission:""
}

export const termsSLice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        setTerms(state,action: PayloadAction<string>) {
            state.termsAndCondition = action.payload;
        },
        setPrivacy(state,action: PayloadAction<string>) {
            state.privacyPolicy = action.payload;
        },
        setOurMission(state,action: PayloadAction<string>) {
            state.ourMission = action.payload;
        },
    }
})

export default termsSLice.reducer;
