import { TSvgComponent } from "../../../types/utils/component";

const SvgHexagon: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 15 17"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.9942 3.29146L8.90125 0.924375C8.1175 0.473125 6.89042 0.473125 6.10667 0.924375L1.97417 3.30729C0.335418 4.41563 0.240417 4.58187 0.240417 6.34729V10.646C0.240417 12.4115 0.335418 12.5856 2.00583 13.7098L6.09875 16.0769C6.49458 16.3065 7.00125 16.4173 7.5 16.4173C7.99875 16.4173 8.50542 16.3065 8.89333 16.0769L13.0258 13.694C14.6646 12.5856 14.7596 12.4194 14.7596 10.654V6.34729C14.7596 4.58187 14.6646 4.41563 12.9942 3.29146ZM7.5 11.0735C6.08292 11.0735 4.92708 9.91771 4.92708 8.50062C4.92708 7.08354 6.08292 5.92771 7.5 5.92771C8.91708 5.92771 10.0729 7.08354 10.0729 8.50062C10.0729 9.91771 8.91708 11.0735 7.5 11.0735Z" />
    </svg>
  );
};

export default SvgHexagon;
