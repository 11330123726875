import { TSvgComponent } from "../../../types/utils/component";

const SvgClipboard: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 16 18"
      fill="#898D91"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.95834 0.666992H6.04167C5.17501 0.666992 4.46667 1.36699 4.46667 2.23366V3.01699C4.46667 3.88366 5.16667 4.58366 6.03334 4.58366H9.95834C10.825 4.58366 11.525 3.88366 11.525 3.01699V2.23366C11.5333 1.36699 10.825 0.666992 9.95834 0.666992Z" />
      <path d="M12.3667 3.01689C12.3667 4.34189 11.2834 5.42523 9.95837 5.42523H6.0417C4.7167 5.42523 3.63337 4.34189 3.63337 3.01689C3.63337 2.55023 3.13337 2.25856 2.7167 2.47523C1.5417 3.10023 0.741699 4.34189 0.741699 5.76689V13.6086C0.741699 15.6586 2.4167 17.3336 4.4667 17.3336H11.5334C13.5834 17.3336 15.2584 15.6586 15.2584 13.6086V5.76689C15.2584 4.34189 14.4584 3.10023 13.2834 2.47523C12.8667 2.25856 12.3667 2.55023 12.3667 3.01689ZM8.3167 13.1252H4.6667C4.32503 13.1252 4.0417 12.8419 4.0417 12.5002C4.0417 12.1586 4.32503 11.8752 4.6667 11.8752H8.3167C8.65837 11.8752 8.9417 12.1586 8.9417 12.5002C8.9417 12.8419 8.65837 13.1252 8.3167 13.1252ZM10.5 9.79189H4.6667C4.32503 9.79189 4.0417 9.50856 4.0417 9.16689C4.0417 8.82523 4.32503 8.54189 4.6667 8.54189H10.5C10.8417 8.54189 11.125 8.82523 11.125 9.16689C11.125 9.50856 10.8417 9.79189 10.5 9.79189Z" />
    </svg>
  );
};

export default SvgClipboard;
