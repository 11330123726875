import Achievements from "./Achievments";
import Account from "./Account";
import Tasks from "./Tasks";
import { useGamification } from "../_hooks";

const GamificationPage = () => {
  const { data } = useGamification();
  return (
    <main role="main" className="grid gap-4 lg:grid-cols-2">
      <Account account={data?.success ? data?.data?.user_level : undefined} />
      <Achievements
        achievements={data?.success ? data?.data?.achievements : undefined}
      />
      <Tasks tasks={data?.success ? data?.data?.user_tasks : undefined} />
    </main>
  );
};
export default GamificationPage;
