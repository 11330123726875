import SvgLoadingInfinity from "../../../assets/svgs/loading/SvgLoadingInfinity";
import { useDocuments } from "./_hooks";

const DocumentsPage = () => {
  const { data, isLoading, handleChange, selected } = useDocuments();

  return (
    <section id="client-documents" className="h-full">
      {!isLoading ? (
        data?.success ? (
          data.data.length > 0 ? (
            <>
              <nav>
                <ul className="mb-4 flex flex-wrap items-center gap-4">
                  {data.data.map(({ id, label }, index) => {
                    return (
                      <li
                        key={`business_document_${id}`}
                        className={`relative grid after:mt-0.5 after:h-0.5 after:w-full after:origin-top after:rounded-t-xl after:bg-primary-orange after:duration-300 ${
                          index === selected
                            ? "after:scale-100"
                            : "after:scale-0"
                        }`}
                      >
                        <button
                          title={label}
                          type="button"
                          className={
                            index !== selected ? "hover-link" : undefined
                          }
                          onClick={(e) => handleChange(e, index)}
                        >
                          {label}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </nav>
              <div
                className="[&_h2]:[font-size:unset]"
                dangerouslySetInnerHTML={{
                  __html: data.data[selected]?.information,
                }}
              />
            </>
          ) : (
            <p>No documents</p>
          )
        ) : (
          <p>Something went wrong...</p>
        )
      ) : (
        <div className="grid h-full w-full place-items-center">
          <SvgLoadingInfinity className="h-24 w-24" />
        </div>
      )}
    </section>
  );
};

export default DocumentsPage;
