import { TSvgComponent } from "../../types/utils/component";

const HeartFilledSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6267 0.511283C10.5605 0.495856 9.45121 0.808632 8.59845 1.68815C7.73924 0.810197 6.61874 0.511225 5.58073 0.511225C3.03487 0.511225 0.486328 2.62134 0.486328 5.60546C0.486328 8.48978 2.3318 10.8959 4.16555 12.5366C5.09259 13.366 6.04343 14.0245 6.81532 14.4778C7.20105 14.7043 7.54815 14.8832 7.83069 15.0075C7.97149 15.0694 8.10293 15.1208 8.21937 15.1577C8.32157 15.1901 8.46129 15.2279 8.59963 15.2279C8.73797 15.2279 8.87769 15.1901 8.97989 15.1577C9.09633 15.1208 9.22777 15.0694 9.36857 15.0075C9.65111 14.8832 9.99821 14.7043 10.3839 14.4778C11.1558 14.0245 12.1067 13.366 13.0337 12.5366C14.8675 10.8959 16.7129 8.48978 16.7129 5.60546C16.7129 2.61474 14.1588 0.547921 11.6267 0.511283Z"
      />
    </svg>
  );
};

export default HeartFilledSvg;
