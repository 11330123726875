import ClockArrowSvg from "../../../component/SvgComponents/ClockArrowSvg";
import LogoHandsSvg from "../../../component/SvgComponents/LogoHandsSvg";

const Flexibility = () => {
  return (
    <section id="about-flexibility" className="section-margin container">
      <div className="max-lg:single-grid container grid-cols-[1fr_3fr] rounded-3xl bg-primary-navy lg:grid">
        <div className="max-lg:single-grid-item relative lg:grid lg:place-items-center max-lg:h-full max-lg:w-full">
          <img
            loading="lazy"
            src="/assets/images/pages/about/flexibility-watch.webp"
            alt="Hand watch"
            className="absolute max-w-[125px] -rotate-12 rounded-3xl border-2 border-white lg:max-w-full lg:translate-y-1/4 max-lg:-top-20"
          />
          <div className="shadow-card absolute -rotate-12 rounded-xl bg-white p-2.5 shadow-red-500 lg:-translate-y-16 lg:translate-x-24 lg:rotate-[30deg] lg:p-4 max-lg:-bottom-10 max-lg:-left-6">
            <LogoHandsSvg className="h-16 w-16 lg:h-24 lg:w-24" />
          </div>
          <div className="shadow-card absolute rotate-12 rounded-xl bg-white p-3 shadow-red-500 lg:-translate-x-20 lg:-translate-y-36 lg:-rotate-[30deg] max-lg:bottom-4 max-lg:right-0">
            <ClockArrowSvg className="h-10 w-10" />
          </div>
        </div>
        <div className="max-lg:single-grid-item flex flex-col justify-between gap-10 py-16 lg:px-20">
          <h2 className="text-4xl capitalize text-white">
            Flexibility for every travel style
          </h2>
          <p className="rounded-xl border border-white bg-primary-orange p-4 text-xl capitalize text-white">
            Enjoy the convenience of free cancellation up to 24 hours before
            your activity, allowing you to adapt to changing plans without any
            hassle.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Flexibility;
