import EventLocation from "../../../../components/common/EventLocation";
import SvgArrow from "../../../../assets/svgs/arrows/SvgArrow";
import SvgClock from "../../../../assets/svgs/icons/SvgClock";
import SvgLoadingInfinity from "../../../../assets/svgs/loading/SvgLoadingInfinity";
import { convertPrices } from "../../../../utils/functions";
import { useOrderHistory } from "../_hooks";

const History = () => {
  const {
    t,
    data,
    isLoading,
    handleNext,
    handlePrevious,
    selectedLanguage,
    selectedCurrency,
    page,
  } = useOrderHistory();
  return (
    <section id="overview-history" className="overflow-x-hidden 2xl:col-span-2">
      <h3 className="section-title mb-4">
        {t("businessProfile.overview.history.title")}
      </h3>
      <div className="overflow-x-auto">
        <table className="dashboard-table min-w-[1000px]">
          <thead>
            <tr>
              <td className="w-[10%]">
                <p>Image</p>
              </td>
              <td className="w-1/4">
                <p>Title</p>
              </td>
              <td className="w-1/4">
                <p>Location</p>
              </td>
              <td className="w-1/4 text-center">
                <p>Tags</p>
              </td>
              <td className="w-[15%] text-center">
                <p>Cost</p>
              </td>
            </tr>
          </thead>
          <tbody>
            {data?.success && data.data.data.length > 0 ? (
              data.data.data.map(({ id, event, paid_amount }) => {
                const {
                  images,
                  translations,
                  location,
                  duration,
                  duration_type,
                  types,
                } = event;
                return (
                  <tr key={`business_history_${id}`}>
                    <td className="grid place-items-center">
                      <img
                        src={images[0].image_path.toString()}
                        alt={translations[selectedLanguage].title}
                        title={translations[selectedLanguage].title}
                        className="aspect-square w-16 rounded-xl object-cover"
                      />
                    </td>
                    <td>
                      <p className="font-bold">
                        {translations[selectedLanguage].title}
                      </p>
                    </td>
                    <td>
                      <EventLocation location={location} />
                    </td>
                    <td>
                      <div className="flex flex-wrap gap-2">
                        <div className="flex w-fit items-center gap-1 rounded-3xl bg-primary-gray/25 px-4 py-1">
                          <SvgClock className="h-4 w-4" />
                          <p>{duration + " " + duration_type}</p>
                        </div>
                        {types.length > 0 &&
                          types.map((type) => {
                            return (
                              <div
                                key={`event_${id}_type_${type.id}`}
                                className="flex w-fit items-center gap-1 rounded-3xl bg-primary-gray/25 px-4 py-1"
                              >
                                <p>{type.title}</p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <p className="text-center font-bold">
                        {convertPrices(paid_amount, selectedCurrency)}
                      </p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>No events...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && (
        <div className="my-10 flex justify-center">
          <SvgLoadingInfinity className="h-8 w-8" />
        </div>
      )}
      {data?.success &&
        (data.data.next_page_url || data.data.prev_page_url) && (
          <div className="grid w-full grid-cols-[1fr_1.5rem_1fr] items-center gap-1">
            <div className="flex w-full justify-end">
              {data.data.prev_page_url && (
                <button
                  disabled={isLoading}
                  onClick={handlePrevious}
                  type="button"
                  className="[&:not(:disabled)]:hover-link grid h-6 w-6 place-items-center rounded-lg bg-primary-orange disabled:opacity-50"
                  title="Previous page"
                >
                  <SvgArrow className="h-3 w-3 -translate-x-px rotate-90" />
                </button>
              )}
            </div>
            <div className="grid h-6 w-6 place-items-center rounded-lg bg-primary-orange">
              <span className="aspect-square text-xs leading-none text-white">
                {page}
              </span>
            </div>
            <div>
              {data.data.next_page_url && (
                <button
                  disabled={isLoading}
                  onClick={handleNext}
                  type="button"
                  className="[&:not(:disabled)]:hover-link grid h-6 w-6 place-items-center rounded-lg bg-primary-orange disabled:opacity-50"
                  title="Next page"
                >
                  <SvgArrow className="h-3 w-3 translate-x-px -rotate-90" />
                </button>
              )}
            </div>
          </div>
        )}
    </section>
  );
};
export default History;
