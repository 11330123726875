import { TSvgComponent } from "../../../types/utils/component";

const SvgTeam: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="#888D90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.86666 0C3.68333 0 1.90833 1.775 1.90833 3.95833C1.90833 6.1 3.58333 7.83333 5.76666 7.90833C5.83333 7.9 5.89999 7.9 5.94999 7.90833C5.96666 7.90833 5.97499 7.90833 5.99166 7.90833C5.99999 7.90833 5.99999 7.90833 6.00833 7.90833C8.14166 7.83333 9.81666 6.1 9.82499 3.95833C9.82499 1.775 8.04999 0 5.86666 0Z" />
      <path d="M10.1 10.1273C7.775 8.57734 3.98333 8.57734 1.64167 10.1273C0.583333 10.8357 0 11.794 0 12.819C0 13.844 0.583333 14.794 1.63333 15.494C2.8 16.2773 4.33333 16.669 5.86667 16.669C7.4 16.669 8.93333 16.2773 10.1 15.494C11.15 14.7857 11.7333 13.8357 11.7333 12.8023C11.725 11.7773 11.15 10.8273 10.1 10.1273Z" />
      <path d="M15.025 4.45106C15.1583 6.06773 14.0083 7.48439 12.4167 7.67606C12.4083 7.67606 12.4083 7.67606 12.4 7.67606H12.375C12.325 7.67606 12.275 7.67606 12.2333 7.69273C11.425 7.73439 10.6833 7.47606 10.125 7.00106C10.9833 6.23439 11.475 5.08439 11.375 3.83439C11.3167 3.15939 11.0833 2.54273 10.7333 2.01773C11.05 1.85939 11.4167 1.75939 11.7917 1.72606C13.425 1.58439 14.8833 2.80106 15.025 4.45106Z" />
      <path d="M16.6917 12.1587C16.625 12.967 16.1083 13.667 15.2417 14.142C14.4083 14.6003 13.3583 14.817 12.3167 14.792C12.9167 14.2503 13.2667 13.5753 13.3333 12.8587C13.4167 11.8253 12.925 10.8337 11.9417 10.042C11.3833 9.60033 10.7333 9.25033 10.025 8.992C11.8667 8.45866 14.1833 8.817 15.6083 9.967C16.375 10.5837 16.7667 11.3587 16.6917 12.1587Z" />
    </svg>
  );
};

export default SvgTeam;
