import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { usePartners } from "../_hooks";
import { TPartnerDisplay } from "../../../types/partner";

const Carousel = () => {
  const { data, isLoading, pathname } = usePartners();
  return (
    <section id="partner-carousel" className="section-margin container">
      <h2 className="section-heading heading-bar mb-6 text-primary-navy after:bg-primary-navy">
        Our unique partners
      </h2>
      {isLoading ? (
        <div className="py-8">
          <div className="grid h-24 grid-cols-2 gap-10 overflow-y-hidden md:h-40 md:grid-cols-3 xl:grid-cols-5">
            <div className="h-24 w-full animate-pulse rounded-lg bg-primary-gray md:h-40"></div>
            <div className="h-24 w-full animate-pulse rounded-lg bg-primary-gray md:h-40"></div>
            <div className="h-24 w-full animate-pulse rounded-lg bg-primary-gray md:h-40"></div>
            <div className="h-24 w-full animate-pulse rounded-lg bg-primary-gray md:h-40"></div>
            <div className="h-24 w-full animate-pulse rounded-lg bg-primary-gray md:h-40"></div>
          </div>
        </div>
      ) : data?.success ? (
        <Swiper
          spaceBetween={40}
          breakpoints={{
            0: { slidesPerView: 2 },
            1028: { slidesPerView: 3 },
            1400: { slidesPerView: 5 },
          }}
        >
          {data?.data.map(({ id, logo, name }) => {
            return (
              <SwiperSlide key={`partners_carousel_${id}`}>
                {pathname === `/partners/${id}` ? (
                  <Partner partner={{ id, logo, name }} isPage />
                ) : (
                  <Link
                    to={`/partners/${id}`}
                    className="hover-link group inline-block w-full"
                    title={name}
                  >
                    <Partner partner={{ id, logo, name }} />
                  </Link>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <p className="text-red-600">Something went wrong...</p>
      )}
    </section>
  );
};

export default Carousel;

type TProps = {
  partner: TPartnerDisplay;
  isPage?: boolean;
};

const Partner = ({ partner, isPage = false }: TProps) => {
  const { logo, name } = partner;
  return (
    <article
      className={`grid h-24 place-content-center rounded-lg shadow-xl duration-200 group-hover:bg-primary-orange group-hover:text-white group-focus:bg-primary-orange md:h-40 ${
        isPage ? "bg-primary-orange" : "bg-white"
      }`}
    >
      <img
        src={logo}
        alt={name}
        title={name}
        className="h-16 w-full rounded-md object-contain md:h-24"
      />
    </article>
  );
};
