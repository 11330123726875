import { TSvgComponent } from "../../../types/utils/component";

const SvgEnvelope: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30667 1.875H8.33463H11.668H11.6959C12.839 1.875 13.7352 1.87499 14.4555 1.93384C15.189 1.99377 15.7949 2.11782 16.3433 2.39726C17.2449 2.85665 17.978 3.58969 18.4374 4.4913C18.7168 5.03974 18.8409 5.64566 18.9008 6.37911C18.9596 7.09938 18.9596 7.99554 18.9596 9.13859V9.13864V9.13868V9.16667V10.8333V10.8613V10.8614V10.8614C18.9596 12.0045 18.9596 12.9006 18.9008 13.6209C18.8409 14.3543 18.7168 14.9603 18.4374 15.5087C17.978 16.4103 17.2449 17.1433 16.3433 17.6027C15.7949 17.8822 15.189 18.0062 14.4555 18.0662C13.7352 18.125 12.839 18.125 11.696 18.125H11.668H8.33464H8.30665C7.16356 18.125 6.26737 18.125 5.54708 18.0662C4.81363 18.0062 4.20771 17.8822 3.65926 17.6027C2.75765 17.1433 2.02462 16.4103 1.56523 15.5087C1.28578 14.9603 1.16174 14.3543 1.10181 13.6209C1.04296 12.9006 1.04296 12.0044 1.04297 10.8613L1.04297 10.8333V9.16667L1.04297 9.1387C1.04296 7.9956 1.04296 7.0994 1.10181 6.37911C1.16174 5.64566 1.28578 5.03974 1.56523 4.4913C2.02462 3.58969 2.75765 2.85665 3.65926 2.39726C4.20771 2.11782 4.81363 1.99377 5.54708 1.93384C6.26737 1.87499 7.16357 1.875 8.30667 1.875ZM5.34734 6.14664C5.06013 5.95516 4.67209 6.03277 4.48062 6.31998C4.28915 6.60718 4.36676 6.99523 4.65396 7.1867L5.4873 7.74225L5.58427 7.80691C6.83285 8.63948 7.57587 9.13495 8.37794 9.3766C9.43625 9.69546 10.5651 9.69546 11.6234 9.3766C12.4254 9.13495 13.1685 8.63949 14.417 7.80691L14.514 7.74225L15.3473 7.1867C15.6345 6.99523 15.7122 6.60718 15.5207 6.31998C15.3292 6.03277 14.9412 5.95516 14.654 6.14664L13.8206 6.70219C12.4454 7.61901 11.8645 7.99844 11.2628 8.17975C10.4396 8.42775 9.56167 8.42775 8.73854 8.17975C8.13677 7.99844 7.5559 7.61901 6.18067 6.70219L6.1806 6.70214L5.34734 6.14664Z"
      />
    </svg>
  );
};

export default SvgEnvelope;
