import {
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

type TProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?:
    | DetailedHTMLProps<
        HTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
      >["children"]
    | true;
  labelClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  error?: string;
  containerClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
};

const CustomCheckboxInput = forwardRef<HTMLInputElement, TProps>(
  function CustomCheckboxInput(
    { label, labelClass, error, containerClass, ...props },
    ref,
  ) {
    return (
      <>
        <div className={twMerge("flex items-center gap-1.5", containerClass)}>
          <input
            id={
              props.id
                ? props.id
                : props.name
                ? `${props.name}-input`
                : undefined
            }
            {...props}
            className={twMerge(
              "rounded-md border border-primary-gray/50 px-2 py-1.5",
              props.className,
            )}
            type="checkbox"
            ref={ref}
          />
          <label
            htmlFor={
              props.id
                ? props.id
                : props.name
                ? `${props.name}-input`
                : undefined
            }
            className={twMerge(label ? "mb-1 block" : "", labelClass)}
            hidden={!!!label}
          >
            {label === true ? props.title : label}
            {label && props.required && " *"}
          </label>
        </div>
        <div
          className={`flex items-center gap-1.5 overflow-y-hidden duration-500 ${
            error ? "max-h-[100px]" : "max-h-0"
          }`}
        >
          <span className="text-sm text-red-600">{error && error}</span>
        </div>
      </>
    );
  },
);

export default CustomCheckboxInput;
