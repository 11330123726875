import Choices from "./Choices";
import Commission from "./Commission";
import Services from "./Services";

const Additional = () => {
  return (
    <>
      <Services />
      <Choices />
      <Commission />
    </>
  );
};

export default Additional;
