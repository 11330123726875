export const PageNotFound = () => {
  return (
    <main id="not-found-content">
      <section id="not-found" className="container my-40">
        <h1 className="text-center text-4xl font-bold italic text-primary-orange">
          Page Not Found
        </h1>
      </section>
    </main>
  );
};
export default PageNotFound;
