import Contribution from "./Contribution";
import { TContributer } from "../../../types/contribution";
import VulnerableCommunities from "./VulnerableCommunities";

type TProps = {
  contributors: Array<TContributer> | undefined;
};
export const SupportCommunities = ({ contributors }: TProps) => {
  return (
    <section className="container my-10 rounded-3xl bg-[#141D24] p-8">
      <div className="gap-8 md:grid md:grid-cols-[2fr_1fr]">
        <VulnerableCommunities contributors={contributors} />
        <Contribution contributors={contributors} />
      </div>
    </section>
  );
};
export default SupportCommunities;
