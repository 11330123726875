import Balance from "./Balance";
import EventSelection from "./EventSelection";
import History from "./History";

const OverviewPage = () => {
  return (
    <div className="grid gap-10 2xl:grid-cols-[1fr_2fr]">
      <Balance />
      <EventSelection />
      <History />
    </div>
  );
};
export default OverviewPage;
