import OurMissionSummary from "./components/OurMissionSummary";
import BannerImage from "../../components/section/BannerImage";

const OurMission = () => {
  return (
    <>
      <BannerImage
        id="mission"
        src="/assets/images/mission-banner.png"
        title="Our Mission"
      />
      <OurMissionSummary />
    </>
  );
};

export default OurMission;
