import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  TLaravelError,
  TLaravelPagination,
  TLaravelResponse,
} from "../../../types/utils/laravel";
import { TEvent } from "../../../types/event";
import { TDocument, TNotification } from "../../../types/business";
import { useLocation } from "react-router-dom";
import {
  errorResponseToast,
  scrollToTop,
  toastFallback,
} from "../../../utils/functions";
import { useAtom, useAtomValue } from "jotai";
import { roleAtom, userAtom } from "../../../store/user";
import { TCountry } from "../../../types/country";
import { TUser } from "../../../types/user";
import { currencyAtom } from "../../../store/currency";

export const useOrderHistory = () => {
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const { selectedLanguage } = useAppSelector((state) => state.languageReducer);
  const selectedCurrency = useAtomValue(currencyAtom);
  const { data, isLoading } = useQuery({
    queryKey: ["business-order-history", page],
    queryFn: async () => {
      const res = await axios.get<
        TLaravelResponse<
          TLaravelPagination<
            Array<{
              event: TEvent;
              id: number;
              event_id: number;
              paid_amount: number;
            }>
          >
        >
      >(`business/profile/paid-events?page=${page}`);
      return res.data;
    },
  });
  const handlePrevious = () => {
    if (data?.success && data.data.prev_page_url) {
      setPage((_prev) => (_prev = page - 1));
    }
  };
  const handleNext = () => {
    if (data?.success && data.data.next_page_url) {
      setPage((_prev) => (_prev = page + 1));
    }
  };
  return {
    data,
    isLoading,
    t,
    handlePrevious,
    handleNext,
    selectedLanguage,
    selectedCurrency,
    page,
  };
};

export const useNotifications = () => {
  const [role] = useAtom(roleAtom);
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ["business-notifications"],
    queryFn: async () => {
      if (role.name === "business") {
        const res = await axios.get<TLaravelResponse<Array<TNotification>>>(
          "business/profile/news-notifications",
        );
        return res.data;
      }
      return null;
    },
  });
  return { t, data };
};

export const useEventsTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return { t, pathname };
};

export const useEventsList = () => {
  const { pathname } = useLocation();
  let status = pathname === "/event-list/accepted" ? "open" : "pending";
  const { data, isLoading } = useQuery({
    queryKey: ["events", `business-events-list-${status}`],
    queryFn: async () => {
      if (status === "open" || status === "pending") {
        const res = await axios.get<
          TLaravelResponse<TLaravelPagination<Array<TEvent>>>
        >(`business/profile/events/list?status=${status}`);
        return res.data;
      }
      return undefined;
    },
  });
  useEffect(() => {
    scrollToTop();
  }, []);
  return { data, isLoading };
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteEvent, isPending } = useMutation({
    mutationFn: async (id: number) => {
      const res = await axios.delete<TLaravelResponse<"Event Deleted.">>(
        `business/profile/events/${id}/delete`,
      );
      return res.data;
    },
    onError: (err: AxiosError<TLaravelError>) => {
      toastFallback(err.response?.data.message);
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({ queryKey: ["events"] });
        toastFallback(res.message, "success");
      }
    },
  });
  return { deleteEvent, isPending };
};

export const useInsuranceFile = () => {
  const [user, setUser] = useAtom(userAtom);
  const { mutate, isPending, isSuccess, isIdle } = useMutation({
    mutationFn: async (file: File) => {
      const form = new FormData();
      form.append("business_insurance_url", file);
      const res = await axios.post<TLaravelResponse<TUser>>(
        "business/profile/add-insurance",
        form,
      );
      return res.data;
    },
    onError: errorResponseToast,
    onSuccess: (res) => {
      if (res.success) {
        setUser((_prev) => (_prev = res.data));
        toastFallback(res.message, "success", "Document saved");
      }
    },
  });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      mutate(e.target.files[0]);
    }
  };
  return { handleChange, isPending, isSuccess, isIdle, user };
};

export const useAddress = () => {
  const [submittable, setSubmittable] = useState(false);
  const { data: countries, isLoading: isCountriesLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<Array<TCountry>>>("countries");
      return res.data;
    },
  });
  const [user, setUser] = useAtom(userAtom);
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse<TUser>>(
        "business/profile/add-address-details",
        form,
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        setUser((_prev) => (_prev = res.data));
        toastFallback(res.message, "success");
        setSubmittable((_prev) => (_prev = false));
      }
    },
    onError: errorResponseToast,
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  const handleChange = () => {
    setSubmittable((_prev) => (_prev = true));
  };
  return {
    countries,
    isCountriesLoading,
    handleSubmit,
    user,
    submittable,
    isPending,
    handleChange,
  };
};

export const useDocuments = () => {
  const [selected, setSelected] = useState(0);
  const { data, isLoading } = useQuery({
    queryKey: ["business-documents"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<Array<TDocument>>>(
        "business/profile/documents",
      );
      return res.data;
    },
  });
  const handleChange = (e: MouseEvent<HTMLButtonElement>, index: number) => {
    setSelected((_prev) => (_prev = index));
  };
  return {
    data,
    isLoading,
    selected,
    handleChange,
  };
};
