import { TSvgComponent } from "../../../types/utils/component";

const SvgCalendar: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="#65CC93"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83325 0.666016C5.83325 0.389873 5.60939 0.166016 5.33325 0.166016C5.05711 0.166016 4.83325 0.389873 4.83325 0.666016V0.92211C4.15126 0.998742 3.58729 1.14456 3.08325 1.43557C2.39917 1.83053 1.8311 2.3986 1.43614 3.08268C1.11693 3.63557 0.972411 4.26056 0.90228 5.03434C0.833246 5.79603 0.833249 6.74932 0.833252 7.97412V7.99935V8.02458C0.833249 9.24938 0.833246 10.2027 0.90228 10.9644C0.972411 11.7381 1.11693 12.3631 1.43614 12.916C1.8311 13.6001 2.39917 14.1682 3.08325 14.5631C3.63614 14.8823 4.26113 15.0269 5.03491 15.097C5.7966 15.166 6.74988 15.166 7.97467 15.166H7.97471H7.99992H8.02513H8.02516C9.24996 15.166 10.2032 15.166 10.9649 15.097C11.7387 15.0269 12.3637 14.8823 12.9166 14.5631C13.6007 14.1682 14.1687 13.6001 14.5637 12.916C14.8829 12.3631 15.0274 11.7381 15.0976 10.9644C15.1666 10.2027 15.1666 9.24939 15.1666 8.0246V8.02456V7.99935V7.97414V7.9741C15.1666 6.74931 15.1666 5.79603 15.0976 5.03434C15.0274 4.26056 14.8829 3.63557 14.5637 3.08268C14.1687 2.3986 13.6007 1.83053 12.9166 1.43557C12.4126 1.14456 11.8486 0.998742 11.1666 0.92211V0.666016C11.1666 0.389873 10.9427 0.166016 10.6666 0.166016C10.3904 0.166016 10.1666 0.389873 10.1666 0.666016V0.854183C9.56095 0.832678 8.85544 0.83268 8.02515 0.832682H8.02515H7.99992H7.97469C7.1444 0.83268 6.43889 0.832678 5.83325 0.854183V0.666016ZM4.16654 5.33301C4.16654 5.05687 4.3904 4.83301 4.66654 4.83301H11.3332C11.6094 4.83301 11.8332 5.05687 11.8332 5.33301C11.8332 5.60915 11.6094 5.83301 11.3332 5.83301H4.66654C4.3904 5.83301 4.16654 5.60915 4.16654 5.33301Z"
      />
    </svg>
  );
};

export default SvgCalendar;
