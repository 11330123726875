import { useTranslation } from "react-i18next";
const Token = () => {
  const { t } = useTranslation();
  return (
    <div className="empty grid place-items-center pb-20">
      <img loading="lazy" src="/assets/images/empty.png" alt="Empty Wishlist" />
      <h3 className="px-4 text-lg font-bold text-primary-orange 2xl:px-7 2xl:text-xl">
        {t("userProfile.token.comingSoon")}
      </h3>
    </div>
  );
};
export default Token;
