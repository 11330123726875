import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoSvg from "../../../component/SvgComponents/LogoSvg";
import { partnersData } from "../../../constants/footer/partners";

export const Identity = () => {
  const { t } = useTranslation();
  return (
    <section className="footer-identity">
      <Link to="/" title="Home" className="hover-link mb-2 inline-block">
        <LogoSvg className="h-auto w-40 fill-white" />
      </Link>
      <p className="mb-4 text-primary-gray">{t("footer.title")}</p>
      <h3 className="mb-2 text-lg text-white">{t("footer.partners")}</h3>
      <div className="flex flex-wrap gap-2">
        {partnersData.map((partners) => {
          return (
            <li
              key={`footer_partners_${partners.name}`}
              className="hover-link w-max cursor-pointer list-none uppercase transition-transform duration-500"
            >
              <Link
                to={partners.path}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="grid h-max rounded-full bg-white px-3 py-1"
              >
                <img
                  loading="lazy"
                  src={partners.image}
                  alt={"partners " + partners.name}
                  title={"partners " + partners.name}
                  className="h-6"
                />
              </Link>
            </li>
          );
        })}
      </div>
    </section>
  );
};

export default Identity;
