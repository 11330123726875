import {
  ComponentProps,
  DetailedHTMLProps,
  ForwardedRef,
  HTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import Select from "react-select";
import { GroupBase, StylesConfig } from "react-select";
import { twMerge } from "tailwind-merge";

type TProps = ComponentProps<typeof Select> & {
  label?:
    | DetailedHTMLProps<
        LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
      >["children"]
    | true;
  labelClass?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >["className"];
  containerClass?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["className"];
  error?: string;
  ref?: ForwardedRef<typeof Select>;
};

const CustomSelect = ({
  label,
  labelClass,
  containerClass,
  error,
  ...props
}: TProps) => {
  const style: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    placeholder: (base) => ({ ...base, color: "#898D91" }),
    input: (base) => ({
      ...base,
      paddingBlock: 0,
      margin: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingRight: "0.75rem",
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: state.isMulti ? "0.6rem" : "0 1rem",
      borderTopLeftRadius: "1rem",
      borderBottomLeftRadius: "1rem",
      gap: "0.5rem 0",
      // flexWrap: "nowrap",
      // overflowX: "auto",
    }),
    control: (base, state) => ({
      ...base,
      border: 0,
      transition: "box-shadow linear 0.5s",
      boxShadow: state.isFocused ? "0 0 1px 1px #FF913F" : "none",
      borderRadius: "1rem",
    }),
    option: (base, state) => ({
      ...base,
      transition: "background-color 1s, color 0.25s",
      backgroundColor:
        state.isSelected || state.isFocused ? "#FF913F" : "white",
    }),
    multiValue: (base) => ({
      ...base,
      marginBlock: 0,
      marginInline: 2,
      padding: 0,
      minWidth: "unset",
    }),
    multiValueLabel: (base) => ({
      ...base,
      textWrap: "wrap",
      padding: 0,
    }),
    ...props.styles,
  };
  return (
    <div className={containerClass}>
      {label && (
        <label
          hidden={!!!label}
          htmlFor={props.id ? `react-select-${props.id}-input` : undefined}
          className={twMerge("mb-0.5 block", labelClass)}
        >
          {label && label}
          {props.required && label && " *"}
        </label>
      )}
      <Select instanceId={props.id} {...props} styles={{ ...style }} />
    </div>
  );
};

export default CustomSelect;
