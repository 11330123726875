import React from "react";
import { setActiveTab } from "../../../store/Auth/ActionAuth";
import { useAppDispatch } from "../../../store/redux";
import {useTranslation} from "react-i18next";

type AuthTabsProps = {
    activeTab: string,
};
const AuthTabs: React.FC<AuthTabsProps> = ({ activeTab }) => {
   
    const dispatch = useAppDispatch()
   const {t}=useTranslation()
    return (
        <div className="grid grid-cols-2 text-center mb-4 text-md text-[#131C23]/50 border-b-2 border-[#131C23]/10">
            <div
                className={`cursor-pointer ${
                    activeTab === "client" ? "text-[#131C23] font-bold" : ""
                }`}
                onClick={() => dispatch(setActiveTab("client"))}
            >
                {t('client')}
                {activeTab === "client" && (
                    <div
                        className={`cursor-pointer mt-1 ${
                            activeTab === "client"
                                ? "text-[#131C23] font-bold h-1 w-full bg-[#FF913F] rounded-t-[50px]"
                                : "h-1 w-full bg-transparent rounded-t-2xl"
                        }`}
                    ></div>
                )}
            </div>

            <div
                className={`cursor-pointer ${
                    activeTab === "business" ? "text-[#131C23] font-bold" : ""
                }`}
                onClick={() =>dispatch(setActiveTab("business"))}
            >
                {t('business')}
                {activeTab === "business" && (
                    <div
                        className={`cursor-pointer mt-1 ${
                            activeTab === "business"
                                ? "text-[#131C23] font-bold h-1 w-full bg-[#FF913F] rounded-t-[50px]"
                                : "h-1 w-full bg-transparent rounded-t-2xl"
                        }`}
                    ></div>
                )}
            </div>
        </div>
    );
};
export default AuthTabs;
