import PaymentDetails from "./SecondaryPay";
import StripeAccount from "./Stripe";
import Details from "../../common/Details";
import ChangePassword from "../../common/ChangePassword";
import Notifications from "../../common/Notifications";
import ProfileImage from "../../common/ProfileImage";
import BusinessInsurance from "./Insurance";
import Address from "./Address";

const BusinessProfilePage = () => {
  return (
    <>
      <div className="grid gap-4 lg:grid-cols-[1fr_2fr]">
        <div className="flex flex-col gap-4">
          <Details />
          <BusinessInsurance />
        </div>
        <div className="grid gap-4 sm:grid-cols-2">
          <Notifications />
          <ChangePassword />
          <Address />
          <ProfileImage />
          <StripeAccount />
        </div>
        <PaymentDetails />
      </div>
    </>
  );
};
export default BusinessProfilePage;
