import { TSvgComponent } from "../../types/utils/component";

const HeartsSvg: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 37 33"
      fill="#FF585F"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.7055 26.6579C19.7171 26.6579 28.8211 21.6003 28.8211 14.5195C28.8211 10.9792 25.7864 8.49427 22.7517 8.45036C21.2344 8.42841 19.7171 8.95615 18.7055 10.4734C17.6939 8.95615 16.1503 8.45036 14.6592 8.45036C11.6245 8.45036 8.58984 10.9792 8.58984 14.5195C8.58984 21.6003 17.6939 26.6579 18.7055 26.6579Z" />
      <g clipPath="url(#clip0_109_10329)">
        <path d="M7.79036 29.415C8.07614 29.2305 9.72556 26.141 8.43398 24.1406C7.7882 23.1405 6.47761 22.992 5.61228 23.5332C5.17961 23.8037 4.84722 24.2296 4.83821 24.8428C4.27567 24.5986 3.74733 24.7373 3.32609 25.0093C2.46877 25.5628 2.07272 26.8308 2.71851 27.831C4.01009 29.8313 7.50459 29.5995 7.79036 29.415Z" />
      </g>
      <g clipPath="url(#clip1_109_10329)">
        <path d="M30.1253 8.75484C30.4336 8.89851 33.927 8.6499 34.9326 6.4916C35.4354 5.41246 34.8633 4.22402 33.9446 3.77963C33.4852 3.55743 32.9477 3.50279 32.4239 3.82161C32.331 3.21545 31.9324 2.84205 31.4779 2.63028C30.5529 2.19927 29.2687 2.53909 28.7659 3.61823C27.7602 5.77653 29.8169 8.61117 30.1253 8.75484Z" />
      </g>
      <defs>
        <clipPath id="clip0_109_10329">
          <rect
            width="8.16399"
            height="8.16399"
            fill="white"
            transform="translate(0.486328 25.6279) rotate(-32.8493)"
          />
        </clipPath>
        <clipPath id="clip1_109_10329">
          <rect
            width="8.16399"
            height="8.16399"
            fill="white"
            transform="translate(29.4424 0.556152) rotate(24.9829)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeartsSvg;
