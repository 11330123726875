import { Dispatch } from "react";
import CustomInput from "../../../../../../../components/common/inputs/NewInput";
import CustomTextArea from "../../../../../../../components/common/inputs/TextArea";
import SvgChecked from "../../../../../../../assets/svgs/icons/SvgChecked";
import SvgEdit from "../../../../../../../assets/svgs/icons/SvgEdit";
import SvgTrash from "../../../../../../../assets/svgs/icons/SvgTrash";
import {
  useEventCreationAboutStop,
  useEventCreationAboutStops,
} from "../../../_hooks";
import { TEventMultiInformation } from "../../../../../../../types/event";
import { SetStateAction } from "jotai";

const Multi = () => {
  const { handleSave, multi, setMulti } = useEventCreationAboutStops();
  return (
    <>
      <div className="mb-6 grid gap-2">
        <CustomInput
          id="multi-title"
          placeholder="Location Title"
          className="w-fit"
        />
        <CustomTextArea id="multi-description" placeholder="Description" />
        <button
          type="button"
          title="Save"
          className="hover-link ml-auto text-green-500"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      {multi.length > 0 && (
        <ul className="grid gap-4">
          {multi.map((_, index) => {
            return (
              <li key={`faq_${index}`}>
                <Stop index={index} multi={multi} setMulti={setMulti} />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Multi;

type TStop = {
  index: number;
  multi: TEventMultiInformation[];
  setMulti: Dispatch<SetStateAction<TEventMultiInformation[]>>;
};

const Stop = ({ index, multi, setMulti }: TStop) => {
  const {
    handleDelete,
    handleEdit,
    handleInputChange,
    handleTextAreaChange,
    isEditing,
  } = useEventCreationAboutStop(multi, setMulti, index);
  return (
    <article className="grid gap-2 rounded-xl bg-primary-gray/25 p-4 shadow-md">
      <div className="flex flex-wrap-reverse items-center justify-between">
        <CustomInput
          type="text"
          required
          tabIndex={isEditing ? 0 : -1}
          readOnly={!isEditing}
          name={`multi_information[${index}][location_title]`}
          value={multi[index]?.location_title}
          onChange={handleInputChange}
          className={
            isEditing
              ? undefined
              : "rounded-none bg-transparent p-0 font-bold focus:shadow-none focus-visible:shadow-none"
          }
        />
        <div className="flex items-center gap-2">
          <button
            title={isEditing ? "Save" : "Edit"}
            type="button"
            className="hover-link"
            onClick={handleEdit}
          >
            {isEditing ? (
              <SvgChecked className="h-5 w-5" />
            ) : (
              <SvgEdit className="h-5 w-5" />
            )}
          </button>
          <button
            title="Delete"
            type="button"
            className="hover-link"
            onClick={handleDelete}
          >
            <SvgTrash className="h-5 w-5" />
          </button>
        </div>
      </div>
      <CustomTextArea
        required
        tabIndex={isEditing ? 0 : -1}
        readOnly={!isEditing}
        name={`multi_information[${index}][description]`}
        value={multi[index]?.description}
        onChange={handleTextAreaChange}
        className={
          isEditing
            ? undefined
            : "h-fit rounded-none bg-transparent p-0 focus:shadow-none focus-visible:shadow-none"
        }
      />
    </article>
  );
};
