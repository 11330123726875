import CustomInput from "../../../../components/common/inputs/Input";
import { Button, styled, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LinkIcon from "@mui/icons-material/Link";
import { useEffect, useRef, useState } from "react";
import { setCreateEventData } from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useAppDispatch } from "../../../../store/redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BusinessDashboardSlice } from "../../../../store/BusinessDashboard/BusinessDashboardSlice";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";
import { Cropper, CropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
type ImageObject = {
  order: number;
  image: any;
  link: string | undefined;
};

const ImageSection = () => {
  const [largeImage] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const fieldsError = useSelector(
    (state: RootState) => state.businessDashboardReducer?.errors,
  );
  const isLoading = useSelector(
    (state: RootState) => state.globalStateReducer.isLoading,
  );
  const eventData = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );
  const [imageList, setImageList] = useState<ImageObject[]>([
    { order: 1, image: "", link: "" },
    { order: 2, image: "", link: "" },
    { order: 3, image: "", link: "" },
    { order: 4, image: "", link: "" },
  ]);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [imageIndex, setImageIndex] = useState<number>(0);
  const cropperRef = useRef<CropperRef>(null);
  const [aspectRatio, setAspectRatio] = useState<any>({});

  const handleRemoveErrorKey = (name: string[]) => {
    let errorfields = { ...fieldsError };
    name?.forEach((key) => {
      delete errorfields[key];
    });

    dispatch(BusinessDashboardSlice.actions.setErrors(errorfields));
  };

  const handleCreateImage = (event: any, index: number) => {
    const file = event?.target?.files?.[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      handleRemoveErrorKey(
        fieldsError["images"] ? ["images"] : [`images.${index}.image`],
      );

      setSelectedImage(imageUrl);
      setShowImageModal(true);
      setImageIndex(index);
      return imageUrl;
    }
  };
  const handleAddRow = () => {
    if (imageList.length < 12) {
      setImageList((prevList) => [
        ...prevList,
        { order: prevList.length + 1, image: "", link: "" },
      ]);
    }
  };

  const handleRemoveRow = () => {
    if (imageList.length > 4) {
      setImageList((prevList) => prevList.slice(0, -1));
    }
  };
  const handleRemoveImage = (index: number) => {
    let array = [...imageList];
    array[index] = {
      ...array[index],
      image: "",
      link: "",
    };
    setImageList(array);
    dispatch(setCreateEventData("images", array));
  };

  function base64ToWebPFile(base64Data: string, filename: string): File | null {
    try {
      const base64DataWithoutPrefix = base64Data.split(",")[1];
      const trimmedBase64Data = base64DataWithoutPrefix.trim();
      const byteCharacters = atob(trimmedBase64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/webp" }); // Specify 'image/webp' MIME type for WebP
      const file = new File([blob], filename, { type: "image/webp" }); // Specify 'image/webp' type for WebP
      return file;
    } catch (error) {
      console.error("Error decoding base64:", error);
      return null;
    }
  }
  const onCrop = () => {
    if (cropperRef.current) {
      let array = [...imageList];
      let file = base64ToWebPFile(
        cropperRef.current.getCanvas()?.toDataURL() ?? "",
        `image${imageIndex}`,
      );
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        array[imageIndex] = {
          ...array[imageIndex],
          image: file,
          link: imageUrl,
        };
      }
      setImageList(array);
      dispatch(setCreateEventData("images", array));
      setShowImageModal(false);
      setSelectedImage("");
      setImageIndex(0);
      setAspectRatio({
        width: 200,
        height: 200,
      });
    }
  };

  useEffect(() => {
    if (params.eventId) {
      setImageList(
        eventData["images"]?.map((item) => {
          return {
            order: item.order,
            image: item.image,
            link: item.image,
          };
        }),
      );
    } else {
      setImageList([
        { order: 1, image: "", link: "" },
        { order: 2, image: "", link: "" },
        { order: 3, image: "", link: "" },
        { order: 4, image: "", link: "" },
      ]);
    }
    //eslint-disable-next-line
  }, [isLoading, params]);

  return (
    <div className={"mb-4 mt-11 rounded-lg bg-[#f3f3f4] p-5"}>
      <p className="mb-4 flex items-center gap-3 text-xl font-bold">
        {t("businessProfile.eventCreation.imageSection.title")}
        <span className={"text-base font-medium text-primary-orange"}>
          {t("businessProfile.eventCreation.imageSection.infoMessage")}
        </span>
      </p>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
        {imageList?.map((item, index) =>
          item.link === "" ? (
            <div
              key={"uploaded_image_" + index}
              className="flex h-full flex-col gap-2"
            >
              <Button
                key={index}
                href="#file-upload"
                component="label"
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  height: "200px",
                  borderRadius: "25px",
                }}
              >
                <AddCircleOutlineIcon sx={{ fill: "#22272F" }} />
                <VisuallyHiddenInput
                  value={item.image}
                  name={"profile_pict_" + index}
                  onChange={(e: any) => handleCreateImage(e, index)}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
              </Button>
              {index < 4 && fieldsError[`images.${index}.image`] && (
                <span className="ml-2 text-[12px] text-red-600">
                  {fieldsError[`images.${index}.image`]}
                </span>
              )}
            </div>
          ) : (
            <>
              {item.link && (
                <div className={"flex flex-col items-center"}>
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white",
                      color: "white",
                      borderRadius: "25px",
                    }}
                  >
                    <img
                      loading="lazy"
                      className="h-full w-full rounded-[25px] object-cover object-center"
                      alt=""
                      src={item.link}
                    />
                    <div className="absolute  right-[43%] top-[46%] ">
                      <DeleteForeverIcon
                        onClick={() => handleRemoveImage(index)}
                        className="cursor-pointer rounded-full  bg-white p-1"
                        sx={{ fill: "#FF585F", width: "30px", height: "30px" }}
                      />
                    </div>
                  </div>
                  {fieldsError[`images.${index}.image`] && (
                    <span className="ml-2 text-[12px] text-red-600">
                      {fieldsError[`images.${index}.image`]}
                    </span>
                  )}
                </div>
              )}
            </>
          ),
        )}
      </div>
      <div className="flex justify-between pt-2">
        <Button onClick={handleAddRow}>
          <AddCircleOutlineIcon />
          Add Image
        </Button>
        <div className="text-red-500">
          <Button onClick={handleRemoveRow}>
            <RemoveCircleOutlineIcon />
            Remove Image
          </Button>
        </div>
      </div>
      {fieldsError["images"] && fieldsError["images"].length > 0 && (
        <span className="ml-2 text-[12px] text-red-600">
          {t("businessProfile.eventCreation.imageSection.requiredImages")}
          {t("businessProfile.eventCreation.imageSection.requiredImages")}
        </span>
      )}
      {largeImage && (
        <span className="ml-2 text-[12px] text-red-600">
          One of the images is larger than 3.5MB
        </span>
      )}
      <p className="mt-5 flex items-center gap-3 text-xl font-bold">
        {`${t("businessProfile.eventCreation.imageSection.videoLink")}`}
        <Tooltip
          title={`${t(
            "businessProfile.eventCreation.imageSection.videoInfoMessage",
          )} https://total.wpexplorer.com/docs/get-embed-urllink-youtube-video/`}
        >
          <InfoIcon className="!h-[20px] !w-[20px] cursor-pointer" />
        </Tooltip>
        <span className={"w-1/2 text-base font-medium text-primary-orange"}>
          {t("businessProfile.eventCreation.imageSection.videoInfoLink")}
        </span>
      </p>
      <div className="mt-5 w-full lg:w-1/2">
        <CustomInput
          inputBgColor="bg-white"
          name="link"
          type="text"
          value={eventData["video_url"]}
          placeholder={t("businessProfile.eventCreation.imageSection.link")}
          onChange={(e) => {
            dispatch(setCreateEventData("video_url", e.target.value));
          }}
          icon={<LinkIcon sx={{ fill: "#D0D2D3" }} />}
        />
      </div>
      {eventData["video_url"] && (
        <div className="w-1/2 rounded-lg  bg-white">
          <iframe
            title="youtube video"
            src={eventData["video_url"]}
            className="col-start-1 row-span-2 max-h-[500px] min-h-[350px] rounded-xl md:row-span-1"
          />
        </div>
      )}

      {fieldsError["video_url"] && (
        <span className="ml-2 text-[12px] text-red-600">
          {fieldsError["video_url"][0]}
        </span>
      )}

      <Modal
        open={showImageModal}
        onClose={() => {
          setShowImageModal(false);
          setSelectedImage("");
          setImageIndex(0);
        }}
        aria-labelledby="modal-modal-cropImage"
        aria-describedby="modal-modal-cropImageDescription"
      >
        <div className="flex flex-col bg-white">
          <Cropper
            className="!max-h-[330px] !max-w-[330px] sm:!max-h-[500px] sm:!max-w-[500px]"
            defaultCoordinates={{
              width: 200,
              height: 200,
            }}
            onResize={(env) => setAspectRatio(env.getCoordinates())}
            ref={cropperRef}
            src={selectedImage}
          />
          <div>
            <div className="mx-2 my-2 flex flex-col gap-1">
              <p className="text-center font-light text-gray-500">
                {t(
                  "businessProfile.eventCreation.imageSection.recommendedSize",
                )}{" "}
                <span>500x500</span>
              </p>
              <div className="h-[0.5px] bg-gray-300"></div>
              <div className="mt-2 flex justify-around">
                <p className="text-primary-orange">
                  {t("width")}
                  <span className="ml-1 text-black">
                    {aspectRatio?.width ?? 200} px
                  </span>
                </p>
                <span className="w-[1px] bg-primary-orange"></span>
                <p className="text-primary-orange">
                  {t("height")}
                  <span className="ml-1 text-black">
                    {aspectRatio?.height ?? 200} px
                  </span>
                </p>
              </div>
              <div className="mt-2 h-[0.5px] bg-gray-300"></div>
            </div>
            <div className="mx-2 my-2 flex justify-end gap-10">
              <button
                onClick={() => {
                  setShowImageModal(false);
                  setSelectedImage("");
                  setImageIndex(0);
                }}
                className="w-1/2 rounded-lg bg-gray-300 py-2"
              >
                Cancel
              </button>
              <button
                onClick={onCrop}
                className="w-1/2 rounded-lg bg-primary-orange py-2 text-white"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ImageSection;
