import HeartsCard from "../../../components/common/cards/HeartsCard";

const RapidlyGrowing = () => {
  return (
    <section id="about-rapidly-growing" className="single-grid section-margin">
      <div className="single-grid-item container relative grid place-items-center">
        <img
          src="/assets/images/pages/about/rapidly-growing-background.webp"
          alt="Flight over the world"
          className="brightness-200 contrast-75"
        />
        <HeartsCard className="right-14 top-14 -rotate-[30deg] lg:right-[15%] lg:top-[20%]" />
        <HeartsCard className="bottom-14 left-14 rotate-[30deg] lg:bottom-[20%] lg:left-[15%]" />
      </div>
      <div className="lg:single-grid-item conatiner container z-10 my-10 text-center">
        <h4 className="text-xl md:text-2xl">
          Rapidly growing online travel agency,
        </h4>
        <h2 className="">shaking up the world of travel!</h2>
      </div>
    </section>
  );
};

export default RapidlyGrowing;
