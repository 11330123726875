import { Link } from "react-router-dom";
import ArrowedButton from "../../components/common/buttons/ArrowedButton";
import { useDashboardSidebar } from "./_hooks";
import { useNotifications } from "../../pages/Dashboard/BusinessProfile/_hooks";
import SvgX from "../../assets/svgs/icons/SvgX";
import SvgBurger from "../../assets/svgs/icons/SvgBurger";

type props = {
  type: "business" | "user";
};
const DashboardSideBar = ({ type }: props) => {
  const {
    t,
    handleLogout,
    navigationLinks,
    isPath,
    openMenu,
    handleClose,
    handleOpen,
  } = useDashboardSidebar(type);
  const { data } = useNotifications();
  return (
    <aside className="rounded-3xl bg-white py-5 shadow-lg">
      <div className="container flex items-center gap-2">
        <button
          type="button"
          title={openMenu ? "Close menu" : "Open menu"}
          className="w-fit xl:hidden"
          onClick={openMenu ? handleClose : handleOpen}
        >
          {openMenu ? (
            <SvgX className="h-8 w-8 scale-75" />
          ) : (
            <SvgBurger className="h-8 w-8" />
          )}
        </button>
        <h2 className="section-title">Profile Management</h2>
      </div>
      <nav
        className={`grid duration-300 xl:grid-rows-1 ${
          openMenu ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        }`}
      >
        <ul
          className={`grid gap-8 overflow-y-hidden duration-300 xl:my-8 xl:overflow-y-visible ${
            openMenu ? "mt-8" : "mt-0"
          }`}
        >
          {navigationLinks.map(({ id, path, Svg, title }, index) => {
            return (
              <li
                key={`user_navigation_${index}`}
                className={
                  isPath(path)
                    ? "relative after:absolute after:top-0 after:h-full after:w-1 after:rounded-r-md after:bg-primary-orange"
                    : undefined
                }
              >
                {isPath(path) ? (
                  <div className="container flex items-center gap-3 text-primary-orange">
                    <Svg className="h-6 w-6 fill-primary-orange" />
                    <p>{t(`dashboardSideBar.sidebar.${id}`)}</p>
                    {id === "notifications" && data?.success && (
                      <span className="ml-auto aspect-square h-5 w-5 content-center rounded-full bg-blue-700 text-center text-xs text-white">
                        {data.data.length}
                      </span>
                    )}
                  </div>
                ) : (
                  <Link
                    to={path}
                    title={title}
                    className="hover-link container flex items-center gap-3"
                  >
                    <Svg className="h-6 w-6 fill-primary-gray" />
                    {t(`dashboardSideBar.sidebar.${id}`)}
                    {id === "notifications" && data?.success && (
                      <span className="ml-auto aspect-square h-5 w-5 content-center rounded-full bg-blue-700 text-center text-xs text-white">
                        {data.data.length}
                      </span>
                    )}
                  </Link>
                )}
              </li>
            );
          })}
          <li className="container mt-6">
            <ArrowedButton
              title="Log out"
              className="border border-red-600 bg-red-100"
              onClick={handleLogout}
            >
              {t("dashboardSideBar.logout")}
            </ArrowedButton>
          </li>
        </ul>
      </nav>
    </aside>
  );
};
export default DashboardSideBar;
