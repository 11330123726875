import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalState, ICountry } from "./type";

export const initialState: GlobalState = {
    isLoading: false,
    showBackDrop:false,
    showButtonLoader:false,
    severity: 'success',
    country:[]
}

export const GlobalStateSLice = createSlice({
    name: 'GlobalState',
    initialState,
    reducers: {
        // todo : add your reducers to change and manipulate the state
        toggleLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        toggleBackDrop(state, action: PayloadAction<boolean>) {
            state.showBackDrop = action.payload;
        },
        toggleButtonLoader(state, action: PayloadAction<boolean>) {
            state.showButtonLoader = action.payload;
        },
        setCountries(state, action: PayloadAction<ICountry[]>) {
            state.country = action.payload;
        },
    }
})

export default GlobalStateSLice.reducer;
