import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { TLaravelError, TLaravelResponse } from "../../types/utils/laravel";
import { useTranslation } from "react-i18next";
import { ChangeEvent, FormEvent, useState } from "react";
import {
  checkImageSize,
  errorResponseToast,
  toastFallback,
} from "../../utils/functions";
import { useAtom } from "jotai";
import { roleAtom, userAtom } from "../../store/user";
import { TUser } from "../../types/user";
import { TClientNotification } from "../../types/client";
import { zodRequiredString } from "../../utils/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const useProfileDetails = () => {
  const { t } = useTranslation();
  const [birthDate, setBirthDate] = useState<string>("");
  const [user, setUser] = useAtom(userAtom);
  const [role] = useAtom(roleAtom);
  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => {
    setIsEditing((_prev) => (_prev = !isEditing));
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(new FormData(e.currentTarget));
  };
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse<TUser>>("update-profile", {
        ...Object.fromEntries(form),
        birth_date: birthDate,
      });
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        setUser((_prev) => (_prev = res.data));
        toastFallback(res.message, "success");
      }
    },
    onError: errorResponseToast,
  });
  return {
    handleSubmit,
    t,
    toggleEdit,
    isEditing,
    user,
    setBirthDate,
    birthDate,
    isPending,
    role,
  };
};

export const useProfileNotification = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryKey: ["client-notifications"],
    queryFn: async () => {
      const res =
        await axios.get<TLaravelResponse<Array<TClientNotification>>>(
          "notifications",
        );
      return res.data;
    },
  });
  return { t, data, isLoading };
};

export const useProfileToggleNotification = () => {
  const { data, mutate, isPending } = useMutation({
    mutationFn: async (id: number) => {
      const res = await axios.post<TLaravelResponse<"">>(
        `notifications/${id}/toggle`,
      );
      return res.data;
    },
    onSuccess: (res) => toastFallback(res.message, "success", "Success"),
    onError: errorResponseToast,
  });
  return { data, mutate, isPending };
};

export const useProfileChangePassword = () => {
  const { t } = useTranslation();
  const schema = z
    .object({
      current_password: zodRequiredString(
        "password",
        t("userProfile.profileSettings.changePassword.passwordRequired"),
      ),
      password: zodRequiredString(
        "password",
        t("userProfile.profileSettings.changePassword.required"),
      )
        .min(
          8,
          t("userProfile.profileSettings.changePassword.requiredCharacters"),
        )
        .regex(
          new RegExp(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-.#?!@$%^&_*=()+]).{8,}$/,
          ),
          t("userProfile.profileSettings.changePassword.specialCharacter"),
        ),
      password_confirmation: zodRequiredString(
        "confirmation",
        t("userProfile.profileSettings.changePassword.required"),
      ),
    })
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: "custom",
          message: t(
            "userProfile.profileSettings.changePassword.passwordMatch",
          ),
          path: ["password_confirmation"],
        });
      }
    });
  type TSchema = z.infer<typeof schema>;
  const [resError, setResError] = useState<TLaravelError<TSchema> | null>(null);
  const {
    register,
    handleSubmit: submit,
    reset,
    formState: { errors },
  } = useForm<TSchema>({
    resolver: zodResolver(schema),
  });
  const [disabled, setDisabled] = useState(true);
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: TSchema) => {
      const res = await axios.post<TLaravelResponse<null>>(
        "change-password",
        form,
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        reset();
        setDisabled((_prev) => (_prev = true));
        toastFallback(res.message, "success", "Success");
      }
    },
    onError: (err: AxiosError<TLaravelError>) => {
      setResError((_prev) => (_prev = err.response?.data ?? null));
      errorResponseToast(err);
    },
  });
  const handleSubmit = submit((data) => mutate(data));
  const errorMessage = (field: keyof typeof errors) => errors[field]?.message;
  const handleChange = () => {
    if (disabled) setDisabled((_prev) => (_prev = false));
  };
  return {
    handleSubmit,
    t,
    disabled,
    handleChange,
    register,
    resError,
    errorMessage,
    isPending,
  };
};

export const useProfileImage = () => {
  const [user, setUser] = useAtom(userAtom);
  const [disabled, setDisabled] = useState(true);
  const [url, setUrl] = useState<URL | false>(false);
  const { mutate, isPending } = useMutation({
    mutationFn: async (form: FormData) => {
      const res = await axios.post<TLaravelResponse<TUser>>(
        "update-profile-picture",
        form,
      );
      return res.data;
    },
    onError: errorResponseToast,
    onSuccess: (res) => {
      if (res.success) {
        setUser((_prev) => (_prev = res.data));
        setDisabled((_prev) => (_prev = true));
        toastFallback(res.message, "success", "Profile changed");
      }
    },
  });
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].type.startsWith("image/")) {
        const image = await checkImageSize(e.target.files[0], 100);
        setUrl((_prev) => (_prev = image));
        if (image) {
          setDisabled((_prev) => (_prev = false));
        } else {
          setDisabled((_prev) => (_prev = true));
          toastFallback("Image must be less than 100x100px", "warning");
        }
      } else {
        setDisabled((_prev) => (_prev = true));
        toastFallback("Not a valid image type", "warning");
      }
    }
  };
  return {
    mutate,
    url,
    user,
    isPending,
    disabled,
    handleChange,
  };
};
