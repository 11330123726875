import SvgTeam from "../../../../assets/svgs/icons/SvgTeam";

const List = () => {
  return (
    <section id="referral-list" className="h-full w-full lg:col-span-2">
      <h3 className="section-title mb-6">Your Referrals</h3>
      <div className="grid h-full w-full place-items-center text-primary-gray">
        <div>
          <SvgTeam className="mx-auto mb-4 h-40 w-40 fill-primary-gray/50" />
          <p>You don't have referrals yet :(</p>
        </div>
      </div>
    </section>
  );
};
export default List;
