import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { setupStore } from "./store/store";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { AxiosMiddleware } from "./utils/middleware";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});
const store = setupStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false, staleTime: 60_000 },
  },
});
AxiosMiddleware.boot();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </GoogleOAuthProvider>,
);

reportWebVitals();
