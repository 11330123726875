import SvgBell from "../../../assets/svgs/icons/SvgBell";
import { useNotifications } from "./_hooks";

const NotificationsPage = () => {
  const { data, t } = useNotifications();
  return (
    <section id="business-notifications">
      <h3 className="section-title mb-4">
        {t("dashboardSideBar.sidebar.notifications")}
      </h3>
      {data?.success ? (
        data.data.length > 0 && (
          <ul className="grid cursor-none gap-y-4">
            {data.data.map(({ id, notification }) => {
              return (
                <li key={`business_notification_${id}`}>
                  <article className="hover-link group grid items-center gap-4 rounded-3xl border border-opacity-50 p-4 shadow-lg hover:bg-primary-orange/75 lg:grid-cols-[3.5rem_auto] [&_ol]:list-decimal [&_ol]:pl-4">
                    <SvgBell className="h-14 w-14 rounded-full bg-primary-orange/50 fill-primary-orange p-3 duration-200 group-hover:bg-white" />
                    <div
                      dangerouslySetInnerHTML={{ __html: notification }}
                    ></div>
                  </article>
                </li>
              );
            })}
          </ul>
        )
      ) : (
        <p>No new notifications...</p>
      )}
    </section>
  );
};

export default NotificationsPage;