import { TSvgComponent } from "../../../types/utils/component";

const SvgLock: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 14 15"
      fill="#131C23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.5 5.33398V6.73398C11.2067 6.69398 10.8733 6.67398 10.5 6.66732V5.33398C10.5 3.23398 9.90667 1.83398 7 1.83398C4.09333 1.83398 3.5 3.23398 3.5 5.33398V6.66732C3.12667 6.67398 2.79333 6.69398 2.5 6.73398V5.33398C2.5 3.40065 2.96667 0.833984 7 0.833984C11.0333 0.833984 11.5 3.40065 11.5 5.33398Z" />
      <path d="M11.5002 6.73268C11.2068 6.69268 10.8735 6.67268 10.5002 6.66602H3.50016C3.12683 6.67268 2.7935 6.69268 2.50016 6.73268C0.800163 6.93935 0.333496 7.77268 0.333496 9.99935V11.3327C0.333496 13.9993 1.00016 14.666 3.66683 14.666H10.3335C13.0002 14.666 13.6668 13.9993 13.6668 11.3327V9.99935C13.6668 7.77268 13.2002 6.93935 11.5002 6.73268ZM4.80683 11.1393C4.68016 11.2593 4.50683 11.3327 4.3335 11.3327C4.24683 11.3327 4.16016 11.3127 4.08016 11.2793C3.9935 11.246 3.92683 11.1993 3.86016 11.1393C3.74016 11.0127 3.66683 10.8393 3.66683 10.666C3.66683 10.5793 3.68683 10.4927 3.72016 10.4127C3.7535 10.3327 3.80016 10.2593 3.86016 10.1927C3.92683 10.1327 3.9935 10.086 4.08016 10.0527C4.32683 9.94602 4.62016 10.006 4.80683 10.1927C4.86683 10.2593 4.9135 10.3327 4.94683 10.4127C4.98016 10.4927 5.00016 10.5793 5.00016 10.666C5.00016 10.8393 4.92683 11.0127 4.80683 11.1393ZM7.6135 10.9193C7.58016 10.9993 7.5335 11.0727 7.4735 11.1393C7.34683 11.2593 7.1735 11.3327 7.00016 11.3327C6.82016 11.3327 6.6535 11.2593 6.52683 11.1393C6.46683 11.0727 6.42016 10.9993 6.38683 10.9193C6.3535 10.8393 6.3335 10.7527 6.3335 10.666C6.3335 10.486 6.40683 10.3193 6.52683 10.1927C6.7735 9.94602 7.22016 9.94602 7.4735 10.1927C7.5935 10.3193 7.66683 10.486 7.66683 10.666C7.66683 10.7527 7.64683 10.8393 7.6135 10.9193ZM10.1402 11.1393C10.0135 11.2593 9.84016 11.3327 9.66683 11.3327C9.4935 11.3327 9.32016 11.2593 9.1935 11.1393C9.0735 11.0127 9.00016 10.846 9.00016 10.666C9.00016 10.486 9.0735 10.3193 9.1935 10.1927C9.44683 9.94602 9.8935 9.94602 10.1402 10.1927C10.1668 10.226 10.1935 10.2593 10.2202 10.2993C10.2468 10.3327 10.2668 10.3727 10.2802 10.4127C10.3002 10.4527 10.3135 10.4927 10.3202 10.5327C10.3268 10.5793 10.3335 10.626 10.3335 10.666C10.3335 10.8393 10.2602 11.0127 10.1402 11.1393Z" />
    </svg>
  );
};

export default SvgLock;
