import { TSvgComponent } from "../../../types/utils/component";

const SvgClock: TSvgComponent = (props) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="#131C23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7ZM7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90253 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
      />
    </svg>
  );
};

export default SvgClock;
