import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
type CongratulationsModalProps = {
    open: boolean;
    onClose: () => void;
};

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 6,
    width: 400,
    textAlign: "center",
    justifyItems: "center"
};
const CongratulationsModal: React.FC<CongratulationsModalProps> = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <h3 className="font-bold text-2xl"> Congratulations</h3>
                <p className="text-[#131C23]/40 font-medium"> Mail verification was successful </p>
                <div className="flex justify-center py-5">
                    <img loading="lazy" src="/assets/svgs/common/congratulations-icon.svg"/>
                </div>
                <div className="flex justify-center">
                    <p
                        className="items-center flex justify-center rounded-full bg-[#FF913F] text-white w-full my-5 py-2 cursor-pointer" onClick={onClose}
                    >
                        <span className="px-1">Continue</span>
                        <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4"/>
                    </p>
                </div>
            </Box>
        </Modal>
    );
};

export default CongratulationsModal;
