import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  TLaravelError,
  TLaravelResponse,
  TODO,
} from "../../../../types/utils/laravel";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  TEventAge,
  TEventCategory,
  TEventCreationData,
  TEventCreationDraft,
  TEventInterest,
  TEventMultiInformation,
  TEventType,
  TEventUpdate,
} from "../../../../types/event";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  eventCreationAddressAtom,
  eventCreationAgesPricesAtom,
  eventCreationDataAtom,
  eventCreationDraftAtom,
  eventCreationImagesAtom,
  eventUpdateAtom,
} from "./_atom";
import {
  checkImageSize,
  isModalOpen,
  toastFallback,
  toggleModal,
} from "../../../../utils/functions";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import usePlacesAutocomplete, {
  LatLng,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useJsApiLoader } from "@react-google-maps/api";
import { TLabel } from "../../../../types/utils/component";
import { DateObject } from "react-multi-date-picker";

export const useEventCreationData = () => {
  const [, setEventCreationData] = useAtom(eventCreationDataAtom);
  useQuery({
    queryKey: ["event-create-data"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<TEventCreationData>>(
        "business/profile/events",
      );
      if (res.data.success) {
        setEventCreationData(res.data.data);
      }
      return res.data;
    },
  });
};

export const useEventCreation = () => {
  const { t } = useTranslation();
  const [, setEdit] = useAtom(eventCreationDraftAtom);
  const [, setDraft] = useAtom(eventCreationDraftAtom);
  const [, setImages] = useAtom(eventCreationImagesAtom);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: update, isPending: isUpdatePending } = useMutation({
    mutationFn: async (data: FormData) => {
      const res = await axios.post<TLaravelResponse<TODO>>(
        `business/profile/events/${eventId}/update`,
        data,
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({ queryKey: ["events"] });
        navigate("/event-list/pending");
      }
    },
    onError: (err: AxiosError<TLaravelError>) => {
      toastFallback(err.response?.data?.message);
    },
  });
  const { mutate: create, isPending: isCreatePending } = useMutation({
    mutationFn: async (data: FormData) => {
      const res = await axios.post<TLaravelResponse<TODO>>(
        "business/profile/events",
        data,
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({ queryKey: ["events"] });
        navigate("/event-list/pending");
      }
    },
    onError: (err: AxiosError<TLaravelError>) => {
      toastFallback(err.response?.data?.message);
    },
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (eventId) {
      update(new FormData(e.currentTarget));
    } else {
      create(new FormData(e.currentTarget));
    }
  };
  const isPending = isUpdatePending || isCreatePending;
  useEffect(() => {
    setEdit(null);
    setDraft(null);
    setImages([null, null, null, null]);
  }, []);
  return { t, handleSubmit, isPending };
};

export const useEventUpdate = () => {
  const { eventId } = useParams();
  const [, setEdit] = useAtom(eventUpdateAtom);
  useQuery({
    queryKey: ["event-update", eventId],
    queryFn: async () => {
      if (eventId) {
        const res = await axios.get<TLaravelResponse<TEventUpdate>>(
          `business/profile/events/${eventId}/show`,
        );
        if (res.data.success) {
          setEdit(res.data.data);
          return res.data;
        }
      }
      return null;
    },
  });

  return { eventId };
};

export const useEventCreationDraft = () => {
  const { eventId } = useParams();
  const [draft, setDraft] = useAtom(eventCreationDraftAtom);
  useQuery({
    queryKey: ["event-create-draft"],
    queryFn: async () => {
      if (!eventId) {
        const res = await axios.get<TLaravelResponse<TEventCreationDraft>>(
          "business/profile/events/get-draft",
        );
        if (res.data.success) {
          setDraft(res.data.data);
          console.log(draft);
          if (!res.data.data) {
            toast.warning("No draft found");
          }
        } else {
          toast.error("Failed to fetch draft");
        }
        return res.data;
      }
      return null;
    },
  });
};

export const useEventCreationSaveDraft = () => {
  const { eventId } = useParams();
  const { mutate } = useMutation({
    mutationFn: async () => {
      const form = document.getElementById("event-creation-form");
      if (form && !eventId) {
        const formData = new FormData(form as HTMLFormElement);
        const res = await axios.post<TLaravelResponse<TEventCreationDraft>>(
          "business/profile/events/save-draft",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        );
        return res.data;
      }
      return null;
    },
    onSuccess: () => {
      toastFallback("Draft saved", "success");
    },
    onError: () => {
      toastFallback("Failed to save draft", "error");
    },
  });
  return { mutate };
};

export const useEventCreationSaveDraftTimer = () => {
  const { mutate } = useEventCreationSaveDraft();
  const { eventId } = useParams();
  useEffect(() => {
    if (!eventId) {
      const interval = setInterval(
        () => {
          mutate();
        },
        process.env.REACT_APP_EVENT_CREATION_DRAFT_TIMER
          ? parseInt(process.env.REACT_APP_EVENT_CREATION_DRAFT_TIMER) * 1000
          : 90000,
      );
      return () => clearInterval(interval);
    }
    //eslint-disable-next-line
  }, []);
};

export const useEventCreationDates = () => {
  const { data } = useQuery({
    queryKey: ["event-create-dates"],
    queryFn: async () => {
      const res = await axios.get<TLaravelResponse<Array<string>>>(
        "business/profile/events/generate-dates",
      );
      return res.data;
    },
  });
  return { data };
};

export const useEventCreationType = () => {
  const { pathname } = useLocation();
  const [edit] = useAtom(eventUpdateAtom);
  return edit?.duration_option || pathname.split("/")[2].replace("-", "_");
};

export const useEventCreationAge = () => {
  const { t } = useTranslation();
  const [draft] = useAtom(eventCreationDraftAtom);
  const [data] = useAtom(eventCreationDataAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const [newAge, setNewAge] = useState({
    label: "",
    min_age: "",
    max_age: "",
  });
  const [ages, setAges] = useState<
    TEventCreationData["ages"] | TEventUpdate["ages"]
  >([]);
  useEffect(() => {
    setAges(edit?.ages ?? data?.ages ?? []);
    if (edit) setPrices(edit.ages.map((i) => i.price) || []);
    if (draft?.ages)
      setPrices(draft?.ages.map((i) => parseFloat(i.price)) || []);
  }, [data, draft, edit]);
  const handleOpenModal = () => {
    toggleModal("event-creation-age");
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof TEventAge,
  ) => {
    const age = { ...newAge, [key]: e.target.value };
    setNewAge(age);
  };
  const { mutate } = useMutation({
    mutationFn: async (age: {
      label: string;
      min_age: string;
      max_age: string;
    }) => {
      const res = await axios.post<TLaravelResponse<TEventAge>>(
        "business/profile/events/ages",
        age,
      );
      return res.data;
    },
    onSuccess: (res) => {
      if (res.success) {
        setAges([...ages, res.data]);
        toggleModal("event-creation-age");
        setNewAge({
          label: "",
          min_age: "",
          max_age: "",
        });
      }
    },
    onError: (err: AxiosError<TLaravelError>) => {
      toastFallback(err.response?.data?.message);
    },
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (newAge.label === "" || newAge.min_age === "" || newAge.max_age === "") {
      toast.error("Please fill all fields");
      return;
    }
    if (newAge.min_age > newAge.max_age) {
      toast.error("Min age must be less than max age");
      return;
    }
    mutate(newAge);
  };
  const [, setPrices] = useAtom(eventCreationAgesPricesAtom);
  const handleUpdatePrices = () => {
    const array: number[] = [];
    document.querySelectorAll("input[data-id='age-input']").forEach((el) => {
      (el as HTMLInputElement).value &&
        array.push(parseFloat((el as HTMLInputElement).value));
    });
    setPrices(array);
  };
  return {
    t,
    edit,
    data,
    draft,
    ages,
    handleChange,
    handleSubmit,
    handleOpenModal,
    newAge,
    handleUpdatePrices,
  };
};

export const useEventCreationTypesCategories = () => {
  const [fields, setFields] = useState<{
    event_types?: TEventType[];
    categories?: TEventCategory[];
  }>({});
  const [draft] = useAtom(eventCreationDraftAtom);
  const [data] = useAtom(eventCreationDataAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const handleChangeTypes = (types: unknown) => {
    setFields({
      ...fields,
      event_types: types as TEventType[],
    });
  };
  const handleChangeCategories = (categories: unknown) => {
    setFields({
      ...fields,
      categories: categories as TEventCategory[],
    });
  };
  const categories = (edit ?? draft)?.categories?.map(
    (cat) => data?.categories.find((c) => c.id === parseInt(cat)),
  );
  const types = (edit ?? draft)?.event_types?.map(
    (type) => data?.types.find((t) => t.id === parseInt(type)),
  );
  useEffect(() => {
    setFields({
      ...fields,
      event_types: (types as TEventType[]) ?? [],
      categories: (categories as TEventCategory[]) ?? [],
    });
    //eslint-disable-next-line
  }, [draft, edit, data]);
  return { data, fields, handleChangeTypes, handleChangeCategories };
};

export const useEventCreationImages = () => {
  const { t } = useTranslation();
  const [images, setImages] = useAtom(eventCreationImagesAtom);
  const handleImageChange = async (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const file = e.target.files?.[0];
    const array = [...images];
    if (!file) {
      array[index] = null;
      setImages(array);
      return;
    }
    if (file.size > 1024 * 1024 * 3) {
      toast.warning("Image size must be less than 3MB");
      return;
    }
    if (!file.type.startsWith("image/")) {
      toast.warning("Please provide a valid image");
      return;
    }
    const valid = await checkImageSize(file, 500);
    if (!valid) toast.warning("Recommended size is 500x500");
    const url = URL.createObjectURL(file);
    if (url) {
      array[index] = {
        image: file,
        order: index,
        url: url,
      };
      setImages(array);
    }
  };
  const handleAdd = () => {
    if (images[images.length - 1] === null) {
      toast.warning("Please fill all images first");
      return;
    }
    setImages((_prev) => [..._prev, null]);
  };
  const handleRemove = (_?: MouseEvent<HTMLButtonElement>, index?: number) => {
    const array = [...images];
    if (index) {
      array[index] = null;
    } else {
      array.splice(-1, 1);
    }
    setImages(array);
  };
  const [edit] = useAtom(eventUpdateAtom);
  useEffect(() => {
    edit?.images && setImages(edit?.images);
  }, [edit]);
  return { t, images, handleImageChange, handleAdd, handleRemove };
};

export const useEventCreationVideo = () => {
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoUrl(e.target.value);
    setError(null);
    const url = new URL(e.target.value);
    if (
      !e.target.value?.includes("https://youtu.be") &&
      !e.target.value?.includes("https://www.youtube.com/watch?") &&
      !e.target.value?.includes("https://www.youtube.com/embed/")
    ) {
      setError("Please provide a youtube link");
      return;
    }
    if (!e.target.value?.includes("youtu.be") && url.searchParams.get("v")) {
      setVideoUrl(`https://www.youtube.com/embed/${url.searchParams.get("v")}`);
    } else if (e.target.value?.includes("youtu.be")) {
      setVideoUrl(`https://www.youtube.com/embed${url.pathname}`);
    }
  };
  useEffect(() => {
    if (draft?.video_url) setVideoUrl(draft?.video_url);
    if (edit?.video_url) setVideoUrl(edit?.video_url);
  }, [draft, edit]);
  return { t, videoUrl, handleVideoChange, error };
};

export const useEventCreationCard = (
  name: "highlights" | "includes" | "important_information",
) => {
  const [drafts] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const [isEditing, setIsEditing] = useState(true);
  const [array, setArray] = useState<string[]>([]);
  useEffect(() => {
    setArray(edit?.[name] ?? drafts?.[name] ?? []);
  }, [drafts, edit, name]);
  const { t } = useTranslation();
  const handleRemove = (_: MouseEvent<HTMLButtonElement>, index: number) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    setArray(newArray);
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setArray((prev) => [
      ...prev.slice(0, index),
      e.target.value,
      ...prev.slice(index + 1),
    ]);
  };
  const handleAdd = () => {
    setArray((prev) => [...prev, ""]);
  };
  const { mutate } = useEventCreationSaveDraft();
  const handleSave = () => {
    const arr: string[] = [];
    if (isEditing) {
      array.forEach((item) => {
        if (item !== "") {
          arr.push(item);
        }
      });
      if (arr.length > 0) {
        setArray(arr);
        edit && mutate();
      } else {
        toast.warning("Please fill all fields");
      }
    }
    if (isEditing && arr.length < 1) {
      return;
    }
    setIsEditing((_prev) => !_prev);
  };
  return {
    t,
    isEditing,
    array,
    handleRemove,
    handleChange,
    handleAdd,
    handleSave,
  };
};

export const useEventCreationIntrests = () => {
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const [data] = useAtom(eventCreationDataAtom);
  const [fields, setFields] = useState<TEventInterest[]>([]);
  const handleChange = (e: unknown) => {
    setFields(e as TEventInterest[]);
  };
  useEffect(() => {
    const draftInterests = (edit ?? draft)?.interests?.map(
      (int) => data?.interests.find((i) => i.id === parseInt(int)),
    );
    setFields((_prev) => (_prev = (draftInterests as TEventInterest[]) ?? []));
  }, [draft, data, edit]);
  return { data, fields, handleChange };
};

export const useEventCreationFAQs = () => {
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const { t } = useTranslation();
  const [faqs, setFaqs] = useState<{ question: string; answer: string }[]>([]);
  const handleSave = () => {
    const q = document.getElementById("faq-question") as HTMLInputElement;
    const a = document.getElementById("faq-answer") as HTMLTextAreaElement;
    if (!q.value || !a.value) {
      toast.warning("Please fill both question and answer fields");
      return;
    }
    const array = [...faqs];
    array.push({
      question: q.value,
      answer: a.value,
    });
    setFaqs(array);
    a.value = "";
    q.value = "";
  };
  useEffect(() => {
    setFaqs(edit?.faqs ?? draft?.faqs ?? []);
  }, [draft, edit]);
  return { handleSave, faqs, setFaqs, t };
};

export const useEventCreationFAQ = (
  faqs: { question: string; answer: string }[],
  setFaqs: Dispatch<SetStateAction<{ question: string; answer: string }[]>>,
  index: number,
) => {
  const { mutate } = useEventCreationSaveDraft();
  const [isEditing, setEditing] = useState(false);
  const handleEdit = () => {
    setEditing(!isEditing);
    isEditing && mutate();
  };
  const handleDelete = () => {
    const newArray = [...faqs];
    newArray.splice(index, 1);
    setFaqs(newArray);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newArray = [...faqs];
    newArray[index] = {
      ...faqs[index],
      question: e.target.value,
    };
    setFaqs(newArray);
  };
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newArray = [...faqs];
    newArray[index] = {
      ...faqs[index],
      answer: e.target.value,
    };
    setFaqs(newArray);
  };
  return {
    handleEdit,
    handleDelete,
    handleInputChange,
    handleTextAreaChange,
    isEditing,
  };
};

export const useEventCreationServices = () => {
  const { t } = useTranslation();
  const [fees, setFees] = useState<{ title: string; price: string }[]>([]);
  const handleAdd = () => {
    setFees([...fees, { title: "", price: "" }]);
  };
  const handleChangeTitle = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const array = [...fees];
    array[index].title = e.target.value;
    setFees(array);
  };
  const handleChangePrice = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const array = [...fees];
    array[index].price = e.target.value;
    setFees(array);
  };
  const handleRemoveFee = (_: MouseEvent<HTMLButtonElement>, index: number) => {
    const array = [...fees];
    array.splice(index, 1);
    setFees(array);
  };
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  useEffect(() => {
    if (edit?.event_fees) setFees(edit.fees);
    if (draft?.extra_fees) setFees(draft.extra_fees);
  }, [draft]);
  return {
    t,
    handleAdd,
    fees,
    handleChangeTitle,
    handleChangePrice,
    handleRemoveFee,
  };
};

export const useEventCreationChoices = () => {
  const { t } = useTranslation();
  const [choice, setChoice] = useState<
    "quantity" | "quantity_per_duration" | null
  >(null);
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (choice === e.target.value) setChoice(null);
    else setChoice(e.target.value as typeof choice);
  };
  useEffect(() => {
    if (edit?.quantity ?? draft?.quantity) setChoice("quantity");
    if (edit?.quantity_per_duration ?? draft?.quantity_per_duration)
      setChoice("quantity_per_duration");
  }, [draft, edit]);
  return { t, handleCheck, choice, draft, edit };
};

export const useEventCreationTime = () => {
  const eventType = useEventCreationType();
  const [draft] = useAtom(eventCreationDraftAtom);
  const options = [
    { label: "Hours", value: "h" },
    { label: "Days", value: "d" },
  ];
  const [duration, setDuration] = useState("");
  const [durationType, setDurationType] = useState(options[0]);
  const [startWeek, setStartWeek] = useState<TLabel>(null);
  const [endWeek, setEndWeek] = useState<TLabel>(null);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [days, setDays] = useState<Array<DateObject | string>>([]);
  const week = [
    {
      value: "0",
      label: "Sunday",
    },
    {
      value: "1",
      label: "Monday",
    },
    {
      value: "2",
      label: "Tuesday",
    },
    {
      value: "3",
      label: "Wednesday",
    },
    {
      value: "4",
      label: "Thursday",
    },
    {
      value: "5",
      label: "Friday",
    },
  ];
  let remainder: TLabel[] = [];
  if (startWeek) {
    remainder = week.filter(
      (item) => parseFloat(item.value) > parseFloat(startWeek.value),
    );
    remainder = [
      ...remainder,
      {
        value: "6",
        label: "Saturday",
      },
    ];
  }
  const { mutate } = useMutation({
    mutationFn: async () => {
      const res = await axios.post<TLaravelResponse<string[]>>(
        "business/profile/events/generate-dates",
        { start_weekday: startWeek?.value, end_weekday: endWeek?.value },
      );
      if (res.data.success) setDays(res.data.data as unknown as DateObject[]);
      return res.data;
    },
  });
  const [edit] = useAtom(eventUpdateAtom);
  useEffect(() => {
    if (eventType === "custom") {
      setStartWeek(null);
      setEndWeek(null);
      setDays([]);
    }
  }, [eventType]);
  useEffect(() => {
    console.log(edit?.working_days);
    setDays(edit?.working_days ?? []);
    setDuration(edit?.duration ?? draft?.duration ?? "");
    setDurationType(
      options.find(
        (o) => o.value === edit?.duration_type || draft?.duration_type,
      ) ?? options[0],
    );
    setStartWeek(
      week.find(
        (w) => w.value === edit?.start_weekday || draft?.start_weekday,
      ) ?? null,
    );
    setEndWeek(
      week.find((w) => w.value === edit?.end_weekday || draft?.end_weekday) ??
        null,
    );
    setStartTime(edit?.start_time ?? draft?.start_time ?? "");
    setEndTime(edit?.end_time ?? draft?.end_time ?? "");
  }, [draft, edit]);
  const possibleNumber =
    parseInt(duration) < 1
      ? 1
      : Math.floor(
          (parseInt(endTime.split(":")[0]) -
            parseInt(startTime.split(":")[0])) /
            parseInt(duration),
        );
  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(e.target.value) < 1) {
      toast.warning("Please enter a valid duration");
      setDuration("1");
      return;
    }
    setDuration(e.target.value);
  };
  const handleDurationTypeChange = (e: TLabel) => {
    setDurationType(e as (typeof options)[0]);
  };
  const handleStartWeekChange = (e: TLabel) => {
    setEndWeek(null);
    setDays([]);
    setStartWeek(e as TLabel);
  };
  const handleEndWeekChange = (e: TLabel) => {
    setEndWeek(e as TLabel);
    if (e) {
      mutate();
    }
  };
  useEffect(() => {
    if (possibleNumber < 0) {
      toast.error("Please pick a valid time");
    } else if (possibleNumber < 1) {
      toast.warning("Time selected is too short");
    }
  }, [possibleNumber]);
  const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
  };
  const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
  };
  const handleCalendarChange = (dates: DateObject[]) => {
    if (Array.isArray(dates)) {
      setDays(dates);
    }
  };
  return {
    duration,
    handleDurationChange,
    durationType,
    options,
    handleDurationTypeChange,
    eventType,
    week,
    startWeek,
    handleStartWeekChange,
    remainder,
    endWeek,
    handleEndWeekChange,
    startTime,
    handleStartTimeChange,
    endTime,
    handleEndTimeChange,
    possibleNumber,
    days,
    handleCalendarChange,
  };
};

export const useEventCreationAddress = () => {
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(isModalOpen("event-creation-map"));
  }, [isOpen]);
  const openModal = () => {
    setIsOpen(true);
    toggleModal("event-creation-map");
  };
  const [defaultLocation, setDefaultLocation] = useState<LatLng>({
    lat: 34.01207890141392,
    lng: 100.65496676648927,
  });
  const [selectedLocation, setSelectedLocation] = useAtom(
    eventCreationAddressAtom,
  );
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setDefaultLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
      },
      () => alert("Please enable location permission to view events near you"),
    );
  }, []);
  const handleChangeLocation = (lat: number, lng: number) => {
    setSelectedLocation({ lng, lat });
  };
  useEffect(() => {
    if (
      (edit?.latitude && edit.longitude) ||
      (draft?.latitude && draft?.longitude)
    ) {
      setSelectedLocation({
        lng: parseFloat((edit?.longitude || draft?.longitude) ?? ""),
        lat: parseFloat((edit?.latitude || draft?.latitude) ?? ""),
      });
      setDefaultLocation({
        lng: parseFloat((edit?.longitude || draft?.longitude) ?? ""),
        lat: parseFloat((edit?.latitude || draft?.latitude) ?? ""),
      });
    }
  }, [draft, edit]);
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "GOOGLE_MAP",
  });
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
  });
  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const selectedLocation = { lat, lng };
      setDefaultLocation(selectedLocation);
      const countryComponent = results[0].address_components.find((component) =>
        component.types.includes("country"),
      );
      if (countryComponent) {
        const country = countryComponent.long_name;
        await getCountryCenter(country);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  async function getCountryCenter(country: string) {
    try {
      const results = await getGeocode({ address: country });
      const { lat, lng } = await getLatLng(results[0]);
      setSelectedLocation({ lat, lng });
    } catch (error) {
      console.error("Error getting coordinates for country:", error);
      return null;
    }
  }
  return {
    selectedLocation,
    openModal,
    isLoaded,
    handleSelect,
    value,
    setValue,
    data,
    isOpen,
    defaultLocation,
    handleChangeLocation,
  };
};

export const useEventCreationAboutStops = () => {
  const [draft] = useAtom(eventCreationDraftAtom);
  const [multi, setMulti] = useState<Array<TEventMultiInformation>>([]);
  const handleSave = () => {
    const t = document.getElementById("multi-title") as HTMLInputElement;
    const d = document.getElementById(
      "multi-description",
    ) as HTMLTextAreaElement;
    if (!t.value || !d.value) {
      toast.warning("Please fill both question and answer fields");
      return;
    }
    const array = [...multi];
    array.push({
      location_title: t.value,
      description: d.value,
    });
    setMulti(array);
    t.value = "";
    d.value = "";
  };
  useEffect(() => {
    setMulti(draft?.multi_information ?? []);
  }, [draft]);
  return { handleSave, multi, setMulti };
};

export const useEventCreationAboutStop = (
  multi: TEventMultiInformation[],
  setMulti: Dispatch<SetStateAction<TEventMultiInformation[]>>,
  index: number,
) => {
  const { mutate } = useEventCreationSaveDraft();
  const [isEditing, setEditing] = useState(false);
  const handleEdit = () => {
    setEditing(!isEditing);
    isEditing && mutate();
  };
  const handleDelete = () => {
    const newArray = [...multi];
    newArray.splice(index, 1);
    setMulti(newArray);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newArray = [...multi];
    newArray[index] = {
      ...multi[index],
      location_title: e.target.value,
    };
    setMulti(newArray);
  };
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newArray = [...multi];
    newArray[index] = {
      ...multi[index],
      description: e.target.value,
    };
    setMulti(newArray);
  };
  return {
    handleEdit,
    handleDelete,
    handleInputChange,
    handleTextAreaChange,
    isEditing,
  };
};
