import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { eventCreationDraftAtom, eventUpdateAtom } from "../../_atom";
import CustomTextArea from "../../../../../../components/common/inputs/TextArea";
import { useEventCreationType } from "../../_hooks";
import Multi from "./components/Multi";

const About = () => {
  const { t } = useTranslation();
  const [draft] = useAtom(eventCreationDraftAtom);
  const [edit] = useAtom(eventUpdateAtom);
  const eventType = useEventCreationType();
  const isMulti = eventType === "multi_day" || eventType === "multi_stop";
  return (
    <section id="event-creation-about" className="dashboard-card gray">
      <h3 className="section-title mb-4 capitalize">
        {isMulti
          ? "Add details about each location"
          : t("businessProfile.eventCreation.aboutEvents.title")}
      </h3>
      {isMulti ? (
        <Multi />
      ) : (
        <CustomTextArea
          required
          name="description"
          defaultValue={edit?.description ?? draft?.description}
          placeholder="Description"
        />
      )}
    </section>
  );
};

export default About;
